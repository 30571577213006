import axiosInstance from '../../../../../utils/axios';

import { ISendDiscordTagService } from './interfaces';
import { handleError } from './errors';

class SendDiscordTagService implements ISendDiscordTagService {
  async sendDiscordTag(
    token: string,
    userUUID: string,
    discordTag: string,
  ): Promise<void> {
    try {
      await axiosInstance.post(
        `/discord/user/${userUUID}/set-available-roles`,
        { discordTag },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new SendDiscordTagService();
