import { UpdatePermissionsResponse } from './interfaces';
import axiosInstance from '../../../../utils/axios'; // Assurez-vous que le chemin est correct
import { AxiosError } from 'axios';
import { handleApiError } from './errors'; // Pour gérer les erreurs

export const getUserPermissions = async (
  userUUID: string,
  token: string,
): Promise<UpdatePermissionsResponse> => {
  try {
    const response = await axiosInstance.get<UpdatePermissionsResponse>(
      `permissions/users/${userUUID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    // Gérez les erreurs avec une fonction dédiée
    handleApiError(error as AxiosError);
    throw error;
  }
};
