import { toast } from 'react-toastify';
import axiosInstance from '../../../utils/axios';

import {
  AffiliatePayoutResponse,
  PurchasedChallengesResponse,
  TradingPayoutResponse,
  WithdrawHistoryResponse,
} from './interface';

export const fetchPurchasedChallenges = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<PurchasedChallengesResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<PurchasedChallengesResponse>(
      `/payments/all-sales?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );

    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des purchased challenges');
  }
};

export const fetchTradingPayout = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<TradingPayoutResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<TradingPayoutResponse>(
      `/payout/treated?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );

    return response.data;
  } catch (error) {
    throw new Error(
      'Erreur lors de la récupération des trading treated payouts',
    );
  }
};

export const fetchAffiliatePayout = async (
  accessToken: string,
  page: number,
  size: number,
  referral: boolean,
): Promise<AffiliatePayoutResponse> => {
  try {
    const response = await axiosInstance.get<AffiliatePayoutResponse>(
      `/affiliations/payout-requests/processed?page=${page}&size=${size}&referral=${referral}`,
      {
        headers: { Authorization: accessToken },
      },
    );

    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des affiliation payouts');
  }
};

export const fetchWithdrawHistory = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<WithdrawHistoryResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<WithdrawHistoryResponse>(
      `/withdraw/history?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );

    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des withdraw history');
  }
};

export const downloadCertificate = async (accessToken: string, id: number) => {
  try {
    const response = await axiosInstance.get(`/certificate/payout/${id}`, {
      headers: { Authorization: accessToken },
      responseType: 'json',
    });

    if (response.status === 200) {
      const imageBase64 = response.data.image;
      const byteCharacters = atob(imageBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const imageBlob = new Blob([byteArray], { type: 'image/png' });
      const url = window.URL.createObjectURL(imageBlob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'certificate.png');
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  } catch (error: any) {
    if (error.response.status === 404) {
      toast.error(`Certificate not found`, {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 400) {
      toast.error(`This payout is not processed`, {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 500) {
      toast.error(`Unknown error`, {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  }
};

export const exportWithdrawHistoryToXLS = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<void> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    params.append('export', 'true');

    const response = await axiosInstance.get('/withdraw/history', {
      params: params,
      headers: { Authorization: accessToken },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Erreur lors de l'exportation du withdraw history", error);
  }
};

export const exportChallengesPurchasedToXLS = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<void> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    params.append('export', 'true');

    const response = await axiosInstance.get('/payments/all-sales', {
      params: params,
      headers: { Authorization: accessToken },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(
      "Erreur lors de l'exportation des purchased challenges",
      error,
    );
  }
};

export const exportTradingPayoutToXLS = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<void> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    params.append('export', 'true');

    const response = await axiosInstance.get('/payout/treated', {
      params: params,
      headers: { Authorization: accessToken },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Erreur lors de l'exportation des trading payouts", error);
  }
};
