import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../context/AuthContext/AuthContext';

import useTabNavigation from './hooks/useNavigationTabs';

import { fetchKYC } from './services/get/kyc/getKYC';

import ProfileTabs from './components/ProfileTabs/ProfileTabs';
import ProfileContent from './components/ProfileContent/ProfileContent';
import KYCContent from './components/KYCContent/KYCContent';

import { Box } from '@mui/material';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const Profile: React.FC = () => {
  const { currentTab, handleTabChange } = useTabNavigation();
  const [isKYCCompleted, setIsKYCCompleted] = useState<boolean | null>(null);

  const { tokens } = useAuth();

  useDocumentTitle('Profile & KYC');

  useEffect(() => {
    const getKYCStatus = async () => {
      try {
        if (tokens && tokens.accessToken) {
          const kycResponse = await fetchKYC(tokens.accessToken);
          setIsKYCCompleted(kycResponse.kyccompleted);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du statut KYC', error);
      }
    };

    getKYCStatus();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <ProfileTabs
        currentTab={currentTab}
        handleTabChange={handleTabChange}
        isKYCCompleted={isKYCCompleted}
      />
      <Box sx={{ marginTop: 4 }}>
        {currentTab === 0 && <ProfileContent />}
        {currentTab === 1 && <KYCContent />}
      </Box>
    </Box>
  );
};

export default Profile;
