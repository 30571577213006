import { Palette } from './type';

export const green: Palette = {
  50: '#dff6ec',
  100: '#b2e9d0',
  200: '#7adab1',
  300: '#1fcc92',
  400: '#00c07b',
  500: '#00b465',
  600: '#00a45a',
  700: '#00924d',
  800: '#008040',
  900: '#00612a',
};

export const yellow: Palette = {
  50: '#fffde7',
  100: '#fff9c4',
  200: '#fff59d',
  300: '#fef175',
  400: '#fcec55',
  500: '#f9e635',
  600: '#fdd935',
  700: '#fbc12d',
  800: '#f9a925',
  900: '#f58016',
};

export const red: Palette = {
  50: '#ffeaed',
  100: '#ffcbcf',
  200: '#f59694',
  300: '#ec6c6a',
  400: '#F64644',
  500: '#fb3225',
  600: '#ec2526',
  700: '#da1620',
  800: '#cd0618',
  900: '#be000a',
};
