import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  AffiliateUserContent,
  User,
} from '../../../services/admin/users/interface';
import {
  exportActiveUsersToXLS,
  exportAffiliateClientsToXLS,
  exportFundedUsersToXLS,
  exportUsersToXLS,
  fetchActiveClients,
  fetchAffiliateClients,
  fetchFundedClients,
  fetchUsers,
} from '../../../services/admin/users/users';
import { logout } from '../../../services/auth/login';
import { accountInformations } from '../../../services/admin/userAbout/userAbout';

import { useAuth } from '../../../context/AuthContext/AuthContext';
import CreateUserModal from './CreateUserModal';

import useDocumentTitle from '../../../hooks/useDocumentTitle';

import Tab from '../../../components/Tab/Tab';
import Pagination from '../../../components/Pagination/Pagination';
import Loader from '../../../components/Loader/Loader';
import AdminTable from '../../../components/Admin/AdminTable/AdminTable';
import Icon from '../../../components/userInterface/Icon/Icon';
import UserProfile from '../../../components/Admin/UserProfile/UserProfile';
import Filter, { FilterType } from '../../../components/Filter/Filter';

import classes from './_users.module.scss';

const Users = () => {
  const { tokens, isTokensLoading } = useAuth();
  useDocumentTitle('Users');

  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

  const openCreateUserModal = useCallback(
    () => setIsCreateUserModalOpen(true),
    [],
  );
  const closeCreateUserModal = useCallback(
    () => setIsCreateUserModalOpen(false),
    [],
  );

  const size = 10;
  const tableUsersData = [];
  const tableActiveUsersData = [];
  const tableFundedUsersData = [];
  const tableAffiliateUsersData = [];

  const tableColumns = [
    'Creation Date',
    'Email',
    'Phone Number',
    'Country',
    'Wallet',
    'Referred by',
    'Firstname',
    'Lastname',
    'ID',
  ];

  const affiliateUsersColumns = [
    'ID',
    'Firstname',
    'Lastname',
    'Display Name',
    'Gains',
    'MT5 Group',
    'Percentage',
    'Sales',
    'Total Amount In',
    'Total Users',
    'Type',
    'Creation Date',
    'BD ID',
  ];

  const usersFilterTypes: FilterType[] = [
    { key: 'email', type: 'string' },
    { key: 'phoneNumber', type: 'string' },
    { key: 'country', type: 'string' },
    { key: 'wallet', type: 'string' },
    { key: 'referredBy', type: 'string' },
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'uuid', type: 'string' },
    { key: 'adminId', type: 'string' },
  ];

  const affiliateUsersFilterTypes: FilterType[] = [
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'email', type: 'string' },
    { key: 'displayName', type: 'string' },
    { key: 'fromDate', type: 'date' },
    { key: 'toDate', type: 'date' },
    { key: 'adminId', type: 'string' },
    { key: 'gainsMin', type: 'string' },
    { key: 'gainsMax', type: 'string' },
    { key: 'salesMin', type: 'string' },
    { key: 'salesMax', type: 'string' },
    { key: 'country', type: 'string' },
    { key: 'metaTraderGroup', type: 'string' },
    { key: 'businessDevelopperId', type: 'string' },
    { key: 'phoneNumber', type: 'string' },
    { key: 'userUUID', type: 'string' },
  ];

  const [isLoading, setIsLoading] = useState(true);

  const [usersPage, setUsersPage] = useState<number>(0);
  const [totalUsersPages, setTotalUsersPages] = useState<number>(0);
  const [users, setUsers] = useState<User[]>([]);
  const [usersFilters, setUsersFilters] = useState<{ [key: string]: string }>(
    {},
  );
  const [usersTotalOfElements, setUsersTotalOfElements] = useState<number>(0);

  const [activeUsersPage, setActiveUsersPage] = useState<number>(0);
  const [totalActiveUsersPages, setTotalActiveUsersPages] = useState<number>(0);
  const [activeUsers, setActiveUsers] = useState<User[]>([]);
  const [activeUsersFilters, setActiveUsersFilters] = useState<{
    [key: string]: string;
  }>({});
  const [activeUsersTotalOfElements, setActiveUsersTotalOfElements] =
    useState<number>(0);

  const [fundedUsersPage, setFundedUsersPage] = useState<number>(0);
  const [totalFundedUsersPages, setTotalFundedUsersPages] = useState<number>(0);
  const [fundedUsers, setFundedUsers] = useState<User[]>([]);
  const [fundedUsersFilters, setFundedUsersFilters] = useState<{
    [key: string]: string;
  }>({});
  const [fundedUsersTotalOfElements, setFundedUsersTotalOfElements] =
    useState<number>(0);

  const [affiliateUsersPage, setAffiliateUsersPage] = useState<number>(0);
  const [totalAffiliateUsersPages, setTotalAffiliateUsersPages] =
    useState<number>(0);
  const [affiliateUsers, setAffiliateUsers] = useState<AffiliateUserContent[]>(
    [],
  );
  const [affiliateUsersFilters, setAffiliateUsersFilters] = useState<{
    [key: string]: string;
  }>({});
  const [affiliateUsersTotalOfElements, setAffiliateUsersTotalOfElements] =
    useState<number>(0);

  const [userUuid, setUserUuid] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const tabNames = [
    <div className={classes.tabNamesContainer} key="allClients">
      All Clients <span className={classes.chip}>{usersTotalOfElements}</span>
    </div>,
    <div className={classes.tabNamesContainer} key="activeClients">
      Active Clients{' '}
      <span className={classes.chip}>{activeUsersTotalOfElements}</span>
    </div>,
    <div className={classes.tabNamesContainer} key="fundedClients">
      Funded Clients{' '}
      <span className={classes.chip}>{fundedUsersTotalOfElements}</span>
    </div>,
    <div className={classes.tabNamesContainer} key="allAffiliates">
      All Affiliates{' '}
      <span className={classes.chip}>{affiliateUsersTotalOfElements}</span>
    </div>,
  ];

  for (const user of users) {
    tableUsersData.push({
      'Creation Date': user.creationDate,
      Email: user.email,
      'Phone Number': user.phoneNumber,
      Country: user.country,
      Wallet: user.wallet,
      'Referred by': user.referredBy
        ? user.referredBy.firstname +
          ' ' +
          user.referredBy.lastname +
          ' (' +
          user.referredBy.adminId +
          ')'
        : 'N/A',
      Firstname: user.firstname,
      Lastname: user.lastname,
      UUID: user.uuid,
      ID: user.adminId,
    });
  }

  for (const user of activeUsers) {
    tableActiveUsersData.push({
      'Creation Date': user.creationDate,
      Email: user.email,
      'Phone Number': user.phoneNumber,
      Country: user.country,
      Wallet: user.wallet,
      'Referred by': user.referredBy
        ? user.referredBy.firstname +
          ' ' +
          user.referredBy.lastname +
          ' (' +
          user.referredBy.adminId +
          ')'
        : 'N/A',
      Firstname: user.firstname,
      Lastname: user.lastname,
      UUID: user.uuid,
      ID: user.adminId,
    });
  }

  for (const user of fundedUsers) {
    tableFundedUsersData.push({
      'Creation Date': user.creationDate,
      Email: user.email,
      'Phone Number': user.phoneNumber,
      Country: user.country,
      Wallet: user.wallet,
      'Referred by': user.referredBy
        ? user.referredBy.firstname +
          ' ' +
          user.referredBy.lastname +
          ' (' +
          user.referredBy.adminId +
          ')'
        : 'N/A',
      Firstname: user.firstname,
      Lastname: user.lastname,
      UUID: user.uuid,
      ID: user.adminId,
    });
  }

  for (const user of affiliateUsers) {
    tableAffiliateUsersData.push({
      ID: user.adminID,
      Firstname: user.firstname,
      Lastname: user.lastname,
      'Display Name': user.displayName,
      Gains: user.gains,
      'MT5 Group': user.metaTraderGroup,
      Percentage: user.percentage,
      Sales: user.sales,
      'Total Amount In': user.totalAmountIn,
      'Total Users': user.totalUniqueUsers,
      Type: user.type,
      'Creation Date': user.creationDate,
      'BD ID': user.businessDevelopperId,
      UUID: user.userUUID,
    });
  }

  const loadUsers = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchUsers(
          tokens.accessToken,
          usersPage,
          size,
          usersFilters,
        );
        setUsers(response.content);
        setTotalUsersPages(response.totalPages);
        setUsersTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, usersPage, size, usersFilters]);

  const handleApplyUsersSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setUsersFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
      setUsersPage(0);
    },
    [],
  );
  const handleRemoveUsersFilter = useCallback((filterType: string) => {
    setUsersFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
    setUsersPage(0);
  }, []);

  const handleApplyAffiliateUsersSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setAffiliateUsersPage(0);

      setAffiliateUsersFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveAffiliateUsersFilter = useCallback((filterType: string) => {
    setAffiliateUsersPage(0);
    setAffiliateUsersFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  const handleExportUsersClick = async () => {
    if (tokens && tokens.accessToken) {
      try {
        await exportUsersToXLS(
          tokens.accessToken,
          usersPage,
          size,
          usersFilters,
        );
        toast.success('Le téléchargement va commencer.', {
          progressStyle: { backgroundColor: 'green' },
        });
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de l'export.", {
          progressStyle: { backgroundColor: 'red' },
        });
      }
    } else {
      logout();
    }
  };

  useEffect(() => {
    const filtersAreSet = Object.values(usersFilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadUsers();
    }
  }, [usersFilters, loadUsers]);

  const loadActiveUsers = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchActiveClients(
          tokens.accessToken,
          activeUsersPage,
          size,
          activeUsersFilters,
        );
        setActiveUsers(response.content);
        setTotalActiveUsersPages(response.totalPages);
        setActiveUsersTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, activeUsersPage, size, activeUsersFilters]);
  const handleApplyActiveUsersSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setActiveUsersPage(0);

      setActiveUsersFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveActiveUsersFilter = useCallback((filterType: string) => {
    setActiveUsersPage(0);

    setActiveUsersFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  useEffect(() => {
    const filtersAreSet = Object.values(activeUsersFilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadActiveUsers();
    }
  }, [activeUsersFilters, loadActiveUsers]);

  const handleExportActiveUsersClick = async () => {
    if (tokens && tokens.accessToken) {
      try {
        await exportActiveUsersToXLS(
          tokens.accessToken,
          activeUsersPage,
          size,
          activeUsersFilters,
        );
        toast.success('Le téléchargement va commencer.', {
          progressStyle: { backgroundColor: 'green' },
        });
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de l'export.", {
          progressStyle: { backgroundColor: 'red' },
        });
      }
    } else {
      logout();
    }
  };

  const handleExportAffiliateClientClick = async () => {
    if (tokens && tokens.accessToken) {
      try {
        await exportAffiliateClientsToXLS(
          tokens.accessToken,
          affiliateUsersPage,
          size,
          affiliateUsersFilters,
        );
        toast.success('Le téléchargement va commencer.', {
          progressStyle: { backgroundColor: 'green' },
        });
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de l'export.", {
          progressStyle: { backgroundColor: 'red' },
        });
      }
    } else {
      logout();
    }
  };

  const loadFundedUsers = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchFundedClients(
          tokens.accessToken,
          fundedUsersPage,
          size,
          fundedUsersFilters,
        );
        setFundedUsers(response.content);
        setTotalFundedUsersPages(response.totalPages);
        setFundedUsersTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, fundedUsersPage, size, fundedUsersFilters]);

  const loadAffiliateUsers = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchAffiliateClients(
          tokens.accessToken,
          affiliateUsersPage,
          size,
          affiliateUsersFilters,
        );
        setAffiliateUsers(response.content);
        setTotalAffiliateUsersPages(response.totalPages);
        setAffiliateUsersTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, affiliateUsersPage, size, affiliateUsersFilters]);

  useEffect(() => {
    const filtersAreSet = Object.values(affiliateUsersFilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadAffiliateUsers();
    }
  }, [affiliateUsersFilters, loadAffiliateUsers]);

  const handleApplyFundedUsersSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setFundedUsersPage(0);

      setFundedUsersFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveFundedUsersFilter = useCallback((filterType: string) => {
    setFundedUsersPage(0);

    setFundedUsersFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  useEffect(() => {
    const filtersAreSet = Object.values(fundedUsersFilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadFundedUsers();
    }
  }, [fundedUsersFilters, loadFundedUsers]);

  const handleExportFundedUsersClick = async () => {
    if (tokens && tokens.accessToken) {
      try {
        await exportFundedUsersToXLS(
          tokens.accessToken,
          fundedUsersPage,
          size,
          fundedUsersFilters,
        );
        toast.success('Le téléchargement va commencer.', {
          progressStyle: { backgroundColor: 'green' },
        });
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de l'export.", {
          progressStyle: { backgroundColor: 'red' },
        });
      }
    } else {
      logout();
    }
  };

  useEffect(() => {
    if (!isTokensLoading) {
      loadUsers();
      loadActiveUsers();
      loadFundedUsers();
      loadAffiliateUsers();
    }
  }, [
    loadUsers,
    loadActiveUsers,
    loadFundedUsers,
    loadAffiliateUsers,
    isTokensLoading,
  ]);

  const userAccount = async (uuid: string) => {
    if (tokens && tokens.accessToken) {
      await accountInformations(tokens.accessToken, uuid);
      setUserUuid(uuid);
      openModal();
    }
  };

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <UserProfile isOpen={isModalOpen} onClose={openModal} uuid={userUuid} />
      <h1 className={classes.title}>User list</h1>
      <Tab id="users" tabs={tabNames}>
        <div>
          <Filter
            filters={usersFilters}
            onApplySearch={handleApplyUsersSearch}
            onRemoveFilter={handleRemoveUsersFilter}
            filterTypes={usersFilterTypes}
          />
          {tableUsersData.length === 0 ? (
            'Aucun utilisateur'
          ) : (
            <AdminTable
              data={tableUsersData || 'Aucun utilisateur'}
              columns={tableColumns}
              aboutButton={true}
              onAboutButtonClick={userAccount}
            />
          )}

          <div className={classes.footer}>
            <Pagination
              total={totalUsersPages}
              perPage={size}
              currentPage={usersPage}
              onPageChange={(page) => setUsersPage(page)}
            />
            <div className={classes.buttons}>
              <p onClick={handleExportUsersClick} className={classes.exportXLS}>
                <Icon
                  color=""
                  fill="#B7B7B7"
                  size={18}
                  path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
                />
                Export to XLS
              </p>
              <p
                onClick={openCreateUserModal}
                className={classes.createUserButton}
              >
                <Icon
                  color=""
                  fill="#B7B7B7"
                  size={18}
                  path="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 11H7V13H11V17H13V13H17V11H13V7H11V11Z"
                />
                Create User
              </p>
            </div>
            <CreateUserModal
              isOpen={isCreateUserModalOpen}
              onClose={closeCreateUserModal}
            />
          </div>
        </div>
        <div>
          <Filter
            filters={activeUsersFilters}
            onApplySearch={handleApplyActiveUsersSearch}
            onRemoveFilter={handleRemoveActiveUsersFilter}
            filterTypes={usersFilterTypes}
          />
          {tableActiveUsersData.length === 0 ? (
            'Aucun utilisateurs'
          ) : (
            <AdminTable
              data={tableActiveUsersData}
              columns={tableColumns}
              aboutButton={true}
              onAboutButtonClick={userAccount}
            />
          )}
          <div className={classes.footer}>
            <Pagination
              total={totalActiveUsersPages}
              perPage={size}
              currentPage={activeUsersPage}
              onPageChange={(page) => setActiveUsersPage(page)}
            />
            <p
              onClick={handleExportActiveUsersClick}
              className={classes.exportXLS}
            >
              <Icon
                color=""
                fill="#B7B7B7"
                size={18}
                path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
              />
              Export to XLS
            </p>
          </div>
        </div>
        <div>
          <Filter
            filters={fundedUsersFilters}
            onApplySearch={handleApplyFundedUsersSearch}
            onRemoveFilter={handleRemoveFundedUsersFilter}
            filterTypes={usersFilterTypes}
          />
          {tableFundedUsersData.length === 0 ? (
            'Aucun utilisateurs'
          ) : (
            <AdminTable
              data={tableFundedUsersData}
              columns={tableColumns}
              aboutButton={true}
              onAboutButtonClick={userAccount}
            />
          )}
          <div className={classes.footer}>
            <Pagination
              total={totalFundedUsersPages}
              perPage={size}
              currentPage={fundedUsersPage}
              onPageChange={(page) => setFundedUsersPage(page)}
            />
            <p
              onClick={handleExportFundedUsersClick}
              className={classes.exportXLS}
            >
              <Icon
                color=""
                fill="#B7B7B7"
                size={18}
                path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
              />
              Export to XLS
            </p>
          </div>
        </div>
        <div>
          <Filter
            filters={affiliateUsersFilters}
            onApplySearch={handleApplyAffiliateUsersSearch}
            onRemoveFilter={handleRemoveAffiliateUsersFilter}
            filterTypes={affiliateUsersFilterTypes}
          />
          {tableUsersData.length === 0 ? (
            'Aucun utilisateur'
          ) : (
            <AdminTable
              data={tableAffiliateUsersData || 'Aucun utilisateur'}
              columns={affiliateUsersColumns}
              aboutButton={true}
              onAboutButtonClick={userAccount}
            />
          )}

          <div className={classes.footer}>
            <Pagination
              total={totalAffiliateUsersPages}
              perPage={size}
              currentPage={affiliateUsersPage}
              onPageChange={(page) => setAffiliateUsersPage(page)}
            />
            <div
              onClick={handleExportAffiliateClientClick}
              className={classes.exportXLS}
            >
              <Icon
                color=""
                fill="#B7B7B7"
                size={18}
                path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
              />
              Export to XLS
            </div>
          </div>
        </div>
      </Tab>
    </>
  );
};
export default Users;
