import { useState } from 'react';
import PayoutService from '../../../services/post/getPayout/postPayoutRequest';

const usePayout = () => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>(
    'success',
  );

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handlePayoutRequest = async (
    accountUUID: number,
    amount: number,
    accessToken: string,
    successMessage: string,
  ) => {
    setLoading(true);
    try {
      await PayoutService.requestPayout(
        accountUUID,
        amount,
        accessToken,
        (message: string) => {
          setSnackbarMessage(message);
          setSnackbarType('success');
          setSnackbarOpen(true);
        },
        (errorMessage: string) => {
          setSnackbarMessage(errorMessage);
          setSnackbarType('error');
          setSnackbarOpen(true);
        },
        successMessage, // Pass the custom success message
      );
    } catch (error) {
      console.error('Failed to request payout:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handlePayoutRequest,
    snackbarOpen,
    snackbarMessage,
    snackbarType,
    handleSnackbarClose,
  };
};

export default usePayout;
