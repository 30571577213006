import classes from './_error.module.scss';

interface ErrorProps {
  error: string;
}

const Error: React.FC<ErrorProps> = ({ error }) => {
  return <div className={classes.container}>{error}</div>;
};
export default Error;
