import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useTabNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabNameToIndex: { [key: string]: number } = {
    '/profile': 0,
    '/profile/kyc': 1,
  };

  const indexToTabName: { [key: number]: string } = {
    0: '/profile',
    1: '/profile/kyc',
  };

  const [currentTab, setCurrentTab] = useState(
    tabNameToIndex[location.pathname] || 0,
  );

  useEffect(() => {
    // Mettez à jour le currentTab lorsque l'URL change directement
    setCurrentTab(tabNameToIndex[location.pathname] || 0);
  }, [location.pathname]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);

    // Utilisez `replace` pour ne pas ajouter une nouvelle entrée dans l'historique
    navigate(indexToTabName[newValue], { replace: true });
  };

  return {
    currentTab,
    handleTabChange,
  };
};

export default useTabNavigation;
