import axiosInstance from '../../../../../utils/axios';
import { decodeJWT } from './decodeJWT';
import { handleError } from './errors';
import { SupportTicketResponse } from './interfaces';

export const submitSupportTicket = async (
  accessToken: string,
  subject: string,
  content: string,
  attachments: File | null,
): Promise<SupportTicketResponse> => {
  const decoded = decodeJWT(accessToken);

  if (!decoded || !decoded.userId) {
    throw new Error('Invalid access token - userId not found');
  }

  const formData = new FormData();
  formData.append('subject', subject);
  formData.append('content', content);
  if (attachments) {
    formData.append('attachments', attachments);
  }
  formData.append('userUuid', decoded.userId);

  try {
    const response = await axiosInstance.post<SupportTicketResponse>(
      '/support/ticket',
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
