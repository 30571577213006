export const useHandleDownload = () => {
  const handleDownload = (image: string, type: string) => {
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${image}`;
    link.download = `${type}_certificate.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return { handleDownload };
};
