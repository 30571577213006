import React, { forwardRef } from 'react'; // Assurez-vous d'importer forwardRef
import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';
import classes from './_button.module.scss';

interface ButtonProps {
  variant: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: any;
  disabled?: any;
  children: React.ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, children, onClick, type, disabled }, ref) => {
    const { isDarkMode } = useThemeContext();

    return (
      <button
        ref={ref} // Assignez la ref ici
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={`${classes[variant]} ${className || ''} ${
          isDarkMode ? classes.dark : classes.light
        }`}
      >
        {children}
      </button>
    );
  },
);

export default Button;
