export const handleError = (error: any): string => {
  if (error.response) {
    // La requête a été faite et le serveur a répondu avec un code de statut qui n'est pas dans la plage 2xx
    console.error('Response error:', error.response.data);
    return (
      error.response.data.message ||
      'An error occurred while fetching the certificates.'
    );
  } else if (error.request) {
    // La requête a été faite mais aucune réponse n'a été reçue
    console.error('Request error:', error.request);
    return 'No response received from the server.';
  } else {
    // Quelque chose s'est passé lors de la création de la requête qui a déclenché une erreur
    console.error('General error:', error.message);
    return error.message;
  }
};
