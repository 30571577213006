// import axiosInstance from '../../../../../utils/axios';
// import {
//   PostDiscordActivityRequest,
//   PostDiscordActivityResponse,
// } from './interfaces';
// import { handlePostDiscordActivityError } from './errors';

// export async function postDiscordActivity(
//   activityRequest: PostDiscordActivityRequest,
//   startPeriod?: string,
//   endPeriod?: string,
// ): Promise<PostDiscordActivityResponse[]> {
//   try {
//     const params = {
//       startPeriod,
//       endPeriod,
//     };
//     const response = await axiosInstance.post<PostDiscordActivityResponse[]>(
//       '/discord/activity',
//       activityRequest,
//       { params },
//     );
//     return response.data;
//   } catch (error) {
//     handlePostDiscordActivityError(error);
//     throw error;
//   }
// }

import {
  PostDiscordActivityRequest,
  PostDiscordActivityResponse,
} from './interfaces';
import { handlePostDiscordActivityError } from './errors';

export async function postDiscordActivity(
  activityRequest: PostDiscordActivityRequest,
  accessToken: string,
  startPeriod?: string,
  endPeriod?: string,
): Promise<PostDiscordActivityResponse[]> {
  try {
    const params = new URLSearchParams({
      startPeriod: startPeriod || '',
      endPeriod: endPeriod || '',
    });

    const response = await fetch(
      `https://preprod.raisemyfunds.com:4000/api/discord/activities?${params.toString()}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken || ''}`
        },
        body: JSON.stringify(activityRequest),
      },
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.message || 'Failed to post Discord activity',
      );
    }

    if(response.status === 204){
      return [];
    }

    const data = await response.json();
    return data as PostDiscordActivityResponse[];
  } catch (error) {
    handlePostDiscordActivityError(error);
    throw error;
  }
}
