import React, { useState } from 'react';
import PieChartTabsPanel from '../../components/PieChartTabsPanel/PieChartTabsPanel';
import { Card, Stack } from '@mui/material';
import ChartTabsNav from '../../components/ChartTabsNav/ChartTabsNav';
import DataChartTab from '../../components/ChartContent/DataChartTab';
import { useTranslation } from 'react-i18next';

const PieChart: React.FC = () => {
  const { t } = useTranslation('dashboard');

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Card elevation={0}>
      <ChartTabsNav value={value} handleChange={handleChange} />

      <Stack padding={2}>
        <PieChartTabsPanel value={value} index={0}>
          <DataChartTab
            dataType="failed"
            isActive={value === 0}
            chartLabel={t('CHART.NUMBER_AXIS_TITLE')} // Label personnalisé
          />
        </PieChartTabsPanel>

        <PieChartTabsPanel value={value} index={1}>
          <DataChartTab
            dataType="passed"
            isActive={value === 1}
            chartLabel={t('CHART.NUMBER_AXIS_TITLE')} // Label personnalisé
          />
        </PieChartTabsPanel>

        <PieChartTabsPanel value={value} index={2}>
          <DataChartTab
            dataType="cashEntry"
            isActive={value === 2}
            chartLabel={t('CHART.AMOUNT_AXIS_TITLE')} // Label personnalisé
          />
        </PieChartTabsPanel>

        <PieChartTabsPanel value={value} index={3}>
          <DataChartTab
            dataType="sales"
            isActive={value === 3}
            chartLabel={t('CHART.SALES_AXIS_TITLE')} // Label personnalisé
          />
        </PieChartTabsPanel>
      </Stack>
    </Card>
  );
};

export default PieChart;
