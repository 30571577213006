import React from 'react';
import { Tabs, Tab } from '@mui/material';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';

interface ChartTabsNavProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const ChartTabsNav: React.FC<ChartTabsNavProps> = ({ value, handleChange }) => {
  const { t } = useTranslation('dashboard');
  const { isBetweenXSAndSM } = useBreakpoints();

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant={isBetweenXSAndSM ? 'scrollable' : 'fullWidth'}
      scrollButtons={false}
    >
      <Tab
        label={t('CHART.TABS_NAME.CHALLENGES_FAILED')}
        sx={{ textWrap: 'nowrap' }}
      />
      <Tab
        label={t('CHART.TABS_NAME.CHALLENGES_PASSED')}
        sx={{ textWrap: 'nowrap' }}
      />
      <Tab
        label={t('CHART.TABS_NAME.CASH_ENTRY')}
        sx={{ textWrap: 'nowrap' }}
      />
      <Tab label={t('CHART.TABS_NAME.SALES')} sx={{ textWrap: 'nowrap' }} />
    </Tabs>
  );
};

export default ChartTabsNav;
