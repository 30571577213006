import React, { useRef, useState } from 'react';
import { Button, Card, Stack, Typography, Box } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTranslation } from 'react-i18next';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

interface FileUploadCardProps {
  label: string;
  documentType:
    | 'IDENTITY_PROOF_RECTO'
    | 'IDENTITY_PROOF_VERSO'
    | 'ADDRESS_PROOF';
  onFileUpload: (file: File, documentType: string) => void;
}

const FileUploadCard: React.FC<FileUploadCardProps> = ({
  label,
  documentType,
  onFileUpload,
}) => {
  const { t } = useTranslation('profile');
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      if (selectedFile.size > MAX_FILE_SIZE) {
        setErrorMessage(
          t('KYC.ERROR_FILE_SIZE_EXCEEDS_THE_MAXIMUM_LIMIT_OF_5MB'),
        );
        setFile(null);
        setPreview(null);
      } else {
        setFile(selectedFile);
        setErrorMessage(null);
        generatePreview(selectedFile);
        onFileUpload(selectedFile, documentType);
      }
    }
  };

  const handleBrowseClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const generatePreview = (file: File) => {
    if (file.type.startsWith('image/')) {
      const fileUrl = URL.createObjectURL(file);
      setPreview(fileUrl);
    } else {
      setPreview(null);
    }
  };

  return (
    <Stack>
      <Card elevation={0} sx={{ marginBottom: '24px' }}>
        <Stack
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            padding: '16px',
            border: '2px dashed #697399',
            borderRadius: '8px',
            width: '100%',
            minHeight: '200px',
          }}
        >
          {preview ? (
            <Box
              component="img"
              src={preview}
              alt="File preview"
              sx={{
                maxWidth: '100%',
                maxHeight: '200px',
                objectFit: 'contain',
              }}
            />
          ) : file && file.type === 'application/pdf' ? (
            <Stack justifyContent="center" alignItems="center">
              <PictureAsPdfIcon sx={{ fontSize: '64px', color: 'red' }} />
              <Typography variant="body1" component="p">
                PDF: {file.name}
              </Typography>
            </Stack>
          ) : (
            <Stack justifyContent={'center'} alignItems={'center'}>
              <FileUploadIcon sx={{ fontSize: '64px' }} />
              <Typography variant="h6" component={'h6'}>
                {t('KYC.TITLE_DRAG_DROP_FILE_TO_UPLOAD')}
              </Typography>
            </Stack>
          )}

          <Button variant="contained" onClick={handleBrowseClick}>
            {t('KYC.BUTTON_BROWSE')}
          </Button>

          <input
            type="file"
            ref={inputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="image/png, image/jpeg, application/pdf"
          />

          {errorMessage && (
            <Typography
              variant="body2"
              component="p"
              sx={{
                backgroundColor: '#f64644',
                padding: '8px 16px',
                borderRadius: '8px',
                color: 'white',
              }}
            >
              {errorMessage}
            </Typography>
          )}
        </Stack>
      </Card>
      <Card
        elevation={0}
        sx={{ padding: '8px 16px', border: '2px solid #697399' }}
      >
        <Typography textAlign={'center'}>{label}</Typography>
      </Card>
    </Stack>
  );
};

export default FileUploadCard;
