import axiosInstance from '../../../../../../utils/axios';

import { IGetEconomicCalendarService } from './interfaces';
import { CalendarResponse } from './models';
import { handleError } from './errors';

class GetEconomicCalendarService implements IGetEconomicCalendarService {
  async getEconomicCalendar(accessToken: string): Promise<CalendarResponse[]> {
    try {
      const response = await axiosInstance.get<CalendarResponse[]>(
        `/economic-event`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new GetEconomicCalendarService();
