import React from 'react';

import { Card, Skeleton, Stack } from '@mui/material';

import classes from '../styles/certificate.module.scss';

export const CertificateCardSkeleton = () => {
  return (
    <Card elevation={0} className={classes.cards} sx={{ width: '100%' }}>
      <Skeleton
        variant="rounded"
        width={180}
        height={40}
        sx={{ marginBottom: 2 }}
      />
      <Skeleton
        variant="rounded"
        width={'100%'}
        sx={{ aspectRatio: '4/3', height: 'auto' }}
      />
      <Stack direction={'row'} gap={2} mt={4}>
        <Skeleton variant="rounded" width={180} height={40} />
        <Skeleton variant="rounded" width={180} height={40} />
      </Stack>
    </Card>
  );
};
