import { Stack, useTheme } from '@mui/material';
import React from 'react';
import HeaderCard from '../../../../features/shared/components/HeaderCard/HeaderCard';
import { useOverviewData } from '../../hooks/pieChart/useOverviewData';

import GroupIcon from '@mui/icons-material/Group';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useTranslation } from 'react-i18next';

const BasicsCard = () => {
  const theme = useTheme();
  const { t } = useTranslation('dashboard');

  const { loading, data } = useOverviewData();

  return (
    <Stack direction="row" flexWrap="wrap" gap={2}>
      <HeaderCard
        title={t('HEADER_CARDS.TOTAL_USERS')}
        value={data?.totalUsers ?? 0}
        icon={<GroupIcon sx={{ fill: theme.palette.primary.main }} />}
        iconColor={theme.palette.primary.main}
        isLoading={loading}
      />
      <HeaderCard
        title={t('HEADER_CARDS.TOTAL_FUNDED')}
        value={data?.totalFunded ?? 0}
        icon={<AccountBalanceIcon sx={{ fill: '#1FCC92' }} />}
        iconColor={'#1FCC92'}
        isLoading={loading}
      />
      <HeaderCard
        title={t('HEADER_CARDS.TOTAL_PHASE_1')}
        value={data?.totalPhase1 ?? 0}
        icon={<Filter1Icon sx={{ fill: '#ff6384' }} />}
        iconColor={'#ff6384'}
        isLoading={loading}
      />
      <HeaderCard
        title={t('HEADER_CARDS.TOTAL_PHASE_2')}
        value={data?.totalPhase2 ?? 0}
        icon={<Filter2Icon sx={{ fill: '#ffce56' }} />}
        iconColor={'#ffce56'}
        isLoading={loading}
      />
      <HeaderCard
        title={t('HEADER_CARDS.TOTAL_FAILED')}
        value={data?.totalFailed ?? 0}
        icon={<DisabledByDefaultIcon sx={{ fill: '#9747FF' }} />}
        iconColor={'#9747FF'}
        isLoading={loading}
      />
    </Stack>
  );
};

export default BasicsCard;
