import React from 'react';

import { useTab } from '../../context/TabContext/TabContext';

import classes from './_tab.module.scss';

interface TabProps {
  id: string;
  tabs: any[];
  children: React.ReactNode[];
}

const Tab: React.FC<TabProps> = ({ id, tabs, children }) => {
  const { activeTabs, setActiveTab } = useTab();
  const activeTab = activeTabs[id] || 0;
  const [sliderStyles, setSliderStyles] = React.useState({});
  const tabHeadersRef = React.useRef<HTMLDivElement | null>(null);
  const updateSliderStyles = () => {
    const tabElements = tabHeadersRef.current?.children;
    const activeTabElement = tabElements?.[activeTab] as HTMLElement;

    if (activeTabElement) {
      const left = activeTabElement.offsetLeft;
      const width = activeTabElement.offsetWidth;

      setSliderStyles({
        width: `${width}px`,
        left: `${left}px`,
      });
    }
  };

  React.useEffect(() => {
    updateSliderStyles();
    const handleResize = () => {
      updateSliderStyles();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [activeTab]);

  const handleTabClick = (index: number) => {
    setActiveTab(id, index);
  };

  return (
    <>
      <div className={classes.tabContainer}>
        <div className={classes.header} ref={tabHeadersRef}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`${classes.tab} ${
                index === activeTab ? classes.active : ''
              }`}
              onClick={() => handleTabClick(index)}
            >
              {tab}
            </div>
          ))}
          <div className={classes.slider} style={sliderStyles}></div>
        </div>
      </div>
      <div>{children[activeTab]}</div>
    </>
  );
};

export default Tab;
