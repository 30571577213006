import React from 'react';
import {
  Button,
  Stack,
  Snackbar,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadCard from '../FileUploadCard/FileUploadCard';
import { useKYCUpload } from '../../hooks/useKYCUpload';
import { useTranslation } from 'react-i18next';

const KYCContent: React.FC = () => {
  const { t } = useTranslation('profile');
  const {
    handleFileUpload,
    handleSendAllDocuments,
    isButtonDisabled,
    snackbarOpen,
    snackbarMessage,
    handleCloseSnackbar,
  } = useKYCUpload();

  // Utilisation des breakpoints de Material UI pour gérer le responsive
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detecte les petits écrans

  return (
    <Stack
      spacing={3}
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {/* Stack pour les cartes, change de direction selon la taille de l'écran */}
      <Stack
        spacing={3}
        direction={isMobile ? 'column' : 'row'} // Utiliser 'column' pour mobile
        justifyContent={'center'}
        alignItems={'center'}
      >
        <FileUploadCard
          label={t('KYC.TEXT_NATIONAL_ID_CARD_FRONT')}
          documentType="IDENTITY_PROOF_RECTO"
          onFileUpload={handleFileUpload}
        />
        <FileUploadCard
          label={t('KYC.TEXT_NATIONAL_ID_CARD_BACK')}
          documentType="IDENTITY_PROOF_VERSO"
          onFileUpload={handleFileUpload}
        />
        <FileUploadCard
          label={t('KYC.TEXT_PROOF_OF_ADDRESS')}
          documentType="ADDRESS_PROOF"
          onFileUpload={handleFileUpload}
        />
      </Stack>

      <Button
        variant="contained"
        onClick={handleSendAllDocuments}
        disabled={isButtonDisabled}
      >
        {t('KYC.BUTTON_SEND_ALL_DOCUMENTS')}
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Stack>
  );
};

export default KYCContent;
