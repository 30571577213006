import {
  UpdatePermissionsRequest,
  UpdatePermissionsResponse,
} from './interfaces';
import axiosInstance from '../../../../utils/axios'; // Assurez-vous que le chemin est correct
import { AxiosError } from 'axios';

export const updateUserPermissions = async (
  userUUID: string,
  permissions: UpdatePermissionsRequest,
  token: string,
): Promise<UpdatePermissionsResponse | null> => {
  try {
    const response = await axiosInstance.put<UpdatePermissionsResponse>(
      `permissions/users/${userUUID}/permissions`,
      permissions,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    // Vérifier le statut de la réponse et retourner les données
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};
