import { useEffect, useState } from 'react';

import { useAuth } from '../../../../context/AuthContext/AuthContext';

import { fetchProfile } from '../services/get/profile/getProfile';
import { updateProfile } from '../services/put/profile/updateProfile';
import { Profile } from '../services/get/profile/models';
import { ProfileInformations } from '../services/put/profile/models';

export const useProfile = () => {
  const { tokens } = useAuth();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [updatedProfile, setUpdatedProfile] = useState<ProfileInformations>({
    firstName: '',
    lastName: '',
    title: '',
    birthdate: '',
    phoneNumber: '',
    country: '',
    city: '',
    street: '',
    postalCode: '',
  });
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    const getProfileData = async () => {
      if (tokens && tokens.accessToken) {
        try {
          const data = await fetchProfile(tokens.accessToken);
          setProfile(data);
          setUpdatedProfile({
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            title: data.title || '',
            birthdate: data.birthdate || '',
            phoneNumber: data.phoneNumber || '',
            country: data.country || '',
            city: data.city || '',
            street: data.street || '',
            postalCode: data.postalCode || '',
          });
        } catch (error) {
          console.error('Failed to fetch profile', error);
        }
      }
    };

    getProfileData();
  }, [tokens]);

  const handleInputChange = (
    field: keyof ProfileInformations,
    value: string,
  ) => {
    setUpdatedProfile((prev) => ({ ...prev, [field]: value }));
    setIsModified(true);
  };

  const handleSave = async () => {
    if (tokens && tokens.accessToken) {
      try {
        await updateProfile(tokens.accessToken, updatedProfile);
        setIsModified(false);
      } catch (error) {
        console.error('Failed to update profile', error);
      }
    }
  };

  return {
    profile,
    updatedProfile,
    isModified,
    handleInputChange,
    handleSave,
  };
};
