import { Card, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import PieChart from '../../components/PieChart/PieChart';
import { useLightDashboard } from '../../context/LightDashboardContext/LightDashboardContext';
import PieChartLightSkeleton from '../../components/PieChart/PieChartSkeleton';
import { useTranslation } from 'react-i18next';

const PieChartLight = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const { data, isLoading, error } = useLightDashboard();
  const { t } = useTranslation('lightDashboard');

  if (isLoading) {
    return <PieChartLightSkeleton inCard checkboxs={3} />;
  } else if (!data) {
    return null;
  }

  const labels = [
    t('PIE_CHART.LABELS.FUNDED'),
    t('PIE_CHART.LABELS.PHASE_1'),
    t('PIE_CHART.LABELS.PHASE_2'),
  ];
  const chartData = [data.totalFunded, data.totalPhase1, data.totalPhase2];
  const backgroundColors = [
    'rgba(153, 102, 255, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(255, 206, 86, 1)',
  ];

  return (
    <Card
      elevation={0}
      sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <Stack
        sx={{
          backgroundColor: isDarkMode ? '#292938' : '#ece9e9',
          padding: '8px',
          borderRadius: '8px',
          margin: '16px',
        }}
      >
        <Typography
          fontWeight={'bold'}
          variant="h6"
          component={'h6'}
          textAlign={'center'}
          sx={{ textWrap: 'nowrap' }}
        >
          {t('PIE_CHART.TITLE_TOTAL_USERS')}{' '}
          <Typography component={'span'} variant="h6" color={'primary'} ml={1}>
            {data.totalUsers.toLocaleString('fr-FR')}
          </Typography>
        </Typography>
      </Stack>
      <Stack sx={{ margin: '30px 80px' }}>
        <PieChart
          labels={labels}
          data={chartData}
          backgroundColors={backgroundColors}
        />
      </Stack>
    </Card>
  );
};

export default PieChartLight;
