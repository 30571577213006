import axiosInstance from '../../../../../utils/axios';
import { Page, CommissionDetail } from '../payouts/models';

// Fonction pour obtenir les commissions paginées
export const getCommissionsByMonth = async (
  accessToken: string,
  month?: number,
  year?: number,
  page: number = 0,
  size: number = 100,
): Promise<Page<CommissionDetail>> => {
  try {
    const response = await axiosInstance.get<Page<CommissionDetail>>(
      '/v2/affiliations/commissions/month',
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          month,
          year,
          page,
          size,
        },
      },
    );
    return response.data; // La réponse est bien un objet de type Page<CommissionDetail>
  } catch (error) {
    throw new Error('Failed to fetch commissions');
  }
};
