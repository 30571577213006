import axiosInstance from '../../../../../../utils/axios';
import { decodeJWT } from '../../auth/decodeJWT';
import { Profile } from './models';
import { handleError } from './errors';

export const fetchProfile = async (accessToken: string): Promise<Profile> => {
  const decoded = decodeJWT(accessToken);

  if (!decoded?.sub) {
    throw new Error('Invalid access token - email not found');
  }

  try {
    const response = await axiosInstance.get<Profile>(
      `/users/profile/edit?email=${decoded.sub}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data; // Directement un objet de type Profile
  } catch (error) {
    handleError(error);
    throw error;
  }
};
