import axiosInstance from '../../../../../../utils/axios';
import { LoginResponse } from './interfaces';
import { User, JWTPayload } from './models';
import { decodeJWT } from '../../utils/decodedJWT';
import { handleAuthError } from './errors';

export const loginUser = async (
  email: string,
  password: string,
  remember: boolean,
): Promise<{ tokens: LoginResponse; user: JWTPayload }> => {
  const user: User = { email, password };

  try {
    const response = await axiosInstance.post<LoginResponse>(
      `/users/login`,
      user,
    );
    const tokens = response.data;

    if (remember) {
      localStorage.setItem('accessToken', tokens.accessToken);
      localStorage.setItem('refreshToken', tokens.refreshToken);
    } else {
      sessionStorage.setItem('accessToken', tokens.accessToken);
      sessionStorage.setItem('refreshToken', tokens.refreshToken);
    }

    const decoded = decodeJWT(tokens.accessToken);

    if (!decoded) {
      throw new Error('Failed to decode the JWT.');
    }

    return { tokens, user: decoded };
  } catch (error) {
    handleAuthError(error);
    throw new Error('Login failed due to an error');
  }
};
