import React, { useState, useEffect, ReactNode } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext'; // Assure-toi que le chemin est correct
import { LightDashboardContext } from '../../context/LightDashboardContext/LightDashboardContext';
import { SimpleDashboardStatsDTO } from '../../services/get/lightDashboard/models';
import { getLightDashboard } from '../../services/get/lightDashboard/getLightDashboard';
import { ApiError } from '../../services/get/lightDashboard/errors';

interface LightDashboardProviderProps {
  children: ReactNode;
}

export const LightDashboardProvider: React.FC<LightDashboardProviderProps> = ({
  children,
}) => {
  const { tokens } = useAuth(); // Récupère les tokens via le contexte d'authentification
  const [data, setData] = useState<SimpleDashboardStatsDTO | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchLightDashboardData = async () => {
    if (tokens && tokens.accessToken) {
      try {
        const dashboardData = await getLightDashboard(tokens.accessToken);
        setData(dashboardData);
        setError(null);
      } catch (err) {
        if (err instanceof ApiError) {
          setError(err.message);
        } else {
          setError('An unexpected error occurred');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (tokens && tokens.accessToken) {
      fetchLightDashboardData();
    }
  }, [tokens]);

  return (
    <LightDashboardContext.Provider
      value={{
        data,
        isLoading,
        error,
        fetchLightDashboardData,
      }}
    >
      {children}
    </LightDashboardContext.Provider>
  );
};
