import axiosInstance from '../../../../../../utils/axios';
import { decodeJWT } from '../../auth/decodeJWT';
import { ProfileInformations } from './models';
import { UpdateProfileResponse } from './interfaces';
import { handleUpdateProfileError } from './errors';

export const updateProfile = async (
  accessToken: string,
  profile: ProfileInformations,
): Promise<UpdateProfileResponse> => {
  const decoded = decodeJWT(accessToken);

  if (!decoded?.userId) {
    throw new Error('Invalid access token - userId not found');
  }

  try {
    const response = await axiosInstance.put<UpdateProfileResponse>(
      `/users/${decoded.userId}`,
      profile,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    handleUpdateProfileError(error);
    throw error;
  }
};
