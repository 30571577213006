import { JWTPayload } from '../post/login/models';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}
