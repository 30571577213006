import React, { useState } from 'react';

import { CommissionsProvider } from '../../provider/Commissions/CommissionsProvider';

import useBreakpoints from '../../../../hooks/useBreakpoints';

import { Card, Stack } from '@mui/material';

import { Chart, CommissionsCard } from '../../partner';

const Commissions = () => {
  const { isBetweenXSAndSM } = useBreakpoints();

  const [timeframe, setTimeframe] = useState<
    'WEEK' | 'MONTH' | 'YEAR' | 'TOTAL'
  >('WEEK');

  return (
    <Card elevation={0} style={{ padding: '16px' }}>
      <Stack direction={isBetweenXSAndSM ? 'column' : 'row'} spacing={2}>
        <CommissionsProvider timeframe={timeframe}>
          <Chart setTimeframe={setTimeframe} />
          <CommissionsCard timeframe={timeframe} />
        </CommissionsProvider>
      </Stack>
    </Card>
  );
};

export default Commissions;
