import React, { useCallback, useEffect, useState } from 'react';

import { useAuth } from '../../../context/AuthContext/AuthContext';

import useDocumentTitle from '../../../hooks/useDocumentTitle';

import { logout } from '../../../services/auth/login';
import {
  FundedContent,
  FundedCreationRequestContent,
  FundedResponse,
} from '../../../services/admin/funded/interface';
import { accountInformations } from '../../../services/admin/userAbout/userAbout';
import {
  acceptFundedCreationRequest,
  fetchAllFundeds,
  fetchFundedCreationRequest,
  refuseFundedCreationRequest,
} from '../../../services/admin/funded/funded';

import AdminTable from '../../../components/Admin/AdminTable/AdminTable';
import Filter, { FilterType } from '../../../components/Filter/Filter';
import Pagination from '../../../components/Pagination/Pagination';
import Loader from '../../../components/Loader/Loader';
import Icon from '../../../components/userInterface/Icon/Icon';
import UserProfile from '../../../components/Admin/UserProfile/UserProfile';
import Tab from '../../../components/Tab/Tab';

import classes from './_funded.module.scss';

const Funded = () => {
  const { tokens, isTokensLoading } = useAuth();
  useDocumentTitle('Funded');

  const size = 10;

  const fundedCreationRequestfilterTypes: FilterType[] = [
    { key: 'challengeLogin', type: 'string' },
    { key: 'email', type: 'string' },
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'userAdminId', type: 'string' },
  ];

  const fundedAccountsfilterTypes: FilterType[] = [
    { key: 'mt5Account', type: 'string' },
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'email', type: 'string' },
    { key: 'adminId', type: 'string' },
  ];

  const tableFundedCreationRequestsData = [];
  const tableFundedCreationRequestsColumn = [
    'Challenge Login',
    'Funded Status',
    'Firstname',
    'Lastname',
    'Profit Split',
    'Email',
  ];

  const tableFundedAccountsData = [];
  const tableFundedAccountsColumn = [
    'MT5 Account',
    'Email',
    'Firstname',
    'Lastname',
    'Admin ID',
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [userUuid, setUserUuid] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [fundedCreationRequestfilters, setFundedCreationRequestFilters] =
    useState<{
      [key: string]: string;
    }>({});
  const [fundedCreationRequest, setFundedCreationRequest] = useState<
    FundedCreationRequestContent[]
  >([]);
  const [fundedCreationRequestPage, setFundedCreationRequestPage] =
    useState<number>(0);
  const [totalFundedCreationRequestPages, setTotalFundedCreationRequestPages] =
    useState<number>(0);
  const [fundedTotalOfElements, setFundedTotalOfElements] = useState<number>(0);

  const [fundedAccountsfilters, setFundedAccountsFilters] = useState<{
    [key: string]: string;
  }>({});
  const [fundedAccounts, setFundedAccounts] = useState<FundedContent[]>([]);
  const [fundedAccountsPage, setFundedAccountsPage] = useState<number>(0);
  const [totalFundedAccountsPages, setTotalFundedAccountsPages] =
    useState<number>(0);
  const [fundedAccountsTotalOfElements, setFundedAccountsotalOfElements] =
    useState<number>(0);

  const userAccount = async (uuid: string) => {
    if (tokens && tokens.accessToken) {
      await accountInformations(tokens.accessToken, uuid);
      setUserUuid(uuid);
      openModal();
    }
  };

  for (const request of fundedCreationRequest) {
    tableFundedCreationRequestsData.push({
      'Challenge Login': request.challengeMetaTraderLogin,
      ID: request.fundedId,
      'Funded Status': request.fundedStatus,
      'Profit Split': request.profitSplit,
      Firstname: request.user.firstname,
      Lastname: request.user.lastname,
      Email: request.user.email,
      UUID: request.user.uuid,
    });
  }

  for (const account of fundedAccounts) {
    tableFundedAccountsData.push({
      UUID: account.user.uuid,
      'MT5 Account': account.mt5Account,
      Email: account.user.email,
      Firstname: account.user.firstname,
      Lastname: account.user.lastname,
      'Admin ID': account.user.adminId,
    });
  }

  const loadFundedCreationRequests = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchFundedCreationRequest(
          tokens.accessToken,
          fundedCreationRequestPage,
          size,
          fundedCreationRequestfilters,
        );
        setFundedCreationRequest(response.content);
        setTotalFundedCreationRequestPages(response.totalPages);
        setFundedTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, fundedCreationRequestfilters, fundedCreationRequestPage, size]);

  const loadFundedAccounts = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchAllFundeds(
          tokens.accessToken,
          fundedAccountsPage,
          size,
          fundedAccountsfilters,
        );
        setFundedAccounts(response.content);
        setTotalFundedAccountsPages(response.totalPages);
        setFundedAccountsotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, fundedAccountsfilters, fundedAccountsPage, size]);

  useEffect(() => {
    if (!isTokensLoading) {
      loadFundedCreationRequests();
      loadFundedAccounts();
    }
  }, [loadFundedCreationRequests, loadFundedAccounts, isTokensLoading]);

  useEffect(() => {
    const filtersAreSet = Object.values(fundedCreationRequestfilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadFundedCreationRequests();
    }
  }, [fundedCreationRequestfilters, loadFundedCreationRequests]);

  useEffect(() => {
    const filtersAreSet = Object.values(fundedAccountsfilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadFundedAccounts();
    }
  }, [fundedAccountsfilters, loadFundedAccounts]);

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleApplyFundedCreationRequestSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setFundedCreationRequestFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveFundedCreationRequestFilter = useCallback(
    (filterType: string) => {
      setFundedCreationRequestFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: '',
      }));
    },
    [],
  );

  const handleApplyFundedAccountsSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setFundedAccountsFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveFundedAccountsFilter = useCallback((filterType: string) => {
    setFundedAccountsFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  const handleAcceptFundedCreationRequest = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await acceptFundedCreationRequest(tokens.accessToken, id);
      loadFundedCreationRequests();
    } else {
      logout();
    }
  };
  const handleRefuseFundedCreationRequest = async (
    id: number,
    reason: string,
  ) => {
    if (tokens && tokens.accessToken) {
      await refuseFundedCreationRequest(tokens.accessToken, id, reason);
      loadFundedCreationRequests();
    } else {
      logout();
    }
  };

  const tabNames = [
    <div className={classes.tabNamesContainer} key={'fundedRequests'}>
      Funded Requests
      <span className={classes.chip}>{fundedTotalOfElements}</span>
    </div>,
    <div className={classes.tabNamesContainer} key={'funded'}>
      Funded
      <span className={classes.chip}>{fundedAccountsTotalOfElements}</span>
    </div>,
  ];

  return (
    <>
      <UserProfile isOpen={isModalOpen} onClose={openModal} uuid={userUuid} />
      <h1 className={classes.title}>Funded Requests</h1>
      <Tab id="funded" tabs={tabNames}>
        <div>
          <Filter
            filters={fundedCreationRequestfilters}
            onApplySearch={handleApplyFundedCreationRequestSearch}
            onRemoveFilter={handleRemoveFundedCreationRequestFilter}
            filterTypes={fundedCreationRequestfilterTypes}
          />
          {tableFundedCreationRequestsData.length === 0 ? (
            'Aucun utilisateur'
          ) : (
            <AdminTable
              data={tableFundedCreationRequestsData}
              columns={tableFundedCreationRequestsColumn}
              aboutButton={true}
              acceptButton={true}
              refuseButton={true}
              onAboutButtonClick={userAccount}
              onAcceptButtonClick={handleAcceptFundedCreationRequest}
              onRefuseButtonClick={(id, reason) =>
                handleRefuseFundedCreationRequest(
                  id,
                  reason ?? 'NO REASON PROVIDED',
                )
              }
            />
          )}

          <div className={classes.footer}>
            <Pagination
              total={totalFundedCreationRequestPages}
              perPage={size}
              currentPage={fundedCreationRequestPage}
              onPageChange={(page) => setFundedCreationRequestPage(page)}
            />
          </div>
        </div>
        <div>
          <Filter
            filters={fundedAccountsfilters}
            onApplySearch={handleApplyFundedAccountsSearch}
            onRemoveFilter={handleRemoveFundedAccountsFilter}
            filterTypes={fundedAccountsfilterTypes}
          />
          {tableFundedAccountsData.length === 0 ? (
            'Aucun utilisateur'
          ) : (
            <AdminTable
              data={tableFundedAccountsData}
              columns={tableFundedAccountsColumn}
              aboutButton={true}
              acceptButton={false}
              refuseButton={false}
              onAboutButtonClick={userAccount}
            />
          )}

          <div className={classes.footer}>
            <Pagination
              total={totalFundedAccountsPages}
              perPage={size}
              currentPage={fundedAccountsPage}
              onPageChange={(page) => setFundedAccountsPage(page)}
            />
          </div>
        </div>
      </Tab>
    </>
  );
};

export default Funded;
