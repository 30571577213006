import axiosInstance from '../../../../../../utils/axios';
import { Profile } from './interfaces';

export const fetchProfile = async (
  accessToken: string,
  userUUID: string,
): Promise<Profile> => {
  try {
    const response = await axiosInstance.get<Profile>(
      `/users/sidebar?userUUID=${userUUID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error fetching profile');
  }
};
