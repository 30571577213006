import React, { useState, useEffect, ReactNode } from 'react';
import { FundedAccountBaseDataContext } from '../../context/FundedAccountBaseDataContext/FundedAccountBaseDataContext';
import { FundedAccountBase } from '../../services/get/fundedAccountBase/models';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { getFundedAccountBase } from '../../services/get/fundedAccountBase/getFundedAccountBase';

interface FundedAccountBaseProviderProps {
  children: ReactNode;
  selectedAccountLogin: string | null;
}

export const FundedAccountBaseProvider: React.FC<
  FundedAccountBaseProviderProps
> = ({ children, selectedAccountLogin }) => {
  const [fundedAccountBaseData, setFundedAccountBaseData] =
    useState<FundedAccountBase | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { tokens } = useAuth();

  const fetchFundedAccountBaseData = async (login: string | null) => {
    if (login && tokens) {
      setLoading(true);
      try {
        const data = await getFundedAccountBase(login, tokens.accessToken);
        setFundedAccountBaseData(data);
        setError(null);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unexpected error occurred');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchFundedAccountBaseData(selectedAccountLogin);
  }, [selectedAccountLogin, tokens]);

  return (
    <FundedAccountBaseDataContext.Provider
      value={{
        fundedAccountBaseData,
        loading,
        error,
        fetchFundedAccountBaseData,
      }}
    >
      {children}
    </FundedAccountBaseDataContext.Provider>
  );
};
