import axiosInstance from '../../../../../utils/axios';
import { SimpleDashboardStatsDTO } from './models';
import { ApiError } from './errors';

export const getLightDashboard = async (
  token: string,
): Promise<SimpleDashboardStatsDTO> => {
  try {
    const response = await axiosInstance.get<SimpleDashboardStatsDTO>(
      '/v2/admin/simple-dashboard',
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 500) {
      throw new ApiError(error.response.data.error || 'Internal Server Error');
    } else {
      throw new ApiError('An unexpected error occurred');
    }
  }
};
