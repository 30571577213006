import React, { useState } from 'react';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { Button, ButtonGroup, Card } from '@mui/material';
import ZoomableChart from '../../../../components/ZoomableChart/ZoomableChart';
import { useCommissions } from '../../context/Commissions/CommissionsContext';
import classes from './chart.module.scss';
import { useTranslation } from 'react-i18next';
import ChartSkeleton from './ChartSkeleton';

interface ChartProps {
  setTimeframe: (timeframe: 'WEEK' | 'MONTH' | 'YEAR' | 'TOTAL') => void; // Prop pour changer timeframe
}

const Chart: React.FC<ChartProps> = ({ setTimeframe }) => {
  const { t } = useTranslation('partnerArea');
  const { isBetweenXSAndSM } = useBreakpoints();
  const { commissionsData, loading } = useCommissions();
  const [selectedTimeframe, setSelectedTimeframe] = useState<
    'WEEK' | 'MONTH' | 'YEAR' | 'TOTAL'
  >('WEEK'); // État local pour la timeframe sélectionnée

  const handleTimeframeChange = (
    timeframe: 'WEEK' | 'MONTH' | 'YEAR' | 'TOTAL',
  ) => {
    setSelectedTimeframe(timeframe);
    setTimeframe(timeframe);
  };

  if (loading) {
    return <ChartSkeleton />;
  } else if (!commissionsData) {
    return null;
  }

  return (
    <Card elevation={1} className={classes.chart}>
      <ZoomableChart
        labels={commissionsData.graphData.map((data) => data.date) || []}
        datasets={[
          {
            label: t('CHART.Y_AXIS_TITLE'),
            data: commissionsData.graphData.map((data) => data.count) || [],
            fill: 'start',
            backgroundColor: 'rgba(231, 71, 80, 0.1)',
            borderColor: 'rgb(231, 71, 80)',
          },
        ]}
        xAxisTitle={t('CHART.X_AXIS_TITLE')}
        yAxisTitle={t('CHART.Y_AXIS_TITLE')}
      />
      <ButtonGroup
        sx={{ width: 'fit-content' }}
        variant="contained"
        size={isBetweenXSAndSM ? 'small' : 'medium'}
        aria-label="Chart period button group"
      >
        <Button
          onClick={() => handleTimeframeChange('WEEK')}
          disabled={selectedTimeframe === 'WEEK'}
        >
          {t('CHART.BUTTON_WEEK')}
        </Button>
        <Button
          onClick={() => handleTimeframeChange('MONTH')}
          disabled={selectedTimeframe === 'MONTH'}
        >
          {t('CHART.BUTTON_MONTH')}
        </Button>
        <Button
          onClick={() => handleTimeframeChange('YEAR')}
          disabled={selectedTimeframe === 'YEAR'}
        >
          {t('CHART.BUTTON_YEAR')}
        </Button>
        <Button
          onClick={() => handleTimeframeChange('TOTAL')}
          disabled={selectedTimeframe === 'TOTAL'}
        >
          {t('CHART.BUTTON_TOTAL')}
        </Button>
      </ButtonGroup>
    </Card>
  );
};

export default Chart;
