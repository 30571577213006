export const handleErrorScaleUpAccount = (
  error: any,
  onError: (message: string) => void,
): void => {
  const errorMessage =
    error.response?.data?.error ||
    'An error occurred during the account scale up.';
  onError(errorMessage);
  console.error(errorMessage, error);
};
