import React from 'react';

import LanguageSelector from '../../../../../components/LanguageSelector/LanguageSelector';

import { Menu, IconButton, Stack, Typography } from '@mui/material';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useTranslation } from 'react-i18next';

interface LanguageMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleBack: () => void;
  handleClose: () => void;
}

const LanguageMenu: React.FC<LanguageMenuProps> = ({
  anchorEl,
  open,
  handleBack,
  handleClose,
}) => {
  const { t } = useTranslation('header');

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        mt: 2,
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          borderBottom: '1px solid #949494',
          padding: '8px 16px',
          marginBottom: '16px',
        }}
      >
        <IconButton onClick={handleBack}>
          <KeyboardBackspaceIcon />
        </IconButton>

        <Typography
          variant="subtitle1"
          component={'p'}
          sx={{ textWrap: 'nowrap' }}
        >
          {t('LANGUAGE_MENU.TITLE_CHOOSE_YOUR_LANGUAGE')}
        </Typography>
      </Stack>

      <LanguageSelector />
    </Menu>
  );
};

export default LanguageMenu;
