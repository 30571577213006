export function hexToRgba(hex: string, opacity: number): string {
  hex = hex.replace(/^#/, '');

  if (hex.length !== 3 && hex.length !== 6) {
    return `rgba(0, 0, 0, ${opacity})`;
  }

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
