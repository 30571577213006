import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Typography, Modal, Paper } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import { green, red } from '../../../../themes/colors/colors';
import GetSummaryService from '../../services/get/summary/getSummary';
import GetTradeHistoryService from '../../services/get/tradeHistory/getTradeHistory';
import { DayDetailsDTO } from '../../services/get/summary/models';
import { TradeDTO } from '../../services/get/tradeHistory/models';
import { handleError } from '../../services/get/summary/errors';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import classes from './summary.module.scss';
import { useTranslation } from 'react-i18next';
import SummarySkeleton from './SummarySkeleton';

interface SummaryProps {
  selectedAccountLogin: string | null;
  selectedDate: string | null;
  onTradeHistoryFetched: (tradeHistory: TradeDTO[]) => void;
  tableRef: React.RefObject<HTMLDivElement>;
  inAccordion?: boolean;
}

const Summary: React.FC<SummaryProps> = ({
  selectedAccountLogin,
  selectedDate,
  onTradeHistoryFetched,
  tableRef,
  inAccordion = false,
}) => {
  const { t } = useTranslation('area');
  const { tokens } = useAuth();
  const { isBetweenXSAndLG } = useBreakpoints();
  const [summary, setSummary] = useState<DayDetailsDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (selectedAccountLogin && selectedDate && tokens) {
      setLoading(true); // Mettre loading à true immédiatement

      const fetchSummary = async () => {
        try {
          const data: DayDetailsDTO = await GetSummaryService.getSummary(
            selectedAccountLogin,
            selectedDate,
            tokens.accessToken,
          );
          setSummary(data);
          if (inAccordion) {
            handleOpenModal(); // Ouvrir la modal si on est dans un Accordion
          }
        } catch (error) {
          handleError(error);
        } finally {
          setLoading(false);
        }
      };

      fetchSummary();
    } else {
      setSummary(null);
      setLoading(false);
    }
  }, [selectedAccountLogin, selectedDate, tokens, inAccordion]);

  const fetchTradeHistory = async () => {
    if (selectedAccountLogin && selectedDate && tokens) {
      setLoading(true);
      try {
        const data = await GetTradeHistoryService.getTradeHistory(
          selectedAccountLogin,
          tokens.accessToken,
          { date: selectedDate },
        );
        onTradeHistoryFetched(data.content);
        if (tableRef.current) {
          tableRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (inAccordion) {
          handleCloseModal();
        }
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleScrollToView = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (inAccordion) {
      handleCloseModal();
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const formatProfitLoss = (value: number | null) => {
    if (value === null || value === undefined) {
      return '-';
    }
    const color = value >= 0 ? green[400] : red[400];
    const sign = value >= 0 ? '+' : '-';
    return (
      <Typography variant="h5" component="h6" color={color}>
        {`${sign} $${Math.abs(value).toFixed(2)}`}
      </Typography>
    );
  };

  const getCardStyles = (value: number | null) => {
    if (value === null || value === undefined) {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        border: '2px solid #697399',
      };
    }
    return {
      backgroundColor:
        value >= 0 ? 'rgba(31, 204, 146, 0.5)' : 'rgba(231, 71, 80, 0.5)',
      border: `2px solid ${value >= 0 ? green[400] : red[400]}`,
    };
  };

  // Modifiez la logique de rendu comme suit :

  if (loading) {
    return <SummarySkeleton />;
  }

  if (!selectedDate) {
    return (
      <Card
        elevation={0}
        className={classes.summary}
        sx={{
          width: isBetweenXSAndLG ? '100%' : '30%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="h4">
          {t('SUMMARY.TEXT_NO_DAY_CHOSEN')}
        </Typography>
      </Card>
    );
  }

  if (!summary) {
    // Afficher le skeleton si les données ne sont pas disponibles
    return <SummarySkeleton />;
  }

  const summaryContent = (
    <Card elevation={0} className={classes.summary}>
      <Typography variant="h6" component="h4" mb={4}>
        {t('SUMMARY.TITLE_SUMMARY_OF', {
          date: formatDate(summary.date),
        })}
      </Typography>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }} mb={4}>
        <Card
          elevation={0}
          className={classes.summaryCard}
          sx={{ flex: '1 1 calc(50% - 16px)', boxSizing: 'border-box' }}
        >
          <Typography variant="h6" component="h5">
            {t('SUMMARY.TITLE_TRADES_TAKEN')}
          </Typography>
          <Typography variant="h5" component="h6" color={red[400]}>
            {summary.numberOfTrades ?? '-'}
          </Typography>
        </Card>

        <Card
          elevation={0}
          className={classes.summaryCard}
          sx={{ flex: '1 1 calc(50% - 16px)', boxSizing: 'border-box' }}
        >
          <Typography variant="h6" component="h5">
            {t('SUMMARY.TITLE_LOTS_USED')}
          </Typography>
          <Typography variant="h5" component="h6" color={red[400]}>
            {summary.lotsUsed ?? '-'}
          </Typography>
        </Card>

        <Card
          elevation={0}
          className={classes.summaryCard}
          sx={{
            flex: '1 1 calc(50% - 16px)',
            boxSizing: 'border-box',
          }}
        >
          <Typography variant="h6" component="h5">
            {t('SUMMARY.TITLE_ENDING_BALANCE')}
          </Typography>
          <Typography variant="h5" component="h6" color={red[400]}>
            {summary.finalBalance || 0}
          </Typography>
        </Card>

        <Card
          elevation={0}
          sx={{
            flex: '1 1 calc(50% - 16px)',
            boxSizing: 'border-box',
            ...getCardStyles(summary.totalProfitLoss || 0),
            padding: '8px',
          }}
        >
          <Typography variant="h6" component="h5">
            {t('SUMMARY.TITLE_PROFIT')}
          </Typography>
          {formatProfitLoss(summary.totalProfitLoss || 0)}
        </Card>
      </Box>
      <Button
        variant="text"
        size="large"
        startIcon={<TodayIcon />}
        onClick={handleScrollToView}
        disabled={loading}
      >
        {t('SUMMARY.BUTTON_SEE_TRADING_HISTORY_FOR_THIS_DAY')}
      </Button>
    </Card>
  );

  if (!selectedDate) {
    return (
      <Card
        elevation={0}
        className={classes.summary}
        sx={{
          width: isBetweenXSAndLG ? '100%' : '30%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="h4">
          {t('SUMMARY.TEXT_NO_DAY_CHOSEN')}
        </Typography>
      </Card>
    );
  }

  if (inAccordion) {
    // Affichage dans une modal si on est dans un AccordionCard
    return (
      <Modal open={!!selectedDate && modalOpen} onClose={handleCloseModal}>
        <Paper
          elevation={0}
          sx={{
            padding: '16px',
            margin: 'auto',
            width: isBetweenXSAndLG ? '90%' : '30%',
            marginTop: '10%',
          }}
        >
          {summaryContent}
        </Paper>
      </Modal>
    );
  }

  // Affichage normal si on n'est pas dans un AccordionCard
  return summaryContent;
};

export default Summary;
