import { toast } from 'react-toastify';

import axiosInstance from '../../../utils/axios';
import { KycDocumentResponse } from './interface';

export const fetchKycDocuments = async (
  accessToken: string,
  page: number,
  size: number,
): Promise<KycDocumentResponse> => {
  try {
    const response = await axiosInstance.get<KycDocumentResponse>(
      `/kyc/pending?page=${page}&size=${size}`,
      {
        headers: { Authorization: accessToken },
      },
    );

    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des kyc documents');
  }
};

export const acceptKycRequest = async (accessToken: string, id: number) => {
  try {
    const response = await axiosInstance.post(`/kyc/approve/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });
    if (response.status === 200) {
      toast.success('Le requête a été acceptée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le requête n'a pas pu être acceptée", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const refuseKycRequest = async (
  accessToken: string,
  id: number,
  reason: string,
) => {
  try {
    const response = await axiosInstance.post(
      `/kyc/reject/${id}`,
      {
        reason: reason,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );
    if (response.status === 200) {
      toast.success('Le requête a été rejetée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le requête n'a pas pu être rejetée", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const downloadKycDocument = async (
  accessToken: string,
  id: number,
  fileName: string,
) => {
  try {
    const response = await axiosInstance.get(`/kyc/download/${id}`, {
      headers: { Authorization: accessToken },
      responseType: 'blob',
    });
    const blob = new Blob([response.data]);
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Erreur lors du téléchargement de fichier Kyc', error);
    throw new Error('Erreur lors du téléchargement de fichier Kyc');
  }
};
