import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../../context/AuthContext/AuthContext';

import { green, yellow } from '../../../../themes/index';

import getUserPatrimony from '../../services/get/patrimony/getUserPatrimony';
import { PatrimonyInformation } from '../../services/get/patrimony/models';

import { HeaderCard } from '../../index';

import RadarIcon from '@mui/icons-material/Radar';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WalletIcon from '@mui/icons-material/Wallet';

import WithdrawModal from '../../components/WithdrawModal/WithdrawModal';

import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const HeaderCards = () => {
  const theme = useTheme();
  const { t } = useTranslation('area');
  const { tokens, isTokensLoading, user } = useAuth();

  const [patrimony, setPatrimony] = useState<PatrimonyInformation | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    const fetchPatrimony = async () => {
      if (user && tokens && !isTokensLoading) {
        setLoading(true);
        try {
          const data = await getUserPatrimony.getUserPatrimony(
            user.userId,
            tokens.accessToken,
          );
          setPatrimony(data);
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message);
          } else {
            setError('An unexpected error occurred');
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPatrimony();
  }, [user, tokens, isTokensLoading]);

  return (
    <>
      <Stack direction="row" flexWrap="wrap" sx={{ rowGap: 2, columnGap: 4 }}>
        <HeaderCard
          icon={<RadarIcon sx={{ fill: theme.palette.primary.main }} />}
          value={patrimony?.activeChallenge || 0}
          title={t('HEADER_CARDS.TITLE_ACTIVE_CHALLENGES')}
          iconColor={theme.palette.primary.main}
          isLoading={loading}
        />
        <HeaderCard
          icon={<AccountBalanceIcon sx={{ fill: green[300] }} />}
          value={patrimony?.activeFunded || 0}
          title={t('HEADER_CARDS.TITLE_FUNDED_ACCOUNTS')}
          iconColor={green[300]}
          isLoading={loading}
        />
        <HeaderCard
          icon={<WalletIcon sx={{ fill: yellow[700] }} />}
          value={`${patrimony?.walletBalance}€`}
          walletVariation={patrimony?.walletVariation}
          title={t('HEADER_CARDS.TITLE_CURRENT_WALLET')}
          iconColor={yellow[700]}
          withdrawButton
          onClick={handleOpenModal}
          isLoading={loading}
        />
      </Stack>

      <WithdrawModal
        open={openModal}
        onClose={handleCloseModal}
        walletBalance={Number(patrimony?.walletBalance)}
        setWalletBalance={(newBalance: number) =>
          setPatrimony((prev) =>
            prev ? { ...prev, walletBalance: newBalance.toString() } : null,
          )
        }
      />
    </>
  );
};

export default HeaderCards;
