import { useState } from 'react';
import ScaleUpAccountService from '../../../services/post/scaleUp/postScaleUpAccount';

const useScaleUp = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>(
    'success',
  );
  const [loading, setLoading] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleScaleUpAccount = async (accountUUID: string) => {
    setLoading(true);
    try {
      await ScaleUpAccountService.scaleUpAccount(
        accountUUID,
        (message: string) => {
          setSnackbarMessage(message);
          setSnackbarType('success');
          setSnackbarOpen(true);
        },
        (error: string) => {
          setSnackbarMessage(error);
          setSnackbarType('error');
          setSnackbarOpen(true);
        },
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleScaleUpAccount,
    snackbarOpen,
    snackbarMessage,
    snackbarType,
    handleSnackbarClose,
    loading,
  };
};

export default useScaleUp;
