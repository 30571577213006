import classes from './_tooltip.module.scss';

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  position?: string;
  className?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  position = classes.top,
  className,
}) => {
  return (
    <div className={`${classes.tooltipContainer} ${className || ''}`}>
      {children}
      <div className={`${classes.tooltip} ${position}`}>{content}</div>
    </div>
  );
};

export default Tooltip;
