import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Card, Divider, Stack, Typography } from '@mui/material';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { useLightDashboard } from '../../context/LightDashboardContext/LightDashboardContext';
import OverviewCardSkeleton from './OverviewCardSkeleton';
import { useTranslation } from 'react-i18next';

const OverviewCard = () => {
  const navigate = useNavigate();
  const { isBetweenXSAndLG } = useBreakpoints();
  const { data, isLoading, error } = useLightDashboard();
  const { t } = useTranslation('lightDashboard');

  if (isLoading) {
    return <OverviewCardSkeleton />;
  } else if (!data) {
    return null;
  }

  return (
    <Card elevation={0} sx={{ padding: '32px' }}>
      <Stack
        direction={isBetweenXSAndLG ? 'column' : 'row'}
        width={'100%'}
        justifyContent={'space-between'}
      >
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          gap={2}
        >
          <Typography
            variant="h6"
            component={'h5'}
            fontWeight={'normal'}
            textAlign={'center'}
          >
            {t('OVERVIEW_CARD.AFFILIATION_PAYOUT_REQUEST')}
          </Typography>
          <Typography variant="h5" component={'h6'} fontWeight={'bold'}>
            {data.affiliationPayoutRequest.toLocaleString('fr-FR')}
          </Typography>
          <Button
            variant="text"
            size="large"
            onClick={() => navigate('/admin/cash-management')}
          >
            {t('OVERVIEW_CARD.BUTTON_SHOW_ALL')}
          </Button>
        </Stack>
        <Divider
          orientation={isBetweenXSAndLG ? 'horizontal' : 'vertical'}
          variant="middle"
          flexItem
          sx={{
            borderRightWidth: '2px',
            margin: isBetweenXSAndLG ? '20px 0px' : '0px',
          }}
        />

        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          gap={2}
        >
          <Typography
            variant="h6"
            component={'h5'}
            fontWeight={'normal'}
            textAlign={'center'}
          >
            {t('OVERVIEW_CARD.PAYMENT_ERROR')}
          </Typography>
          <Typography variant="h5" component={'h6'} fontWeight={'bold'}>
            {data.paymentError.toLocaleString('fr-FR')}
          </Typography>
          <Button
            variant="text"
            size="large"
            onClick={() => navigate('/admin/cash-management')}
          >
            {t('OVERVIEW_CARD.BUTTON_SHOW_ALL')}
          </Button>
        </Stack>
        <Divider
          orientation={isBetweenXSAndLG ? 'horizontal' : 'vertical'}
          variant="middle"
          flexItem
          sx={{
            borderRightWidth: '2px',
            margin: isBetweenXSAndLG ? '20px 0px' : '0px',
          }}
        />

        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          gap={2}
        >
          <Typography
            variant="h6"
            component={'h5'}
            fontWeight={'normal'}
            textAlign={'center'}
          >
            {t('OVERVIEW_CARD.WITHDRAW_REQUEST')}
          </Typography>
          <Typography variant="h5" component={'h6'} fontWeight={'bold'}>
            {data.withdrawRequest.toLocaleString('fr-FR')}
          </Typography>
          <Button
            variant="text"
            size="large"
            onClick={() => navigate('/admin/cash-management')}
          >
            {t('OVERVIEW_CARD.BUTTON_SHOW_ALL')}
          </Button>
        </Stack>
        <Divider
          orientation={isBetweenXSAndLG ? 'horizontal' : 'vertical'}
          variant="middle"
          flexItem
          sx={{
            borderRightWidth: '2px',
            margin: isBetweenXSAndLG ? '20px 0px' : '0px',
          }}
        />

        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          gap={2}
        >
          <Typography
            variant="h6"
            component={'h5'}
            fontWeight={'normal'}
            textAlign={'center'}
          >
            {t('OVERVIEW_CARD.PENDING_KYC_REQUEST')}
          </Typography>
          <Typography variant="h5" component={'h6'} fontWeight={'bold'}>
            {data.pendingKycRequest.toLocaleString('fr-FR')}
          </Typography>
          <Button
            variant="text"
            size="large"
            onClick={() => navigate('/admin/kyc')}
          >
            {t('OVERVIEW_CARD.BUTTON_SHOW_ALL')}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default OverviewCard;
