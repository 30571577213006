import React from 'react';

import {
  Box,
  Typography,
  Modal,
  Stack,
  Paper,
  IconButton,
  Divider,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import classes from './modal.module.scss';

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  handleClose,
  title,
  children,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-content"
    >
      <Paper elevation={0} className={classes.modal}>
        <Stack
          id="modal-modal-title"
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          pl={4}
          pr={4}
          pb={2}
          pt={2}
        >
          <Typography variant="h5" component={'h2'}>
            {title}
          </Typography>
          <IconButton onClick={handleClose} aria-label="close-modal">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <Stack className={classes.content} id="modal-modal-content">
          {children}
        </Stack>
      </Paper>
    </Modal>
  );
};

export default CustomModal;
