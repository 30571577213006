import React, { useEffect, useState } from 'react';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { DaysInMonthCards } from '../../index';
import { Button, ButtonGroup, Card, Stack, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import classes from './calendar.module.scss';
import GetAccountPnLCalendarService from '../../services/get/calendar/getAccountPnLCalendar';
import {
  MonthProfitLossDTO,
  DayProfitLossDTO,
} from '../../services/get/calendar/models';
import { handleError } from '../../services/get/calendar/errors';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';
import CalendarSkeleton from './CalendarSkeleton';

interface CalendarProps {
  elevation: number;
  selectedAccountLogin: string | null;
  onSelectDate: (date: string) => void;
  onMonthYearChange?: (month: number, year: number) => void;
  onResetPagination: () => void;
  currentMonth: number; // Ajout de currentMonth
  currentYear: number; // Ajout de currentYear
}

const Calendar: React.FC<CalendarProps> = ({
  elevation,
  selectedAccountLogin,
  onSelectDate,
  onMonthYearChange,
  onResetPagination,
  currentMonth, // Ajout de currentMonth en props
  currentYear, // Ajout de currentYear en props
}) => {
  const { i18n } = useTranslation();
  const { tokens } = useAuth();
  const { isBetweenXSAndSM } = useBreakpoints();
  const [daysInfo, setDaysInfo] = useState<DayProfitLossDTO[]>([]);
  const [month, setMonth] = useState<number>(currentMonth); // Utilise les valeurs des props
  const [year, setYear] = useState<number>(currentYear);
  const [loading, setLoading] = useState<boolean>(true);
  const [prevMonthAvailable, setPrevMonthAvailable] = useState<boolean>(false);
  const [nextMonthAvailable, setNextMonthAvailable] = useState<boolean>(false);

  useEffect(() => {
    setMonth(currentMonth); // Met à jour si le mois change dans le parent
    setYear(currentYear); // Met à jour si l'année change dans le parent
  }, [currentMonth, currentYear]);

  const fetchPnLCalendar = async (
    accountLogin: string,
    year: number,
    month: number,
    token: string,
  ) => {
    setLoading(true);
    try {
      const data: MonthProfitLossDTO =
        await GetAccountPnLCalendarService.getAccountPnLCalendar(
          accountLogin,
          year,
          month,
          token,
        );
      setDaysInfo(data.days);
      setPrevMonthAvailable(data.prevMonth);
      setNextMonthAvailable(data.nextMonth);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAccountLogin && tokens) {
      fetchPnLCalendar(selectedAccountLogin, year, month, tokens.accessToken);
    }
  }, [selectedAccountLogin, year, month, tokens]);

  const handlePrevMonth = async () => {
    const newMonth = month === 1 ? 12 : month - 1;
    const newYear = month === 1 ? year - 1 : year;
    setMonth(newMonth);
    setYear(newYear);
    await fetchPnLCalendar(
      selectedAccountLogin!,
      newYear,
      newMonth,
      tokens!.accessToken,
    );
    if (onMonthYearChange) onMonthYearChange(newMonth, newYear);
  };

  const handleNextMonth = async () => {
    const newMonth = month === 12 ? 1 : month + 1;
    const newYear = month === 12 ? year + 1 : year;
    setMonth(newMonth);
    setYear(newYear);
    await fetchPnLCalendar(
      selectedAccountLogin!,
      newYear,
      newMonth,
      tokens!.accessToken,
    );
    if (onMonthYearChange) onMonthYearChange(newMonth, newYear);
  };

  const handleDayClick = (date: string) => {
    onSelectDate(date);
    onResetPagination();
  };

  const transformedDaysInfo = daysInfo.map((day) => ({
    day: new Date(day.date).getDate(),
    isProfit: day.totalProfitLoss >= 0,
    totalProfitLoss: day.totalProfitLoss,
  }));

  const formattedMonth = new Date(year, month - 1)
    .toLocaleString(i18n.language, { month: 'long', year: 'numeric' })
    .replace(/^\w/, (c) => c.toUpperCase());

  if (loading) {
    return <CalendarSkeleton elevation={elevation} />;
  }

  return (
    <>
      <Card elevation={elevation} className={classes.calendar}>
        <Stack direction="row" justifyContent="space-between" mb={10}>
          <Typography variant="h4" component="h4">
            {formattedMonth}
          </Typography>
          <ButtonGroup
            variant="contained"
            size={isBetweenXSAndSM ? 'small' : 'medium'}
          >
            <Button
              onClick={handlePrevMonth}
              disabled={!prevMonthAvailable || loading}
            >
              <WestIcon />
            </Button>
            <Button
              onClick={handleNextMonth}
              disabled={!nextMonthAvailable || loading}
            >
              <EastIcon />
            </Button>
          </ButtonGroup>
        </Stack>
        <DaysInMonthCards
          year={year}
          month={month}
          daysInfo={transformedDaysInfo}
          onDayClick={handleDayClick}
        />
      </Card>
    </>
  );
};

export default Calendar;
