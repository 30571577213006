import axiosInstance from '../../../../../utils/axios';
import { IGetTradeHistoryService } from './interfaces';
import { PageableTradeDTO } from './models';
import { handleError } from './errors';

class GetTradeHistoryService implements IGetTradeHistoryService {
  async getTradeHistory(
    accountLogin: string,
    token: string,
    params?: {
      date?: string;
      year?: number;
      month?: number;
      page?: number;
      size?: number;
    },
  ): Promise<PageableTradeDTO> {
    try {
      const response = await axiosInstance.get<PageableTradeDTO>(
        `/v2/trading-account/account/${accountLogin}/trade-history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          params: {
            ...params,
          },
        },
      );
      return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new GetTradeHistoryService();
