// import { GetDiscordUsersResponse, DiscordUser } from './interfaces';
// import { handleGetDiscordUsersError } from './errors';
// import axiosInstance from '../../../../../utils/axios'; // Assurez-vous que le chemin est correct

// export async function getDiscordUsers(): Promise<GetDiscordUsersResponse> {
//   try {
//     // Utilisation de l'instance axios pour faire la requête GET
//     const response =
//       await axiosInstance.get<GetDiscordUsersResponse>('/discord/users');

//     // Si la réponse contient directement les utilisateurs
//     const users: DiscordUser[] = response.data.users;

//     return { users };
//   } catch (error) {
//     // Gestion des erreurs via la fonction dédiée
//     handleGetDiscordUsersError(error);
//     throw error;
//   }
// }

import { GetDiscordUsersResponse, DiscordUser } from './interfaces';
import { handleGetDiscordUsersError } from './errors';

export async function getDiscordUsers(accessToken: string): Promise<GetDiscordUsersResponse> {
  try {
    const response = await fetch(
      'https://preprod.raisemyfunds.com:4000/api/discord/users',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken || ''}`
        },
      },
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'Failed to fetch Discord users');
    }

    // Récupérer la réponse JSON
    const data = await response.json();
    // console.log('API Response:', data);

    // Adapter le format si nécessaire et renvoyer le bon type
    const users: DiscordUser[] = data.map((user: any) => ({
      displayName: user.displayName,
      id: user.id,
    }));

    return { users };
  } catch (error) {
    handleGetDiscordUsersError(error);
    throw error;
  }
}

// export async function getDiscordUsers() {
//   const url =
//     'https://95a7-2a01-e0a-300-f6e0-c183-1509-b9d3-bac.ngrok-free.app/api/discord/users';

//   try {
//     const response = await fetch(url);

//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const data = await response.json();
//     console.log('Discord Users:', data);
//   } catch (error) {
//     console.error('Error fetching Discord users:', error);
//   }
// }

// // Appel de la fonction
