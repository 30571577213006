import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';

import { logout } from '../../../services/auth/login';
import { accountInformations } from '../../../services/admin/userAbout/userAbout';
import {
  AffiliationContent,
  WithdrawRequestResponseContent,
  CreationErrorsContent,
  PaymentErrorsContent,
  PayoutRequestsContent,
} from '../../../services/admin/cashManagement/interface';
import {
  acceptAffiliationPayoutRequest,
  acceptTradingPayoutRequest,
  acceptWithdrawRequest,
  executePayoutRequest,
  exportAffiliationPayoutToXLS,
  fetchAffiliationPayoutRequests,
  fetchCreationErrors,
  fetchGetPayoutRequests,
  fetchPaymentErrors,
  fetchReferralPayoutRequest,
  fetchWithdrawRequests,
  refuseAffiliationPayoutRequest,
  refuseTradingPayoutRequest,
  refuseWithdrawRequest,
  resolvePaymentError,
} from '../../../services/admin/cashManagement/cashManagement';

import { useAuth } from '../../../context/AuthContext/AuthContext';

import useDocumentTitle from '../../../hooks/useDocumentTitle';

import Tab from '../../../components/Tab/Tab';
import Loader from '../../../components/Loader/Loader';
import UserProfile from '../../../components/Admin/UserProfile/UserProfile';
import Pagination from '../../../components/Pagination/Pagination';
import AdminTable from '../../../components/Admin/AdminTable/AdminTable';
import Icon from '../../../components/userInterface/Icon/Icon';
import Filter, { FilterType } from '../../../components/Filter/Filter';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import classes from './_cashManagement.module.scss';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { stringify } from 'querystring';

const CashManagement = () => {
  const { tokens, isTokensLoading } = useAuth();
  const { isDarkMode } = useThemeContext();
  useDocumentTitle('Cash Management');
  const size = 10;

  const WithdrawfilterTypes: FilterType[] = [
    { key: 'id', type: 'string' },
    { key: 'amount', type: 'string' },
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'email', type: 'string' },
    { key: 'uuid', type: 'string' },
  ];

  const AffiliationPayoutfilterTypes: FilterType[] = [
    { key: 'amount', type: 'string' },
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'email', type: 'string' },
    { key: 'uuid', type: 'string' },
  ];

  const TradingPayoutRequestsTypes: FilterType[] = [
    { key: 'userUUID', type: 'string' },
    { key: 'userEmail', type: 'string' },
    { key: 'userPhoneNumber', type: 'string' },
    { key: 'userCountry', type: 'string' },
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'payoutId', type: 'string' },
    { key: 'lastname', type: 'string' },
  ];

  const PaymentErrorsFilterTypes: FilterType[] = [
    { key: 'firstname', type: 'string' },
    { key: 'externalId', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'email', type: 'string' },
    { key: 'paymentType', type: 'string' },
  ];

  const CreationErrorsFilterTypes: FilterType[] = [
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'email', type: 'string' },
    { key: 'paymentType', type: 'string' },
  ];

  const tableWithdrawRequestsData = [];
  const tableAffiliationPayoutRequestsData = [];
  const tableReferralPayoutRequestsData = [];
  const tablePaymentErrorsData = [];
  const tableTradingPayoutRequestsData = [];
  const tableCreationErrorsData = [];
  let tableWithdrawRequestsColumns = [
    'ID',
    'Label',
    'Amount',
    'Request Date',
    'KYC Verified',
    'Firstname',
    'Lastname',
    'Email',
  ];
  const tableAffiliationPayoutRequestsColumns = [
    'Active',
    'KYC',
    'ID',
    'Status',
    'Crypto Address',
    'Crypto Network',
    'Firstname',
    'Lastname',
    'Commissions',
    'Month',
    'Affiliate Name',
    'Percentage',
    'Unique Users',
    'Sales',
    'Total Paid',
    'Affiliation Type',
    'Admin ID',
    'Email',
    'Comment',
  ];

  const tableReferralPayoutRequestsColumns = [
    'Status',
    'Comment',
    'ID',
    'Admin ID',
    'Email',
    'Firstname',
    'Lastname',
    'Unique Users',
    'Sales',
    'Commissions',
    'Total Paid',
    'Affiliation Type',
    'Month',
    'KYC',
  ];

  const tablePaymentErrorsColumns = [
    'ID',
    'External ID',
    'Amount Paid',
    'Amount Requested',
    'Error Date',
    'Status',
    'Payment Type',
    'Error Message',
    'Firstname',
    'Lastname',
    'Email',
  ];
  const tableCreationErrorsColumns = [
    'ID',
    'Amount Paid',
    'Amount Requested',
    'Error Date',
    'Status',
    'Payment Type',
    'Error Message',
    'Firstname',
    'Lastname',
    'Email',
  ];
  const tableTradingPayoutRequestsColumns = [
    'ID',
    'MT5 Account',
    'Amount Client',
    'Amount Propfirm',
    'Creation Date',
    'Payout Status',
    'Firstname',
    'Lastname',
    'Email',
    'User ID',
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [userUuid, setUserUuid] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [withdrawType, setWithdrawType] = useState('crypto');
  const [canExecute, setCanExecute] = useState<string>('false');

  const [WithdrawRequestsfilters, setWithdrawRequestsFilters] = useState<{
    [key: string]: string;
  }>({});
  const [affiliationPayoutfilters, setaffiliationPayoutFilters] = useState<{
    [key: string]: string;
  }>({});
  const [tradingPayoutfilters, setTradingPayoutFilters] = useState<{
    [key: string]: string;
  }>({});

  const [paymentErrorsFilters, setPaymentErrorsFilters] = useState<{
    [key: string]: string;
  }>({});

  const [creationErrorsFilters, setCreationErrorsFilters] = useState<{
    [key: string]: string;
  }>({});

  const [withdrawRequests, setWithdrawRequests] = useState<
    WithdrawRequestResponseContent[]
  >([]);
  const [withdrawRequestsPage, setWithdrawRequestsPage] = useState<number>(0);
  const [totalWithdrawRequestsPages, setTotalWithdrawRequestsPages] =
    useState<number>(0);
  const [withdrawRequestTotalOfElements, setWithdrawRequestTotalOfElements] =
    useState<number>(0);

  const [affiliatePayoutRequests, setAffiliatePayoutRequests] = useState<
    AffiliationContent[]
  >([]);
  const [affiliatePayoutRequestsPage, setAffiliatePayoutRequestsPage] =
    useState<number>(0);
  const [
    totalAffiliatePayoutRequestsPages,
    setTotalAffiliatePayoutRequestsPages,
  ] = useState<number>(0);
  const [
    affiliatePayoutRequestTotalOfElements,
    setAffiliatePayoutRequestTotalOfElements,
  ] = useState<number>(0);

  const [paymentErrors, setPaymentErrors] = useState<PaymentErrorsContent[]>(
    [],
  );
  const [paymentErrorsPage, setPaymentErrorsPage] = useState<number>(0);
  const [totalPaymentErrorsPages, setTotalPaymentErrorsPages] =
    useState<number>(0);
  const [paymentErrorTotalOfElements, setPaymentErrorTotalOfElements] =
    useState<number>(0);

  const [tradingPayoutRequests, setTradingPayoutRequests] = useState<
    PayoutRequestsContent[]
  >([]);
  const [tradingPayoutRequestsPage, setTradingPayoutRequestsPage] =
    useState<number>(0);
  const [totalTradingPayoutRequestsPages, setTotalTradingPayoutRequestsPages] =
    useState<number>(0);
  const [
    tradingPayoutRequestTotalOfElements,
    setTradingPayoutRequestTotalOfElements,
  ] = useState<number>(0);

  const [creationErrors, setCreationErrors] = useState<CreationErrorsContent[]>(
    [],
  );
  const [creationErrorsPage, setCreationErrorsPage] = useState<number>(0);
  const [totalCreationErrorsPages, setTotalCreationErrorsPages] =
    useState<number>(0);
  const [creationErrorTotalOfElements, setCreationErrorTotalOfElements] =
    useState<number>(0);

  const [referralPayoutRequests, setReferralPayoutRequests] = useState<
    AffiliationContent[]
  >([]);
  const [referralPayoutRequestsPage, setReferralPayoutRequestsPage] =
    useState<number>(0);
  const [
    totalReferralPayoutRequestsPages,
    setTotalReferralPayoutRequestsPages,
  ] = useState<number>(0);
  const [
    referralPayoutRequestTotalOfElements,
    setReferralePayoutRequestTotalOfElements,
  ] = useState<number>(0);

  const tabNames = [
    <div className={classes.tabNamesContainer} key={'withdrawRequest'}>
      Withdraw Request{' '}
      <span className={classes.chip}>{withdrawRequestTotalOfElements}</span>
    </div>,
    <div className={classes.tabNamesContainer} key={'affiliatePayoutRequests'}>
      Affiliate Payout Requests{' '}
      <span className={classes.chip}>
        {affiliatePayoutRequestTotalOfElements}
      </span>
    </div>,
    <div className={classes.tabNamesContainer} key={'referralPayoutRequest'}>
      Referral Payout Request{' '}
      <span className={classes.chip}>
        {referralPayoutRequestTotalOfElements}
      </span>
    </div>,
    <div className={classes.tabNamesContainer} key={'tradingPayoutRequests'}>
      Trading Payout Requests{' '}
      <span className={classes.chip}>
        {tradingPayoutRequestTotalOfElements}
      </span>
    </div>,
    <div className={classes.tabNamesContainer} key={'paymentError'}>
      Payment Error{' '}
      <span className={classes.chip}>{paymentErrorTotalOfElements}</span>
    </div>,
  ];

  const handleChangeWithdrawType = (event: any) => {
    setWithdrawType(event.target.value);
  };

  for (const request of withdrawRequests) {
    tableWithdrawRequestsData.push({
      ID: request.id,
      Amount: request.amount,
      'Request Date': request.requestDate,
      'KYC Verified': request.kycverified ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      ),
      Firstname: request.firstname,
      Lastname: request.lastname,
      Email: request.email,
      Label: request.label,
      UUID: request.userUUID,
      Address: request.cryptoWalletAddress,
      Network: request.cryptoWalletNetwork,
    });
  }

  for (const request of affiliatePayoutRequests) {
    tableAffiliationPayoutRequestsData.push({
      Status: request.checkStatus ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      ),
      Comment: request.comment,
      UUID: request.userUUID,
      ID: request.requestId,
      'Admin ID': request.adminId,
      Email: request.userEmail,
      Firstname: request.userFirstname,
      Lastname: request.userLastname,
      'Affiliate Name': request.affiliateDisplayName,
      Percentage: request.affiliationPercentage,
      'Unique Users': request.totalUniqueUsers,
      Sales: request.totalChallengeSales,
      Commissions: request.totalCommission,
      'Total Paid': request.totalPaid,
      'Affiliation Type': request.affiliationType,
      Month: request.month,
      'Crypto Address': request.cryptoWalletAddress,
      'Crypto Network': request.cryptoNetwork,
      KYC: request.kycValidated ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      ),
      Active: request.active ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      ),
    });
  }

  for (const request of referralPayoutRequests) {
    tableReferralPayoutRequestsData.push({
      Status: request.checkStatus ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      ),
      Comment: request.comment,
      UUID: request.userUUID,
      ID: request.requestId,
      'Admin ID': request.adminId,
      Email: request.userEmail,
      Firstname: request.userFirstname,
      Lastname: request.userLastname,
      'Unique Users': request.totalUniqueUsers,
      Sales: request.totalChallengeSales,
      Commissions: request.totalCommission,
      'Total Paid': request.totalPaid,
      'Affiliation Type': request.affiliationType,
      Month: request.month,
      KYC: request.kycValidated ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      ),
    });
  }

  for (const error of paymentErrors) {
    tablePaymentErrorsData.push({
      ID: error.id,
      'External ID': error.externalId,
      'Amount Paid': error.amountPaid,
      'Amount Requested': error.amountRequested,
      'Error Date': error.errorDate,
      Status: error.status,
      'Payment Type': error.paymentType,
      'Error Message': error.errorMessage,
      Firstname: error.firstname,
      Lastname: error.lastname,
      Email: error.userMail,
    });
  }

  for (const error of creationErrors) {
    tableCreationErrorsData.push({
      ID: error.id,
      'Amount Paid': error.amountPaid,
      'Amount Requested': error.amountRequested,
      'Error Date': error.errorDate,
      Status: error.status,
      'Payment Type': error.paymentType,
      'Error Message': error.errorMessage,
      Firstname: error.firstname,
      Lastname: error.lastname,
      Email: error.userMail,
    });
  }

  for (const request of tradingPayoutRequests) {
    tableTradingPayoutRequestsData.push({
      ID: request.id,
      'MT5 Account': request.mt5Account,
      'Amount Client': request.amountClient,
      'Amount Propfirm': request.amountPropfirm,
      'Creation Date': request.creationDate,
      'Payout Status': request.payoutStatus,
      Firstname: request.firstname,
      Lastname: request.lastname,
      Email: request.email,
      UUID: request.userUUID,
      'User ID': request.userId,
    });
  }

  const loadWithdrawRequests = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchWithdrawRequests(
          tokens.accessToken,
          withdrawRequestsPage,
          size,
          withdrawType,
          WithdrawRequestsfilters,
        );
        setWithdrawRequests(response.content);
        setTotalWithdrawRequestsPages(response.totalPages);
        setWithdrawRequestTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [
    tokens,
    WithdrawRequestsfilters,
    withdrawRequestsPage,
    size,
    withdrawType,
  ]);

  const loadAffiliatePayoutRequests = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchAffiliationPayoutRequests(
          tokens.accessToken,
          affiliatePayoutRequestsPage,
          size,
          affiliationPayoutfilters,
        );
        setAffiliatePayoutRequests(response.content);
        setTotalAffiliatePayoutRequestsPages(response.totalPages);
        setAffiliatePayoutRequestTotalOfElements(response.totalElements);
        setCanExecute(response.canExecuteHeader);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [
    tokens,
    affiliationPayoutfilters,
    affiliatePayoutRequestsPage,
    canExecute,
    size,
  ]);

  const loadReferralPayoutRequests = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchReferralPayoutRequest(
          tokens.accessToken,
          referralPayoutRequestsPage,
          size,
          affiliationPayoutfilters,
        );
        setReferralPayoutRequests(response.content);
        setTotalReferralPayoutRequestsPages(response.totalPages);
        setReferralePayoutRequestTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, affiliationPayoutfilters, referralPayoutRequestsPage, size]);

  const loadPaymentErrors = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchPaymentErrors(
          tokens.accessToken,
          paymentErrorsPage,
          size,
          paymentErrorsFilters,
        );
        setPaymentErrors(response.content);
        setTotalPaymentErrorsPages(response.totalPages);
        setPaymentErrorTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, paymentErrorsFilters, paymentErrorsPage, size]);

  const loadCreationErrors = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchCreationErrors(
          tokens.accessToken,
          creationErrorsPage,
          size,
          creationErrorsFilters,
        );
        setCreationErrors(response.content);
        setTotalCreationErrorsPages(response.totalPages);
        setCreationErrorTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, creationErrorsFilters, creationErrorsPage, size]);

  const loadTradingPayoutRequests = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchGetPayoutRequests(
          tokens.accessToken,
          tradingPayoutRequestsPage,
          size,
          tradingPayoutfilters,
        );
        setTradingPayoutRequests(response.content);
        setTotalTradingPayoutRequestsPages(response.totalPages);
        setTradingPayoutRequestTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, tradingPayoutfilters, tradingPayoutRequestsPage, size]);

  useEffect(() => {
    if (!isTokensLoading) {
      loadWithdrawRequests();
      loadAffiliatePayoutRequests();
      loadReferralPayoutRequests();
      loadPaymentErrors();
      loadCreationErrors();
      loadTradingPayoutRequests();
    }
  }, [
    loadWithdrawRequests,
    loadAffiliatePayoutRequests,
    loadReferralPayoutRequests,
    loadPaymentErrors,
    loadCreationErrors,
    loadTradingPayoutRequests,
    isTokensLoading,
  ]);

  useEffect(() => {
    const filtersAreSet = Object.values(WithdrawRequestsfilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadWithdrawRequests();
    }
  }, [WithdrawRequestsfilters, loadWithdrawRequests]);

  useEffect(() => {
    const filtersAreSet = Object.values(affiliationPayoutfilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadAffiliatePayoutRequests();
    }
  }, [affiliationPayoutfilters, loadAffiliatePayoutRequests]);

  useEffect(() => {
    const filtersAreSet = Object.values(tradingPayoutfilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadTradingPayoutRequests();
    }
  }, [tradingPayoutfilters, loadTradingPayoutRequests]);

  useEffect(() => {
    const filtersAreSet = Object.values(paymentErrorsFilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadPaymentErrors();
    }
  }, [paymentErrorsFilters, loadPaymentErrors]);

  useEffect(() => {
    const filtersAreSet = Object.values(creationErrorsFilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadCreationErrors();
    }
  }, [creationErrorsFilters, loadCreationErrors]);

  const userAccount = async (uuid: string) => {
    if (tokens && tokens.accessToken) {
      await accountInformations(tokens.accessToken, uuid);
      setUserUuid(uuid);
      openModal();
    }
  };

  if (withdrawType === 'crypto') {
    tableWithdrawRequestsColumns = tableWithdrawRequestsColumns.concat([
      'Address',
      'Network',
    ]);
  }

  const handleAcceptWithdrawRequest = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await acceptWithdrawRequest(tokens.accessToken, id, withdrawType);
      loadWithdrawRequests();
    } else {
      logout();
    }
  };
  const handleRefuseWithdrawRequest = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await refuseWithdrawRequest(tokens.accessToken, id);
      loadWithdrawRequests();
    } else {
      logout();
    }
  };

  const handleAcceptAffiliationPayoutRequest = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await acceptAffiliationPayoutRequest(tokens.accessToken, id);
      loadAffiliatePayoutRequests();
    } else {
      logout();
    }
  };
  const handleExecuteAffiliationPayoutRequest = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await executePayoutRequest(tokens.accessToken, id);
      loadAffiliatePayoutRequests();
    } else {
      logout();
    }
  };
  const handleRefuseAffiliationPayoutRequest = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await refuseAffiliationPayoutRequest(tokens.accessToken, id);
      loadAffiliatePayoutRequests();
    } else {
      logout();
    }
  };

  const handleAcceptTradingPayoutRequest = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await acceptTradingPayoutRequest(tokens.accessToken, id);
      loadTradingPayoutRequests();
    } else {
      logout();
    }
  };
  const handleRefuseTradingPayoutRequest = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await refuseTradingPayoutRequest(tokens.accessToken, id);
      loadTradingPayoutRequests();
    } else {
      logout();
    }
  };

  const handleResolvePaymentError = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await resolvePaymentError(tokens.accessToken, id);
      loadPaymentErrors();
    } else {
      logout();
    }
  };

  const handleExportAffiliationPayoutClick = async () => {
    if (tokens && tokens.accessToken) {
      try {
        await exportAffiliationPayoutToXLS(
          tokens.accessToken,
          affiliatePayoutRequestsPage,
          size,
          affiliationPayoutfilters,
        );
        toast.success('Le téléchargement va commencer.', {
          progressStyle: { backgroundColor: 'green' },
        });
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de l'export.", {
          progressStyle: { backgroundColor: 'red' },
        });
      }
    } else {
      logout();
    }
  };

  const handleApplyWithdrawRequestsSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setWithdrawRequestsFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveWithdrawRequestsFilter = useCallback(
    (filterType: string) => {
      setWithdrawRequestsFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: '',
      }));
    },
    [],
  );

  const handleApplyAffiliatePayoutSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setaffiliationPayoutFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveAffiliatePayoutFilter = useCallback(
    (filterType: string) => {
      setaffiliationPayoutFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: '',
      }));
    },
    [],
  );

  const handleApplyTradingPayoutSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setTradingPayoutFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveTradingPayoutFilter = useCallback((filterType: string) => {
    setTradingPayoutFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  const handleApplyPaymentErrorsSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setPaymentErrorsFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemovePaymentErrorsFilter = useCallback((filterType: string) => {
    setPaymentErrorsFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  const handleApplyCreationErrorsSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setCreationErrorsFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveCreationErrorsFilter = useCallback((filterType: string) => {
    setCreationErrorsFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <UserProfile isOpen={isModalOpen} onClose={openModal} uuid={userUuid} />
      <h1 className={classes.title}>Cash Management</h1>
      <Tab id="cashManagement" tabs={tabNames}>
        <div>
          <FormControl>
            <FormLabel
              sx={{
                color: isDarkMode ? 'white' : 'black',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
              id="request-method-radio-buttons-group-label"
            >
              Request method (Crypto / Bank)
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="request-method-radio-buttons-group-label"
              name="request-method-radio-buttons-group"
              value={withdrawType}
              onChange={handleChangeWithdrawType}
            >
              <FormControlLabel
                value="crypto"
                control={
                  <Radio
                    sx={{
                      color: 'white',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                    }}
                  />
                }
                label="Crypto"
              />
              <FormControlLabel
                value="bank"
                control={
                  <Radio
                    sx={{
                      color: 'white',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                    }}
                  />
                }
                label="Bank"
              />
            </RadioGroup>
          </FormControl>
          <Filter
            filters={WithdrawRequestsfilters}
            onApplySearch={handleApplyWithdrawRequestsSearch}
            onRemoveFilter={handleRemoveWithdrawRequestsFilter}
            filterTypes={WithdrawfilterTypes}
          />
          {tableWithdrawRequestsData.length === 0 ? (
            'Aucun utilisateur'
          ) : (
            <AdminTable
              data={tableWithdrawRequestsData}
              columns={tableWithdrawRequestsColumns}
              aboutButton={true}
              acceptButton={true}
              refuseButton={true}
              onAboutButtonClick={userAccount}
              onAcceptButtonClick={handleAcceptWithdrawRequest}
              onRefuseButtonClick={handleRefuseWithdrawRequest}
            />
          )}

          <div className={classes.footer}>
            <Pagination
              total={totalWithdrawRequestsPages}
              perPage={size}
              currentPage={withdrawRequestsPage}
              onPageChange={(page) => setWithdrawRequestsPage(page)}
            />
          </div>
        </div>
        <div>
          <Filter
            filters={affiliationPayoutfilters}
            onApplySearch={handleApplyAffiliatePayoutSearch}
            onRemoveFilter={handleRemoveAffiliatePayoutFilter}
            filterTypes={AffiliationPayoutfilterTypes}
          />
          {tableAffiliationPayoutRequestsData.length === 0 ? (
            'Aucune requête'
          ) : (
            <AdminTable
              data={tableAffiliationPayoutRequestsData}
              columns={tableAffiliationPayoutRequestsColumns}
              aboutButton={true}
              acceptButton={true}
              refuseButton={true}
              executeButton={canExecute === 'false' ? false : true}
              onAboutButtonClick={userAccount}
              onAcceptButtonClick={handleAcceptAffiliationPayoutRequest}
              onRefuseButtonClick={handleRefuseAffiliationPayoutRequest}
              onExecuteButtonClick={handleExecuteAffiliationPayoutRequest}
            />
          )}
          <div className={classes.footer}>
            <Pagination
              total={totalAffiliatePayoutRequestsPages}
              perPage={size}
              currentPage={affiliatePayoutRequestsPage}
              onPageChange={(page) => setAffiliatePayoutRequestsPage(page)}
            />
            <p
              onClick={handleExportAffiliationPayoutClick}
              className={classes.exportXLS}
            >
              <Icon
                color=""
                fill="#B7B7B7"
                size={18}
                path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
              />
              Export to XLS
            </p>
          </div>
        </div>
        <div id="referralPayoutRequest">
          <Filter
            filters={affiliationPayoutfilters}
            onApplySearch={handleApplyAffiliatePayoutSearch}
            onRemoveFilter={handleRemoveAffiliatePayoutFilter}
            filterTypes={AffiliationPayoutfilterTypes}
          />
          {tableReferralPayoutRequestsData.length === 0 ? (
            'Aucun referral payout'
          ) : (
            <>
              <AdminTable
                data={tableReferralPayoutRequestsData}
                columns={tableReferralPayoutRequestsColumns}
                aboutButton={true}
                acceptButton={true}
                refuseButton={true}
                onAboutButtonClick={userAccount}
                onAcceptButtonClick={handleAcceptAffiliationPayoutRequest}
                onRefuseButtonClick={handleRefuseAffiliationPayoutRequest}
              />
              <div className={classes.footer}>
                <Pagination
                  total={totalReferralPayoutRequestsPages}
                  perPage={size}
                  currentPage={referralPayoutRequestsPage}
                  onPageChange={(page) => setReferralPayoutRequestsPage(page)}
                />
              </div>
            </>
          )}
        </div>
        <div>
          <Filter
            filters={tradingPayoutfilters}
            onApplySearch={handleApplyTradingPayoutSearch}
            onRemoveFilter={handleRemoveTradingPayoutFilter}
            filterTypes={TradingPayoutRequestsTypes}
          />
          {tableTradingPayoutRequestsData.length === 0 ? (
            'Aucune requête'
          ) : (
            <AdminTable
              data={tableTradingPayoutRequestsData}
              columns={tableTradingPayoutRequestsColumns}
              aboutButton={true}
              acceptButton={true}
              refuseButton={true}
              onAboutButtonClick={userAccount}
              onAcceptButtonClick={handleAcceptTradingPayoutRequest}
              onRefuseButtonClick={handleRefuseTradingPayoutRequest}
            />
          )}
          <div className={classes.footer}>
            <Pagination
              total={totalTradingPayoutRequestsPages}
              perPage={size}
              currentPage={tradingPayoutRequestsPage}
              onPageChange={(page) => setTradingPayoutRequestsPage(page)}
            />
            <p
              onClick={handleExportAffiliationPayoutClick}
              className={classes.exportXLS}
            >
              <Icon
                color=""
                fill="#B7B7B7"
                size={18}
                path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
              />
              Export to XLS
            </p>
          </div>
        </div>
        <div id="paymentError">
          <Filter
            filters={paymentErrorsFilters}
            onApplySearch={handleApplyPaymentErrorsSearch}
            onRemoveFilter={handleRemovePaymentErrorsFilter}
            filterTypes={PaymentErrorsFilterTypes}
          />
          {tablePaymentErrorsData.length === 0 ? (
            'Aucune erreur'
          ) : (
            <>
              <AdminTable
                data={tablePaymentErrorsData}
                columns={tablePaymentErrorsColumns}
              />
              <div className={classes.footer}>
                <Pagination
                  total={totalPaymentErrorsPages}
                  perPage={size}
                  currentPage={paymentErrorsPage}
                  onPageChange={(page) => setPaymentErrorsPage(page)}
                />
              </div>
            </>
          )}
        </div>
      </Tab>
    </>
  );
};
export default CashManagement;
