import axiosInstance from '../../../../../utils/axios';
import { ChallengeAccountBase } from './models';
import { ApiError } from './errors';

const getAccountBaseData = async (
  accountLogin: string,
  token: string,
): Promise<ChallengeAccountBase> => {
  try {
    const response = await axiosInstance.get<ChallengeAccountBase>(
      `/v2/challenges/account/${accountLogin}/base`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 500) {
      throw new ApiError(error.response.data.error);
    } else {
      throw new ApiError('An unexpected error occurred');
    }
  }
};

export default getAccountBaseData;
