import React, { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import {
  Typography,
  useTheme,
  FormControlLabel,
  Checkbox,
  Grid,
  Stack,
} from '@mui/material';
import useBreakpoints from '../../../../hooks/useBreakpoints';

import classes from './pieChart.module.scss';

ChartJS.register(ArcElement, Tooltip);

interface PieChartProps {
  labels: string[];
  data: number[];
  backgroundColors: string[];
}

const PieChart: React.FC<PieChartProps> = ({
  labels,
  data,
  backgroundColors,
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // État pour suivre les sections visibles
  const [visibility, setVisibility] = useState<boolean[]>(
    Array(labels.length).fill(true),
  );

  // Gestion du changement des checkbox pour afficher/masquer les sections
  const handleCheckboxChange = (index: number) => {
    setVisibility((prevVisibility) =>
      prevVisibility.map((visible, i) => (i === index ? !visible : visible)),
    );
  };

  // Ajuster les données: les sections masquées ont une valeur de 0
  const adjustedData = data.map((value, index) =>
    visibility[index] ? value : 0,
  );

  const chartData = {
    labels, // Ne modifie pas les labels pour garder la légende
    datasets: [
      {
        label: '# of Votes',
        data: adjustedData, // Utilise les données ajustées (0 pour les sections masquées)
        backgroundColor: backgroundColors,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false, // Désactive la légende de base de Chart.js
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.label}: ${tooltipItem.raw.toLocaleString(
              'fr-FR',
            )}`;
          },
        },
      },
    },
  };

  return (
    <>
      {/* Utilisation de Grid pour aligner le chart et les checkboxes */}
      <Grid container alignItems="center" justifyContent="center" mt={4}>
        <Grid item>
          {/* Conteneur du Pie Chart avec une taille fixe */}
          <div style={{ width: '300px', height: '300px' }}>
            <Pie data={chartData} options={options} />
          </div>
        </Grid>

        <Grid item>
          <Stack
            sx={{
              flexDirection: {
                xs: 'row',
                '@media (max-width: 960px)': {
                  flexDirection: 'column',
                },
                '@media (max-width: 829px)': {
                  flexDirection: 'row',
                },
                '@media (max-width: 2040px)': {
                  flexDirection: 'row',
                },
                '@media (min-width: 2041px)': {
                  flexDirection: 'column',
                },
              },
            }}
            flexWrap={'wrap'}
            ml={2}
          >
            {labels.map((label, index) => (
              <FormControlLabel
                key={label}
                control={
                  <Checkbox
                    checked={visibility[index]}
                    onChange={() => handleCheckboxChange(index)}
                    sx={{
                      color: backgroundColors[index], // Couleur de la checkbox selon la couleur du graphique
                      '&.Mui-checked': {
                        color: backgroundColors[index], // Couleur quand cochée
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      textWrap: 'nowrap',
                      color: isDarkMode ? '#fff' : '#000',
                      textDecoration: visibility[index]
                        ? 'none'
                        : 'line-through', // Texte barré si décoché
                    }}
                  >
                    {label}
                  </Typography>
                }
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default PieChart;
