import { toast } from 'react-toastify';

import axiosInstance from '../../../utils/axios';

import {
  AffiliationCommissionsGetPayout,
  CryptoAddressResponse,
  GetAffiliationsCommissionsResponse,
  GetTransactionsResponse,
  IbanDocumentsResponse,
  KycDocumentsResponse,
  ResponseAffiliateGetPayout,
  accountInformationsResponse,
  activeChallengesResponse,
  activeFundedResponse,
  JWTPayload,
  BookSwitchRequest,
} from './interface';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}

export const accountInformations = async (
  accessToken: string,
  userUUID: string,
): Promise<accountInformationsResponse> => {
  try {
    const response = await axiosInstance.get<accountInformationsResponse>(
      `/users/${userUUID}/info`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllActiveChallenges = async (
  accessToken: string,
  userUUID: string,
): Promise<activeChallengesResponse[]> => {
  try {
    const response = await axiosInstance.get<activeChallengesResponse[]>(
      `/challenges/user/${userUUID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllActiveFunded = async (
  accessToken: string,
  userUUID: string,
): Promise<activeFundedResponse[]> => {
  try {
    const response = await axiosInstance.get<activeFundedResponse[]>(
      `/funded/user/${userUUID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createVariant = async (
  accessToken: string,
  type: string,
  userAdminID: number,
): Promise<string> => {
  try {
    const response = await axiosInstance.post(`/funded/create-variant`, null, {
      params: {
        type,
        userAdminID,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resentCredentials = async (
  accessToken: string,
  userMail: string,
): Promise<activeChallengesResponse[]> => {
  try {
    const response = await axiosInstance.get<activeChallengesResponse[]>(
      `/users/resend-credentials?email=${userMail}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteChallenge = async (accessToken: string, account: string) => {
  try {
    const response = await axiosInstance.delete(
      `/challenges/delete/${account}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteFundedChallenge = async (
  accessToken: string,
  account: string,
) => {
  try {
    const response = await axiosInstance.delete(`/funded/delete/${account}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      toast.success(`Funded challenge successfully deleted.`, {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error: any) {
    if (error.response.status === 404) {
      toast.error(`Account not found`, {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 500) {
      toast.error(`Unknown error`, {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  }
};

export const switchToHFT = async (accessToken: string, login: string) => {
  try {
    const response = await axiosInstance.put(
      `/challenges/switch-hft?login=${login}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const switchToDemo = async (accessToken: string, login: string) => {
  try {
    const response = await axiosInstance.put(
      `/challenges/switch-demo?login=${login}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response;
  } catch (error) {
    throw error;
  }
};
export const switchFundedToGroup = async (
  accessToken: string,
  login: string,
  newGroup: string,
) => {
  try {
    // Encode la partie `newGroup` de l'URL
    const encodedNewGroup = encodeURIComponent(newGroup);

    const response = await axiosInstance.put(
      `/funded/switch-group?login=${login}&newGroup=${encodedNewGroup}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const modifyMetatraderPassword = async (
  accessToken: string,
  login: string,
  newPassword: string,
) => {
  try {
    const updateData = {
      login: login,
      password: newPassword,
    };

    const response = await axiosInstance.put(
      `/mt5accounts/change-password`,
      updateData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    if (response.status === 201) {
      toast.success('Password updated succesfully.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
    return response;
  } catch (error) {
    toast.error('An error occured updating the password.', {
      progressStyle: { backgroundColor: 'red' },
    });

    throw error;
  }
};

export const getMetaTraderGroups = async (
  accessToken: string,
  userAdminID: number,
): Promise<string[]> => {
  try {
    const response = await axiosInstance.get<string[]>(
      `/funded/groups/${userAdminID}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching MetaTrader groups:', error);
    throw new Error('Erreur lors de la récupération des groupes MetaTrader');
  }
};

export const getAllTransactions = async (
  accessToken: string,
  userUUID: string,
  page: number,
  size: number,
): Promise<GetTransactionsResponse> => {
  try {
    const response = await axiosInstance.get<GetTransactionsResponse>(
      `/users/${userUUID}/transactions?page=${page}&size=${size}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des transactions');
  }
};

export const getAllAffiliationsCommissions = async (
  accessToken: string,
  userUUID: string,
  page: number,
  size: number,
): Promise<GetAffiliationsCommissionsResponse> => {
  try {
    const response =
      await axiosInstance.get<GetAffiliationsCommissionsResponse>(
        `/affiliations/commissions?userUUID=${userUUID}&page=${page}&size=${size}`,
        {
          headers: { Authorization: accessToken },
        },
      );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des transactions');
  }
};

export const giveChallengeToUser = async (
  accessToken: string,
  userUUID: string,
  amount: number,
) => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.post(
      `/challenges/give`,
      { userUUID: userUUID, amount: amount, initiatorUUID: decoded?.userId },
      {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.status === 201) {
      toast.success('Le challenge a été donné', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le challenge n'a pas pu être donné", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const updateUserWallet = async (
  accessToken: string,
  userUUID: string,
  amount: number,
) => {
  try {
    const response = await axiosInstance.put(
      '/users/wallet',
      { userUuid: userUUID, amount: amount },
      {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.status === 200) {
      toast.success(`Le wallet est update à ${amount}€`, {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le wallet n'a pas pu être mis à jour", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const updateUserEmail = async (
  accessToken: string,
  userUUID: string,
  email: string,
) => {
  try {
    const response = await axiosInstance.put(
      '/users/change-email',
      { userUuid: userUUID, newEmail: email },
      {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.status === 200) {
      toast.success(`The email has been updated to ${email}`, {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error('An error occured updating the email.', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const modifyPassword = async (
  accessToken: string,
  userUUID: string,
  password: string,
) => {
  try {
    const response = await axiosInstance.put(
      `/users/${userUUID}/password`,
      { newPassword: password },
      {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.status === 200) {
      toast.success(`Le mot de passe a été modifié`, {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le mot de passe n'a pas pu être modifié", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const getKycDocuments = async (
  accessToken: string,
  userUUID: string,
): Promise<KycDocumentsResponse> => {
  try {
    const response = await axiosInstance.get<KycDocumentsResponse>(
      `/kyc/user/${userUUID}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des document KYC');
  }
};

export const getCryptoAddress = async (
  accessToken: string,
  userUUID: string,
): Promise<CryptoAddressResponse> => {
  try {
    const response = await axiosInstance.get<CryptoAddressResponse>(
      `/withdraw/user/${userUUID}/crypto`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des adresse crypto');
  }
};

export const fetchAffiliatePayoutRequest = async (
  accessToken: string,
  uuid: string,
): Promise<ResponseAffiliateGetPayout> => {
  try {
    const response = await axiosInstance.get<ResponseAffiliateGetPayout>(
      `https://live.raisemyfunds.co/api/affiliations/payout-requests/${uuid}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAffiliateCommissionsPayoutRequest = async (
  accessToken: string,
  uuid: string,
  month: number,
  year: number,
  page: number,
  size: number,
): Promise<AffiliationCommissionsGetPayout> => {
  try {
    const response = await axiosInstance.get<AffiliationCommissionsGetPayout>(
      `https://live.raisemyfunds.co/api/affiliations/commissions/month?month=${month}&year=${year}&userUUID=${uuid}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIbanDocuments = async (
  accessToken: string,
  userUUID: string,
): Promise<IbanDocumentsResponse> => {
  try {
    const response = await axiosInstance.get<IbanDocumentsResponse>(
      `/withdraw/user/${userUUID}/documents`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des iban documents');
  }
};

export const downloadIbanDocument = async (
  accessToken: string,
  id: number,
  fileName: string,
) => {
  try {
    const response = await axiosInstance.get(`/withdraw/download/${id}`, {
      headers: { Authorization: accessToken },
      responseType: 'blob',
    });
    const blob = new Blob([response.data]);
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Erreur lors du téléchargement de fichier Iban', error);
    throw new Error('Erreur lors du téléchargement de fichier Iban');
  }
};

export const deleteAffiliationCommission = async (
  accessToken: string,
  id: number,
) => {
  try {
    const response = await axiosInstance.delete(
      `/affiliations/commissions/${id}`,
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );
    if (response.status === 200) {
      toast.success('La commission a été supprimée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("La commissions n'a pas pu être supprimée", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const switchPaymentAffiliate = async (
  accessToken: string,
  id: number,
) => {
  try {
    const response = await axiosInstance.patch(
      `payments/${id}/attach-referrer`,
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );
    if (response.status === 200) {
      toast.success("La commission a été attribuée à l'affilié.", {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error('Une erreur est survenue.', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const switchAffiliationToRank = async (
  accessToken: string,
  userUUID: string,
) => {
  try {
    const response = await axiosInstance.put(
      `/affiliations/${userUUID}/type/percentage`,
      {},
      {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.status === 200) {
      toast.success('Affiliation type updated successfully to RANK.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error('An error occurred, please contact the developer.', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const switchAffiliationToPercentage = async (
  accessToken: string,
  userId: string,
  valueOfPercentage: number,
  fixed: boolean,
) => {
  try {
    const updateData = {
      valueOfPercentage: valueOfPercentage,
      fixed: fixed,
    };

    const response = await axiosInstance.put(
      `/affiliations/${userId}/type/percentage`,
      updateData,

      {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      toast.success('Affiliation percentage updated successfully.', {
        progressStyle: { backgroundColor: 'green' },
      });
      return response.data;
    }
  } catch (error) {
    toast.error('An error occurred, please contact the developer.', {
      progressStyle: { backgroundColor: 'red' },
    });
    throw error;
  }
};

export const changeAffiliationDisplayName = async (
  accessToken: string,
  userUuid: string,
  displayName: string,
) => {
  try {
    const updateData = {
      displayName: displayName,
      userUuid: userUuid,
    };

    const response = await axiosInstance.post(
      `/affiliations/display-name`,
      updateData,

      {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      toast.success('Affiliation display name updated succesfully.', {
        progressStyle: { backgroundColor: 'green' },
      });
      return response.data;
    }
  } catch (error) {
    toast.error('An error occurred, please contact the developer.', {
      progressStyle: { backgroundColor: 'red' },
    });
    throw error;
  }
};

export const changeUserReferrer = async (
  accessToken: string,
  userId: string,
  referrerAdminId: number,
) => {
  try {
    const updateData = {
      userId: userId,
      referrerAdminId: referrerAdminId,
    };

    const response = await axiosInstance.post(
      `users/change-referrer`,
      updateData,

      {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      toast.success('User referrer succesfully changed.', {
        progressStyle: { backgroundColor: 'green' },
      });
      return response.data;
    }
  } catch (error) {
    toast.error('An error occurred, please contact the developer.', {
      progressStyle: { backgroundColor: 'red' },
    });
    throw error;
  }
};

export const deleteKycDocument = async (accessToken: string, id: number) => {
  try {
    const response = await axiosInstance.delete(`/kyc/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      toast.success('Document successfully deleted.', {
        progressStyle: { backgroundColor: 'green' },
      });
      return response.data;
    }
  } catch (error) {
    toast.error('An error occurred, please contact the developer.', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const mergeAccounts = async (
  accessToken: string,
  fromUserUUID: string,
  toUserMail: string,
) => {
  try {
    const response = await axiosInstance.post(
      '/users/merge',
      {
        fromUserUUID: fromUserUUID,
        toUserMail: toUserMail,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (response.status === 200) {
      toast.success('Accounts successfully merged.', {
        progressStyle: { backgroundColor: 'green' },
      });
      return response.data;
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      toast.error('An error occured while accounts merging.', {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 401) {
      toast.error('Please contact a developer to perform this operation', {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  }
};

export const switchAccount = async (
  accessToken: string,
  affiliateUUID: string,
  loginsBBook: string[],
  loginsABook: string[],
) => {
  try {
    const response = await axiosInstance.post(
      'funded/bulk-switch-book',
      {
        affiliateUUID: affiliateUUID,
        loginsBBook: loginsBBook,
        loginsABook: loginsABook,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (response.status === 200) {
      toast.success('X accounts switched to new book successfully.', {
        progressStyle: { backgroundColor: 'green' },
      });
      return response.data;
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      toast.error(
        'No accounts switched, please check the details and try again.',
        {
          progressStyle: { backgroundColor: 'red' },
        },
      );
    } else if (error.response.status === 500) {
      toast.error('An error occurred.', {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  }
};

export const getObjectivesTable = async (accessToken: string) => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance(
      `/challenges/user/${decoded?.userId}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération du tableau des objectif');
  }
};

export const getFundedByReferrerUUID = async (
  accessToken: string,
  referrerUUID: string,
): Promise<BookSwitchRequest> => {
  try {
    const response = await axiosInstance.get<BookSwitchRequest>(
      `/affiliations/funded/${referrerUUID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGroupsByReferrer = async (
  accessToken: string,
  referrerUUID: string,
) => {
  try {
    const response = await axiosInstance.get(
      `/affiliations/groups/${referrerUUID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const setDefaultGroup = async (
  accessToken: string,
  group: string,
  affiliateUUID: string,
) => {
  try {
    const encodedNewGroup = encodeURIComponent(group);

    const response = await axiosInstance.put(
      `affiliations/define-default-group?newGroup=${encodedNewGroup}&userUUID=${affiliateUUID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (response.status === 200) {
      toast.success('Default group defined successfully', {
        progressStyle: { backgroundColor: 'green' },
      });
      return response.data;
    }
  } catch (error: any) {
    if (error.response.status === 500) {
      toast.error('An error occurred.', {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  }
};

export const createNewBooksVariant = async (
  accessToken: string,
  type: string,
  affiliateUUID: string,
) => {
  try {
    const response = await axiosInstance.put(
      `affiliations/define-default-group?newGroup=${type}&userUUID=${affiliateUUID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (response.status === 200) {
      toast.success('Variant created successfully', {
        progressStyle: { backgroundColor: 'green' },
      });
      return response.data;
    }
  } catch (error: any) {
    if (error.response.status === 404) {
      toast.error('User not found (UUID)', {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 409) {
      toast.error('Max group variant reached', {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 400) {
      toast.error('Group already exists', {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 500) {
      toast.error('An error occured', {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  }
};
