import React, { useEffect } from 'react';

declare global {
  interface Window {
    fbq: any;
  }
}

const PixelPageView = () => {
  useEffect(() => {
    const insertPageViewPixelScript = () => {
      if (typeof window.fbq === 'undefined') {
        (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = true;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = true;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      }

      if (window.fbq) {
        window.fbq('init', '562594962962000'); // Pixel ID pour PageView
        window.fbq('track', 'PageView');
      }
    };

    insertPageViewPixelScript();
  }, []);

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=562594962962000&ev=PageView&noscript=1" // Deuxième Pixel ID
        alt="Meta Pixel"
      />
    </noscript>
  );
};

export default PixelPageView;
