import axiosInstance from '../../../../../utils/axios';
import { IUpgradeAccountService } from './interfaces';
import { handleErrorUpgradeAccount } from './errors';
import { UpgradeAccountDTO } from './models';

class UpgradeAccountService implements IUpgradeAccountService {
  async upgradeAccount(
    accountUUID: string,
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
  ): Promise<void> {
    const data: UpgradeAccountDTO = {
      accountUUID,
    };

    try {
      const response = await axiosInstance.post('/challenges/upgrade', data);
      if (response.status === 200) {
        onSuccess('Account successfully upgraded!');
      }
    } catch (error) {
      handleErrorUpgradeAccount(error, onError);
      throw error;
    }
  }
}

export default new UpgradeAccountService();
