import React, { useEffect, useMemo, useRef, useState } from 'react';

import 'hammerjs';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';

import {
  Chart as ChartJS,
  CategoryScale,
  ChartOptions,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

import {
  IconButton,
  Stack,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import MuiTooltip from '@mui/material/Tooltip';

import { RemoveCircleOutline, ZoomIn, ZoomOut } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '../../hooks/useBreakpoints'; // Ajoute ceci pour importer ton hook

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  annotationPlugin,
  Filler,
  zoomPlugin,
);

interface AnnotationProps {
  id: string;
  content: string;
  borderColor: string;
  yMin: number;
  yMax: number;
}

interface ZoomableChartProps {
  style?: any;
  labels: any[];
  datasets: {
    label: string;
    data: number[];
    fill: string;
    borderColor: string;
    backgroundColor: string;
  }[];
  xAxisTitle: string;
  yAxisTitle: string;
  annotations?: AnnotationProps[];
}

const ZoomableChart: React.FC<ZoomableChartProps> = ({
  style,
  labels,
  datasets,
  xAxisTitle,
  yAxisTitle,
  annotations = [],
}) => {
  const { t } = useTranslation('area');
  const chartRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const [isResponsiveMode, setIsResponsiveMode] = useState(
    window.innerWidth < 1024,
  );
  const { isXL } = useBreakpoints(); // Récupération de isXL

  // Ajout de l'état pour gérer la visibilité des annotations individuellement
  const [visibleAnnotations, setVisibleAnnotations] = useState(
    annotations.reduce(
      (acc, annotation) => {
        acc[annotation.id] = true; // Toutes les annotations sont visibles par défaut
        return acc;
      },
      {} as Record<string, boolean>,
    ),
  );

  const handleZoomIn = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.zoom(1.1);
    }
  };

  const handleZoomOut = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.zoom(0.9);
    }
  };

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const toggleAnnotationVisibility = (id: string) => {
    setVisibleAnnotations((prev) => ({
      ...prev,
      [id]: !prev[id], // Bascule la visibilité de l'annotation avec cet id
    }));
  };

  useEffect(() => {
    if (isResponsiveMode) {
      chartRef.current.zoom(1.8);
    }
    const handleResize = () => {
      setIsResponsiveMode(window.innerWidth < 1024);
      if (chartRef.current && chartRef.current.chartInstance) {
        chartRef.current.chartInstance.update('none');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const options = useMemo(
    (): ChartOptions<'line'> => ({
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          type: 'category',
          ticks: {
            autoSkip: true,
          },
          title: {
            display: !isResponsiveMode,
            text: xAxisTitle,
            color: '#cccccc',
            font: {
              size: 24,
              weight: 'bold',
            },
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: !isResponsiveMode,
            text: yAxisTitle,
            color: '#cccccc',
            font: {
              size: 24,
              weight: 'bold',
            },
          },
        },
      },
      plugins: {
        annotation: {
          annotations: annotations.reduce(
            (acc, annotation) => {
              if (visibleAnnotations[annotation.id]) {
                acc[annotation.id] = {
                  type: 'line',
                  yMin: annotation.yMin,
                  yMax: annotation.yMax,
                  borderColor: annotation.borderColor,
                  borderWidth: 2,
                  label: {
                    content: annotation.content,
                    backgroundColor: annotation.borderColor,
                    display: true,
                    position: 'start',
                  },
                };
              }
              return acc;
            },
            {} as Record<string, any>,
          ),
        },
        legend: {
          display: datasets.length > 1,
        },
        tooltip: {
          enabled: true,
          mode: 'index',
          intersect: false,
          animation: false,
        },
        zoom: {
          limits: {
            x: { min: 'original', max: 'original' },
          },
          pan: {
            enabled: true,
            mode: 'x',
            threshold: 10,
          },
          zoom: {
            wheel: {
              enabled: true,
              speed: 0.1,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
      animation: {
        duration: 0,
      },
    }),
    [
      xAxisTitle,
      yAxisTitle,
      datasets.length,
      isResponsiveMode,
      visibleAnnotations,
    ], // Ajout de visibleAnnotations dans les dépendances
  );

  const data = {
    labels,
    datasets,
  };

  return (
    <Stack spacing={1}>
      <Stack>
        <Stack ref={containerRef} direction="row" alignItems="center">
          <MuiTooltip title={t('CHART.TOOLTIP_ZOOM_IN')} placement="top">
            <IconButton onClick={handleZoomIn} aria-label="zoom in">
              <ZoomIn sx={{ fontSize: '30px' }} />
            </IconButton>
          </MuiTooltip>

          <MuiTooltip title={t('CHART.TOOLTIP_ZOOM_OUT')} placement="top">
            <IconButton onClick={handleZoomOut} aria-label="zoom out">
              <ZoomOut sx={{ fontSize: '30px' }} />
            </IconButton>
          </MuiTooltip>

          <MuiTooltip title={t('CHART.TOOLTIP_RESET_ZOOM')} placement="top">
            <IconButton onClick={handleResetZoom} aria-label="reset zoom">
              <RemoveCircleOutline sx={{ fontSize: '25px' }} />
            </IconButton>
          </MuiTooltip>
        </Stack>

        {!isXL && (
          <Stack direction="row" gap={1} flexWrap={'wrap'} marginLeft={1.5}>
            {annotations.map((annotation) => (
              <FormControlLabel
                key={annotation.id}
                control={
                  <Checkbox
                    checked={visibleAnnotations[annotation.id]}
                    onChange={() => toggleAnnotationVisibility(annotation.id)}
                  />
                }
                label={
                  <Typography
                    style={{
                      textDecoration: visibleAnnotations[annotation.id]
                        ? 'none'
                        : 'line-through',
                      color: visibleAnnotations[annotation.id]
                        ? 'inherit'
                        : 'gray',
                    }}
                  >
                    {annotation.content}
                  </Typography>
                }
              />
            ))}
          </Stack>
        )}
      </Stack>

      <Line ref={chartRef} data={data} options={options} style={style} />
    </Stack>
  );
};

export default ZoomableChart;
