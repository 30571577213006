import React from 'react';

import { Card, CircularProgress } from '@mui/material';

import classes from './calendar.module.scss';

interface CalendarSkeletonProps {
  elevation: number;
}

const CalendarSkeleton: React.FC<CalendarSkeletonProps> = ({ elevation }) => {
  return (
    <Card
      elevation={elevation}
      className={classes.calendar}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '670px',
      }}
    >
      <CircularProgress />
    </Card>
  );
};

export default CalendarSkeleton;
