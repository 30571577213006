import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import TimezoneSelect from './components/TimezoneSelect/TimezoneSelect';
import ImpactFilter from './components/ImpactFilter/ImpactFilter';
import DaysSelector from './components/DaysSelector/DaysSelector';
import EventsTable from './components/EventsTable/EventsTable';
import { useEconomicCalendar } from './hooks/useEconomicCalendar';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const Calendar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { economicEvents, loading } = useEconomicCalendar();
  const [selectedDays, setSelectedDays] = useState([moment().startOf('day')]);
  const [timeZone, setTimeZone] = useState(moment.tz.guess());
  const [impactFilter, setImpactFilter] = useState({
    high: true,
    medium: true,
    low: true,
    holiday: true,
  });

  useDocumentTitle('Economic Calendar');

  return (
    <>
      <Stack
        direction={isMobile ? 'column' : 'row-reverse'}
        gap={4}
        justifyContent="space-between"
        alignItems={'flex-end'}
        sx={{ marginBottom: 4 }}
      >
        <TimezoneSelect timeZone={timeZone} setTimeZone={setTimeZone} />
        <ImpactFilter
          impactFilter={impactFilter}
          setImpactFilter={setImpactFilter}
        />
      </Stack>

      <DaysSelector
        selectedDays={selectedDays}
        setSelectedDays={setSelectedDays}
      />

      <EventsTable
        selectedDays={selectedDays}
        impactFilter={impactFilter}
        economicEvents={economicEvents}
        loading={loading}
        timeZone={timeZone}
      />
    </>
  );
};

export default Calendar;
