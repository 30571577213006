import { useCallback } from 'react';
import Button from '../userInterface/Button/Button';
import classes from './_pagination.module.scss';

interface PaginationProps {
  total: number;
  perPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  total,
  perPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = total;
  const maxPageNumbersToShow = 5;

  const handlePrev = useCallback(() => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1);
    }
  }, [currentPage, onPageChange]);

  const handleNext = useCallback(() => {
    if (currentPage < totalPages - 1) {
      onPageChange(currentPage + 1);
    }
  }, [currentPage, totalPages, onPageChange]);

  const pageNumbers: (number | string)[] = [];

  if (totalPages <= maxPageNumbersToShow) {
    for (let i = 0; i < totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Toujours inclure la première page.
    pageNumbers.push(0);
    let startPage = Math.max(currentPage - 1, 1); // Commencer à 1 pour éviter de répéter le premier numéro de page
    let endPage = Math.min(
      startPage + maxPageNumbersToShow - 2, // Ajuster pour inclure la première page et '...'
      totalPages - 1,
    );

    if (startPage > 1) {
      pageNumbers.push('...'); // Ajouter '...' si nous ne commençons pas directement après la première page
    }

    if (endPage - startPage < maxPageNumbersToShow - 2) {
      startPage = Math.max(endPage - maxPageNumbersToShow + 2, 1); // Ajuster si la plage est trop petite
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push('...');
      pageNumbers.push(totalPages - 1);
    }
  }

  if (total < 2) {
    return null;
  }

  return (
    <nav className={classes.nav}>
      <Button
        variant="primary"
        className={classes.arrowButton}
        disabled={currentPage === 0}
        onClick={handlePrev}
      >
        {'<'}
      </Button>

      {pageNumbers.map((page, index) =>
        typeof page === 'number' ? (
          <Button
            variant="outline"
            className={`${classes.button} ${
              currentPage === page ? classes.active : ''
            }`}
            key={index}
            disabled={currentPage === page}
            onClick={() => onPageChange(page)}
          >
            {page + 1}
          </Button>
        ) : (
          <span key={index} className={classes.ellipsis}>
            {page}
          </span>
        ),
      )}

      <Button
        variant="primary"
        className={classes.arrowButton}
        disabled={currentPage === totalPages - 1}
        onClick={handleNext}
      >
        {'>'}
      </Button>
    </nav>
  );
};

export default Pagination;
