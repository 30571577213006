import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { logout } from '../../../services/auth/login';
import { ChallengesResponseContent } from '../../../services/admin/challenges/interface';
import {
  exportChallengesToXLS,
  fetchChallenges,
} from '../../../services/admin/challenges/challenges';

import { useAuth } from '../../../context/AuthContext/AuthContext';

import useDocumentTitle from '../../../hooks/useDocumentTitle';

import AdminTable from '../../../components/Admin/AdminTable/AdminTable';
import Loader from '../../../components/Loader/Loader';
import Pagination from '../../../components/Pagination/Pagination';
import Icon from '../../../components/userInterface/Icon/Icon';
import Filter, { FilterType } from '../../../components/Filter/Filter';

import classes from './_challenges.module.scss';

const Challenges = () => {
  const { tokens, isTokensLoading } = useAuth();
  useDocumentTitle('Challenges');

  const size = 10;
  const filterTypes: FilterType[] = [
    { key: 'challengeId', type: 'string' },
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'email', type: 'string' },
    { key: 'phase', type: 'string' },
    { key: 'amountPaid', type: 'string' },
    { key: 'mt5Account', type: 'string' },
    { key: 'mt5Password', type: 'string' },
    { key: 'group', type: 'string' },
    { key: 'userAdminId', type: 'string' },
  ];

  const tableChallengesData = [];
  const tableChallengesColumns = [
    'Amount',
    'Start Date',
    'MT5 Account',
    'Equity',
    'Balance',
    'Firstname',
    'Lastname',
    'Email',
    'User ID',
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [challenges, setChallenges] = useState<ChallengesResponseContent[]>([]);
  const [challengesPage, setChallengesPage] = useState<number>(0);
  const [totalChallengesPages, setTotalChallengesPages] = useState<number>(0);
  const [challengesTotalOfElements, setChallengesTotalOfElements] =
    useState<number>(0);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});

  for (const challenge of challenges) {
    tableChallengesData.push({
      Amount: challenge.amountPaid,
      'Start Date': challenge.startDate,
      'MT5 Account': challenge.mt5Account,
      Firstname: challenge.user.firstname,
      Lastname: challenge.user.lastname,
      Email: challenge.user.email,
      UUID: challenge.user.uuid,
      'User ID': challenge.user.adminId,
    });
  }

  const handleExportClick = async () => {
    if (tokens && tokens.accessToken) {
      try {
        await exportChallengesToXLS(
          tokens.accessToken,
          challengesPage,
          size,
          filters,
        );
        toast.success('Le téléchargement va commencer.', {
          progressStyle: { backgroundColor: 'green' },
        });
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de l'export.", {
          progressStyle: { backgroundColor: 'red' },
        });
      }
    } else {
      logout();
    }
  };

  const loadChallenges = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchChallenges(
          tokens.accessToken,
          challengesPage,
          size,
          filters,
        );
        setChallenges(response.content);
        setTotalChallengesPages(response.totalPages);
        setChallengesTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, challengesPage, size, filters]);

  const handleApplySearch = useCallback(
    (filterType: string, filterValue: string) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );

  const handleRemoveFilter = useCallback((filterType: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  useEffect(() => {
    if (!isTokensLoading) {
      loadChallenges();
    }
  }, [loadChallenges, isTokensLoading]);

  useEffect(() => {
    const filtersAreSet = Object.values(filters).some((value) => value !== '');
    if (filtersAreSet) {
      loadChallenges();
    }
  }, [filters, loadChallenges]);

  return (
    <>
      <h1 className={classes.title}>
        Challenges{' '}
        <span className={classes.chip}>{challengesTotalOfElements}</span>
      </h1>
      <Filter
        filters={filters}
        onApplySearch={handleApplySearch}
        onRemoveFilter={handleRemoveFilter}
        filterTypes={filterTypes}
      />
      {tableChallengesData.length === 0 ? (
        'Aucunes requêtes'
      ) : (
        <>
          <AdminTable
            data={tableChallengesData}
            columns={tableChallengesColumns}
          />
          <div className={classes.footer}>
            <Pagination
              total={totalChallengesPages}
              perPage={size}
              currentPage={challengesPage}
              onPageChange={(page) => setChallengesPage(page)}
            />
            <p onClick={handleExportClick} className={classes.exportXLS}>
              <Icon
                color="custom"
                fill="#B7B7B7"
                size={18}
                path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
              />
              Export to XLS
            </p>
          </div>
        </>
      )}
    </>
  );
};
export default Challenges;
