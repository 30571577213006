import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import {
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';

interface LoginFormProps {
  email: string;
  setEmail: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
  rememberMe: boolean;
  setRememberMe: (value: boolean) => void;
  emailError: string | null;
  passwordError: string | null;
}

const LoginForm: React.FC<LoginFormProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  rememberMe,
  setRememberMe,
  emailError,
  passwordError,
}) => {
  const { t } = useTranslation('login');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <Stack width="100%">
      <Stack gap={3} width="100%">
        <TextField
          id="email"
          label={t('LABEL_EMAIL_ADDRESS')}
          placeholder={t('PLACEHOLDER_EMAIL_ADDRESS')}
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
          fullWidth
        />
        <TextField
          id="password"
          label={t('LABEL_PASSWORD')}
          placeholder={t('PLACEHOLDER_PASSWORD')}
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!passwordError}
          helperText={passwordError}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mt={0.8}
        mb={4}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            label={t('CHECKBOX_REMEMBER_ME')}
          />
        </FormGroup>
        <Typography
          variant="body1"
          component={Link}
          to={'/forgot-password'}
          sx={{
            textDecoration: 'underline',
            fontStyle: 'italic',
            color: 'inherit',
          }}
        >
          {t('LINK_FORGOT_PASSWORD')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default LoginForm;
