import { useTranslation } from 'react-i18next';

import Button from '../userInterface/Button/Button';
import Icon from '../userInterface/Icon/Icon';
import classes from './_stepper.module.scss';
import LoaderCircle from '../../components/Loader/LoaderCircle';
type Step = {
  title: string;
};

interface StepperProps {
  steps: Step[];
  currentStep: number;
  onStepChange: (step: number) => void;
  className?: string;
  children: React.ReactNode;
  showNextButton?: boolean;
}

const Stepper: React.FC<StepperProps> = ({
  steps,
  currentStep,
  onStepChange,
  className,
  children,
  showNextButton = true,
}) => {
  const { t } = useTranslation('common');

  const renderStepContent = (index: number) => {
    if (index < currentStep) {
      return (
        <Icon
          color="white"
          size={24}
          path="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
        />
      );
    } else if (index === currentStep) {
      return (
        <div className={classes.circle}>
          <LoaderCircle className={classes.loaderInsideCircle} />
          <span className={classes.stepNumber}>{index + 1}</span>
        </div>
      );
    }

    return index + 1;
  };

  return (
    <div>
      <div className={classes.stepperContainer}>
        {steps.map((step, index) => (
          <div key={index} className={classes.step}>
            <div
              className={`${classes.circle} ${
                index <= currentStep ? classes.activeCircle : ''
              }`}
            >
              {renderStepContent(index)}
            </div>
            <div className={classes.stepTitle}>{step.title}</div>
          </div>
        ))}
      </div>

      <div className={`${classes.stepContent} ${className}`}>{children}</div>

      <div className={classes.navigationButtons}>
        {showNextButton && currentStep > 0 ? (
          <Button
            variant="primary"
            onClick={() => onStepChange(currentStep - 1)}
          >
            {t('previous')}
          </Button>
        ) : (
          <div></div>
        )}

        {showNextButton && currentStep < steps.length - 1 ? (
          <Button
            variant="primary"
            onClick={() => onStepChange(currentStep + 1)}
          >
            {t('next')}
          </Button>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
export default Stepper;
