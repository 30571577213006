import React, { useState } from 'react';
import PieChartTabsNav from '../../components/PieChartTabsNav/PieChartTabsNav';
import PieChartTabsPanel from '../../components/PieChartTabsPanel/PieChartTabsPanel';
import OverviewTab from '../../components/PieChartTabsContent/OverviewTab';
import PhaseTab from '../../components/PieChartTabsContent/PhaseTab';
import FundedTab from '../../components/PieChartTabsContent/FundedTab';
import { Card, Stack } from '@mui/material';

const PieChart: React.FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Card elevation={0} sx={{ minWidth: { xs: '100%', sm: '700px' } }}>
      <PieChartTabsNav value={value} handleChange={handleChange} />

      {/* <Stack sx={{ padding: '0px 0px' }}> */}
      <PieChartTabsPanel value={value} index={0}>
        <OverviewTab />
      </PieChartTabsPanel>

      <PieChartTabsPanel value={value} index={1}>
        <PhaseTab phase="phase1" />
      </PieChartTabsPanel>

      <PieChartTabsPanel value={value} index={2}>
        <PhaseTab phase="phase2" />
      </PieChartTabsPanel>

      <PieChartTabsPanel value={value} index={3}>
        <FundedTab />
      </PieChartTabsPanel>
      {/* </Stack> */}
    </Card>
  );
};

export default PieChart;
