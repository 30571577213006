import React from 'react';
import { useFundedAccountBaseData } from '../../context/FundedAccountBaseDataContext/FundedAccountBaseDataContext';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { green, yellow } from '../../../../themes/colors/colors';
import { RulesCard } from '../../index';
import { Card, Chip, Skeleton, Stack, Typography } from '@mui/material';
import classes from './rules.module.scss';
import { useTranslation } from 'react-i18next';
import RulesSkeleton from './RulesSkeleton';

interface FundedRulesProps {
  elevation: number;
  selectedAccountLogin: string | null;
}

const FundedRules: React.FC<FundedRulesProps> = ({
  elevation,
  selectedAccountLogin,
}) => {
  const { fundedAccountBaseData, loading, error } = useFundedAccountBaseData();
  const { isMD } = useBreakpoints();
  const { t, i18n } = useTranslation('area');

  if (loading || !selectedAccountLogin) {
    return <RulesSkeleton elevation={elevation} />;
  }

  if (error) {
    return (
      <Card elevation={elevation} className={classes.rules}>
        <Typography color="error">{error}</Typography>
      </Card>
    );
  }

  const dataToDisplay = fundedAccountBaseData;

  if (!dataToDisplay) {
    return null;
  }

  const { maxDailyLoss, maxLoss, nextPayoutDate, consistencyRule } =
    dataToDisplay;

  return (
    <Card elevation={elevation} className={classes.rules}>
      <Stack direction="row" justifyContent="space-between" mb={4}>
        <Typography variant="h6" component="h2">
          {selectedAccountLogin
            ? `${t('RULES.TITLE_ACCOUNT')} #${selectedAccountLogin}`
            : 'Select an Account'}
        </Typography>
        <Chip
          label={`$ ${dataToDisplay.initialBalance}`}
          size="small"
          sx={{ backgroundColor: yellow[800], color: 'black' }}
        />
      </Stack>
      <Stack
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: isMD ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
        }}
      >
        <Card
          sx={{
            background:
              'linear-gradient(61deg, rgba(230,75,75,1) 0%, rgba(53,70,117,1) 100%)',
            padding: '16px',
          }}
        >
          <Typography variant="body1" component={'p'}>
            {nextPayoutDate
              ? t('RULES.TITLE_NEXT_PAYOUT_AVAILABLE_ON', {
                  date: new Date(nextPayoutDate).toLocaleDateString(i18n.language, {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  }),
                })
              : t('RULES.TITLE_NEXT_PAYOUT_DATE_AFTER_FIRST_TRADING_DAY')}
          </Typography>

        </Card>
        <RulesCard
          title={t('RULES.RULE_CARD.TITLE_MAX_DAILY_LOSS')}
          currentValue={maxDailyLoss.leftValue}
          limitValue={maxDailyLoss.limitValue}
          percentage={maxDailyLoss.percentage}
          passed={maxDailyLoss.passed}
        />
        <RulesCard
          title={t('RULES.RULE_CARD.TITLE_MAX_LOSS')}
          currentValue={maxLoss.leftValue}
          limitValue={maxLoss.limitValue}
          percentage={maxLoss.percentage}
          passed={maxLoss.passed}
        />
        <RulesCard
          title={t('RULES.RULE_CARD.TITLE_45_PERCENT_RULE')}
          currentValue={consistencyRule.leftValue}
          percentage={consistencyRule.currentPercentage}
          passed={consistencyRule.valid}
          customLabel={
            consistencyRule.valid
              ? t('RULES.RULE_CARD.CHIP_PASSED')
              : t('RULES.RULE_CARD.CHIP_ON_GOING')
          }
          isCustom45Percent
          icon
          customLabelColor={consistencyRule.valid ? green[300] : '#5C9DFF'}
          minimumPayoutValue={consistencyRule.minimumPayoutValue}
          maxDailyProfit={consistencyRule.maxDailyProfit}
        />
      </Stack>
    </Card>
  );
};

export default FundedRules;
