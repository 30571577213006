import axiosInstance from '../../../../../utils/axios';

import { IGetCommissionsService } from './interfaces';
import { CommissionSummaryDTO } from './models';
import { handleError } from './errors';

class GetCommissionsService implements IGetCommissionsService {
  async getCommissions(
    token: string,
    timeframe: 'TODAY' | 'WEEK' | 'MONTH' | 'YEAR' | 'TOTAL',
  ): Promise<CommissionSummaryDTO> {
    try {
      const response = await axiosInstance.get<CommissionSummaryDTO>(
        `/v2/affiliations/commissions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { timeframe },
        },
      );
      return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new GetCommissionsService();
