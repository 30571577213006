import axiosInstance from '../../../../utils/axios'; // Utiliser votre instance axios configurée

export const getAvailablePermissions = async (
  userUUID: string,
  token: string,
): Promise<string[]> => {
  try {
    const response = await axiosInstance.get<string[]>(
      `/permissions/users/${userUUID}/available`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch available permissions');
  }
};
