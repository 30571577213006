import { useEffect, useState } from 'react';
import { SalesKPIResponse } from '../../services/get/salesCard/models';
import getDaySalesKPI from '../../services/get/salesCard/getDaySalesKPI';
import getWeekSalesKPI from '../../services/get/salesCard/getWeekSalesKPI';
import getMonthSalesKPI from '../../services/get/salesCard/getMonthSalesKPI';
import getTotalSalesKPI from '../../services/get/salesCard/getTotalSalesKPI';
import { useAuth } from '../../../../context/AuthContext/AuthContext';

type KPIType = 'day' | 'week' | 'month' | 'total';

interface UseSalesKPIProps {
  kpiType: KPIType;
  previousValue?: number;
}

export const useSalesKPI = ({
  kpiType,
  previousValue = 0,
}: UseSalesKPIProps) => {
  const { tokens } = useAuth();
  const [data, setData] = useState<SalesKPIResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSalesKPI = async () => {
      if (!tokens) {
        setError('No token found');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        let response: SalesKPIResponse;

        switch (kpiType) {
          case 'day':
            response = await getDaySalesKPI(tokens.accessToken, previousValue);
            break;
          case 'week':
            response = await getWeekSalesKPI(tokens.accessToken, previousValue);
            break;
          case 'month':
            response = await getMonthSalesKPI(
              tokens.accessToken,
              previousValue,
            );
            break;
          case 'total':
            response = await getTotalSalesKPI(tokens.accessToken);
            break;
          default:
            throw new Error('Invalid KPI type');
        }

        setData(response);
        setError(null);
      } catch (err: any) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchSalesKPI();
  }, [tokens, kpiType, previousValue]);

  return { data, loading, error };
};
