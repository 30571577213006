import React, { useState, useEffect, ReactNode } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { DashboardSummaryDTO } from '../../services/get/summary/models';
import GetDashboardSummaryService from '../../services/get/summary/getSummary';
import { SummaryContext } from '../../context/Summary/SummaryContext';

interface DashboardSummaryProviderProps {
  children: ReactNode;
}

// Le provider pour envelopper l'application
export const DashboardSummaryProvider: React.FC<
  DashboardSummaryProviderProps
> = ({ children }) => {
  const { tokens } = useAuth();
  const [dashboardData, setDashboardData] =
    useState<DashboardSummaryDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDashboardSummary = async () => {
      if (tokens && tokens.accessToken) {
        setLoading(true);
        setError(null);
        try {
          const data = await GetDashboardSummaryService.getDashboardSummary(
            tokens.accessToken,
          );
          setDashboardData(data);
        } catch (error: any) {
          console.error(
            'Erreur lors de la récupération des données du tableau de bord:',
            error,
          );
          setError(
            'Une erreur est survenue lors de la récupération des données du tableau de bord.',
          );
        } finally {
          setLoading(false);
        }
      } else {
        console.warn(
          'Aucun token disponible pour récupérer les données du tableau de bord',
        );
        setError(
          'Impossible de récupérer les données. Aucun token disponible.',
        );
      }
    };

    fetchDashboardSummary();
  }, [tokens]);

  return (
    <SummaryContext.Provider value={{ dashboardData, loading, error }}>
      {children}
    </SummaryContext.Provider>
  );
};
