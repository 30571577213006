import React from 'react';

import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Skeleton,
  Stack,
} from '@mui/material';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface SalesCardSkeletonProps {
  actions?: boolean;
}

const SalesCardSkeleton: React.FC<SalesCardSkeletonProps> = ({
  actions = true,
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '32px',
        }}
      >
        <Stack
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={2}
        >
          <Skeleton variant="rounded" height={20} width={200} />
          <Skeleton variant="rounded" height={30} width={250} />
        </Stack>
      </CardContent>

      {actions && (
        <CardActions
          sx={{
            justifyContent: 'center',
            paddingBottom: '16px',
          }}
        >
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <IconButton disabled>
              <KeyboardArrowLeftIcon fontSize="large" />
            </IconButton>

            <Stack direction={'column'} gap={1}>
              <Stack
                direction={'row'}
                gap={1}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Skeleton variant="rectangular" height={10} width={10} />
                <Skeleton variant="rectangular" height={10} width={25} />
              </Stack>
              <Skeleton height={15} width={60} />
            </Stack>

            <IconButton disabled>
              <KeyboardArrowRightIcon fontSize="large" />
            </IconButton>
          </Stack>
        </CardActions>
      )}
    </Card>
  );
};

export default SalesCardSkeleton;
