import axiosInstance from '../../../../../../utils/axios';

import { IGetProfileService } from './interfaces';
import { Profile } from './models';
import { handleError } from './errors';

class GetProfileService implements IGetProfileService {
  async getProfile(token: string, userUUID: string): Promise<Profile> {
    try {
      const response = await axiosInstance.get<Profile>(
        `/discord/user/${userUUID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new GetProfileService();
