import React from 'react';

import { Tabs, Tab, Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ProfileTabsProps {
  currentTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  isKYCCompleted: boolean | null;
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({
  currentTab,
  handleTabChange,
  isKYCCompleted,
}) => {
  const { t } = useTranslation('profile');

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        centered
        variant="fullWidth"
      >
        <Tab label={t('TABS_NAME.PROFILE')} />
        <Tab
          label={
            <Box display="flex" alignItems="center" justifyContent="center">
              {t('TABS_NAME.KYC')}
              {isKYCCompleted === null ? (
                <CircularProgress size={24} sx={{ ml: 1 }} /> // Affichage du CircularProgress lorsque la valeur est null
              ) : isKYCCompleted ? (
                '✅' // Affichage de la coche si KYC est complété
              ) : (
                '❌' // Affichage de la croix si KYC n'est pas complété
              )}
            </Box>
          }
        />
      </Tabs>
    </Box>
  );
};

export default ProfileTabs;
