import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import CertificateCard from './CertificateCard';
import { CertificateResponse } from '../services/get/certificate/models';
import { CertificateCardSkeleton } from './CertificateCardSkeleton';

interface CertificateTabsProps {
  certificates: CertificateResponse[];
  loading: boolean; // Ajout de l'état de chargement en tant que prop
}

const CertificateTabs: React.FC<CertificateTabsProps> = ({
  certificates,
  loading,
}) => {
  if (loading) {
    return (
      <Grid container spacing={3} justifyContent="center">
        {[...Array(2)].map((_, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
            <CertificateCardSkeleton />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} justifyContent="center">
      {certificates.map((certificate) => (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={certificate.id}>
          <CertificateCard certificate={certificate} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CertificateTabs;
