import { toast } from 'react-toastify';
import axiosInstance from '../../../utils/axios';
import { FundedCreationRequestResponse, FundedResponse } from './interface';

export const fetchFundedCreationRequest = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<FundedCreationRequestResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<FundedCreationRequestResponse>(
      `/funded/requests?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error(
      'Erreur lors de la récupération des funded creation request',
    );
  }
};

export const acceptFundedCreationRequest = async (
  accessToken: string,
  id: number,
) => {
  try {
    const response = await axiosInstance.post(`/funded/requests/${id}/accept`, {
      headers: {
        Authorization: accessToken,
      },
    });
    if (response.status === 200) {
      toast.success('Le requête a été acceptée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le requête n'a pas pu être acceptée", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};
export const refuseFundedCreationRequest = async (
  accessToken: string,
  id: number,
  reason: string,
) => {
  try {
    const response = await axiosInstance.post(
      `/funded/requests/${id}/reject`,
      {
        reason: reason,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );

    if (response.status === 200) {
      toast.success('Le requête a été rejetée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le requête n'a pas pu être rejetée", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const fetchAllFundeds = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<FundedResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<FundedResponse>(
      `/funded/all-accounts?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des funded accounts');
  }
};
