import i18n from '../../i18n';
import { useThemeContext } from '../../context/ThemeContext/ThemeContext';

import enFlag from '../../assets/icons/flags/en.png';
import frFlag from '../../assets/icons/flags/fr.png';
import esFlag from '../../assets/icons/flags/es.png';
import arFlag from '../../assets/icons/flags/sa.png';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/material';

interface LanguageSelectorProps {
  className?: string;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = (className) => {
  const { isDarkMode } = useThemeContext();

  const locales: { [key: string]: { title: string; flag: any } } = {
    en: { title: 'English', flag: enFlag },
    fr: { title: 'Français', flag: frFlag },
    es: { title: 'Español', flag: esFlag },
    ar: { title: 'العربية', flag: arFlag },
  };

  return (
    <>
      {Object.keys(locales).map((locale) => (
        <MenuItem
          key={locale}
          onClick={() => i18n.changeLanguage(locale)}
          sx={{
            '&:hover': {
              backgroundColor: isDarkMode
                ? 'rgba(255, 255, 255, 0.2)'
                : 'rgba(149, 143, 143, 0.2)',
            },
          }}
        >
          <Box
            component="img"
            sx={{
              height: '18px',
              width: '24px',
              borderRadius: '2px',
              marginRight: '8px',
            }}
            alt={locales[locale].title}
            src={locales[locale].flag}
          />
          <ListItemText>{locales[locale].title}</ListItemText>
        </MenuItem>
      ))}
    </>
  );
};
export default LanguageSelector;
