import { useState, useEffect } from 'react';
import { getDiscordUsers } from '../services/get/getDiscordUsers';
import { DiscordUser } from '../services/get/models';
import { useAuth } from '../../../../context/AuthContext/AuthContext';

export const useDiscordUsers = () => {
  const [users, setUsers] = useState<DiscordUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { tokens } = useAuth();

  useEffect(() => {
    const fetchDiscordUsers = async () => {
      try {
        setLoading(true);
        const response = await getDiscordUsers(tokens?.accessToken || '');
        // console.log('Fetched users:', response.users);
        setUsers(response.users);
      } catch (err) {
        setError((err as Error).message || 'Failed to fetch users');
      } finally {
        setLoading(false);
      }
    };

    fetchDiscordUsers();
  }, []);

  return { users, loading, error };
};
