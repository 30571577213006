import axiosInstance from '../../../../../../utils/axios';
import { PhaseOverviewDTO } from './models';
import { handlePhaseApiError } from './errors';

const getPhaseData = async (
  phase: 'phase1' | 'phase2',
  token: string,
): Promise<PhaseOverviewDTO> => {
  try {
    const response = await axiosInstance.get<PhaseOverviewDTO>(
      `/v2/admin/full-dashboard/pie/${phase}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error: any) {
    handlePhaseApiError(error);
    throw error;
  }
};

export default getPhaseData;
