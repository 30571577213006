import { useEffect, useState } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import getPhaseData from '../../services/get/pieChart/phase/getPhase';
import { PhaseOverviewDTO } from '../../services/get/pieChart/phase/models';

export const usePhaseData = (phase: 'phase1' | 'phase2', isActive: boolean) => {
  const { tokens } = useAuth();
  const [data, setData] = useState<PhaseOverviewDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null); // Ajout de l'état error

  useEffect(() => {
    if (isActive) {
      setLoading(true);
      const fetchPhaseData = async () => {
        if (tokens) {
          try {
            const response = await getPhaseData(phase, tokens.accessToken);
            setData(response);
          } catch (err) {
            setError(err as Error); // Capture de l'erreur
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      };

      fetchPhaseData();
    }
  }, [tokens, phase, isActive]);

  return { data, loading, error }; // Retour de l'erreur
};
