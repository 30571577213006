export const handleErrorWithdrawalRequest = (
  error: any,
  onError: (message: string) => void,
): void => {
  console.error('Withdrawal request failed:', error);
  if (error.response?.status === 409) {
    onError('Another user has already used this wallet to make a withdrawal.');
  } else {
    onError('Votre demande a échouée.');
  }
};
