import React from 'react';
import { Tabs, Tab } from '@mui/material';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';

interface PieChartTabsNavProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const PieChartTabsNav: React.FC<PieChartTabsNavProps> = ({
  value,
  handleChange,
}) => {
  const { isBetweenXSAndSM } = useBreakpoints();
  const { t } = useTranslation('dashboard');

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant={isBetweenXSAndSM ? 'scrollable' : 'fullWidth'}
      scrollButtons={false}
    >
      <Tab
        label={t('PIE_CHART.TABS_NAME.OVERVIEW')}
        sx={{ textWrap: 'nowrap' }}
      />
      <Tab
        label={t('PIE_CHART.TABS_NAME.PHASE_1')}
        sx={{ textWrap: 'nowrap' }}
      />
      <Tab
        label={t('PIE_CHART.TABS_NAME.PHASE_2')}
        sx={{ textWrap: 'nowrap' }}
      />
      <Tab
        label={t('PIE_CHART.TABS_NAME.FUNDED')}
        sx={{ textWrap: 'nowrap' }}
      />
    </Tabs>
  );
};

export default PieChartTabsNav;
