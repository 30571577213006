export class SalesAPIError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'SalesAPIError';
  }
}

export const handleSalesApiError = (error: any): void => {
  if (error.response && error.response.data && error.response.data.message) {
    throw new SalesAPIError(error.response.data.message);
  } else {
    throw new SalesAPIError('An unexpected error occurred.');
  }
};
