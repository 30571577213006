import React, { useState, useEffect } from 'react';
import HeaderCards from '../layouts/HeaderCards/HeaderCards';
import {
  Autocomplete,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Pagination,
} from '@mui/material';
import { useDiscordUserActivity } from '../hooks/useDiscordUserActivity';
import { useDiscordUsers } from '../hooks/useDiscordUsers';
import { usePostDiscordActivity } from '../hooks/usePostDiscordActivity'; // Import du hook pour l'activité
import { DiscordUser } from '../services/get/interfaces'; // Interface des utilisateurs Discord

const SpecifiedTab: React.FC = () => {
  const { users, loading: usersLoading, error: usersError } = useDiscordUsers(); // Récupérer les utilisateurs Discord
  const [selectedUser, setSelectedUser] = useState<DiscordUser | null>(null);
  const [startPeriod, setStartPeriod] = useState<string | undefined>(undefined);
  const [endPeriod, setEndPeriod] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1); // Page actuelle

  // Utilisation du hook pour récupérer l'activité utilisateur
  const { data, pagination, loading, error, fetchUserActivity } =
    useDiscordUserActivity();
  const {
    activityData, // Activité postée (utilisée pour HeaderCards)
    loading: postLoading,
    error: postError,
    postActivity,
  } = usePostDiscordActivity();

  // Fonction pour convertir les dates en GMT ISO
  const convertToGMT = (dateString: string): string => {
    const date = new Date(dateString);
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    ).toISOString();
  };

  const handleSearch = (page: number = 1) => {
    if (selectedUser) {
      // Convertir les périodes en GMT si elles sont définies
      const formattedStartPeriod = startPeriod
        ? convertToGMT(startPeriod)
        : undefined;
      const formattedEndPeriod = endPeriod
        ? convertToGMT(endPeriod)
        : undefined;

      // Fetch l'activité utilisateur (pagination)
      fetchUserActivity({
        userId: selectedUser.id,
        startPeriod: formattedStartPeriod,
        endPeriod: formattedEndPeriod,
        page: page, // Inclure la page dans la requête
      });

      // Post l'activité utilisateur
      postActivity(
        [{ id: selectedUser.id, displayName: selectedUser.displayName }],
        formattedStartPeriod,
        formattedEndPeriod,
      );
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
    handleSearch(value); // Rechercher avec la nouvelle page
  };

  const formatDate = (date: string): string => {
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getPeriodText = (): string => {
    if (!startPeriod && !endPeriod) {
      return 'Suivi sur le mois en cours';
    }
    const startDate = startPeriod ? formatDate(startPeriod) : '00/00/0000';
    const endDate = endPeriod ? formatDate(endPeriod) : '00/00/0000';
    return `Suivi sur la période du ${startDate} au ${endDate}`;
  };

  return (
    <>
      {/* Passer les données d'activité postée à HeaderCards */}
      <HeaderCards activityData={activityData} isLoading={postLoading} />

      <Stack direction={'row'} gap={2} mb={6} mt={8} flexWrap={'wrap'}>
        {/* Autocomplete pour sélectionner un utilisateur */}
        <Autocomplete
          disablePortal
          options={users || []}
          getOptionLabel={(option) => option.displayName}
          onChange={(event, newValue) => setSelectedUser(newValue)}
          value={selectedUser}
          sx={{ width: 300 }}
          loading={usersLoading}
          renderInput={(params) => <TextField {...params} label="Employé" />}
        />
        <TextField
          type="date"
          label="Début de Période"
          InputLabelProps={{
            shrink: true,
          }}
          value={startPeriod || ''}
          onChange={(e) => setStartPeriod(e.target.value)}
        />
        <TextField
          type="date"
          label="Fin de Période"
          InputLabelProps={{
            shrink: true,
          }}
          value={endPeriod || ''}
          onChange={(e) => setEndPeriod(e.target.value)}
        />
        <Button variant="contained" onClick={() => handleSearch(1)}>
          {loading ? 'Loading...' : 'Search'}
        </Button>
      </Stack>

      {error && <Typography color="error">{error}</Typography>}

      <>
        <Typography variant="h4" component={'h1'} gutterBottom>
          {getPeriodText()}
        </Typography>

        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Jour</TableCell>
                <TableCell align="left">Heure de connexion</TableCell>
                <TableCell align="left">Heure de déconnexion</TableCell>
                <TableCell align="left">Heure de passage en lunch</TableCell>
                <TableCell align="left">Heure de retour de lunch</TableCell>
                <TableCell align="left">Temps travaillé</TableCell>
                <TableCell align="left">Temps en break</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Aucune donnée disponible
                  </TableCell>
                </TableRow>
              )}
              {data.map((activity, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {activity.day}
                  </TableCell>
                  <TableCell align="left">{activity.arrivalAt}</TableCell>
                  <TableCell align="left">{activity.departAt}</TableCell>
                  <TableCell align="left">
                    {activity.lunchBreakStartAt}
                  </TableCell>
                  <TableCell align="left">{activity.lunchBreakEndAt}</TableCell>
                  <TableCell align="left">
                    {activity.totalWorkTime.hours}h{' '}
                    {activity.totalWorkTime.minutes}m
                  </TableCell>
                  <TableCell align="left">
                    {activity.totalBreakTime.hours}h{' '}
                    {activity.totalBreakTime.minutes}m
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        {pagination && pagination.maxPage > 1 && (
          <Stack spacing={2} mt={4} alignItems="center">
            <Pagination
              count={pagination.maxPage}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              shape="rounded"
            />
          </Stack>
        )}
      </>
    </>
  );
};

export default SpecifiedTab;
