import axiosInstance from '../../../../../../utils/axios';
import { decodeJWT } from '../../auth/decodeJWT';
import { KYCResponse } from './interfaces';
import { handleError } from './errors';

export const fetchKYC = async (accessToken: string): Promise<KYCResponse> => {
  const decoded = decodeJWT(accessToken);

  if (!decoded?.userId) {
    throw new Error('Invalid access token - userId not found');
  }

  try {
    const response = await axiosInstance.get<KYCResponse>(
      `/kyc/area/user/${decoded.userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
