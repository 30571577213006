import { useState } from 'react';

const useVisibility = (
  initialVisibility = false,
): [boolean, () => void, () => void, () => void] => {
  const [isVisible, setIsVisible] = useState<boolean>(initialVisibility);

  const show = (): void => setIsVisible(true);
  const hide = (): void => setIsVisible(false);
  const toggleVisibility = (): void => setIsVisible((prev) => !prev);

  return [isVisible, show, hide, toggleVisibility];
};

export default useVisibility;
