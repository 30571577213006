import React from 'react';

import { useCommissions } from '../../context/Commissions/CommissionsContext';

import { Card, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommissionsCardSkeleton from './CommissionsCardSkeleton';

interface CommissionsCardProps {
  timeframe: string;
}

const CommissionsCard: React.FC<CommissionsCardProps> = ({ timeframe }) => {
  const theme = useTheme();
  const { t } = useTranslation('partnerArea');
  const { commissionsData, loading, error } = useCommissions();

  if (loading) {
    return <CommissionsCardSkeleton />;
  } else if (!commissionsData) {
    return null;
  }

  const getTitle = (timeframe: string) => {
    if (timeframe === 'TOTAL') {
      return t('COMMISSIONS_CARD.TITLE_TOTAL_COMMISSIONS');
    }
    return t('COMMISSIONS_CARD.TITLE_COMMISSIONS_THIS', {
      period:
        timeframe.charAt(0).toUpperCase() + timeframe.slice(1).toLowerCase(),
    });
  };

  return (
    <Card elevation={1} style={{ padding: '16px', flexGrow: '1' }}>
      <Typography variant="h5" component="h5" mb={4} fontWeight={'bold'}>
        {getTitle(timeframe)}
      </Typography>
      <Stack spacing={4}>
        <Stack>
          <Typography variant="h5" component="h5">
            {t('COMMISSIONS_CARD.TITLE_TOTAL_GENERATED_REVENUE')}
          </Typography>
          <Typography
            variant="h4"
            component="h6"
            color={theme.palette.primary.main}
          >
            {commissionsData.totalPurchasedAmount} €
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h5" component="h5">
            {t('COMMISSIONS_CARD.TITLE_TOTAL_PURCHASED')}
          </Typography>
          <Typography
            variant="h4"
            component="h6"
            color={theme.palette.primary.main}
          >
            {commissionsData.totalPurchased}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h5" component="h5">
            {t('COMMISSIONS_CARD.TITLE_TOTAL_EARNED')}
          </Typography>
          <Typography
            variant="h4"
            component="h6"
            color={theme.palette.primary.main}
          >
            {commissionsData.totalEarned} €
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h5" component="h5">
            {t('COMMISSIONS_CARD.TITLE_NEW_REFERRAL')}
          </Typography>
          <Typography
            variant="h4"
            component="h6"
            color={theme.palette.primary.main}
          >
            {commissionsData.newReferrals}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default CommissionsCard;
