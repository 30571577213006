import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  Stack,
  Box,
} from '@mui/material';

const EventsTableSkeleton: React.FC = () => {
  // Fonction qui génère un nombre aléatoire entre 1 et 3 pour chaque ligne
  const getRandomCircularCount = () => Math.floor(Math.random() * 3) + 1;

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              <Skeleton width="100px" />
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              <Skeleton width="100px" />
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              <Skeleton width="100px" />
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              <Skeleton width="100px" />
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              <Skeleton width="100px" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Simulating loading rows */}
          {[...Array(5)].map((_, rowIndex) => {
            const randomCircularCount = getRandomCircularCount(); // Générer un nombre aléatoire pour chaque ligne
            return (
              <TableRow key={rowIndex}>
                <TableCell align="left">
                  <Stack direction="row" alignItems="center" gap={2}>
                    {/* Contrainte de la taille totale des skeleton circulaires */}
                    <Box sx={{ width: '80px', display: 'flex', gap: 1 }}>
                      {[...Array(randomCircularCount)].map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="circular"
                          width={20}
                          height={20}
                        />
                      ))}
                    </Box>
                    {/* Skeleton text aligné après les circulaires */}
                    <Skeleton width="200px" />
                  </Stack>
                </TableCell>
                <TableCell align="left">
                  <Stack direction={'row'} gap={2} alignItems={'center'}>
                    <Skeleton variant="circular" width={50} height={50} />
                    <Skeleton width="50px" height={40} />
                  </Stack>
                </TableCell>
                <TableCell align="left">
                  <Skeleton width="150px" />
                  <Skeleton width="100px" />
                </TableCell>
                <TableCell align="left">
                  <Skeleton width="100px" />
                </TableCell>
                <TableCell align="left">
                  <Skeleton width="100px" />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EventsTableSkeleton;
