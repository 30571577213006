import React from 'react';

import useBreakpoints from '../../../../hooks/useBreakpoints';

import { Card, Divider, Skeleton, Stack } from '@mui/material';

const OverviewCardSkeleton = () => {
  const { isBetweenXSAndLG } = useBreakpoints();

  return (
    <Card elevation={0} sx={{ padding: '28px' }}>
      <Stack
        direction={isBetweenXSAndLG ? 'column' : 'row'}
        width={'100%'}
        justifyContent={'space-between'}
      >
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          gap={2}
        >
          <Skeleton variant="rounded" height={25} width={200} />
          <Skeleton variant="rounded" height={30} width={80} />
          <Skeleton variant="rounded" height={40} width={120} sx={{ mt: 3 }} />
        </Stack>
        <Divider
          orientation={isBetweenXSAndLG ? 'horizontal' : 'vertical'}
          variant="middle"
          flexItem
          sx={{
            borderRightWidth: '2px',
            margin: isBetweenXSAndLG ? '20px 0px' : '0px',
          }}
        />

        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          gap={2}
        >
          <Skeleton variant="rounded" height={25} width={200} />
          <Skeleton variant="rounded" height={30} width={80} />
          <Skeleton variant="rounded" height={40} width={120} sx={{ mt: 3 }} />
        </Stack>
        <Divider
          orientation={isBetweenXSAndLG ? 'horizontal' : 'vertical'}
          variant="middle"
          flexItem
          sx={{
            borderRightWidth: '2px',
            margin: isBetweenXSAndLG ? '20px 0px' : '0px',
          }}
        />

        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          gap={2}
        >
          <Skeleton variant="rounded" height={25} width={200} />
          <Skeleton variant="rounded" height={30} width={80} />
          <Skeleton variant="rounded" height={35} width={120} sx={{ mt: 3 }} />
        </Stack>
        <Divider
          orientation={isBetweenXSAndLG ? 'horizontal' : 'vertical'}
          variant="middle"
          flexItem
          sx={{
            borderRightWidth: '2px',
            margin: isBetweenXSAndLG ? '20px 0px' : '0px',
          }}
        />

        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          gap={2}
        >
          <Skeleton variant="rounded" height={25} width={200} />
          <Skeleton variant="rounded" height={30} width={80} />
          <Skeleton variant="rounded" height={40} width={120} sx={{ mt: 3 }} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default OverviewCardSkeleton;
