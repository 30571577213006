import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import SalesCard from '../../components/SalesCard/SalesCard';
import { useSalesKPI } from '../../hooks/salesCard/useSalesKPI';
import SalesCardSkeleton from '../../components/SalesCard/SalesCardSkeleton';
import { useTranslation } from 'react-i18next';

const SalesCards = () => {
  const { t } = useTranslation('dashboard');

  const [dayPreviousValue, setDayPreviousValue] = useState(0);
  const [weekPreviousValue, setWeekPreviousValue] = useState(0);
  const [monthPreviousValue, setMonthPreviousValue] = useState(0);

  const { data: dayData, loading: dayLoading } = useSalesKPI({
    kpiType: 'day',
    previousValue: dayPreviousValue,
  });

  const { data: weekData, loading: weekLoading } = useSalesKPI({
    kpiType: 'week',
    previousValue: weekPreviousValue,
  });

  const { data: monthData, loading: monthLoading } = useSalesKPI({
    kpiType: 'month',
    previousValue: monthPreviousValue,
  });

  const { data: totalData, loading: totalLoading } = useSalesKPI({
    kpiType: 'total',
  });

  const handlePrevious = (
    setPrevious: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    setPrevious((prev) => prev + 1);
  };

  const handleNext = (
    setPrevious: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    setPrevious((prev) => (prev > 0 ? prev - 1 : prev));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Stack direction="row" flexWrap="wrap" gap={2} sx={{ height: '100%' }}>
        {/* Day Sales Card */}
        <Stack sx={{ flex: '1 1 calc(50% - 8px)' }}>
          {dayLoading && <SalesCardSkeleton />}
          {!dayLoading && (
            <SalesCard
              title={t('KPI_CARDS.TITLES.TODAYS_SALES')}
              value={dayData?.total ? `${dayData.total} €` : '0'}
              date={dayData?.date || 'N/A'}
              tooltip={t('KPI_CARDS.TOOLTIPS.COMPARED_TO_LAST_DAY')}
              variation={dayData?.variation ?? 0}
              onPrevious={() => handlePrevious(setDayPreviousValue)}
              onNext={() => handleNext(setDayPreviousValue)}
              previousValue={dayPreviousValue}
            />
          )}
        </Stack>

        {/* Weekly Sales Card */}
        <Stack sx={{ flex: '1 1 calc(50% - 8px)' }}>
          {weekLoading && <SalesCardSkeleton />}
          {!weekLoading && (
            <SalesCard
              title={t('KPI_CARDS.TITLES.WEEKLY_SALES')}
              value={weekData?.total ? `${weekData.total} €` : '0'}
              date={weekData?.date || 'N/A'}
              tooltip={t('KPI_CARDS.TOOLTIPS.COMPARED_TO_LAST_WEEK')}
              variation={weekData?.variation ?? 0}
              onPrevious={() => handlePrevious(setWeekPreviousValue)}
              onNext={() => handleNext(setWeekPreviousValue)}
              previousValue={weekPreviousValue}
            />
          )}
        </Stack>

        {/* Monthly Sales Card */}
        <Stack sx={{ flex: '1 1 calc(50% - 8px)' }}>
          {monthLoading && <SalesCardSkeleton />}
          {!monthLoading && (
            <SalesCard
              title={t('KPI_CARDS.TITLES.MONTHLY_SALES')}
              value={monthData?.total ? `${monthData.total} €` : '0'}
              date={monthData?.date || 'N/A'}
              tooltip={t('KPI_CARDS.TOOLTIPS.COMPARED_TO_PREVIOUS_MONTH')}
              variation={monthData?.variation ?? 0}
              onPrevious={() => handlePrevious(setMonthPreviousValue)}
              onNext={() => handleNext(setMonthPreviousValue)}
              previousValue={monthPreviousValue}
            />
          )}
        </Stack>

        {/* Total Sales Card */}
        <Stack sx={{ flex: '1 1 calc(50% - 8px)' }}>
          {totalLoading && <SalesCardSkeleton actions={false} />}
          {!totalLoading && (
            <SalesCard
              title={t('KPI_CARDS.TITLES.TOTAL_SALES')}
              value={totalData?.total ? `${totalData.total} €` : '0'}
              date={totalData?.date || 'N/A'}
              variation={totalData?.variation ?? 0}
              actions={false}
              onPrevious={undefined}
              onNext={undefined}
              previousValue={0}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default SalesCards;
