import axiosInstance from '../../../../../../utils/axios';
import { decodeJWT } from '../../auth/decodeJWT';
import { handleError } from './errors';

export const sendKycDocuments = async (
  accessToken: string,
  file: File,
  documentType:
    | 'IDENTITY_PROOF_RECTO'
    | 'IDENTITY_PROOF_VERSO'
    | 'ADDRESS_PROOF',
): Promise<void> => {
  const decoded = decodeJWT(accessToken);

  if (!decoded?.userId) {
    throw new Error('Invalid access token - userId not found');
  }

  const formData = new FormData();
  formData.append('userId', decoded.userId);
  formData.append('file', file);
  formData.append('documentType', documentType);

  try {
    const response = await axiosInstance.post('/kyc/upload', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status !== 200) {
      throw new Error('Upload failed');
    }
  } catch (error) {
    handleError(error);
    throw error;
  }
};
