import React, { useState } from 'react';

import OverviewTab from './components/OverviewTab';
import SpecifiedTab from './components/SpecifiedTab';

import { Box, Tab, Tabs } from '@mui/material';

const TimeTracker = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        centered
      >
        <Tab label="OVERVIEW" />
        <Tab label="SPECIFIED" />
      </Tabs>

      {selectedTab === 0 && <OverviewTab />}
      {selectedTab === 1 && <SpecifiedTab />}
    </Box>
  );
};

export default TimeTracker;
