import React from 'react';
import {
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ImpactFilterProps {
  impactFilter: {
    high: boolean;
    medium: boolean;
    low: boolean;
    holiday: boolean;
  };
  setImpactFilter: React.Dispatch<
    React.SetStateAction<{
      high: boolean;
      medium: boolean;
      low: boolean;
      holiday: boolean;
    }>
  >;
}

const ImpactFilter: React.FC<ImpactFilterProps> = ({
  impactFilter,
  setImpactFilter,
}) => {
  const { t } = useTranslation('calendar');

  const handleImpactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImpactFilter({
      ...impactFilter,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <FormGroup>
      <Typography variant="h4" component="h4" fontWeight="bold">
        {t('TITLES.IMPACT')}
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap={2} sx={{ marginTop: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={impactFilter.low}
              onChange={handleImpactChange}
              name="low"
              sx={{
                color: '#FFC700',
                '&.Mui-checked': {
                  color: '#FFC700',
                },
              }}
            />
          }
          label={t('IMPACTS.LOW')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={impactFilter.medium}
              onChange={handleImpactChange}
              name="medium"
              sx={{
                color: '#FF9264',
                '&.Mui-checked': {
                  color: '#FF9264',
                },
              }}
            />
          }
          label={t('IMPACTS.MEDIUM')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={impactFilter.high}
              onChange={handleImpactChange}
              name="high"
              sx={{
                color: '#FF3548',
                '&.Mui-checked': {
                  color: '#FF3548',
                },
              }}
            />
          }
          label={t('IMPACTS.HIGH')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={impactFilter.holiday}
              onChange={handleImpactChange}
              name="holiday"
              sx={{
                color: '#B164FF',
                '&.Mui-checked': {
                  color: '#B164FF',
                },
              }}
            />
          }
          label={t('IMPACTS.HOLIDAY')}
        />
      </Stack>
    </FormGroup>
  );
};

export default ImpactFilter;
