import React, { useState } from 'react';
import { useSummary } from '../../context/Summary/SummaryContext';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { hexToRgba } from '../../../../utils/hexConverter';
import {
  Button,
  Card,
  Chip,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import PercentIcon from '@mui/icons-material/Percent';
import StarIcon from '@mui/icons-material/Star';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DiamondIcon from '@mui/icons-material/Diamond';
import { useTranslation } from 'react-i18next';
import LinkCardSkeleton from './LinkCardSkeleton';

const LinkCard = () => {
  const { t } = useTranslation('partnerArea');
  const { dashboardData, loading } = useSummary();
  const { isBetweenXSAndSM } = useBreakpoints();
  const [buttonText, setButtonText] = useState(
    t('AFFILIATION_CARD.BUTTON_COPY_LINK'),
  );

  if (loading) {
    return <LinkCardSkeleton />;
  } else if (!dashboardData) {
    return null;
  }

  const affiliationTypeLabels: { [key: string]: string } = {
    REFERRAL: t('AFFILIATION_CARD.RANKS.REFERRAL_RANK'),
    FIXED_PERCENTAGE: dashboardData?.affiliationAttributes?.attribute || '0%',
    RANK_SILVER: t('AFFILIATION_CARD.RANKS.SILVER_RANK'),
    RANK_GOLD: t('AFFILIATION_CARD.RANKS.GOLD_RANK'),
    RANK_PLATINIUM: t('AFFILIATION_CARD.RANKS.PLATINIUM_RANK'),
  };

  const affiliationTypeColors: { [key: string]: string } = {
    REFERRAL: '#0075ff',
    FIXED_PERCENTAGE: '#0075ff',
    RANK_SILVER: '#C0C0C0',
    RANK_GOLD: '#f0c300',
    RANK_PLATINIUM: '#ed0000',
  };

  const affiliationTypeText: { [key: string]: string } = {
    REFERRAL: t(
      'AFFILIATION_CARD.RANKS.RANKS_DESCRIPTION.REFERRAL_DESCRIPTION',
    ),
    FIXED_PERCENTAGE: t(
      'AFFILIATION_CARD.RANKS.RANKS_DESCRIPTION.PERCENTAGE_DESCRIPTION',
    ),
    RANK_SILVER: t(
      'AFFILIATION_CARD.RANKS.RANKS_DESCRIPTION.SILVER_DESCRIPTION',
    ),
    RANK_GOLD: t('AFFILIATION_CARD.RANKS.RANKS_DESCRIPTION.GOLD_DESCRIPTION'),
    RANK_PLATINIUM: t(
      'AFFILIATION_CARD.RANKS.RANKS_DESCRIPTION.PLATINIUM_DESCRIPTION',
    ),
  };

  const affiliationTypeIcons: { [key: string]: JSX.Element } = {
    REFERRAL: <GroupIcon sx={{ fill: '#0075ff' }} />,
    FIXED_PERCENTAGE: <PercentIcon sx={{ fill: '#0075ff' }} />,
    RANK_SILVER: <StarIcon sx={{ fill: '#C0C0C0' }} />,
    RANK_GOLD: <EmojiEventsIcon sx={{ fill: '#f0c300' }} />,
    RANK_PLATINIUM: <DiamondIcon sx={{ fill: '#ed0000' }} />,
  };

  const { type, attribute } = dashboardData.affiliationAttributes;

  let label = '';
  let color = '';
  let icon = null;
  let tooltipText = '';

  if (type === 'FIXED_PERCENTAGE') {
    label = attribute;
    color = affiliationTypeColors['FIXED_PERCENTAGE'];
    icon = affiliationTypeIcons['FIXED_PERCENTAGE'];
    tooltipText = affiliationTypeText['FIXED_PERCENTAGE'];
  } else if (type === 'AFFILIATION_RANK' && attribute) {
    label = affiliationTypeLabels[attribute] || '';
    color = affiliationTypeColors[attribute] || '';
    icon = affiliationTypeIcons[attribute] || null;
    tooltipText = affiliationTypeText[attribute] || '';
  } else {
    label = affiliationTypeLabels[type] || '';
    color = affiliationTypeColors[type] || '';
    icon = affiliationTypeIcons[type] || null;
    tooltipText = affiliationTypeText[type] || '';
  }

  const rgbaColor = hexToRgba(color, 0.5);

  const handleCopyClick = () => {
    const fullAffiliationLink = `https://live.raisemyfunds.co/buy-challenge?affiliate=${dashboardData.affiliationLink}`;
    navigator.clipboard.writeText(fullAffiliationLink).then(() => {
      setButtonText(t('AFFILIATION_CARD.BUTTON_COPIED'));
      setTimeout(() => {
        setButtonText(t('AFFILIATION_CARD.BUTTON_COPY_LINK'));
      }, 2000);
    });
  };

  return (
    <Card
      elevation={0}
      sx={{
        width: isBetweenXSAndSM || type === 'REFERRAL' ? '100%' : '50%',
        padding: '16px',
      }}
    >
      <Stack direction={'row'} justifyContent="space-between">
        <Typography variant="h5" component="h6" mb={7}>
          {type === 'REFERRAL'
            ? t('AFFILIATION_CARD.TITLE_AFFILIATION_REFERRAL')
            : t('AFFILIATION_CARD.TITLE_AFFILIATION_AFFILIATION')}
        </Typography>
        <Tooltip title={tooltipText} placement="top">
          <Chip
            variant="filled"
            label={label}
            sx={{
              backgroundColor: rgbaColor,
              color: color,
              fontWeight: 'bold',
            }}
            icon={icon}
          />
        </Tooltip>
      </Stack>

      <Stack spacing={2} direction={isBetweenXSAndSM ? 'column' : 'row'}>
        <TextField
          fullWidth
          id="affiliation-link"
          label={t('AFFILIATION_CARD.LABEL_AFFILIATION_LINK')}
          defaultValue={`https://live.raisemyfunds.co/buy-challenge?affiliate=${dashboardData.affiliationLink}`}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
        <Button
          variant="contained"
          onClick={handleCopyClick}
          fullWidth={isBetweenXSAndSM}
          style={{ textWrap: 'nowrap' }}
        >
          {buttonText}
        </Button>
      </Stack>
    </Card>
  );
};

export default LinkCard;
