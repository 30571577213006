import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

import { getMetaTraderGroups } from '../../../services/admin/userAbout/userAbout';

interface AsynchronousProps {
  accessToken: string;
  userAdminID: number;
  onSelect: (selectedGroup: string | null) => void;
}

export default function Asynchronous({
  accessToken,
  userAdminID,
  onSelect,
}: AsynchronousProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const loading = open && options.length === 0;
  const theme = useTheme();

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const groups = await getMetaTraderGroups(accessToken, userAdminID);

        if (active) {
          setOptions(groups);
        }
      } catch (error) {
        console.error('Failed to fetch MetaTrader groups:', error);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, accessToken, userAdminID]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      onChange={(event, newValue) => {
        onSelect(newValue);
      }}
      sx={{
        width: 250,
        '.MuiAutocomplete-popupIndicator': {
          color: 'white',
          transform: 'none', // S'assure que l'icône n'est pas transformée ou déplacée
        },
        direction: 'ltr',
        '.MuiAutocomplete-clearIndicator': {
          color: 'white',
          marginRight: theme.spacing(1), // Ajuste le positionnement à droite
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white',
          },
          '&:hover fieldset': {
            borderColor: 'primary.main',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'primary.main',
          },
        },
        '& .MuiSvgIcon-root': {
          color: 'white',
        },
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => option}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Group Name"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            style: { color: 'white' },
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            style: { color: 'lightgray' },
          }}
        />
      )}
    />
  );
}
