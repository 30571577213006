import React from 'react';

import { DashboardSummaryProvider } from '../../provider/Summary/SummaryProvider';
import useBreakpoints from '../../../../hooks/useBreakpoints';

import { Stack } from '@mui/material';

import {
  Commissions,
  HeaderCards,
  LinkCard,
  PayoutCard,
  WalletCard,
} from '../../partner';
import { useSummary } from '../../context/Summary/SummaryContext';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';

const PartnerContent = () => {
  const { isBetweenXSAndSM } = useBreakpoints();
  const { dashboardData } = useSummary(); // Déplacement ici, après le DashboardSummaryProvider

  useDocumentTitle('Partner Area');

  // Récupérer l'affiliation type depuis dashboardData
  const affiliationType = dashboardData?.affiliationAttributes.type;

  return (
    <>
      <HeaderCards />
      <Stack
        mt={4}
        mb={4}
        direction={isBetweenXSAndSM ? 'column' : 'row'}
        spacing={4}
        width={'100%'}
      >
        <LinkCard />
        {/* <WalletCard /> */}
        {affiliationType !== 'REFERRAL' && <WalletCard />}
      </Stack>
      <Commissions />
      <Stack mt={4} spacing={2}>
        <PayoutCard />
      </Stack>
    </>
  );
};

const Partner = () => {
  return (
    <DashboardSummaryProvider>
      <PartnerContent />
    </DashboardSummaryProvider>
  );
};

export default Partner;
