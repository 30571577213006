import { useAuth } from './../../../../context/AuthContext/AuthContext';
import { useState, useEffect } from 'react';
import { getUserPermissions } from '../services/getPermissions'; // Votre service qui fait la requête GET

export const useGetUserPermissions = (userUUID: string) => {
  const { tokens } = useAuth(); // Récupérer le token depuis votre context useAuth
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPermissions = async () => {
      if (!tokens?.accessToken) {
        setError('No access token available');
        setLoading(false);
        return; // Arrêter si pas de token
      }

      try {
        setLoading(true);
        const data = await getUserPermissions(userUUID, tokens.accessToken); // Appel de l'API

        if (Array.isArray(data)) {
          // Vérification que la réponse est bien un tableau
          setPermissions(data); // Assigner les permissions si la réponse est correcte
        } else {
          throw new Error('Invalid response format');
        }
      } catch (err) {
        setError('Failed to fetch permissions');
      } finally {
        setLoading(false);
      }
    };

    if (userUUID && tokens?.accessToken) {
      // Vérifier que l'utilisateur et le token sont définis
      fetchPermissions();
    }
  }, [userUUID, tokens]);

  return { permissions, loading, error };
};
