import React from 'react';

import { LightDashboardProvider } from '../../provider/LightDashboardProvider/LightDashboardProvider';

import useBreakpoints from '../../../../hooks/useBreakpoints';

import HeaderCards from '../../layouts/HeaderCards/HeaderCards';
import PieChartLight from '../../layouts/PieChartLight/PieChartLight';

import OverviewCard from '../../components/OverviewCard/OverviewCard';

import { Stack } from '@mui/material';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';

const LightDashboard = () => {
  const { isBetweenXSAndLG } = useBreakpoints();

  useDocumentTitle('Dashboard');

  return (
    <LightDashboardProvider>
      <Stack direction={isBetweenXSAndLG ? 'column' : 'row'} spacing={2} mb={4}>
        <PieChartLight />
        <HeaderCards />
      </Stack>

      <OverviewCard />
    </LightDashboardProvider>
  );
};

export default LightDashboard;
