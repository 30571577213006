import { Card, Skeleton, Stack } from '@mui/material';
import React from 'react';

const PayoutCardSkeleton = () => {
  return (
    <Card elevation={0}>
      <Stack spacing={2}>
        <Skeleton variant="rounded" height={100} />
        <Skeleton variant="rounded" height={100} />
        <Skeleton variant="rounded" height={100} />
      </Stack>
    </Card>
  );
};

export default PayoutCardSkeleton;
