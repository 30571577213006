export class FundedApiError extends Error {
  constructor(
    message: string,
    public statusCode: number,
  ) {
    super(message);
    this.name = 'FundedApiError';
  }
}

export const handleFundedApiError = (response: Response) => {
  if (!response.ok) {
    throw new FundedApiError(
      `Funded API error: ${response.statusText}`,
      response.status,
    );
  }
};
