import axiosInstance from '../../../../../utils/axios';
import { SalesKPIResponse } from './models';
import { handleSalesApiError } from './errors';

const getWeekSalesKPI = async (
  token: string,
  previousValue = 0,
): Promise<SalesKPIResponse> => {
  try {
    const response = await axiosInstance.get<SalesKPIResponse>(
      `/v2/admin/full-dashboard/kpi/week`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          previousValue,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    handleSalesApiError(error);
    throw error;
  }
};

export default getWeekSalesKPI;
