import React from 'react';

import { Paper, Skeleton, Stack } from '@mui/material';

import classes from './headerCard.module.scss';

const HeaderCardSkeleton: React.FC = () => {
  return (
    <Paper elevation={0} className={classes.card}>
      <Stack
        className={classes.header}
        justifyContent="space-between"
        direction="row"
      >
        <Stack mb={2}>
          <Skeleton
            height={42}
            width={42}
            sx={{ borderRadius: '6px' }}
            variant="rectangular"
          />
        </Stack>
      </Stack>
      <Stack>
        <Skeleton variant="text" width={80} height={30} />
        <Skeleton variant="text" width={180} height={40} />
      </Stack>
    </Paper>
  );
};

export default HeaderCardSkeleton;
