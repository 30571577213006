import axiosInstance from '../../../../../utils/axios';
import { IGetAccountCredentialsService } from './interfaces';
import { MetatraderCreationInfoDTO } from './models';
import { handleError } from './errors';

class GetAccountCredentialsService implements IGetAccountCredentialsService {
  async getAccountCredentials(
    accountLogin: string,
    token: string,
  ): Promise<MetatraderCreationInfoDTO> {
    try {
      const response = await axiosInstance.get<MetatraderCreationInfoDTO>(
        `/v2/challenges/account/${accountLogin}/credentials`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new GetAccountCredentialsService();
