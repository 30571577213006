import React from 'react';

import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../../context/AuthContext/AuthContext';

import { styled, useTheme } from '@mui/material/styles';

import DrawerHeader from './Header/Header';
import SidebarList from './SidebarList/SidebarList';

import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import adminItems from '../../Sidebar/adminItems.json';
import items from '../../Sidebar/items.json';
import { Link } from 'react-router-dom';

const drawerWidth = 305;

const SidebarWrapper = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
}));

interface SidebarProps {
  open: boolean;
  handleDrawerClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = theme.palette.mode === 'dark';
  const { t } = useTranslation(['sidebar']);

  if (!user) return null;

  return (
    <SidebarWrapper
      elevation={0}
      variant={isMobile ? 'temporary' : 'permanent'} // En mobile, la Sidebar sera temporaire
      open={open}
      onClose={handleDrawerClose} // Pour fermer la sidebar en version mobile
      sx={{
        ...(open && {
          width: drawerWidth,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          '& .MuiDrawer-paper': { width: drawerWidth },
        }),
        ...(!open && {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: isMobile ? 0 : `calc(${theme.spacing(7)} + 1px)`, // Cacher complètement en mobile
          '& .MuiDrawer-paper': {
            width: isMobile ? 0 : `calc(${theme.spacing(10)} + 1px)`,
          },
        }),
      }}
    >
      <DrawerHeader
        open={open}
        handleDrawerClose={handleDrawerClose}
        logoSrc={'/path/to/logo.png'}
        isMobile={isMobile}
      />
      <Typography
        variant="subtitle1"
        fontWeight={'bold'}
        sx={{
          marginLeft: '16px',
          ...(!open && { display: 'none' }),
        }}
      >
        {t('LINKS.TITLE_MAIN_MENU')}
      </Typography>
      <Divider />
      <Stack
        justifyContent="space-between"
        direction="column"
        sx={{ overflowY: 'scroll', height: '100%', position: 'relative' }}
      >
        <Box
          component={Link}
          to={'/buy-challenge'}
          sx={{
            ...(open ? { display: 'none' } : { display: 'flex' }),
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            backgroundColor: theme.palette.primary.main,
            marginTop: '25px',
            borderRadius: '100%',
            padding: '20px',
            height: '20px',
            width: '20px',
            left: '50%',
            right: '50%',
            transform: 'translateX(-50%)',
            textDecoration: 'none', // Centrage uniquement horizontal
            color: 'white',
          }}
        >
          <AddIcon />
        </Box>
        <SidebarList
          items={user.role === 'ROLE_ADMIN' ? adminItems : items}
          open={open}
          isMobile={isMobile}
          handleDrawerClose={handleDrawerClose}
          isDarkMode={isDarkMode}
        />
      </Stack>
    </SidebarWrapper>
  );
};

export default Sidebar;
