import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext/AuthContext';
import { TabProvider } from './context/TabContext/TabContext';
import { AccessControlProvider } from './context/AccessControlContext/AccessControlContext';

import Router from './Router';
import Layout from './components/layouts/Layout/Layout';
import Loader from './components/Loader/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProviderComponent } from './context/ThemeContext/ThemeContext';

export default function App() {
  return (
    <BrowserRouter>
      <AccessControlProvider>
        <div className="App">
          <AuthProvider>
            <ThemeProviderComponent>
              <TabProvider>
                <Suspense fallback={<Loader global={true} />}>
                  <Layout>
                    <Router />
                  </Layout>
                </Suspense>
              </TabProvider>
            </ThemeProviderComponent>
          </AuthProvider>
        </div>
        <ToastContainer />
      </AccessControlProvider>
    </BrowserRouter>
  );
}
