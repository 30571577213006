import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';
import { useAuth } from '../../../context/AuthContext/AuthContext';
import { useAccessControl } from '../../../context/AccessControlContext/AccessControlContext';

import Error from '../Error/Error';
import Sidebar from '../Navigation/Sidebar/Sidebar';
import Header from '../Navigation/Header/Header';

import { Box, CssBaseline, Stack } from '@mui/material';

import classes from './_layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

// Si on met à 0 elle prend 100% de la width
const drawerWidth = 305;

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { user } = useAuth();
  const { isDarkMode, toggleTheme } = useThemeContext();
  const { accessGranted } = useAccessControl();

  const navigate = useNavigate();

  // Détecte si on est en version mobile au premier rendu en fonction de la largeur de l'écran
  const isMobile = window.innerWidth < 960; // Utilise 960px comme seuil pour la version mobile

  // Initialisation de l'état de `open` : fermé par défaut en mobile, ouvert en desktop
  const [open, setOpen] = useState(!isMobile);

  // Ajout de l'événement 'resize' pour mettre à jour `open` en fonction de la largeur de l'écran
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 960) {
        setOpen(false); // Ferme la Sidebar en mobile
      } else {
        setOpen(true); // Ouvre la Sidebar en desktop
      }
    };

    // Attache l'événement 'resize'
    window.addEventListener('resize', handleResize);

    // Nettoie l'événement lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Le tableau vide [] garantit que l'effet est exécuté une seule fois

  const handleDrawerOpen = () => {
    setOpen(!open); // Ouvre la Sidebar manuellement
  };

  const handleDrawerClose = () => {
    setOpen(false); // Ferme la Sidebar manuellement
  };

  if (!accessGranted) {
    return (
      <Error
        documentTitle="Access Denied"
        title={'Access Denied'}
        subtitle={
          "If you believe you're seeing this message in error, please contact our support team at support@raisemyfunds.co. We're sorry, but access to our site is not available in your region at this time due to regulatory reasons."
        }
        button={'Return to homepage'}
        onClick={() => navigate('/')}
      />
    );
  }

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Stack>
        <CssBaseline />
        <Header
          open={open}
          drawerWidth={drawerWidth}
          handleDrawerOpen={handleDrawerOpen} // Contrôle via le Header
        />
        <Sidebar open={open} handleDrawerClose={handleDrawerClose} />{' '}
        {/* Sidebar contrôlée uniquement par `open` */}
      </Stack>
      <main className={classes.main}>{children}</main>
    </Box>
  );
};

export default Layout;
