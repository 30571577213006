import React from 'react';
import { useSummary } from '../../context/Summary/SummaryContext';
import { green, yellow } from '../../../../themes/index';
import HeaderCard from '../../../shared/components/HeaderCard/HeaderCard';
import { Stack, Typography, useTheme } from '@mui/material';
import { purple } from '@mui/material/colors';
import SavingsIcon from '@mui/icons-material/Savings';
import GroupIcon from '@mui/icons-material/Group';
import RadarIcon from '@mui/icons-material/Radar';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useTranslation } from 'react-i18next';

const HeaderCards: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation('partnerArea');
  const { dashboardData, loading, error } = useSummary();

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  if (!dashboardData && !loading) {
    return null;
  }

  return (
    <Stack direction="row" flexWrap="wrap" sx={{ rowGap: 2, columnGap: 4 }}>
      <HeaderCard
        value={`${dashboardData?.commissions ?? 0} €`}
        title={t('HEADER_CARD.TITLE_COMMISSIONS')}
        icon={<SavingsIcon sx={{ fill: yellow[700] }} />}
        iconColor={yellow[700]}
        isLoading={loading}
      />
      <HeaderCard
        value={dashboardData?.referrals ?? 0}
        title={t('HEADER_CARD.TITLE_REFERRALS')}
        icon={<GroupIcon sx={{ fill: green[300] }} />}
        iconColor={green[300]}
        isLoading={loading}
      />
      <HeaderCard
        value={dashboardData?.purchasedChallenges ?? 0}
        title={t('HEADER_CARD.TITLE_PURCHASED_CHALLENGES')}
        icon={<RadarIcon sx={{ fill: theme.palette.primary.main }} />}
        iconColor={theme.palette.primary.main}
        isLoading={loading}
      />
      <HeaderCard
        value={dashboardData?.averageReferralSpend ?? 0}
        title={t('HEADER_CARD.TITLE_AVERAGE_REFERRAL_SPEND')}
        icon={<LeaderboardIcon sx={{ fill: purple[700] }} />}
        iconColor={purple[700]}
        isLoading={loading}
      />
    </Stack>
  );
};

export default HeaderCards;
