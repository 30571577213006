import React, { useState } from 'react';

import ProfileMenu from './ProfileMenu/ProfileMenu';

import { SupportModal } from '../../../../features/support/support';

import {
  Card,
  Toolbar,
  IconButton,
  Tooltip,
  Box,
  useMediaQuery,
  useTheme,
  Stack,
} from '@mui/material';

import FullLogo from '../../../../assets/images/fullLogo.png';

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface HeaderProps {
  open: boolean;
  drawerWidth: number;
  handleDrawerOpen: () => void;
}

const Header: React.FC<HeaderProps> = ({
  open,
  drawerWidth,
  handleDrawerOpen,
}) => {
  const theme = useTheme();
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(['header']);

  // State pour la modal de support
  const [openSupportModal, setOpenSupportModal] = useState(false);

  const handleOpenSupportModal = () => {
    setOpenSupportModal(true);
  };

  const handleCloseSupportModal = () => {
    setOpenSupportModal(false);
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Card
        elevation={0}
        sx={{
          position: 'fixed',
          top: 0,
          left: open && !isMobile ? `${drawerWidth}px` : 0, // Si mobile, le Header reste à 0
          width: open && !isMobile ? `calc(100% - ${drawerWidth}px)` : '100%', // Si mobile, le Header prend 100% de la largeur
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: (theme) =>
            theme.transitions.create(['left', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
            transition: 'none',
          },
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* Logo et bouton Menu (à gauche) */}
          <Stack direction="row" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 2,
                ...(open && !isMobile && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box component={Link} to={'/'}>
              <Box
                component="img"
                sx={{
                  height: '50px',
                  width: 'auto',
                  ...(open && { display: 'none' }),
                  marginLeft: '16px',
                }}
                alt="RaiseMyFunds logo"
                src={FullLogo}
              />
            </Box>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Tooltip title={t('BUTTON.BUTTON_CONTACT_SUPPORT')}>
              <IconButton onClick={handleOpenSupportModal}>
                <SupportAgentIcon />
              </IconButton>
            </Tooltip>
            <ProfileMenu />
          </Stack>
        </Toolbar>
      </Card>

      {/* Affichage de la modal de support */}
      <SupportModal
        openSupportModal={openSupportModal}
        handleClose={handleCloseSupportModal}
      />
    </>
  );
};

export default Header;
