// AuthContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { loginUser } from '../../pages/user/login/services/post/login/login';
import { decodeJWT } from '../../pages/user/login/services/utils/decodedJWT';
import { fetchProfile } from '../../pages/user/profil/services/get/header/getProfile';
import getUserPermissions from '../../services/permissions/getPermissions'; // Import de la fonction pour récupérer les permissions
import { LoginResponse } from '../../pages/user/login/services/post/login/interfaces';
import { JWTPayload } from '../../pages/user/login/services/post/login/models';

interface AuthContextProps {
  tokens: LoginResponse | null;
  user: JWTPayload | null;
  profile: any | null;
  permissions: string[]; // Initialisé à un tableau vide
  isPermissionsLoading: boolean; // État de chargement des permissions
  login: (email: string, password: string, remember: boolean) => Promise<void>;
  isTokensLoading: boolean;
  logout: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [tokens, setTokens] = useState<LoginResponse | null>(null);
  const [user, setUser] = useState<JWTPayload | null>(null);
  const [profile, setProfile] = useState<any | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]); // Permissions initialisées à un tableau vide
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(true); // État de chargement des permissions
  const [isTokensLoading, setIsTokensLoading] = useState(true);

  useEffect(() => {
    const storedAccessToken =
      localStorage.getItem('accessToken') ||
      sessionStorage.getItem('accessToken');
    const storedRefreshToken =
      localStorage.getItem('refreshToken') ||
      sessionStorage.getItem('refreshToken');

    if (storedAccessToken && storedRefreshToken) {
      const decoded = decodeJWT(storedAccessToken);

      if (decoded) {
        setTokens({
          accessToken: storedAccessToken,
          refreshToken: storedRefreshToken,
        });
        setUser(decoded);

        // Récupérer le profil utilisateur
        fetchProfile(storedAccessToken, decoded.userId)
          .then((profileData) => setProfile(profileData))
          .catch((error) =>
            console.error('Failed to fetch sidebar profile', error),
          );

        // Récupérer les permissions utilisateur
        setIsPermissionsLoading(true); // Commencer le chargement des permissions
        getUserPermissions(storedAccessToken)
          .then((permissionsData) => {
            if (Array.isArray(permissionsData)) {
              setPermissions(permissionsData);
            } else {
              console.warn('Unexpected permissions format:', permissionsData);
              setPermissions([]);
            }
          })
          .catch((error) => {
            console.error('Failed to fetch user permissions', error);
            setPermissions([]); // Si erreur, mettre un tableau vide
          })
          .finally(() => {
            setIsPermissionsLoading(false); // Le chargement des permissions est terminé
          });
      }
    } else {
      setIsPermissionsLoading(false); // Aucun token trouvé, donc on arrête le chargement
    }

    setIsTokensLoading(false);
  }, []);

  const login = async (email: string, password: string, remember: boolean) => {
    try {
      const { tokens, user } = await loginUser(email, password, remember);
      setTokens(tokens);
      setUser(user);

      const profileData = await fetchProfile(tokens.accessToken, user.userId);
      setProfile(profileData);

      // Récupérer les permissions après le login
      setIsPermissionsLoading(true); // Commencer le chargement des permissions
      const permissionsData = await getUserPermissions(tokens.accessToken);
      if (Array.isArray(permissionsData)) {
        setPermissions(permissionsData);
      } else {
        console.warn('Unexpected permissions format:', permissionsData);
        setPermissions([]);
      }
      setIsPermissionsLoading(false); // Le chargement des permissions est terminé

      // Stocker les tokens dans localStorage ou sessionStorage
      if (remember) {
        localStorage.setItem('accessToken', tokens.accessToken);
        localStorage.setItem('refreshToken', tokens.refreshToken);
      } else {
        sessionStorage.setItem('accessToken', tokens.accessToken);
        sessionStorage.setItem('refreshToken', tokens.refreshToken);
      }
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    setTokens(null);
    setUser(null);
    setProfile(null);
    setPermissions([]); // Réinitialiser les permissions à un tableau vide
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        tokens,
        profile,
        permissions, // Permissions sous forme de tableau
        isPermissionsLoading, // Ajouter l'état de chargement des permissions
        login,
        isTokensLoading,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }
  return context;
};
