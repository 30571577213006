import axiosInstance from '../../../../../utils/axios';
import { IGetSummaryService } from './interfaces';
import { DayDetailsDTO } from './models';
import { handleError } from './errors';

class GetSummaryService implements IGetSummaryService {
  async getSummary(
    accountLogin: string,
    date: string,
    token: string,
  ): Promise<DayDetailsDTO> {
    try {
      const response = await axiosInstance.get<DayDetailsDTO>(
        `/v2/trading-account/account/${accountLogin}/pnl-calendar/day-details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          params: {
            date,
          },
        },
      );
      return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new GetSummaryService();
