import React, { useState } from 'react';
import { AccountBase } from '../../../../../features/area/services/get/challenges/models';
import { FundedSideCardDTO } from '../../../../../features/area/services/get/fundedChallenges/models';
import { green, yellow } from '../../../../../themes/colors/colors';
import { useTheme } from '@mui/material/styles'; // Import du hook pour accéder au thème MUI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import classes from '../card.module.scss';
import GetPayoutModal from '../../GetPayoutModal/GetPayoutModal';
import ScaleUpModal from '../../ScaleUpModal/ScaleUpModal';
import UpgradeButton from '../../UpgradeButton/UpgradeButton';
import CredentialsModal from '../../CredentialsModal/CredentialsModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { el } from 'date-fns/locale';

interface AccordionCardProps {
  onSelect: (accountLogin: string) => void;
  onResetDate: () => void; // Ajout de la prop onResetDate
  challenges: (AccountBase | FundedSideCardDTO)[];
  isFunded?: boolean;
  children?: React.ReactNode;
}

const AccordionCard: React.FC<AccordionCardProps> = ({
  onSelect,
  onResetDate, // Ajout de la prop onResetDate
  challenges,
  isFunded = false,
  children,
}) => {
  const { t } = useTranslation(['area', 'paymentStatusPage']);

  const [isVisible, setIsVisible] = useState<{ [key: string]: boolean }>({});
  const [isExpanded, setIsExpanded] = useState<{ [key: string]: boolean }>({});
  const [openCredentials, setOpenCredentials] = useState<boolean>(false);
  const [openPayoutModal, setOpenPayoutModal] = useState<boolean>(false);
  const [openScaleUpModal, setOpenScaleUpModal] = useState<boolean>(false);
  const [selectedAccountLogin, setSelectedAccountLogin] = useState<
    string | null
  >(null);

  const theme = useTheme(); // Hook pour accéder au thème MUI
  const navigate = useNavigate();

  const handleOpenCredentials = (login: string): void => {
    setSelectedAccountLogin(login);
    setOpenCredentials(true);
  };

  const handleCloseCredentials = (): void => {
    setOpenCredentials(false);
  };

  const handleOpenPayoutModal = (login: string): void => {
    setSelectedAccountLogin(login);
    setOpenPayoutModal(true);
  };

  const handleClosePayoutModal = (): void => {
    setOpenPayoutModal(false);
  };

  const handleOpenScaleUpModal = (login: string): void => {
    setSelectedAccountLogin(login);
    setOpenScaleUpModal(true);
  };

  const handleCloseScaleUpModal = (): void => {
    setOpenScaleUpModal(false);
  };

  const handleCardClick = (login: string): void => {
    setIsExpanded((prevState) => ({
      ...prevState,
      [login]: !prevState[login],
    }));
    onSelect(login); // Sélectionner le compte
    onResetDate(); // Réinitialiser la date sélectionnée
  };

  const handleGoToPaymentStatusPage = (externalPamentId: string) => {
    navigate(`/buy-challenge/payment-status/${externalPamentId}`);
  }

  const isAccountBase = (challenge: AccountBase | FundedSideCardDTO): challenge is AccountBase => {
    return (challenge as AccountBase).phase !== undefined;
  };

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    login: string,
  ): void => {
    event.stopPropagation();
    setIsVisible((prevState) => ({
      ...prevState,
      [login]: !prevState[login],
    }));

    if (!isVisible[login]) {
      setIsExpanded((prevState) => ({
        ...prevState,
        [login]: false,
      }));
    }
  };

  return (
    <>
      {selectedAccountLogin && (
        <CredentialsModal
          open={openCredentials}
          handleClose={handleCloseCredentials}
          selectedAccountLogin={selectedAccountLogin}
          isFunded={isFunded}
        />
      )}

      {challenges.map((challenge) => {
        const login = challenge.login;
        const balance = challenge.balance;

        let phase: string | undefined;
        let externalPaymentId: string = '';

        if(isAccountBase(challenge)){
          phase = challenge.phase;
          externalPaymentId = challenge.challengeUUID;
        }

        const isSelectCommission = (phase !== undefined && phase === 'SELECT_COMMISSION');
        const isAccountInCreation = (phase !== undefined && phase === 'CREATING');

        const label = isFunded
          ? (challenge as FundedSideCardDTO).status === 'ACTIVE'
            ? t('CHALLENGE_CARD.CHIP_STATUS.ACTIVE')
            : t('CHALLENGE_CARD.CHIP_STATUS.INACTIVE')
          : (challenge as AccountBase).phase === 'PHASE_1'
          ? t('CHALLENGE_CARD.CHIP_STATUS.PHASE_1')
          : (challenge as AccountBase).phase === 'FAILED'
          ? t('CHALLENGE_CARD.CHIP_STATUS.FAILED')
          : t('CHALLENGE_CARD.CHIP_STATUS.PHASE_2');

        const chipBackgroundColor =
          isFunded && (challenge as FundedSideCardDTO).status === 'ACTIVE'
            ? green[300]
            : (challenge as AccountBase).phase === 'PHASE_1'
            ? yellow[600]
            : (challenge as AccountBase).phase === 'PHASE_2'
            ? yellow[800]
            : (challenge as AccountBase).phase === 'SELECT_COMMISSION'
            ? yellow[50]
            : theme.palette.primary.main;

        return (
          <Stack key={login} sx={{ position: 'relative' }}>
            <Card
              className={classes.card}
              onClick={() => handleCardClick(login)} // Pas besoin de condition ici grâce à pointerEvents
              elevation={0}
              sx={{
                filter: isSelectCommission || isAccountInCreation ? 'blur(5px)' : isVisible[login] ? 'blur(5px)' : 'none',
                transition: 'filter 0.3s',
                pointerEvents: isSelectCommission || isAccountInCreation ? 'none' : isVisible[login] ? 'none' : 'auto',
                border: isExpanded[login]
                  ? '2px solid rgb(209, 72, 72)'
                  : '2px solid transparent',
              }}
            >
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignContent="flex-start"
                >
                  <Typography variant="h5" component="h3">
                    {t('CHALLENGE_CARD.TITLE_ACCOUNT')} {login}
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    {!isFunded && (challenge as AccountBase).canUpgrade && (
                      <UpgradeButton
                        accountUUID={(challenge as AccountBase).challengeUUID}
                        phase={(challenge as AccountBase).phase}
                      />
                    )}
                    {isFunded && (challenge as FundedSideCardDTO).canPayout && (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpenPayoutModal(login);
                        }}
                      >
                        {t('CHALLENGE_CARD.BUTTONS.GET_PAYOUT')}
                      </Button>
                    )}
                    {isFunded &&
                      (challenge as FundedSideCardDTO).canScaleUp && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleOpenScaleUpModal(login);
                          }}
                          sx={{
                            backgroundColor: green[300],
                            '&:hover': { backgroundColor: green[400] },
                          }}
                        >
                          {t('CHALLENGE_CARD.BUTTONS.SCALE_UP')}
                        </Button>
                      )}
                    <Chip
                      label={label}
                      sx={{
                        backgroundColor: chipBackgroundColor,
                        color: 'black',
                      }}
                    />
                  </Stack>
                </Stack>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  color="text.secondary"
                >
                  {t('CHALLENGE_CARD.TITLE_BALANCE')} ${balance}
                </Typography>
              </CardContent>
              <CardActions
                sx={{ justifyContent: 'space-between' }}
                onClick={(event) => event.stopPropagation()}
              >
                <Button
                  variant="text"
                  size="large"
                  startIcon={<LayersIcon />}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenCredentials(login);
                  }}
                >
                  {t('CHALLENGE_CARD.BUTTONS.CREDENTIALS')}
                </Button>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isVisible[login] || false}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => handleSwitchChange(event, login)}
                      />
                    }
                    label={t('CHALLENGE_CARD.BUTTONS.CHECKBOX_VISIBLE')}
                  />
                </FormGroup>
              </CardActions>
              <Collapse
                in={isExpanded[login] || false}
                timeout="auto"
                unmountOnExit
              >
                <Divider variant="middle" />
                <CardContent onClick={(event) => event.stopPropagation()}>
                  {children}
                </CardContent>
              </Collapse>
            </Card>
            {(isVisible[challenge.login] || isSelectCommission || isAccountInCreation) && (
              <Stack className={classes.hidden}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(event) => {
                    event.stopPropagation();
                    if(!(isSelectCommission || isAccountInCreation)){
                      setIsVisible((prevState) => ({
                        ...prevState,
                        [challenge.login]: false,
                      }));
                    } else if (isSelectCommission) {
                      handleGoToPaymentStatusPage(externalPaymentId);
                    }
                  }}
                >
                  {isAccountInCreation
                  ? t('paymentStatusPage:CARD.ACCOUNT_CREATION_PENDING')
                  : isSelectCommission
                  ? t('paymentStatusPage:CARD.CHOOSE_ACCOUNT_TYPE_BUTTON')
                  : t('CHALLENGE_CARD.BUTTONS.SHOW_ACCOUNT')}
                </Button>
              </Stack>
            )}
          </Stack>
        );
      })}

      {isFunded && selectedAccountLogin && (
        <GetPayoutModal
          open={openPayoutModal}
          onClose={handleClosePayoutModal}
          accountUUID={Number(selectedAccountLogin)}
          accessToken={'example-token'}
        />
      )}

      {isFunded && selectedAccountLogin && (
        <ScaleUpModal
          open={openScaleUpModal}
          onClose={handleCloseScaleUpModal}
          accountUUID={selectedAccountLogin}
        />
      )}
    </>
  );
};

export default AccordionCard;
