import React from 'react';
import {
  Card,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import classes from './tradingHistory.module.scss';
import { TradeDTO } from '../../services/get/tradeHistory/models';
import { useTranslation } from 'react-i18next';

interface TradingHistoryProps {
  elevation: number;
  selectedAccountLogin: string | null;
  selectedDate: string | null;
  tradeHistory: TradeDTO[];
  totalPages: number;
  onPageChange: (page: number) => void;
  currentPage: number;
}

const TradingHistory: React.FC<TradingHistoryProps> = ({
  elevation,
  tradeHistory,
  selectedDate,
  totalPages,
  currentPage,
  onPageChange,
}) => {
  const { t, i18n } = useTranslation('area');

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    onPageChange(page);
  };

  return (
    <Card elevation={elevation} className={classes.tradingHistory}>
      <Typography variant="h4" component="h4">
        {selectedDate
          ? t('TRADING_HISTORY.TITLE_TRADING_HISTORY_FOR', {
              date: new Date(selectedDate).toLocaleDateString(i18n.language, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }),
            })
          : t('TRADING_HISTORY.TITLE_TRADING_HISTORY_CURRENT_MONTH')}
      </Typography>
      <TableContainer
        component={Paper}
        elevation={elevation}
        sx={{ marginTop: 2, marginBottom: 1.5 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('TRADING_HISTORY.TABLE_HEAD_SYMBOL')}</TableCell>
              <TableCell align="right">
                {t('TRADING_HISTORY.TABLE_HEAD_TYPE')}
              </TableCell>
              <TableCell align="right">
                {t('TRADING_HISTORY.TABLE_HEAD_OPEN_DATE')}
              </TableCell>
              <TableCell align="right">
                {t('TRADING_HISTORY.TABLE_HEAD_OPEN')}
              </TableCell>
              <TableCell align="right">
                {t('TRADING_HISTORY.TABLE_HEAD_CLOSED_DATE')}
              </TableCell>
              <TableCell align="right">
                {t('TRADING_HISTORY.TABLE_HEAD_CLOSE')}
              </TableCell>
              <TableCell align="right">
                {t('TRADING_HISTORY.TABLE_HEAD_PROFIT')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tradeHistory.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {selectedDate
                    ? t('TRADING_HISTORY.ERROR_NO_DATA_AVAILABLE_FOR_THIS_DATE')
                    : t(
                        'TRADING_HISTORY.ERROR_NO_DATA_AVAILABLE_FOR_THIS_MONTH',
                      )}
                </TableCell>
              </TableRow>
            ) : (
              tradeHistory.map((trade, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {trade.symbol}
                  </TableCell>
                  <TableCell align="right">{trade.type}</TableCell>
                  <TableCell align="right">
                    {new Date(trade.openDate).toLocaleString()}
                  </TableCell>
                  <TableCell align="right">{trade.open}</TableCell>
                  <TableCell align="right">
                    {new Date(trade.closedDate).toLocaleString()}
                  </TableCell>
                  <TableCell align="right">{trade.close}</TableCell>
                  <TableCell align="right">{trade.profit}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      )}
    </Card>
  );
};

export default TradingHistory;
