// import { useTheme } from '../../context/ThemeContext/ThemeContext';
import { useTheme } from '@mui/material/styles';

import Card from '../Cards/Card/Card';
import Icon from '../userInterface/Icon/Icon';

import classes from './_modal.module.scss';

interface OldModalProps {
  title: string;
  customContent?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showCloseButton?: boolean;
  fullOpacity?: boolean;
}

const OldModal: React.FC<OldModalProps> = ({
  title,
  isOpen,
  onClose,
  children,
  showCloseButton = true,
  fullOpacity = false,
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <div
      className={`${classes.container} ${isOpen ? classes.active : ''} ${
        fullOpacity ? classes.fullOpacity : ''
      }`}
    >
      <Card
        className={`${classes.card} ${
          isDarkMode ? classes.dark : classes.light
        }`}
      >
        <header className={classes.header}>
          <h1>{title}</h1>
          {showCloseButton && ( // Afficher le bouton "close" si showCloseButton est vrai
            <div onClick={onClose} className={classes.closeButton}>
              <Icon
                color="white"
                size={24}
                path="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
              />
            </div>
          )}
        </header>
        <main className={classes.main}>{children}</main>
      </Card>
    </div>
  );
};

export default OldModal;
