export class PostDiscordActivityError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PostDiscordActivityError';
  }
}

export const handlePostDiscordActivityError = (error: any): void => {
  if (error.response && error.response.data && error.response.data.message) {
    throw new PostDiscordActivityError(error.response.data.message);
  } else {
    throw new PostDiscordActivityError(
      'An unexpected error occurred while posting Discord activity.',
    );
  }
};
