import axiosInstance from '../../../../../utils/axios';
import { IPutModifyPasswordService } from './interfaces';
import { ModifyPasswordDTO } from './models';
import { handleErrorModifyPassword } from './errors';

class PutModifyPasswordService implements IPutModifyPasswordService {
  async putModifyPassword(
    accountLogin: string,
    password: string,
    token: string,
    successMessage?: string, // Note: Utilisation du successMessage n'est pas nécessaire ici, c'est juste pour rappel de sa disponibilité
    errorMessage: string = 'Failed to modify password', // Ajout d'un message d'erreur par défaut
  ): Promise<ModifyPasswordDTO> {
    try {
      const response = await axiosInstance.put<ModifyPasswordDTO>(
        `/mt5accounts/modify-password`,
        {
          login: accountLogin,
          password: password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      handleErrorModifyPassword(error, errorMessage); // Ajout du message d'erreur
      throw error; // Laisse la gestion des erreurs se faire côté UI
    }
  }
}

export default new PutModifyPasswordService();
