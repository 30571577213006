import axiosInstance from './axios';

export const isCountryAuthorized = async () => {
  try {
    const response = await axiosInstance.get(
      `https://live.raisemyfunds.co/api/localisation/is-authorized`,
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des withdraw requests');
  }
};
