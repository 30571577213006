import { createContext, useContext } from 'react';
import { FundedAccountBase } from '../../services/get/fundedAccountBase/models'; // Import the models

interface FundedAccountBaseContextProps {
  fundedAccountBaseData: FundedAccountBase | null;
  loading: boolean;
  error: string | null;
  fetchFundedAccountBaseData: (accountLogin: string) => Promise<void>;
}

export const FundedAccountBaseDataContext = createContext<
  FundedAccountBaseContextProps | undefined
>(undefined);

export const useFundedAccountBaseData = (): FundedAccountBaseContextProps => {
  const context = useContext(FundedAccountBaseDataContext);
  if (!context) {
    throw new Error(
      'useFundedAccountBaseData must be used within a FundedAccountBaseProvider',
    );
  }
  return context;
};
