import React from 'react';
import { Card, Box, Skeleton, Button } from '@mui/material';
import classes from './summary.module.scss';

const SummarySkeleton = () => {
  return (
    <Card elevation={0} className={classes.summary} sx={{ width: '30%' }}>
      <Skeleton variant="rectangular" width={250} height={30} sx={{ mb: 4 }} />

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }} mb={4}>
        <Card
          elevation={0}
          sx={{
            flex: '1 1 calc(50% - 8px)',
            boxSizing: 'border-box',
            p: 0,
          }}
        >
          <Skeleton variant="rectangular" height={100} />
        </Card>

        <Card
          elevation={0}
          sx={{
            flex: '1 1 calc(50% - 8px)',
            boxSizing: 'border-box',
            p: 0,
          }}
        >
          <Skeleton variant="rectangular" height={100} />
        </Card>

        <Card
          elevation={0}
          sx={{
            flex: '1 1 calc(50% - 8px)',
            boxSizing: 'border-box',
            p: 0,
          }}
        >
          <Skeleton variant="rectangular" height={100} />
        </Card>

        <Card
          elevation={0}
          sx={{
            flex: '1 1 calc(50% - 8px)',
            boxSizing: 'border-box',
            p: 0,
          }}
        >
          <Skeleton variant="rectangular" height={100} />
        </Card>
      </Box>
      <Skeleton width={300} height={80} />
    </Card>
  );
};

export default SummarySkeleton;
