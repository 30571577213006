import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';

export const useLogin = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const { login, user } = useAuth();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  useEffect(() => {
    if (user) {
      if (user.role === 'ROLE_ADMIN') {
        navigate('/admin/dashboard');
      } else {
        navigate('/');
      }
    }
  }, [user, navigate]);

  const handleLogin = async () => {
    setEmailError(null);
    setPasswordError(null);

    if (!email) {
      setEmailError(t('ERROR_EMAIL_ADDRESS_IS_REQUIRED'));
    }
    if (!password) {
      setPasswordError(t('ERROR_PASSWORD_IS_REQUIRED'));
    }

    if (!email || !password) {
      return;
    }

    setIsSubmitting(true);

    try {
      await login(email, password, rememberMe);
    } catch (error: any) {
      if (error.message === 'Invalid Credentials') {
        setEmailError(t('ERROR_INVALID_CREDENTIALS'));
        setPasswordError(t('ERROR_INVALID_CREDENTIALS'));
      } else if (
        error.message === 'Too many login attempts. Please try again later.'
      ) {
        setSnackbarMessage(
          t('ERROR_TOO_MANY_LOGIN_ATTEMPTS_PLEASE_TRY_AGAIN_LATER'),
        );
      } else {
        setSnackbarMessage(t('ERROR_AN_ERROR_OCCURED_PLEASE_TRY_AGAIN'));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    rememberMe,
    setRememberMe,
    emailError,
    passwordError,
    isSubmitting,
    snackbarMessage,
    handleLogin,
  };
};
