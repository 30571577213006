import React from 'react';
import { Card, Grid, Skeleton, Stack, useTheme } from '@mui/material';
import classes from './pieChart.module.scss';

interface PieChartSkeletonProps {
  checkboxs: number;
  inCard?: boolean;
}

const PieChartSkeleton: React.FC<PieChartSkeletonProps> = ({
  checkboxs = 0,
  inCard = false,
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <>
      {inCard ? (
        <Card
          elevation={0}
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              backgroundColor: isDarkMode ? '#292938' : '#ece9e9',
              padding: '8px',
              borderRadius: '8px',
              margin: '16px',
            }}
          >
            <Skeleton variant="rounded" height={30} width={250} />
          </Stack>
          <Stack
            sx={{
              margin: '30px 80px',
            }}
          >
            <Grid container alignItems="center" justifyContent="center" mt={4}>
              <Grid item>
                <Skeleton variant="circular" height={300} width={300} />
              </Grid>
              <Grid item>
                <Stack
                  direction={'column'}
                  className={classes.checkboxContainer}
                  flexWrap={'wrap'}
                  ml={2}
                  mt={2}
                  gap={2}
                >
                  {Array.from({ length: checkboxs }).map((_, index) => (
                    <Stack
                      direction={'row'}
                      gap={1}
                      alignItems={'center'}
                      key={index}
                    >
                      <Skeleton variant="rounded" width={25} height={25} />
                      <Skeleton variant="rectangular" height={20} width={120} />
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Card>
      ) : (
        <Grid container alignItems="center" justifyContent="center" mt={4}>
          <Grid item>
            <Skeleton variant="circular" height={300} width={300} />
          </Grid>
          <Grid item>
            <Stack
              direction={'column'}
              className={classes.checkboxContainer}
              flexWrap={'wrap'}
              ml={2}
              mt={2}
              gap={2}
            >
              {Array.from({ length: checkboxs }).map((_, index) => (
                <Stack
                  direction={'row'}
                  gap={1}
                  alignItems={'center'}
                  key={index}
                >
                  <Skeleton variant="rounded" width={25} height={25} />
                  <Skeleton variant="rectangular" height={20} width={120} />
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PieChartSkeleton;
