import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext/AuthContext';

interface PrivateRouteProps {
  roles?: string[];
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ roles }) => {
  const { user, isTokensLoading } = useAuth();

  if (isTokensLoading) {
    return <div>Loading...</div>; // Affiche un écran de chargement pendant la vérification des tokens
  }

  if (!user) {
    // Si l'utilisateur n'est pas connecté, on le redirige vers /login
    return <Navigate to="/login" />;
  }

  // Si l'utilisateur est connecté mais que son rôle ne correspond pas
  if (roles && !roles.includes(user.role)) {
    return <Navigate to="/unauthorized" />;
  }

  // Si tout est OK, on affiche le contenu de la route privée
  return <Outlet />;
};
