import { Card, Skeleton, Stack } from '@mui/material';
import React from 'react';

const ChallengeSkeleton = () => {
  return (
    <Stack gap={2}>
      <Card
        elevation={0}
        sx={{
          width: '100%',
          height: '150px',
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Card>
      <Card
        elevation={0}
        sx={{
          width: '100%',
          height: '150px',
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Card>
      <Card
        elevation={0}
        sx={{
          width: '100%',
          height: '150px',
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Card>
      <Card
        elevation={0}
        sx={{
          width: '100%',
          height: '150px',
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Card>
      <Card
        elevation={0}
        sx={{
          width: '100%',
          height: '150px',
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Card>
    </Stack>
  );
};

export default ChallengeSkeleton;
