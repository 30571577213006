import React, { useState, useEffect, ReactNode } from 'react';
import { AccountBaseDataContext } from '../../context/AccountBaseDataContext/AccountBaseDataContext';
import { ChallengeAccountBase } from '../../services/get/accountBase/models';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import getAccountBase from '../../services/get/accountBase/getAccountBase';

interface AccountBaseDataProviderProps {
  children: ReactNode;
  selectedAccountLogin: string | null;
}

export const AccountBaseDataProvider: React.FC<
  AccountBaseDataProviderProps
> = ({ children, selectedAccountLogin }) => {
  const [accountBaseData, setAccountBaseData] =
    useState<ChallengeAccountBase | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { tokens } = useAuth();

  const fetchAccountBaseData = async (login: string | null) => {
    if (login && tokens) {
      setLoading(true);
      try {
        const data = await getAccountBase(login, tokens.accessToken);
        setAccountBaseData(data);
        setError(null);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unexpected error occurred');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchAccountBaseData(selectedAccountLogin);
  }, [selectedAccountLogin, tokens]);

  return (
    <AccountBaseDataContext.Provider
      value={{
        accountBaseData,
        loading,
        error,
        fetchAccountBaseData,
      }}
    >
      {children}
    </AccountBaseDataContext.Provider>
  );
};
