import { useState } from 'react';
import {
  UpdatePermissionsRequest,
  UpdatePermissionsResponse,
} from '../services/interfaces';
import { updateUserPermissions } from '../services/putPermissions';
import { useAuth } from '../../../../context/AuthContext/AuthContext'; // Import du contexte Auth
import { toast } from 'react-toastify'; // Importer toastify pour afficher les notifications
import 'react-toastify/dist/ReactToastify.css'; // Importer le style toastify

export const useUpdatePermissions = () => {
  const { tokens } = useAuth(); // Récupération du token via useAuth
  const [loading, setLoading] = useState<boolean>(false);

  const updatePermissions = async (
    userUUID: string,
    permissions: UpdatePermissionsRequest,
  ) => {
    if (!tokens || !tokens.accessToken) {
      toast.error('No token available', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);

    try {
      // Utiliser la fonction de mise à jour des permissions
      const response = await updateUserPermissions(
        userUUID,
        permissions,
        tokens.accessToken,
      );

      // Si la mise à jour est réussie
      if (response) {
        toast.success('Permissions successfully updated', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        // Si la mise à jour échoue sans lever d'exception
        toast.error('An error occurred while updating permissions.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (err) {
      // En cas d'erreur
      toast.error('An error occurred while updating permissions', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    updatePermissions,
    loading,
  };
};
