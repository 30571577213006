import React from 'react';

import { useProfile } from '../../hooks/useProfile';

import ProfileForm from '../../components/ProfileForm/ProfileForm';

import { Stack, Button, Snackbar, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProfileFormSkeleton from './ProfileContentSkeleton';

const ProfileContent: React.FC = () => {
  const { t } = useTranslation('profile');
  const { profile, updatedProfile, isModified, handleInputChange, handleSave } =
    useProfile();

  return (
    <Stack>
      <Typography
        variant="h4"
        component={'h2'}
        gutterBottom
        fontWeight={'bold'}
        color={'#697399'}
      >
        {t('PROFILE.TITLE_IDENTITY')}
      </Typography>
      <Divider sx={{ backgroundColor: '#697399', marginBottom: '64px' }} />

      {!profile ? (
        <ProfileFormSkeleton />
      ) : (
        <ProfileForm
          updatedProfile={updatedProfile}
          handleInputChange={handleInputChange}
        />
      )}

      <Snackbar
        open={isModified}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t('PROFILE.BUTTON_SAVE')}
          </Button>
        }
        message={t('PROFILE.TEXT_SAVE_YOUR_CHANGES')}
      />
    </Stack>
  );
};

export default ProfileContent;
