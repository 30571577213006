import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import PieChart from '../PieChart/PieChart';
import { useFundedData } from '../../hooks/pieChart/useFundedData';
import PieChartLightSkeleton from '../PieChart/PieChartSkeleton';
import { useTranslation } from 'react-i18next';

const FundedTab = () => {
  const { t } = useTranslation('dashboard');

  const { loading, error, data } = useFundedData(true);

  if (loading) {
    return <PieChartLightSkeleton checkboxs={3} />;
  } else if (!data) {
    return null;
  }

  if (error) {
    return <Typography>Error loading funded data: {error.message}</Typography>;
  }

  const labels = [
    t('PIE_CHART.LABELS.FUNDED.ACTIVE'),
    t('PIE_CHART.LABELS.FUNDED.WITH_PAYOUT'),
    t('PIE_CHART.LABELS.FUNDED.WITHOUT_PAYOUT'),
  ];
  const chartData = [
    data.totalActiveFunded,
    data.totalFundedWithPayout,
    data.totalFundedWithoutPayout,
  ];
  const backgroundColors = [
    'rgba(31, 204, 146, 1)',
    'rgba(249, 168, 37, 1)',
    'rgba(231, 71, 80, 1)',
  ];

  return (
    <PieChart
      labels={labels}
      data={chartData}
      backgroundColors={backgroundColors}
    />
  );
};

export default FundedTab;
