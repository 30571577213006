import React, { useState, useEffect } from 'react';
import ZoomableChart from '../../../../components/ZoomableChart/ZoomableChart';
import { Button, ButtonGroup, Stack } from '@mui/material';
import { useFailedChallenges } from '../../hooks/chart/useFailedChallenges';
import { usePassedChallenges } from '../../hooks/chart/usePassedChallenges';
import { useCashEntry } from '../../hooks/chart/useCashEntry';
import { useSales } from '../../hooks/chart/useSales';
import { DateAmountDTO } from '../../services/get/chart/cashEntry/models';
import { DateCountDTO } from '../../services/get/chart/failedChallenges/models';
import DataChartTabSkeleton from './DataChartTabSkeleton';
import { useTranslation } from 'react-i18next';

interface DataChartTabProps {
  dataType: 'failed' | 'passed' | 'cashEntry' | 'sales'; // Détermine le type de données
  isActive: boolean; // Indique si l'onglet est actif
  chartLabel: string; // Détermine le label et le titre de l'axe Y
}

// Helper function to check if the item is of type DateAmountDTO
const isDateAmountDTO = (
  item: DateCountDTO | DateAmountDTO,
): item is DateAmountDTO => {
  return (item as DateAmountDTO).amount !== undefined;
};

const DataChartTab: React.FC<DataChartTabProps> = ({
  dataType,
  isActive,
  chartLabel,
}) => {
  const { t } = useTranslation('dashboard');

  const [timeframe, setTimeframe] = useState<string>('TODAY');
  const [shouldFetch, setShouldFetch] = useState<boolean>(isActive);

  useEffect(() => {
    if (isActive) {
      setShouldFetch(true);
    }
  }, [isActive]);

  const { data: failedData, loading: failedLoading } = useFailedChallenges(
    timeframe,
    shouldFetch && dataType === 'failed',
  );
  const { data: passedData, loading: passedLoading } = usePassedChallenges(
    timeframe,
    shouldFetch && dataType === 'passed',
  );
  const { data: cashEntryData, loading: cashLoading } = useCashEntry(
    timeframe,
    shouldFetch && dataType === 'cashEntry',
  );
  const { data: salesData, loading: salesLoading } = useSales(
    timeframe,
    shouldFetch && dataType === 'sales',
  );

  let data: (DateCountDTO | DateAmountDTO)[] | null = null;
  if (dataType === 'failed') {
    data = failedData;
  } else if (dataType === 'passed') {
    data = passedData;
  } else if (dataType === 'cashEntry') {
    data = cashEntryData;
  } else if (dataType === 'sales') {
    data = salesData;
  }

  const labels = data?.map((item) => item.date) || [];
  const chartData =
    data?.map((item) => (isDateAmountDTO(item) ? item.amount : item.count)) ||
    [];

  if (passedLoading || failedLoading || cashLoading || salesLoading) {
    return <DataChartTabSkeleton />;
  }

  return (
    <Stack direction={'column'}>
      <ZoomableChart
        style={{ maxHeight: '550px' }}
        labels={labels}
        datasets={[
          {
            label: chartLabel,
            data: chartData,
            fill: 'start',
            backgroundColor: 'rgba(231, 71, 80, 0.1)',
            borderColor: 'rgb(231, 71, 80)',
          },
        ]}
        xAxisTitle={t('CHART.X_AXIS_TITLE')}
        yAxisTitle={chartLabel}
      />

      <ButtonGroup
        variant="contained"
        sx={{ width: 'fit-content', mt: 2 }}
        aria-label="timeframes-buttons"
      >
        <Button
          onClick={() => setTimeframe('TODAY')}
          disabled={timeframe === 'TODAY'}
        >
          {t('CHART.BUTTONS.DAY')}
        </Button>
        <Button
          onClick={() => setTimeframe('WEEK')}
          disabled={timeframe === 'WEEK'}
        >
          {t('CHART.BUTTONS.WEEK')}
        </Button>
        <Button
          onClick={() => setTimeframe('MONTH')}
          disabled={timeframe === 'MONTH'}
        >
          {t('CHART.BUTTONS.MONTH')}
        </Button>
        <Button
          onClick={() => setTimeframe('YEAR')}
          disabled={timeframe === 'YEAR'}
        >
          {t('CHART.BUTTONS.YEAR')}
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default DataChartTab;
