import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';
import { Response, JWTPayload, ContractResponse } from '../client/interfaces';
import { useTranslation } from 'react-i18next';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}

export const fetchClientAreaData = async (
  accessToken: string,
): Promise<Response> => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.get<Response>(
      `/challenges/client-area/${decoded?.userId}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTraderAreaData = async (
  accessToken: string,
): Promise<Response> => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.get<Response>(
      `/funded/funded-area/${decoded?.userId}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const upgradeAccount = async (accountUUID: string) => {
  try {
    const response = await axiosInstance.post('/challenges/upgrade', {
      accountUUID,
    });
    return response;
  } catch (error: any) {
    toast.success('Account not upgraded!', {
      progressStyle: { backgroundColor: 'red' },
    });
    throw new Error(
      error.response?.data?.error ||
        'An error occurred during the account upgrade.',
    );
  }
};

export const scaleUpFundedAccount = async (accountUUID: string) => {
  try {
    const response = await axiosInstance.post('/funded/scaleup', {
      accountUUID,
    });
    if (response.status === 200) {
      toast.success('Scale up successfully.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
    return response;
  } catch (error: any) {
    toast.error('Account not scale up', {
      progressStyle: { backgroundColor: 'red' },
    });
    throw new Error(
      error.response?.data?.error ||
        'An error occurred during the account scale up.',
    );
  }
};

export const submitWithdrawalRequest = async (
  accessToken: string,
  amount: number,
  withdrawalType: string,
  cryptoWalletAddress: string | null,
  network: string | null,
) => {
  const decoded = decodeJWT(accessToken);
  if (!decoded || !decoded.userId) {
    throw new Error('Invalid access token - userId not found');
  }

  type Data = {
    userId: string;
    amount: number;
    withdrawalType: string;
    cryptoWalletAddress?: string;
    network?: string;
  };

  const data: Data = {
    userId: decoded.userId,
    amount: amount,
    withdrawalType: withdrawalType,
  };

  if (withdrawalType === 'CRYPTO' && cryptoWalletAddress && network) {
    data.cryptoWalletAddress = cryptoWalletAddress;
    data.network = network;
  }

  try {
    const response = await axiosInstance.post('/withdraw/request', data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      toast.success('Votre demande a été effectuée.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
    return response.data;
  } catch (error: any) {
    if (error.response.status === 409) {
      toast.error(
        'Another user has already used this wallet to make a withdrawal.',
        {
          progressStyle: { backgroundColor: 'red' },
        },
      );
    } else {
      toast.error('Votre demande a échouée.', {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  }
};

export const getPayout = async (
  accountUUID: number,
  amount: number,
  accessToken: any,
) => {
  try {
    const response = await axiosInstance.post('/payout/request', {
      login: accountUUID,
      amountRequested: amount,
    });
    if (response.status === 200) {
      toast.success('Votre demande a été effectuée.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error: any) {
    if (error.response.status === 403) {
      toast.error("You're account is not eligible for payout.", {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 409) {
      toast.error(error.response.data, {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 405) {
      toast.error('KYC must be validated to payout', {
        progressStyle: { backgroundColor: 'red' },
      });
    } else {
      toast.error('Payout failed', {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  }
};

export const defineWallet = async (
  address: string,
  network: string,
  accessToken: string,
) => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.put('/affiliations/define-wallet', {
      address: address,
      network: network,
      userUuid: decoded?.userId,
    });

    if (response.status === 200) {
      toast.success('Your information has been correctly set.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error: any) {
    toast.error('Your information could not be set.', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const modifyPassword = async (
  login: number,
  password: string,
  accessToken: string,
) => {
  try {
    const response = await axiosInstance.put(
      '/mt5accounts/modify-password',
      {
        login: login,
        password: password,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (response.status === 200) {
      toast.success('Your password has been updated', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error: any) {
    toast.error('An error has occurred while password updating', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const fetchContractInformations = async (
  accessToken: string,
): Promise<ContractResponse> => {
  try {
    const response = await axiosInstance.get<ContractResponse>(
      `/funded/contract-informations`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.status === 400) {
      toast.error('You need to have a validated KYC.', {
        progressStyle: { backgroundColor: 'red' },
      });
    }
    throw error;
  }
};

export const submitContractInformations = async (
  accessToken: string,
  accountUUID: string,
  file: File,
) => {
  const formData = new FormData();
  formData.append('attachment', file);
  formData.append('accountUUID', accountUUID);

  try {
    const response = await axiosInstance.post(
      '/funded/submit-contract',
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    if (response.status === 400) {
      toast.error('Account is not eligible for upgrade.', {
        progressStyle: { backgroundColor: 'red' },
      });
    }

    if (response.status === 200) {
      toast.success('Contract successfully signed and send', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    throw error;
  }
};
