import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  AffiliatePayoutResponseContent,
  PurchasedChallengesResponseContent,
  TradingPayoutResponseContent,
  WithdrawHistoryResponseContent,
} from '../../../services/admin/reports/interface';
import {
  downloadCertificate,
  exportChallengesPurchasedToXLS,
  exportTradingPayoutToXLS,
  exportWithdrawHistoryToXLS,
  fetchAffiliatePayout,
  fetchPurchasedChallenges,
  fetchTradingPayout,
  fetchWithdrawHistory,
} from '../../../services/admin/reports/reports';
import { logout } from '../../../services/auth/login';

import { useAuth } from '../../../context/AuthContext/AuthContext';

import useDocumentTitle from '../../../hooks/useDocumentTitle';

import Tab from '../../../components/Tab/Tab';
import Loader from '../../../components/Loader/Loader';
import AdminTable from '../../../components/Admin/AdminTable/AdminTable';
import Pagination from '../../../components/Pagination/Pagination';
import Icon from '../../../components/userInterface/Icon/Icon';
import Filter, { FilterType } from '../../../components/Filter/Filter';

import classes from './_reports.module.scss';

const Reports = () => {
  const { tokens, isTokensLoading } = useAuth();
  useDocumentTitle('Reports');

  const size = 10;

  const PurchasedChallengesfilterTypes: FilterType[] = [
    { key: 'id', type: 'string' },
    { key: 'pricePaid', type: 'string' },
    { key: 'amountToReferrer', type: 'string' },
    { key: 'startDate', type: 'date' },
    { key: 'endDate', type: 'date' },
    { key: 'challengeType', type: 'string' },
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'referrerLastname', type: 'string' },
    { key: 'referrerFirstname', type: 'string' },
    { key: 'email', type: 'string' },
    { key: 'uuid', type: 'string' },
  ];

  const WithdrawfilterTypes: FilterType[] = [
    { key: 'id', type: 'string' },
    { key: 'amount', type: 'string' },
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'startDate', type: 'date' },
    { key: 'endDate', type: 'date' },
    { key: 'email', type: 'string' },
    { key: 'cryptoAddress', type: 'string' },
    { key: 'uuid', type: 'string' },
  ];

  const tradingPayoutFilterTypes: FilterType[] = [
    { key: 'transactionId', type: 'string' },
    { key: 'mt5Account', type: 'string' },
    { key: 'amountClient', type: 'string' },
    { key: 'amountPropfirm', type: 'string' },
    { key: 'creationDate', type: 'date' },
    { key: 'payoutStatus', type: 'string' },
    { key: 'firstname', type: 'string' },
    { key: 'lastname', type: 'string' },
    { key: 'email', type: 'string' },
    { key: 'adminId', type: 'string' },
  ];

  const tablePurchasedChallengesData = [];
  const tablePurchasedChallengesColumns = [
    'ID',
    'Price Paid',
    'Amount to Referrer',
    'Payment Date',
    'Challenge Type',
    'Firstname',
    'Lastname',
    'Email',
    'UUID',
  ];

  const tableTradingPayoutData = [];
  const tableTradingPayoutColumns = [
    'ID',
    'MT5 Account',
    'Amount Client',
    'Amount Propfirm',
    'Creation Date',
    'Payout Status',
    'Firstname',
    'Lastname',
    'Email',
    'User ID',
  ];

  const tableAffiliationPayoutData = [];
  const tableAffiliationPayoutColums = [
    'UUID',
    'Admin ID',
    'Request ID',
    'Email',
    'Firstname',
    'Lastname',
    'Affiliate DisplayName',
    'Affiliation Percentage',
    'Affiliation Type',
    'Month',
    'KYC Validated',
    'Crypto Wallet Address',
    'Crypto Network',
    'Total Commission',
  ];

  const tableReferralPayoutData = [];
  const tableReferralPayoutColums = [
    'UUID',
    'Admin ID',
    'Request ID',
    'Email',
    'Firstname',
    'Lastname',
    'Affiliate DisplayName',
    'Affiliation Percentage',
    'Affiliation Type',
    'Month',
    'KYC Validated',
    'Total Commission',
  ];

  const tableWithdrawHistoryData = [];
  const tableWithdrawHistoryColumns = [
    'Withdraw Label',
    'External ID',
    'Amount',
    'Withdraw Type',
    'Firstname',
    'Lastname',
    'Request Date',
    'Status',
    'Email',
    'User ID',
  ];

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [purchasedChallenges, setPurchasedChallenges] = useState<
    PurchasedChallengesResponseContent[]
  >([]);
  const [purchasedChallengesPage, setPurchasedChallengesPage] =
    useState<number>(0);
  const [totalPurchasedChallengesPages, setTotalPurchasedChallengesPages] =
    useState<number>(0);
  const [purchasedChallengesFilters, setPurchasedChallengesFilters] = useState<{
    [key: string]: string;
  }>({});
  const [
    purchasedChallengesTotalOfElements,
    setPurchasedChallengesTotalOfElements,
  ] = useState<number>(0);

  const [tradingPayout, setTradingPayout] = useState<
    TradingPayoutResponseContent[]
  >([]);
  const [tradingPayoutPage, setTradingPayoutPage] = useState<number>(0);
  const [totalTradingPayoutPages, setTotalTradingPayoutPages] =
    useState<number>(0);
  const [tradingPayoutfilters, setTradingPayoutFilters] = useState<{
    [key: string]: string;
  }>({});
  const [tradingPayoutTotalOfElements, setTradingPayoutTotalOfElements] =
    useState<number>(0);

  const [affiliationPayout, setAffiliationPayout] = useState<
    AffiliatePayoutResponseContent[]
  >([]);
  const [affiliationPayoutPage, setAffiliationPayoutPage] = useState<number>(0);
  const [totalAffiliationPayoutPages, setTotalAffiliationPayoutPages] =
    useState<number>(0);
  const [
    affiliationPayoutTotalOfElements,
    setAffiliationPayoutTotalOfElements,
  ] = useState<number>(0);

  const [referralPayout, setReferralPayout] = useState<
    AffiliatePayoutResponseContent[]
  >([]);
  const [referralPayoutPage, setReferralPayoutPage] = useState<number>(0);
  const [totalReferralPayoutPages, setTotalReferralPayoutPages] =
    useState<number>(0);
  const [referralPayoutTotalOfElements, setReferralPayoutTotalOfElements] =
    useState<number>(0);

  const [withdrawHistory, setWithdrawHistory] = useState<
    WithdrawHistoryResponseContent[]
  >([]);
  const [withdrawHistoryPage, setWithdrawHistoryPage] = useState<number>(0);
  const [totalWithdrawHistoryPages, setTotalWithdrawHistoryPages] =
    useState<number>(0);
  const [withdrawFilters, setWithdrawFilters] = useState<{
    [key: string]: string;
  }>({});
  const [withdrawHistoryTotalOfElements, setWithdrawHistoryTotalOfElements] =
    useState<number>(0);

  const tabNames = [
    <div className={classes.tabNamesContainer} key="challengesPurchased">
      Challenges Purchased{' '}
      <span className={classes.chip}>{purchasedChallengesTotalOfElements}</span>
    </div>,
    <div className={classes.tabNamesContainer} key="tradingPayout">
      Trading Payout{' '}
      <span className={classes.chip}>{tradingPayoutTotalOfElements}</span>
    </div>,
    <div className={classes.tabNamesContainer} key="partnerPayout">
      Partner Payout{' '}
      <span className={classes.chip}>{affiliationPayoutTotalOfElements}</span>
    </div>,
    <div className={classes.tabNamesContainer} key="referralPayout">
      Referral Payout{' '}
      <span className={classes.chip}>{referralPayoutTotalOfElements}</span>
    </div>,
    <div className={classes.tabNamesContainer} key="challengesPurchased">
      Withdraw List{' '}
      <span className={classes.chip}>{withdrawHistoryTotalOfElements}</span>
    </div>,
  ];

  for (const challenge of purchasedChallenges) {
    tablePurchasedChallengesData.push({
      ID: challenge.id,
      'Price Paid': challenge.pricePaid,
      'Amount to Referrer': challenge.amountToReferrer,
      'Payment Date': challenge.paymentDate,
      'Challenge Type': challenge.challengeType,
      Firstname: challenge.firstname,
      Lastname: challenge.lastname,
      Email: challenge.userMail,
      UUID: challenge.userUUID,
    });
  }

  for (const payout of tradingPayout) {
    tableTradingPayoutData.push({
      ID: payout.id,
      'MT5 Account': payout.mt5Account,
      'Amount Client': payout.amountClient,
      'Amount Propfirm': payout.amountPropfirm,
      'Creation Date': payout.creationDate,
      'Payout Status': payout.payoutStatus,
      Firstname: payout.firstname,
      Lastname: payout.lastname,
      Email: payout.email,
      UUID: payout.userUUID,
      'User ID': payout.userId,
    });
  }

  for (const payout of affiliationPayout) {
    tableAffiliationPayoutData.push({
      UUID: payout.userUUID,
      'Admin ID': payout.adminId,
      'Request ID': payout.requestId,
      Email: payout.userEmail,
      Firstname: payout.userFirstname,
      Lastname: payout.userLastname,
      'Affiliate DisplayName': payout.affiliateDisplayName,
      'Affiliation Percentage': payout.affiliationPercentage,
      'Affiliation Type': payout.affiliationType,
      Month: payout.month,
      'KYC Validated': payout.kycValidated,
      'Crypto Wallet Address': payout.cryptoWalletAddress,
      'Crypto Network': payout.cryptoNetwork,
      'Total Commission': payout.totalCommission,
    });
  }

  for (const payout of referralPayout) {
    tableReferralPayoutData.push({
      UUID: payout.userUUID,
      'Admin ID': payout.adminId,
      'Request ID': payout.requestId,
      Email: payout.userEmail,
      Firstname: payout.userFirstname,
      Lastname: payout.userLastname,
      'Affiliate DisplayName': payout.affiliateDisplayName,
      'Affiliation Percentage': payout.affiliationPercentage,
      'Affiliation Type': payout.affiliationType,
      Month: payout.month,
      'KYC Validated': payout.kycValidated,
      'Total Commission': payout.totalCommission,
    });
  }

  for (const withdraw of withdrawHistory) {
    tableWithdrawHistoryData.push({
      'Withdraw Label': withdraw.withdrawLabel,
      'External ID': withdraw.externalID,
      Amount: withdraw.amount,
      'Withdraw Type': withdraw.withdrawType,
      Firstname: withdraw.firstname,
      Lastname: withdraw.lastname,
      'Request Date': withdraw.requestDate,
      Status: withdraw.status,
      Email: withdraw.email,
      'User ID': withdraw.userID,
    });
  }

  const loadPurchasedChallenges = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchPurchasedChallenges(
          tokens.accessToken,
          purchasedChallengesPage,
          size,
          purchasedChallengesFilters,
        );
        setPurchasedChallenges(response.content);
        setTotalPurchasedChallengesPages(response.totalPages);
        setPurchasedChallengesTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, purchasedChallengesFilters, purchasedChallengesPage, size]);

  const loadTradingPayout = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchTradingPayout(
          tokens.accessToken,
          tradingPayoutPage,
          size,
          tradingPayoutfilters,
        );
        setTradingPayout(response.content);
        setTotalTradingPayoutPages(response.totalPages);
        setTradingPayoutTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, tradingPayoutfilters, tradingPayoutPage, size]);

  const loadAffiliationPayout = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchAffiliatePayout(
          tokens.accessToken,
          affiliationPayoutPage,
          size,
          false,
        );
        setAffiliationPayout(response.content);
        setTotalAffiliationPayoutPages(response.totalPages);
        setAffiliationPayoutTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, affiliationPayoutPage, size]);

  const loadReferralPayout = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchAffiliatePayout(
          tokens.accessToken,
          referralPayoutPage,
          size,
          true,
        );
        setReferralPayout(response.content);
        setTotalReferralPayoutPages(response.totalPages);
        setReferralPayoutTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, referralPayoutPage, size]);

  const loadWithdrawHistory = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchWithdrawHistory(
          tokens.accessToken,
          withdrawHistoryPage,
          size,
          withdrawFilters,
        );
        setWithdrawHistory(response.content);
        setTotalWithdrawHistoryPages(response.totalPages);
        setWithdrawHistoryTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, withdrawHistoryPage, size, withdrawFilters]);

  const handleApplyWithdrawSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setWithdrawFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveWithdrawFilter = useCallback((filterType: string) => {
    setWithdrawFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  const handleApplyTradingPayoutSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setTradingPayoutFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemoveTradingPayoutFilter = useCallback((filterType: string) => {
    setTradingPayoutFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  const handleApplyPurchasedChallengeSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setPurchasedChallengesFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemovePurchasedChallengeFilter = useCallback(
    (filterType: string) => {
      setPurchasedChallengesFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: '',
      }));
    },
    [],
  );

  useEffect(() => {
    const filtersAreSet = Object.values(withdrawFilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadWithdrawHistory();
    }
  }, [withdrawFilters, loadWithdrawHistory]);

  useEffect(() => {
    const filtersAreSet = Object.values(tradingPayoutfilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadTradingPayout();
    }
  }, [tradingPayoutfilters, loadTradingPayout]);

  useEffect(() => {
    const filtersAreSet = Object.values(purchasedChallengesFilters).some(
      (value) => value !== '',
    );
    if (filtersAreSet) {
      loadPurchasedChallenges();
    }
  }, [purchasedChallengesFilters, loadPurchasedChallenges]);

  const handleExportWithdrawClick = async () => {
    if (tokens && tokens.accessToken) {
      try {
        await exportWithdrawHistoryToXLS(
          tokens.accessToken,
          withdrawHistoryPage,
          size,
          withdrawFilters,
        );
        toast.success('Le téléchargement va commencer.', {
          progressStyle: { backgroundColor: 'green' },
        });
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de l'export.", {
          progressStyle: { backgroundColor: 'red' },
        });
      }
    } else {
      logout();
    }
  };

  const handleDownloadCertificate = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await downloadCertificate(tokens.accessToken, id);
      loadTradingPayout();
    } else {
      logout();
    }
  };

  const handleExportChallengesPurchasedClick = async () => {
    if (tokens && tokens.accessToken) {
      try {
        await exportChallengesPurchasedToXLS(
          tokens.accessToken,
          purchasedChallengesPage,
          size,
          purchasedChallengesFilters,
        );
        toast.success('Le téléchargement va commencer.', {
          progressStyle: { backgroundColor: 'green' },
        });
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de l'export.", {
          progressStyle: { backgroundColor: 'red' },
        });
      }
    } else {
      logout();
    }
  };

  const handleExportTradingPayoutClick = async () => {
    if (tokens && tokens.accessToken) {
      try {
        await exportTradingPayoutToXLS(
          tokens.accessToken,
          tradingPayoutPage,
          size,
          tradingPayoutfilters,
        );
        toast.success('Le téléchargement va commencer.', {
          progressStyle: { backgroundColor: 'green' },
        });
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de l'export.", {
          progressStyle: { backgroundColor: 'red' },
        });
      }
    } else {
      logout();
    }
  };

  useEffect(() => {
    if (!isTokensLoading) {
      loadPurchasedChallenges();
      loadTradingPayout();
      loadAffiliationPayout();
      loadReferralPayout();
      loadWithdrawHistory();
    }
  }, [
    loadPurchasedChallenges,
    loadTradingPayout,
    loadAffiliationPayout,
    loadReferralPayout,
    loadWithdrawHistory,
    isTokensLoading,
  ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <h1 className={classes.title}>Reports</h1>
      <Tab id="reports" tabs={tabNames}>
        <div>
          <Filter
            filters={purchasedChallengesFilters}
            onApplySearch={handleApplyPurchasedChallengeSearch}
            onRemoveFilter={handleRemovePurchasedChallengeFilter}
            filterTypes={PurchasedChallengesfilterTypes}
          />
          {tablePurchasedChallengesData.length === 0 ? (
            'Aucunes requêtes'
          ) : (
            <>
              <AdminTable
                data={tablePurchasedChallengesData}
                columns={tablePurchasedChallengesColumns}
              />
              <div className={classes.footer}>
                <Pagination
                  total={totalPurchasedChallengesPages}
                  perPage={size}
                  currentPage={purchasedChallengesPage}
                  onPageChange={(page) => setPurchasedChallengesPage(page)}
                />
                <p
                  onClick={handleExportChallengesPurchasedClick}
                  className={classes.exportXLS}
                >
                  <Icon
                    color=""
                    fill="#B7B7B7"
                    size={18}
                    path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
                  />
                  Export to XLS
                </p>
              </div>
            </>
          )}
        </div>
        <div>
          <Filter
            filters={tradingPayoutfilters}
            onApplySearch={handleApplyTradingPayoutSearch}
            onRemoveFilter={handleRemoveTradingPayoutFilter}
            filterTypes={tradingPayoutFilterTypes}
          />
          {tableTradingPayoutData.length === 0 ? (
            'Aucune requête'
          ) : (
            <AdminTable
              data={tableTradingPayoutData}
              columns={tableTradingPayoutColumns}
              downloadButton={true}
              onDownloadButtonClick={handleDownloadCertificate}
            />
          )}
          <div className={classes.footer}>
            <Pagination
              total={totalTradingPayoutPages}
              perPage={size}
              currentPage={tradingPayoutPage}
              onPageChange={(page) => setTradingPayoutPage(page)}
            />
            <p
              onClick={handleExportTradingPayoutClick}
              className={classes.exportXLS}
            >
              <Icon
                color=""
                fill="#B7B7B7"
                size={18}
                path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
              />
              Export to XLS
            </p>
          </div>
        </div>
        <div>
          {tableAffiliationPayoutData.length === 0 ? (
            'Aucunes requêtes'
          ) : (
            <>
              <AdminTable
                data={tableAffiliationPayoutData}
                columns={tableAffiliationPayoutColums}
              />
              <div className={classes.footer}>
                <Pagination
                  total={totalAffiliationPayoutPages}
                  perPage={size}
                  currentPage={affiliationPayoutPage}
                  onPageChange={(page) => setAffiliationPayoutPage(page)}
                />
                <p className={classes.exportXLS}>
                  <Icon
                    color=""
                    fill="#B7B7B7"
                    size={18}
                    path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
                  />
                  Export to XLS
                </p>
              </div>
            </>
          )}
        </div>
        <div>
          {tableReferralPayoutData.length === 0 ? (
            'Aucunes requêtes'
          ) : (
            <>
              <AdminTable
                data={tableReferralPayoutData}
                columns={tableReferralPayoutColums}
              />
              <div className={classes.footer}>
                <Pagination
                  total={totalReferralPayoutPages}
                  perPage={size}
                  currentPage={referralPayoutPage}
                  onPageChange={(page) => setReferralPayoutPage(page)}
                />
                <p className={classes.exportXLS}>
                  <Icon
                    color=""
                    fill="#B7B7B7"
                    size={18}
                    path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
                  />
                  Export to XLS
                </p>
              </div>
            </>
          )}
        </div>
        <div>
          <Filter
            filters={withdrawFilters}
            onApplySearch={handleApplyWithdrawSearch}
            onRemoveFilter={handleRemoveWithdrawFilter}
            filterTypes={WithdrawfilterTypes}
          />
          {tableWithdrawHistoryData.length === 0 ? (
            'Aucun historique'
          ) : (
            <AdminTable
              data={tableWithdrawHistoryData}
              columns={tableWithdrawHistoryColumns}
            />
          )}
          <div className={classes.footer}>
            <Pagination
              total={totalWithdrawHistoryPages}
              perPage={size}
              currentPage={withdrawHistoryPage}
              onPageChange={(page) => setWithdrawHistoryPage(page)}
            />
            <p
              onClick={handleExportWithdrawClick}
              className={classes.exportXLS}
            >
              <Icon
                color=""
                fill="#B7B7B7"
                size={18}
                path="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM17 3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9 10.2859L7.39999 8.00022H5L7.8 12.0002L5 16.0002H7.39999L9 13.7145L10.6 16.0002H13L10.2 12.0002Z"
              />
              Export to XLS
            </p>
          </div>
        </div>
      </Tab>
    </>
  );
};
export default Reports;
