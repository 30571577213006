import axiosInstance from '../../../../../utils/axios';
import { IGetUserPatrimonyService } from './interfaces';
import { PatrimonyInformation } from './models';
import { handleError } from './errors';

class GetUserPatrimonyService implements IGetUserPatrimonyService {
  async getUserPatrimony(
    userId: string,
    token: string,
  ): Promise<PatrimonyInformation> {
    try {
      const response = await axiosInstance.get<PatrimonyInformation>(
        `/v2/users/${userId}/patrimony`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new GetUserPatrimonyService();
