import { createContext, useContext } from 'react';
import { SimpleDashboardStatsDTO } from '../../services/get/lightDashboard/models';

interface LightDashboardContextProps {
  data: SimpleDashboardStatsDTO | null;
  isLoading: boolean;
  error: string | null;
  fetchLightDashboardData: () => Promise<void>; // La fonction de récupération ne devrait plus prendre de token si c'est géré dans le provider
}

// Créer le contexte
export const LightDashboardContext = createContext<
  LightDashboardContextProps | undefined
>(undefined);

// Hook personnalisé pour accéder au contexte
export const useLightDashboard = (): LightDashboardContextProps => {
  const context = useContext(LightDashboardContext);

  if (!context) {
    throw new Error(
      'useLightDashboard must be used within a LightDashboardProvider',
    );
  }

  return context;
};
