import React, { useEffect, useState } from 'react';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';
import CustomModal from '../../../../components/Modal/Modal';
import { green, red } from '@mui/material/colors';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import { MetatraderCreationInfoDTO } from '../../services/get/accountCredentials/models';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import GetAccountCredentialsService from '../../services/get/accountCredentials/getAccountCredentials';
import GetFundedAccountCredentialsService from '../../services/get/fundedCredentials/getFundedCredentials'; // Import du service pour les comptes funded
import classes from './summary.module.scss';
import useVisibility from '../../../../hooks/useVisibility';
import { handleErrorModifyPassword } from '../../services/put/modifyPassword/errors';
import PutModifyPasswordService from '../../services/put/modifyPassword/putModifyPassword';
import { useTranslation } from 'react-i18next';

interface CredentialsModalProps {
  open: boolean;
  handleClose: () => void;
  selectedAccountLogin: string | null;
  isFunded: boolean; // Ajout de la prop pour différencier si c'est un compte funded
}

const CredentialsModal: React.FC<CredentialsModalProps> = ({
  open,
  handleClose,
  selectedAccountLogin,
  isFunded,
}) => {
  const { t } = useTranslation('area');
  const { tokens } = useAuth();
  const [credentials, setCredentials] =
    useState<MetatraderCreationInfoDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isCopied, copyToClipboard] = useCopyToClipboard();
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);

  const [isVisible, show, hide, toggleVisibility] = useVisibility();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isPasswordVisibleDialog, setPasswordVisibility] =
    useState<boolean>(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisibility] =
    useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const passwordRules = [
    {
      label: t(
        'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.RULE_AT_LEAST_8_CHARACTERS_LONG',
      ),
      regex: /.{8,}/,
    },
    {
      label: t(
        'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.RULE_CONTAIN_A_LOWERCASE_LETTER',
      ),
      regex: /[a-z]/,
    },
    {
      label: t(
        'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.RULE_CONTAIN_AN_UPPERCASE_LETTER',
      ),
      regex: /[A-Z]/,
    },
    {
      label: t(
        'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.RULE_CONTAINS_A_NUMBER',
      ),
      regex: /\d/,
    },
    {
      label: t(
        'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.RULE_CONTAINS_A_SPECIAL_CHARACTER',
      ),
      regex: /[#@!]/,
    },
  ];
  const checkPasswordRules = (password: string) => {
    return passwordRules.map((rule) => ({
      ...rule,
      valid: rule.regex.test(password),
    }));
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    setPasswordError(null);
  };

  useEffect(() => {
    const fetchCredentials = async () => {
      if (selectedAccountLogin && tokens) {
        setLoading(true);
        try {
          let data: MetatraderCreationInfoDTO;
          if (isFunded) {
            // Si c'est un compte funded, utilise le service approprié
            data =
              await GetFundedAccountCredentialsService.fetchAccountCredentials(
                selectedAccountLogin,
                tokens.accessToken,
              );
          } else {
            // Si c'est un compte client, utilise le service pour les comptes standard
            data = await GetAccountCredentialsService.getAccountCredentials(
              selectedAccountLogin,
              tokens.accessToken,
            );
          }
          setCredentials(data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCredentials();
  }, [selectedAccountLogin, tokens, isFunded]);

  const handleCopy = (text: string): void => {
    copyToClipboard(text);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const handleClickOpenDialog = (): void => {
    setOpenDialog(true);
    handleClose(); // Fermer la modal Credentials
  };
  const handleCloseDialog = (): void => {
    setOpenDialog(false);
    setNewPassword('');
    setConfirmNewPassword('');
    setPasswordError(null);
  };
  const handleSnackbarClose = () => {
    setSnackbarMessage(null);
  };
  const handleChangePassword = async (): Promise<void> => {
    const rulesChecked = checkPasswordRules(newPassword);
    const allRulesValid = rulesChecked.every((rule) => rule.valid);
    if (!allRulesValid) {
      setPasswordError(
        t(
          'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.ERROR_PASSWORD_DOES_NOT_MEET_THE_REQUIRED_CRITERIA',
        ),
      );
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setPasswordError(
        t(
          'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.ERROR_PASSWORDS_DO_NOT_MATCH',
        ),
      );
      return;
    }
    if (selectedAccountLogin && tokens) {
      try {
        await PutModifyPasswordService.putModifyPassword(
          selectedAccountLogin,
          newPassword,
          tokens.accessToken,
        );
        setSnackbarMessage(
          t(
            'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.SUCCESS_PASSWORD_MODIFIED_SUCCESSFULLY',
          ),
        );
        setIsError(false);
        handleCloseDialog();
      } catch (error) {
        handleErrorModifyPassword(
          error,
          t(
            'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.ERROR_FAILED_TO_MODIFY_PASSWORD',
          ),
        );
        setSnackbarMessage(
          t(
            'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.ERROR_FAILED_TO_MODIFY_PASSWORD',
          ),
        );
        setIsError(true);
        handleCloseDialog();
      }
    }
  };

  const rulesChecked = checkPasswordRules(newPassword);

  if (!credentials) {
    return (
      <CustomModal
        open={open}
        handleClose={handleClose}
        title={t('CHALLENGE_CARD.MODALS.CREDENTIALS.TITLE_CREDENTIALS')}
      >
        <Typography variant="h6" component="h4" mb={4}>
          {t('CHALLENGE_CARD.MODALS.CREDENTIALS.ERROR_NO_DATA_AVAILABLE')}
        </Typography>
      </CustomModal>
    );
  }

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {t(
            'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.TITLE_MODIFY_PASSWORD',
          )}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            size="small"
            margin="dense"
            id="new-password"
            name="new-password"
            label={t(
              'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.LABEL_NEW_PASSWORD',
            )}
            type={isPasswordVisibleDialog ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            value={newPassword}
            onChange={handlePasswordChange}
            error={!!passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setPasswordVisibility(!isPasswordVisibleDialog)
                    }
                    edge="end"
                  >
                    {isPasswordVisibleDialog ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            size="small"
            margin="dense"
            id="confirm-new-password"
            name="confirm-new-password"
            label={t(
              'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.LABEL_CONFIRM_NEW_PASSWORD',
            )}
            type={isConfirmPasswordVisible ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setConfirmPasswordVisibility(!isConfirmPasswordVisible)
                    }
                    edge="end"
                  >
                    {isConfirmPasswordVisible ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <List dense>
            {rulesChecked.map((rule, index) => (
              <ListItem key={index} sx={{ padding: '0.25rem 0' }}>
                <ListItemIcon sx={{ minWidth: '24px' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      backgroundColor: rule.valid ? green[500] : red[500],
                      marginRight: '8px',
                    }}
                  ></span>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={rule.label}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog}>
            {t(
              'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.BUTTON_CANCEL',
            )}
          </Button>
          <Button variant="contained" onClick={handleChangePassword}>
            {t(
              'CHALLENGE_CARD.MODALS.CREDENTIALS.MODIFY_PASSWORD_DIALOG.BUTTON_MODIFY_PASSWORD',
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <CustomModal
        open={open}
        handleClose={handleClose}
        title={t('CHALLENGE_CARD.MODALS.CREDENTIALS.TITLE_CREDENTIALS')}
      >
        <Stack>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            p={4}
          >
            <Typography variant="h6" component="h3">
              {t('CHALLENGE_CARD.MODALS.CREDENTIALS.TEXT_LOGIN')}
            </Typography>
            <Typography variant="h6" component="p">
              {credentials.login}
            </Typography>
            <Stack>
              <IconButton onClick={() => handleCopy(credentials.login)}>
                <Tooltip
                  title={
                    isCopied
                      ? t('CHALLENGE_CARD.MODALS.CREDENTIALS.TOOLTIP_COPIED')
                      : t('CHALLENGE_CARD.MODALS.CREDENTIALS.TOOLTIP_COPY')
                  }
                  placement="top"
                >
                  <ContentCopyIcon />
                </Tooltip>
              </IconButton>
            </Stack>
          </Stack>

          <Divider />

          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            p={4}
          >
            <Typography variant="h6" component="h3">
              {t('CHALLENGE_CARD.MODALS.CREDENTIALS.TEXT_PASSWORD')}
            </Typography>
            <Typography variant="h6" component="p">
              {isPasswordVisible ? credentials.mt5password : '*******'}
            </Typography>
            <Stack direction="row">
              <IconButton onClick={handleClickOpenDialog}>
                <Tooltip
                  title={t('CHALLENGE_CARD.MODALS.CREDENTIALS.TOOLTIP_EDIT')}
                  placement="top"
                >
                  <EditIcon />
                </Tooltip>
              </IconButton>
              <IconButton onClick={togglePasswordVisibility}>
                <Tooltip
                  title={
                    isPasswordVisible
                      ? t(
                          'CHALLENGE_CARD.MODALS.CREDENTIALS.TOOLTIP_HIDE_PASSWORD',
                        )
                      : t(
                          'CHALLENGE_CARD.MODALS.CREDENTIALS.TOOLTIP_SHOW_PASSWORD',
                        )
                  }
                  placement="top"
                >
                  {isPasswordVisible ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </Tooltip>
              </IconButton>

              <IconButton onClick={() => handleCopy(credentials.mt5password)}>
                <Tooltip
                  title={
                    isCopied
                      ? t('CHALLENGE_CARD.MODALS.CREDENTIALS.TOOLTIP_COPIED')
                      : t('CHALLENGE_CARD.MODALS.CREDENTIALS.TOOLTIP_COPY')
                  }
                  placement="top"
                >
                  <ContentCopyIcon />
                </Tooltip>
              </IconButton>
            </Stack>
          </Stack>

          <Divider />

          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            p={4}
          >
            <Typography variant="h6" component="h3">
              {t('CHALLENGE_CARD.MODALS.CREDENTIALS.TEXT_SERVER')}
            </Typography>
            <Typography variant="h6" component="p">
              RaiseGlobalSA-LIVE
            </Typography>
            <Stack>
              <IconButton onClick={() => handleCopy('RaiseGlobalSA-LIVE')}>
                <Tooltip
                  title={
                    isCopied
                      ? t('CHALLENGE_CARD.MODALS.CREDENTIALS.TOOLTIP_COPIED')
                      : t('CHALLENGE_CARD.MODALS.CREDENTIALS.TOOLTIP_COPY')
                  }
                  placement="top"
                >
                  <ContentCopyIcon />
                </Tooltip>
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>
    </>
  );
};

export default CredentialsModal;
