import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useHandleCopy = () => {
  const { t } = useTranslation('certificate');
  const [copyText, setCopyText] = useState<{ [key: number]: string }>({});

  const handleCopy = (id: number, image: string) => {
    const img = new Image();
    img.src = `data:image/png;base64,${image}`;
    img.crossOrigin = 'anonymous';

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        if (blob) {
          const item = new ClipboardItem({ 'image/png': blob });
          navigator.clipboard.write([item]);
          setCopyText((prevState) => ({
            ...prevState,
            [id]: t('COPIED_BUTTON'),
          }));
          setTimeout(() => {
            setCopyText((prevState) => ({
              ...prevState,
              [id]: t('COPY_BUTTON'),
            }));
          }, 2000);
        }
      });
    };
  };

  return { copyText, handleCopy };
};
