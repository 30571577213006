import { fetchEconomicCalendar } from './../../../../services/calendar/calendar';
import { useState, useEffect } from 'react';
import { CalendarResponse } from '../services/get/calendar/models';
import GetEconomicCalendarService from '../services/get/calendar/getCalendar';
import { useAuth } from '../../../../context/AuthContext/AuthContext';

export const useEconomicCalendar = () => {
  const { tokens } = useAuth();
  const [economicEvents, setEconomicEvents] = useState<CalendarResponse[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEconomicCalendar = async () => {
      if (tokens && tokens.accessToken) {
        try {
          const data = await GetEconomicCalendarService.getEconomicCalendar(
            tokens.accessToken,
          );
          setEconomicEvents(data);
          setLoading(false);
        } catch (error) {
          console.error('Failed to fetch economic calendar', error);
          setLoading(false);
        }
      }
    };

    fetchEconomicCalendar();
  }, [tokens]);

  return { economicEvents, loading, fetchEconomicCalendar };
};
