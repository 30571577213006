import React, { useState, useEffect, ReactNode } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import GetCommissionsService from '../../services/get/commissions/getCommissions';
import { CommissionSummaryDTO } from '../../services/get/commissions/models';
import { CommissionsContext } from '../../context/Commissions/CommissionsContext';

interface CommissionsProviderProps {
  children: ReactNode;
  timeframe: 'TODAY' | 'WEEK' | 'MONTH' | 'YEAR' | 'TOTAL';
}

export const CommissionsProvider: React.FC<CommissionsProviderProps> = ({
  children,
  timeframe,
}) => {
  const { tokens } = useAuth();
  const [commissionsData, setCommissionsData] =
    useState<CommissionSummaryDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCommissions = async () => {
      if (tokens) {
        setLoading(true);
        setError(null);
        try {
          const data = await GetCommissionsService.getCommissions(
            tokens.accessToken,
            timeframe,
          );
          setCommissionsData(data);
        } catch (error) {
          setError('An error occurred while fetching commissions data.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCommissions();
  }, [tokens, timeframe]);

  return (
    <CommissionsContext.Provider value={{ commissionsData, loading, error }}>
      {children}
    </CommissionsContext.Provider>
  );
};
