import { useEffect, useState } from 'react';

import {
  deleteChallenge,
  switchToDemo,
  switchToHFT,
  switchFundedToGroup,
  modifyMetatraderPassword,
} from '../../../services/admin/userAbout/userAbout';
import { reactiveChallenge } from '../../../services/admin/challenges/challenges';
import { logout } from '../../../services/auth/login';
import { toast } from 'react-toastify';

import { useAuth } from '../../../context/AuthContext/AuthContext';
import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';

import Icon from '../../../components/userInterface/Icon/Icon';

import classes from './_dropdown.module.scss';
import Button from '../../userInterface/Button/Button';
import ComboBox from '../Asynchronous/Asynchronous';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Input from '../../../components/userInterface/Input/Input';
import ButtonMUI from '@mui/material/Button';
import Modal from '../../../components/Modal/Modal';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import OldModal from '../../../components/OldModal/OldModal';

interface DropdownProps {
  login: string;
  stageOfChallenge?: string;
  balance?: number;
  equity?: number;
  maxDailyLossAllowed?: string;
  maxDrawdown?: string;
  balancePrevDay?: string;
  numberOfTrades?: number;
  userAdminID?: number;
  group?: string;
  isHFTEnabled?: boolean;
  isChallenge?: boolean;
  isFunded?: boolean;
  // onDelete?: () => void;
  onDelete?: boolean;
  onSwitchHFT?: () => void;
  onSwitchDemo?: () => void;
  onSwitchGroup?: () => void;
  onApplyGroup?: () => void;
  onAddVariant?: () => void;
  onAddModifyPassword?: () => void;
  onDeleteClick?: any;
  children: React.ReactNode;
}

type ModalStateType = {
  isOpen: boolean;
  action: (() => void) | null;
};

const Dropdown: React.FC<DropdownProps> = ({
  login,
  stageOfChallenge,
  balance,
  equity,
  maxDailyLossAllowed,
  maxDrawdown,
  balancePrevDay,
  numberOfTrades,
  isHFTEnabled,
  isChallenge,
  isFunded,
  userAdminID,
  group,
  onDelete,
  onSwitchHFT,
  onSwitchGroup,
  onSwitchDemo,
  onApplyGroup,
  onAddVariant,
  onAddModifyPassword,
  onDeleteClick,
  children,
}) => {
  const { isDarkMode } = useThemeContext();
  const { t } = useTranslation(['clientArea', 'components', 'common']);
  const { tokens } = useAuth();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isReactivateButtonClicked, setIsReactivateButtonClicked] =
    useState(false);
  const [accounts, setAccounts] = useState<any>([]);

  const [isShowMore, setIsShowMore] = useState(false);
  const [groupSelected, setGroupSelected] = useState<string | undefined>();
  const [newPassword, setNewPassword] = useState<string>();
  const [modalState, setModalState] = useState<ModalStateType>({
    isOpen: false,
    action: null,
  });

  const openConfirmationModal = (action: () => void) => {
    setModalState({ isOpen: true, action });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, action: null });
  };

  const handleConfirm = () => {
    if (modalState.action) {
      modalState.action();
      closeModal();
    }
  };

  const handleShowMore = () => {
    setIsShowMore(!isShowMore);
  };

  const [openModifyPassword, setOpenModifyPassword] = useState<boolean>(false);

  const handleOpenModifyPassword = () => {
    setNewPassword('');
    setOpenModifyPassword(true);
  };

  const handleCloseModifyPasswordModal = () => {
    setOpenModifyPassword(false);
  };

  const handleGenerateRandomPassword = (event: any) => {
    event.preventDefault();

    const lettres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const chiffres = '0123456789';
    const caracteresSpeciaux = '@#*!';
    const tousLesCaracteres = lettres + chiffres + caracteresSpeciaux;
    const longueurMotDePasse = 10;
    let nouveauMotDePasse = '';

    for (let i = 0; i < longueurMotDePasse - 1; i++) {
      const indiceAleatoire = Math.floor(
        Math.random() * tousLesCaracteres.length,
      );
      nouveauMotDePasse += tousLesCaracteres[indiceAleatoire];
    }

    const indiceSpecialAleatoire = Math.floor(
      Math.random() * caracteresSpeciaux.length,
    );
    nouveauMotDePasse += caracteresSpeciaux[indiceSpecialAleatoire];

    nouveauMotDePasse = nouveauMotDePasse
      .split('')
      .sort(() => 0.5 - Math.random())
      .join('');

    setNewPassword(nouveauMotDePasse);
  };

  // const handleDeleteChallenge = async () => {
  //   if (tokens && tokens.accessToken) {
  //     try {
  //       await deleteChallenge(tokens.accessToken, login);
  //       if (onDelete) {
  //         onDelete();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     logout();
  //   }
  // };

  const handleSwitchHFTGroup = async (login: string) => {
    if (tokens && tokens.accessToken) {
      try {
        await switchToHFT(tokens.accessToken, login);
        if (onSwitchHFT) {
          onSwitchHFT();
        }
        toast.success('Le challenge est devenu HFT');
      } catch (error) {
        toast.error("Le challenge n'est pas éligible");
      }
    } else {
      logout();
    }
  };

  const handleSwitchDemoGroup = async (login: string) => {
    if (tokens && tokens.accessToken) {
      try {
        await switchToDemo(tokens.accessToken, login);
        if (onSwitchDemo) {
          onSwitchDemo();
        }
        toast.success('Le challenge est redevenu DEMO');
      } catch (error) {
        toast.error("Le challenge n'est pas éligible");
      }
    } else {
      logout();
    }
  };

  const handleSubmitSwitchFundedToGroup = async (
    login: string,
    newGroup?: string,
  ) => {
    if (tokens && tokens.accessToken) {
      if (!newGroup) {
        toast.error('Please select a new group.');
        return;
      }

      try {
        await switchFundedToGroup(tokens.accessToken, login, newGroup);
        handleCloseModifyPasswordModal();

        toast.success(
          'You successfully changed the group of the account ' + login + '.',
        );
      } catch (error) {
        toast.error(
          'An error occured changing the group of the account ' + login + '.',
        );
      }
    } else {
      logout();
    }
  };

  const handleReactiveChallenge = async () => {
    if (tokens && tokens.accessToken) {
      try {
        if (isReactivateButtonClicked) {
          await reactiveChallenge(tokens.accessToken, login);
          setIsReactivateButtonClicked(false);
        } else {
          setIsReactivateButtonClicked(true);
          setTimeout(() => setIsReactivateButtonClicked(false), 5000);
        }
      } catch (error) {
        throw error;
      }
    }
  };

  const handleSubmitModifyPassword = async (
    event: any,
    login: string,
    password?: string,
  ) => {
    event.preventDefault();

    if (tokens && tokens.accessToken) {
      if (!password) {
        toast.error('Please enter a password or generate one.');
        return;
      }

      try {
        await modifyMetatraderPassword(tokens.accessToken, login, password);
        if (onSwitchGroup) {
          onSwitchGroup();
        }
        toast.success(
          'You successfully changed the password of the account ' + login + '.',
        );
      } catch (error) {
        toast.error(
          'An error occured changing the password of the account ' +
            login +
            '.',
        );
      }
    } else {
      logout();
    }
  };

  const handleClick = (toHFT: boolean) => {
    if (isButtonClicked) {
      if (toHFT) {
        handleSwitchHFTGroup(login);
      } else {
        handleSwitchDemoGroup(login);
      }
      setIsButtonClicked(false);
    } else {
      setIsButtonClicked(true);
      setTimeout(() => setIsButtonClicked(false), 5000);
    }
  };

  return (
    <div
      className={`${classes.dropdown} 
      ${isShowMore ? classes.active : ''} 
      ${isDarkMode ? classes.dark : classes.light}`}
    >
      <Dialog
        open={openModifyPassword}
        onClose={handleCloseModifyPasswordModal}
        PaperProps={{
          sx: {
            backgroundColor: 'grey.900', // Fond gris foncé
            color: 'white', // Texte blanc
          },
        }}
      >
        <form
          onSubmit={(event) =>
            handleSubmitModifyPassword(event, login, newPassword)
          }
        >
          <DialogTitle sx={{ color: 'white' }}>
            Modify account {login} password
          </DialogTitle>
          <DialogContent>
            <Input type="text" value={newPassword} />
          </DialogContent>
          <DialogActions>
            <ButtonMUI
              onClick={handleCloseModifyPasswordModal}
              sx={{ color: 'white' }}
              type="button"
            >
              Cancel
            </ButtonMUI>
            <ButtonMUI
              onClick={handleGenerateRandomPassword}
              sx={{ color: 'white' }}
              type="button"
            >
              Generate Random
            </ButtonMUI>
            <ButtonMUI type="submit" sx={{ color: 'white' }}>
              Modify
            </ButtonMUI>
          </DialogActions>
        </form>
      </Dialog>

      {modalState.isOpen && (
        <OldModal
          title={`Are you sure you want to delete this item?`}
          isOpen={modalState.isOpen}
          onClose={closeModal}
          showCloseButton={true}
        >
          <div className={classes.modalButtons}>
            <Button onClick={handleConfirm} variant="primary">
              Delete
            </Button>
            <Button onClick={closeModal} variant="outline">
              Cancel
            </Button>
          </div>
        </OldModal>
      )}

      <div>
        <div className={classes.header}>
          <span>Login</span>
          <span>{login}</span>
          <div className={classes.icons}>
            {/* {stageOfChallenge === 'Failed' ? (
              <Button
                onClick={() => handleReactiveChallenge()}
                className={classes.hftButton}
                variant="disabled"
              >
                {isReactivateButtonClicked ? 'Confirm ? ' : 'Reactivate'}
              </Button>
            ) : null} */}
            <Button
              onClick={() => handleReactiveChallenge()}
              className={`${
                stageOfChallenge === 'Failed'
                  ? classes.hftButton
                  : classes.reactivateButtonVisible
              }`}
              variant="disabled"
            >
              {isReactivateButtonClicked ? 'Confirm ? ' : 'Reactivate'}
            </Button>

            {onDelete && onDeleteClick && (
              <Icon
                // onClick={() => handleDeleteChallenge()}
                onClick={() => openConfirmationModal(onDeleteClick)}
                color="white"
                size={20}
                path="M7 6V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7ZM9 4V6H15V4H9Z"
              />
            )}
            <Icon
              onClick={() => handleShowMore()}
              color="white"
              size={20}
              path={
                isShowMore
                  ? 'M11.9997 10.8284L7.04996 15.7782L5.63574 14.364L11.9997 8L18.3637 14.364L16.9495 15.7782L11.9997 10.8284Z'
                  : 'M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z'
              }
            />
          </div>
        </div>
        <hr />
        <div className={classes.content}>
          <table
            className={`${classes.tableStyle} ${
              isDarkMode ? classes.dark : classes.light
            }`}
          >
            <thead>
              <tr>
                <th className={classes.tableHeader}>Information</th>
                <th className={classes.tableHeader}>Details</th>
              </tr>
            </thead>
            <tbody>
              {stageOfChallenge && (
                <tr className={classes.tableRow}>
                  <td>State of challenge</td>
                  <td>{stageOfChallenge}</td>
                </tr>
              )}
              <tr className={classes.tableRow}>
                <td>Balance</td>
                <td>{balance}$</td>
              </tr>
              <tr className={classes.tableRow}>
                <td>Equity</td>
                <td>{equity}$</td>
              </tr>
              <tr className={classes.tableRow}>
                <td>Max daily loss allowed</td>
                <td>{maxDailyLossAllowed}$</td>
              </tr>
              <tr className={classes.tableRow}>
                <td>Max drawdown</td>
                <td>{maxDrawdown}$</td>
              </tr>
              <tr className={classes.tableRow}>
                <td>Previous Day Balance</td>
                <td>{balancePrevDay}$</td>
              </tr>
              <tr className={classes.tableRow}>
                <td>Number of trades</td>
                <td>{numberOfTrades}</td>
              </tr>
              {group != null && (
                <tr className={classes.tableRow}>
                  <td>MT5 Group</td>
                  <td>{group}</td>
                </tr>
              )}
              {isHFTEnabled != null && (
                <tr className={classes.tableRow}>
                  <td>HFT</td>
                  <td>{isHFTEnabled ? '✅ Enabled' : '❌ Disabled'}</td>
                </tr>
              )}
              {isFunded != null && isFunded && (
                <tr className={classes.tableRow}>
                  <td>Switch MetaTrader Group</td>
                  <td>
                    <div className={classes.choice}>
                      {tokens != null && userAdminID != null && (
                        <ComboBox
                          onSelect={(selectedGroup) =>
                            setGroupSelected(selectedGroup ?? undefined)
                          }
                          accessToken={tokens.accessToken}
                          userAdminID={userAdminID}
                        />
                      )}
                      <Button
                        onClick={() =>
                          handleSubmitSwitchFundedToGroup(login, groupSelected)
                        }
                        variant="primary"
                      >
                        {'Apply'}
                      </Button>
                      <Button onClick={onAddVariant} variant="rounded-outline">
                        {'Add new variant'}
                      </Button>
                    </div>
                  </td>
                </tr>
              )}

              <tr className={classes.tableRow}>
                <td>Modify password</td>
                <td>
                  <div className={classes.choice}>
                    <Button
                      onClick={handleOpenModifyPassword}
                      variant="primary"
                    >
                      {'Change Password'}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {children}
      </div>
    </div>
  );
};
export default Dropdown;
