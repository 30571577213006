import axiosInstance from '../../../../../utils/axios';
import { FundedAccountBase } from './models';
import { ApiError } from './errors';

export const getFundedAccountBase = async (
  accountLogin: string,
  token: string,
): Promise<FundedAccountBase> => {
  try {
    const response = await axiosInstance.get<FundedAccountBase>(
      `/v2/funded/account/${accountLogin}/base`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 500) {
      throw new ApiError(error.response.data.error);
    } else {
      throw new ApiError('An unexpected error occurred');
    }
  }
};
