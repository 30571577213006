// hooks/useDiscordUserActivity.ts
import { useState } from 'react';
import { getDiscordUserActivity } from '../services/get/activity/getUserActivity';
import { GetDiscordUserActivityParams } from '../services/get/activity/interfaces';
import {
  DiscordUserActivity,
  PaginationInfo,
} from '../services/get/activity/models';
import { useAuth } from '../../../../context/AuthContext/AuthContext';

export const useDiscordUserActivity = () => {
  const [data, setData] = useState<DiscordUserActivity[]>([]); // Données des activités utilisateur
  const [pagination, setPagination] = useState<PaginationInfo | null>(null); // Informations de pagination
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { tokens } = useAuth();

  const fetchUserActivity = async (params: GetDiscordUserActivityParams) => {
    setLoading(true);
    setError(null);

    try {
      const response = await getDiscordUserActivity(params, tokens?.accessToken || '');

      // Mettre à jour les activités et la pagination dans l'état
      setData(response.result);
      setPagination(response.document); // Stocker les infos de pagination
    } catch (err) {
      setError('Failed to fetch user activity');
    } finally {
      setLoading(false);
    }
  };

  return { data, pagination, loading, error, fetchUserActivity };
};
