import React from 'react';
import { Autocomplete, TextField, Stack } from '@mui/material';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

interface TimezoneSelectProps {
  timeZone: string;
  setTimeZone: (value: string) => void;
}

const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  timeZone,
  setTimeZone,
}) => {
  const { t } = useTranslation('calendar');
  const timeZones = moment.tz.names();

  const getTimeZoneOffset = (tz: string) => {
    const offset = moment.tz(tz).utcOffset() / 60;
    const sign = offset >= 0 ? '+' : '-';
    return `UTC${sign}${Math.abs(offset).toString().padStart(2, '0')}:00`;
  };

  const timeZoneOptions = timeZones.map((tz) => ({
    label: `${tz} (${getTimeZoneOffset(tz)})`,
    value: tz,
  }));

  return (
    <Stack spacing={2}>
      <Autocomplete
        fullWidth
        sx={{ width: '300px' }}
        value={
          timeZoneOptions.find((option) => option.value === timeZone) || null
        }
        onChange={(_, newValue) => {
          if (newValue === null) {
            setTimeZone('');
          } else {
            setTimeZone(newValue.value);
          }
        }}
        options={timeZoneOptions}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('TITLES.TIME_ZONE')}
            variant="outlined"
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
      />
    </Stack>
  );
};

export default TimezoneSelect;
