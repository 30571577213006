import axiosInstance from '../../../../../../utils/axios';
import { FundedOverviewDTO } from './models';
import { handleFundedApiError } from './errors';

const getFundedData = async (token: string): Promise<FundedOverviewDTO> => {
  try {
    const response = await axiosInstance.get<FundedOverviewDTO>(
      '/v2/admin/full-dashboard/pie/funded',
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error: any) {
    handleFundedApiError(error);
    throw error;
  }
};

export default getFundedData;
