import React from 'react';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import PieChart from '../PieChart/PieChart';
import { useOverviewData } from '../../hooks/pieChart/useOverviewData';
import PieChartSkeleton from '../PieChart/PieChartSkeleton';
import { useTranslation } from 'react-i18next';

const OverviewTab = () => {
  const theme = useTheme();
  const { t } = useTranslation('dashboard');

  const isDarkMode = theme.palette.mode === 'dark';

  const { loading, error, data } = useOverviewData(); // Appel du hook pour Overview

  if (loading) {
    return <PieChartSkeleton checkboxs={4} />;
  } else if (!data) {
    return null;
  }

  if (error) {
    return (
      <Typography>Error loading overview data: {error.message}</Typography>
    );
  }

  const labels = [
    t('PIE_CHART.LABELS.OVERVIEW.FUNDED'),
    t('PIE_CHART.LABELS.OVERVIEW.PHASE_1'),
    t('PIE_CHART.LABELS.OVERVIEW.PHASE_2'),
    t('PIE_CHART.LABELS.OVERVIEW.FAILED'),
  ];
  const chartData = [
    data.totalFunded,
    data.totalPhase1,
    data.totalPhase2,
    data.totalFailed,
  ];
  const backgroundColors = [
    'rgba(153, 102, 255, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(123, 31, 162, 1)',
  ];

  return (
    <Stack>
      {/* <Stack
        sx={{
          backgroundColor: isDarkMode ? '#292938' : '#ece9e9',
          padding: '8px',
          borderRadius: '8px',
          margin: '16px',
        }}
      >
        <Typography
          fontWeight={'bold'}
          variant="h6"
          component={'h6'}
          textAlign={'center'}
        >
          USERS TOTAL{' '}
          <Typography component={'span'} variant="h6" color={'primary'} ml={1}>
            {data.totalUsers.toLocaleString('fr-FR')}
          </Typography>
        </Typography>
      </Stack> */}
      <PieChart
        labels={labels}
        data={chartData}
        backgroundColors={backgroundColors}
      />
    </Stack>
  );
};

export default OverviewTab;
