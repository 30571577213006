import { useThemeContext } from '../../context/ThemeContext/ThemeContext';

import classes from './_loader.module.scss';

interface LoaderProps {
  global?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ global }) => {
  const { isDarkMode } = useThemeContext();

  return (
    <div
      className={`${classes.container}  ${
        isDarkMode ? classes.dark : classes.light
      } ${global ? classes.global : ''}`}
    >
      <span className={classes.loader}></span>
    </div>
  );
};
export default Loader;
