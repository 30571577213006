import { ThemeOptions } from '@mui/material/styles/createTheme';

export const commonThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#F64644',
      light: '#F0565C',
      dark: '#E73D43',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#697399',
      contrastText: '#ffffff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-containedSecondary:hover': {
            backgroundColor: '#5969A4',
          },
        },
      },
    },
  },
};
