import axiosInstance from '../../../../../utils/axios';
import { PayoutModalDTO } from './models';
import { handleError } from './errors';

class GetPayoutRequestsService {
  async getPayoutRequests(token: string): Promise<PayoutModalDTO> {
    try {
      const response = await axiosInstance.get<PayoutModalDTO>(
        '/v2/affiliations/payout-requests',
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new GetPayoutRequestsService();
