import React from 'react';
import { useAccountBaseData } from '../../context/AccountBaseDataContext/AccountBaseDataContext';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { green, yellow } from '../../../../themes/colors/colors';
import { RulesCard } from '../../index';
import { Card, Chip, Skeleton, Stack, Typography } from '@mui/material';
import classes from './rules.module.scss';
import { useTranslation } from 'react-i18next';
import RulesSkeleton from './RulesSkeleton';

interface RulesProps {
  elevation: number;
  selectedAccountLogin: string | null;
}

const Rules: React.FC<RulesProps> = ({ elevation, selectedAccountLogin }) => {
  const { t } = useTranslation('area');
  const { accountBaseData, loading, error } = useAccountBaseData();
  const { isMD } = useBreakpoints();

  if (loading || !selectedAccountLogin) {
    return <RulesSkeleton elevation={elevation} />;
  }

  if (error) {
    return (
      <Card elevation={elevation} className={classes.rules}>
        <Typography color="error">{error}</Typography>
      </Card>
    );
  }

  const dataToDisplay = accountBaseData;

  if (!dataToDisplay) {
    return null;
  }

  const { maxDailyLoss, maxLoss, profitTarget, consistencyRule } =
    dataToDisplay;

  return (
    <Card elevation={elevation} className={classes.rules}>
      <Stack direction="row" justifyContent="space-between" mb={4}>
        <Typography variant="h6" component="h2">
          {selectedAccountLogin
            ? `${t('RULES.TITLE_ACCOUNT')} #${selectedAccountLogin}`
            : 'Select an Account'}
        </Typography>
        <Chip
          label={`$ ${dataToDisplay.initialBalance}`}
          size="small"
          sx={{ backgroundColor: yellow[800], color: 'black' }}
        />
      </Stack>
      <Stack
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: isMD ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
        }}
      >
        <RulesCard
          title={t('RULES.RULE_CARD.TITLE_MAX_DAILY_LOSS')}
          currentValue={maxDailyLoss.leftValue}
          limitValue={maxDailyLoss.limitValue}
          percentage={maxDailyLoss.percentage}
          passed={maxDailyLoss.passed}
        />
        <RulesCard
          title={t('RULES.RULE_CARD.TITLE_MAX_LOSS')}
          currentValue={maxLoss.leftValue}
          limitValue={maxLoss.limitValue}
          percentage={maxLoss.percentage}
          passed={maxLoss.passed}
        />
        <RulesCard
          title={t('RULES.RULE_CARD.TITLE_PROFIT_TARGET')}
          currentValue={profitTarget.leftValue}
          limitValue={profitTarget.limitValue}
          percentage={profitTarget.percentage}
          passed={profitTarget.passed}
          customLabel={
            profitTarget.passed
              ? t('RULES.RULE_CARD.CHIP_PASSED')
              : t('RULES.RULE_CARD.CHIP_ON_GOING')
          }
          customLabelColor={profitTarget.passed ? green[300] : '#5C9DFF'}
        />
        <RulesCard
          title={t('RULES.RULE_CARD.TITLE_45_PERCENT_RULE')}
          currentValue={consistencyRule.leftValue}
          percentage={consistencyRule.currentPercentage}
          passed={consistencyRule.valid}
          customLabel={
            consistencyRule.valid
              ? t('RULES.RULE_CARD.CHIP_PASSED')
              : t('RULES.RULE_CARD.CHIP_ON_GOING')
          }
          isCustom45Percent
          icon
          customLabelColor={consistencyRule.valid ? green[300] : '#5C9DFF'}
          minimumPayoutValue={consistencyRule.minimumPayoutValue}
          maxDailyProfit={consistencyRule.maxDailyProfit}
        />
      </Stack>
    </Card>
  );
};

export default Rules;
