import axiosInstance from '../../../../../utils/axios';
import { ChallengeTypeSelection } from '../../get/challenges/models';

export const setChallengeType = async (challengeTypeSelection: ChallengeTypeSelection) => {
    try {
        const response = await axiosInstance.post('/challenges/select-type', {
            challengeId: challengeTypeSelection.challengeId,
            type: challengeTypeSelection.type
        });
        return response;
    } catch (error: any) {
        throw new Error(error.response.data.error);
    }
}