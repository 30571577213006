import axiosInstance from '../../utils/axios'; // Assure-toi que le chemin vers axiosInstance est correct
import { UserPermissionsResponse } from './models';
import { PermissionsError } from './errors';

const getUserPermissions = async (
  token: string,
): Promise<UserPermissionsResponse> => {
  try {
    const response = await axiosInstance.get<UserPermissionsResponse>(
      '/permissions',
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data; // Retourne les permissions sous forme d'un tableau
  } catch (error: any) {
    if (error.response && error.response.status === 500) {
      // Gérer les erreurs de type 500
      throw new PermissionsError(
        error.response.data.error || 'Internal Server Error',
      );
    } else {
      // Gérer les autres types d'erreurs
      throw new PermissionsError('An unexpected error occurred');
    }
  }
};

export default getUserPermissions;
