import React from 'react';
import { useFundedAccountBaseData } from '../../context/FundedAccountBaseDataContext/FundedAccountBaseDataContext';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { red } from '@mui/material/colors';
import { Card, Stack, Typography } from '@mui/material';
import classes from './statsCard.module.scss';
import { useTranslation } from 'react-i18next';
import StatsCardSkeleton from './StatsCardSkeleton';

interface FundedStatsCardProps {
  elevation: number;
}

const FundedStatsCard: React.FC<FundedStatsCardProps> = ({ elevation }) => {
  const { t } = useTranslation('area');
  const { fundedAccountBaseData, loading, error } = useFundedAccountBaseData();
  const { isBetweenXSAndSM } = useBreakpoints();

  if (loading || !fundedAccountBaseData) {
    return <StatsCardSkeleton elevation={elevation} />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const { startDate, profit, balance, numberOfTrades } = fundedAccountBaseData;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <Stack
      sx={
        isBetweenXSAndSM
          ? {
              display: 'flex',
              flexDirection: 'column', // Affiche les cartes en colonne sur petit écran
              gap: 2,
              width: '100%',
              height: 'auto',
            }
          : {
              display: 'flex',
              flexDirection: 'row', // Affiche les cartes en ligne sur grand écran
              justifyContent: 'space-between',
              gap: 2,
              width: '100%',
              height: 'auto',
            }
      }
    >
      <Card
        elevation={elevation}
        className={classes.statsCard}
        sx={{ minHeight: '150px' }}
      >
        <Typography variant="h6" component="h6">
          {t('STATS_CARD.TITLE_START_DATE')}
        </Typography>
        <Typography variant="h5" component="h5" color={red[400]}>
          {formatDate(startDate)}
        </Typography>
      </Card>
      <Card
        elevation={elevation}
        className={classes.statsCard}
        sx={{ minHeight: '150px' }}
      >
        <Typography variant="h6" component="h6">
          {t('STATS_CARD.TITLE_PROFIT')}
        </Typography>
        <Typography variant="h5" component="h5" color={red[400]}>
          {profit} €
        </Typography>
      </Card>
      <Card
        elevation={elevation}
        className={classes.statsCard}
        sx={{ minHeight: '150px' }}
      >
        <Typography variant="h6" component="h6">
          {t('STATS_CARD.TITLE_BALANCE')}
        </Typography>
        <Typography variant="h5" component="h5" color={red[400]}>
          {balance} €
        </Typography>
      </Card>
      <Card
        elevation={elevation}
        className={classes.statsCard}
        sx={{ minHeight: '150px' }}
      >
        <Typography variant="h6" component="h6">
          {t('STATS_CARD.TITLE_TRADES')}
        </Typography>
        <Typography variant="h5" component="h5" color={red[400]}>
          {numberOfTrades}
        </Typography>
      </Card>
    </Stack>
  );
};

export default FundedStatsCard;
