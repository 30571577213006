import React from 'react';

import useBreakpoints from '../../../../hooks/useBreakpoints';

import { Card, Skeleton, Stack } from '@mui/material';

const LinkCardSkeleton = () => {
  const { isBetweenXSAndSM } = useBreakpoints();

  return (
    <Card
      elevation={0}
      sx={{
        width: isBetweenXSAndSM ? '100%' : '50%',
        padding: '16px',
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'} mb={6}>
        <Skeleton variant="rectangular" width={200} height={30} />
        <Skeleton variant="rounded" width={100} height={40} />
      </Stack>

      <Stack spacing={2} direction={isBetweenXSAndSM ? 'column' : 'row'}>
        <Skeleton width={'100%'} height={80} />
        <Skeleton width={150} height={80} />
      </Stack>
    </Card>
  );
};

export default LinkCardSkeleton;
