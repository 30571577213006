import React from 'react';
import { Box, Button, Modal, Stack, Typography, Snackbar } from '@mui/material';
import useScaleUp from '../../components/ChallengeCard/hooks/useScaleUp';
import { useTranslation } from 'react-i18next';

interface ScaleUpModalProps {
  open: boolean;
  onClose: () => void;
  accountUUID: string; // Ajout de l'UUID du compte à mettre à jour
}

const ScaleUpModal: React.FC<ScaleUpModalProps> = ({
  open,
  onClose,
  accountUUID,
}) => {
  const { t } = useTranslation('area');

  const {
    handleScaleUpAccount,
    snackbarOpen,
    snackbarMessage,
    snackbarType,
    handleSnackbarClose,
    loading,
  } = useScaleUp();

  // Met à jour la fonction pour utiliser le hook
  const handleConfirmClick = async () => {
    try {
      await handleScaleUpAccount(accountUUID); // Appel du hook avec l'UUID du compte
      onClose(); // Ferme la modal après confirmation
    } catch (error) {
      console.error('Failed to scale up account:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          maxWidth: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component={'h2'}>
          {t('CHALLENGE_CARD.MODALS.SCALE_UP.TITLE_ELEVATE_YOUR_TRADING')}
        </Typography>
        <Typography
          variant="subtitle1"
          component={'h3'}
          color={'text.secondary'}
          mt={2}
          mb={4}
        >
          {t(
            'CHALLENGE_CARD.MODALS.SCALE_UP.TEXT_UPGRADE_TO_A_NEW_MT5_ACCOUNT',
          )}{' '}
          <br />{' '}
          {t('CHALLENGE_CARD.MODALS.SCALE_UP.TEXT_GAIN_A_90_PROFIT_SPLIT')}{' '}
          <br />{' '}
          {t(
            'CHALLENGE_CARD.MODALS.SCALE_UP.TEXT_NOTE_THIS_WILL_RESET_YOUR_CURRENT_PROFITS',
          )}
        </Typography>
        <Stack spacing={2}>
          <Stack direction="row" gap={2} justifyContent={'flex-end'}>
            <Button variant="outlined" onClick={onClose} disabled={loading}>
              {t('CHALLENGE_CARD.MODALS.SCALE_UP.BUTTON_CANCEL')}
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmClick}
              disabled={loading}
            >
              {t('CHALLENGE_CARD.MODALS.SCALE_UP.BUTTON_CONFIRM_SCALE_UP')}
            </Button>
          </Stack>
        </Stack>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          ContentProps={{
            sx: {
              backgroundColor: snackbarType === 'success' ? 'green' : 'red',
            },
          }}
        />
      </Box>
    </Modal>
  );
};

export default ScaleUpModal;
