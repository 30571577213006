import React from 'react';

import { Card, Skeleton, Stack } from '@mui/material';

import classes from './chart.module.scss';

interface ChartSkeletonProps {
  elevation: number;
}

const ChartSkeleton: React.FC<ChartSkeletonProps> = ({ elevation }) => {
  return (
    <Card elevation={elevation} className={classes.chart}>
      <Stack gap={2} width={'100%'} height={'650px'}>
        <Stack direction={'row'} gap={2}>
          <Skeleton variant="circular" width={25} height={25} />
          <Skeleton variant="circular" width={25} height={25} />
          <Skeleton variant="circular" width={25} height={25} />
        </Stack>
        <Skeleton variant="rounded" width={'100%'} height={'100%'} />
        <Stack direction={'row'} gap={0.5}>
          <Skeleton variant="rounded" width={120} height={40} />
          <Skeleton variant="rounded" width={120} height={40} />
          <Skeleton variant="rounded" width={120} height={40} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default ChartSkeleton;
