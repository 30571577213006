import { Card, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const ProfileCardSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card
      elevation={0}
      sx={{
        padding: '16px',
        flex: 1,
      }}
    >
      <Skeleton
        width={250}
        height={80}
        sx={{ marginBottom: 3, maxWidth: '100%' }}
      />
      <Skeleton
        width={240}
        height={45}
        sx={{ marginBottom: 0.5, maxWidth: '100%' }}
      />
      <Stack direction="row" flexWrap="wrap" gap={2}>
        <Skeleton
          variant="rounded"
          width={120}
          height={30}
          sx={{ borderRadius: '12px' }}
        />
        <Skeleton
          variant="rounded"
          width={120}
          height={30}
          sx={{ borderRadius: '12px' }}
        />
        <Skeleton
          variant="rounded"
          width={120}
          height={30}
          sx={{ borderRadius: '12px' }}
        />
      </Stack>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        gap={2}
        mt={6}
        width="100%"
      >
        <Skeleton
          variant="rounded"
          width={isMobile ? '100%' : 350}
          height={50}
        />
        <Skeleton
          variant="rounded"
          width={isMobile ? '100%' : 120}
          height={50}
        />
      </Stack>
    </Card>
  );
};

export default ProfileCardSkeleton;
