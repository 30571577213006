import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { styled, Box, Button, IconButton, Stack, Tooltip } from '@mui/material';

import FullLogo from '../../../../../assets/images/fullLogo.png';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const DrawerHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface HeaderProps {
  open: boolean;
  handleDrawerClose: () => void;
  logoSrc: string;
  isMobile: boolean;
}

const Header: React.FC<HeaderProps> = ({
  open,
  handleDrawerClose,
  isMobile,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['sidebar']);

  return (
    <DrawerHeaderWrapper
      sx={{
        marginBottom: open ? '20px' : '-16px',
      }}
    >
      <Stack>
        <Stack
          alignItems={isMobile ? 'start' : 'end'}
          mt={6}
          sx={{ ...(!open && { display: 'none' }) }}
        >
          <IconButton
            aria-label="close drawer"
            onClick={handleDrawerClose}
            edge="start"
          >
            <Tooltip title={t('HEADER.BUTTON_REDUCE')}>
              <ChevronLeftIcon />
            </Tooltip>
          </IconButton>
        </Stack>
        <Stack justifyContent="center" spacing={2} mb={8} mt={isMobile ? 4 : 0}>
          <Box
            component={Link}
            to={'/'}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: '70%',
                ...(!open && { display: 'none' }),
              }}
              alt="RaiseMyFunds logo"
              src={FullLogo}
            />
          </Box>
          <Button
            variant="contained"
            onClick={() => {
              navigate('/buy-challenge');
              isMobile && handleDrawerClose();
            }}
            sx={{
              ...(!open && { display: 'none' }),
            }}
          >
            {t('HEADER.BUTTON_NEW_CHALLENGE')}
          </Button>
        </Stack>
      </Stack>
    </DrawerHeaderWrapper>
  );
};

export default Header;
