import React from 'react';

import { Card, Skeleton, Stack } from '@mui/material';

import classes from './rules.module.scss';

interface RulesSkeletonProps {
  elevation: number;
}

const RulesSkeleton: React.FC<RulesSkeletonProps> = ({ elevation }) => {
  return (
    <Card elevation={elevation} className={classes.rules}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={4}
      >
        <Skeleton variant="text" width={200} height={50} />
        <Skeleton variant="rounded" width={100} height={30} />
      </Stack>
      <Stack gap={2}>
        <Skeleton variant="rounded" width="100%" height={110} />
        <Skeleton variant="rounded" width="100%" height={110} />
        <Skeleton variant="rounded" width="100%" height={110} />
        <Skeleton variant="rounded" width="100%" height={110} />
      </Stack>
    </Card>
  );
};

export default RulesSkeleton;
