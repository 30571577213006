import { useState } from 'react';

import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';

import classes from './_select.module.scss';

interface SelectProps {
  id: string;
  options: string[];
  defaultValue?: string;
  onSelect: (selectedOption: string) => void;
}

const Select: React.FC<SelectProps> = ({
  id,
  options,
  onSelect,
  defaultValue,
}) => {
  const { isDarkMode } = useThemeContext();

  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined,
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);
    onSelect(newSelectedOption);
  };

  return (
    <select
      className={`${classes.select} ${
        isDarkMode ? classes.dark : classes.light
      }`}
      id={id}
      value={selectedOption}
      onChange={handleChange}
      defaultValue={defaultValue}
    >
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default Select;
