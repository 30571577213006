import React, { useState, useEffect } from 'react';
import { useSummary } from '../../context/Summary/SummaryContext';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import DefineWalletService from '../../services/put/wallet/putWallet';
import {
  Button,
  Card,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Snackbar,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';
import WalletCardSkeleton from './WalletCardSkeleton';

const WalletCard = () => {
  const { t } = useTranslation('partnerArea');
  const { dashboardData, loading } = useSummary();
  const { tokens, user } = useAuth();
  const { isBetweenXSAndSM, isSM } = useBreakpoints();

  const [walletAddress, setWalletAddress] = useState<string>('');
  const [network, setNetwork] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>(
    'success',
  );

  useEffect(() => {
    if (dashboardData) {
      setWalletAddress(dashboardData.cryptoWalletAddress || '');
      setNetwork(dashboardData.network || '');
    }
  }, [dashboardData]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleUpdate = async () => {
    if (!walletAddress || !network) {
      setError(t('CRYPTO_WALLET_CARD.ERROR_BOTH_FIELDS_ARE_REQUIRED'));
      return;
    }

    setError(null);

    if (tokens && user) {
      try {
        await DefineWalletService.defineWallet(
          walletAddress,
          network,
          user.userId,
          tokens.accessToken,
          (message) => {
            setSnackbarType('success');
            setSnackbarMessage(message);
            setSnackbarOpen(true);
          },
          (message) => {
            setSnackbarType('error');
            setSnackbarMessage(message);
            setSnackbarOpen(true);
          },
        );
      } catch (error) {
        console.error('Error updating wallet', error);
      }
    }
  };

  if (loading) {
    return <WalletCardSkeleton />;
  } else if (!dashboardData) {
    return null;
  }

  return (
    <Card
      elevation={0}
      style={{ width: isBetweenXSAndSM ? '100%' : '50%', padding: '16px' }}
    >
      <Typography variant="h5" component="h6" mb={4}>
        {t('CRYPTO_WALLET_CARD.TITLE_CRYPTO_WALLET')}
      </Typography>

      <Stack
        direction={isBetweenXSAndSM ? 'column' : 'row'}
        spacing={2}
        justifyContent={'space-between'}
      >
        <TextField
          fullWidth
          id="crypto-wallet-address"
          label={t('CRYPTO_WALLET_CARD.LABEL_CRYPTO_WALLET_ADDRESS')}
          placeholder={t(
            'CRYPTO_WALLET_CARD.PLACEHOLDER_CRYPTO_WALLET_ADDRESS',
          )}
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
          variant="outlined"
          error={!!error && !walletAddress}
          helperText={!!error && !walletAddress ? error : ''}
        />
        <TextField
          select
          fullWidth
          id="network"
          label={t('CRYPTO_WALLET_CARD.LABEL_NETWORK')}
          value={network}
          onChange={(e) => setNetwork(e.target.value)}
          placeholder={t('CRYPTO_WALLET_CARD.PLACEHOLDER_SELECT_A_NETWORK')}
          error={!!error && !network}
          helperText={!!error && !network ? error : ''}
        >
          <MenuItem
            value="USDT TRC-20"
            sx={{ display: 'flex', alignItems: 'center', padding: 1 }}
          >
            <img
              src="https://i.postimg.cc/wqRKh8wB/Tether-USDT.png"
              alt="USDT TRC-20"
              style={{
                width: 24,
                height: 24,
                marginRight: 8,
                verticalAlign: 'middle',
              }}
            />
            <span>USDT TRC-20</span>
          </MenuItem>

          <MenuItem
            value="USDT ERC-20"
            sx={{ display: 'flex', alignItems: 'center', padding: 1 }}
          >
            <img
              src="https://i.postimg.cc/y8M9YZ6x/USDT-ERC20.png"
              alt="USDT ERC-20"
              style={{
                width: 24,
                height: 24,
                marginRight: 8,
                verticalAlign: 'middle',
              }}
            />
            <span>USDT ERC-20</span>
          </MenuItem>
        </TextField>
      </Stack>

      <Button
        variant="contained"
        fullWidth
        style={{ marginTop: '24px' }}
        onClick={handleUpdate}
      >
        {t('CRYPTO_WALLET_CARD.BUTTON_UPDATE')}
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Card>
  );
};

export default WalletCard;
