import React, { useEffect, useState } from 'react';

import { red, green } from '../../../../themes/index';

import { hexToRgba } from '../../../../utils/hexConverter';

import { Button, Paper, Stack, Tooltip, Typography } from '@mui/material';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import classes from './headerCard.module.scss';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';
import HeaderCardSkeleton from './HeaderCardSkeleton';

interface HeaderCardProps {
  icon: React.ReactNode;
  iconColor: string;
  withdrawButton?: boolean;
  value: number | string;
  walletVariation?: string;
  title: string;
  isLoading: boolean;
  onClick?: () => void;
}

const HeaderCard: React.FC<HeaderCardProps> = ({
  icon,
  iconColor,
  withdrawButton,
  value,
  walletVariation,
  title,
  isLoading,
  onClick,
}) => {
  const { profile } = useAuth();
  const { t } = useTranslation('area');

  const bgOpacity = hexToRgba(iconColor, 0.5);

  const iconStyle = {
    backgroundColor: bgOpacity,
    border: `2px solid ${iconColor}`,
  };

  if (isLoading) {
    return <HeaderCardSkeleton />;
  }

  return (
    <Paper elevation={0} className={classes.card}>
      <Stack
        className={classes.header}
        justifyContent="space-between"
        direction="row"
      >
        <Stack className={classes.icon} style={iconStyle} mb={2}>
          {icon}
        </Stack>
        {withdrawButton &&
          !isLoading &&
          (!profile.isKYCValidated ? (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              placement="top"
              title={t('HEADER_CARDS.TOOLTIP_YOU_MUST_VALIDATE_YOUR_KYC')}
            >
              <span>
                <Button
                  variant="contained"
                  onClick={onClick}
                  disabled={!profile.isKYCValidated}
                >
                  {t('HEADER_CARDS.BUTTON_WITHDRAW')}
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              variant="contained"
              onClick={onClick}
              disabled={!profile.isKYCValidated}
            >
              {t('HEADER_CARDS.BUTTON_WITHDRAW')}
            </Button>
          ))}
      </Stack>
      <Stack direction="row" gap={2}>
        <Typography variant="h4" component="h4" fontWeight={'bold'}>
          {value}
        </Typography>
        <Typography
          variant="h6"
          component="h4"
          fontWeight="bold"
          color={
            walletVariation?.startsWith('-')
              ? red[400]
              : walletVariation?.startsWith('+')
              ? green[400]
              : 'inherit'
          }
        >
          <Stack direction="row" alignItems="center" gap={1}>
            {walletVariation ? (
              walletVariation?.startsWith('-') ? (
                <TrendingDownIcon sx={{ color: red[400] }} />
              ) : walletVariation?.startsWith('+') ? (
                <TrendingUpIcon sx={{ color: green[400] }} />
              ) : null
            ) : null}
            {walletVariation}
          </Stack>
        </Typography>
      </Stack>

      <Typography variant="h5" component="h5">
        {title}
      </Typography>
    </Paper>
  );
};

export default HeaderCard;
