import axiosInstance from '../../../../../utils/axios';
import { IAccountCredentialsService } from './interfaces';
import { handleErrorFetchingCredentials } from './errors';
import { MetatraderCreationInfoDTO } from './models';

class AccountCredentialsService implements IAccountCredentialsService {
  async fetchAccountCredentials(
    accountLogin: string,
    accessToken: string,
  ): Promise<MetatraderCreationInfoDTO> {
    try {
      const response = await axiosInstance.get<MetatraderCreationInfoDTO>(
        `/v2/funded/account/${accountLogin}/credentials`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      handleErrorFetchingCredentials(error);
      throw error;
    }
  }
}

export default new AccountCredentialsService();
