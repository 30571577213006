import React from 'react';
import HeaderCard from '../../../../../features/shared/components/HeaderCard/HeaderCard';
import { Stack, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { green } from '../../../../../themes/colors/colors';
import { PostDiscordActivityResponse } from '../../services/post/interfaces'; // Interface de la réponse

interface HeaderCardsProps {
  activityData: PostDiscordActivityResponse[]; // Les données postées d'activité
  isLoading: boolean;
}

const HeaderCards: React.FC<HeaderCardsProps> = ({
  activityData,
  isLoading,
}) => {
  const theme = useTheme();

  if (activityData.length === 0) {
    return null; // Ne rien afficher si aucune donnée
  }

  const {
    averageHoursWorkedPerDays,
    averageHoursBreakPerDays,
    totalWorkingHours,
    averageArrivalTime,
    averageDepartTime,
  } = activityData[0];

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      mt={6}
      sx={{ rowGap: 2, columnGap: 4 }}
    >
      <HeaderCard
        icon={<InfoIcon sx={{ fill: '#ff6384' }} />}
        iconColor={'#ff6384'}
        value={
          isLoading
            ? 'Chargement...'
            : `${averageHoursWorkedPerDays.hours}h ${averageHoursWorkedPerDays.minutes}m`
        }
        title={'Heure/Jour (sans pause déjeuner)'}
        isLoading={isLoading}
      />

      <HeaderCard
        icon={<InfoIcon sx={{ fill: '#ffce56' }} />}
        iconColor={'#ffce56'}
        value={
          isLoading
            ? 'Chargement...'
            : `${averageHoursBreakPerDays.hours}h ${averageHoursBreakPerDays.minutes}m`
        }
        title={'Pause moyenne/Jour'}
        isLoading={isLoading}
      />

      <HeaderCard
        icon={<InfoIcon sx={{ fill: '#9966ff' }} />}
        iconColor={'#9966ff'}
        value={
          isLoading
            ? 'Chargement...'
            : `${totalWorkingHours.hours}h ${totalWorkingHours.minutes}m`
        }
        title={'Total des heures sur la période'}
        isLoading={isLoading}
      />
      <HeaderCard
        icon={<InfoIcon sx={{ fill: green[400] }} />}
        iconColor={green[400]}
        value={isLoading ? 'Chargement...' : averageArrivalTime}
        title={"Heure moyenne d'entrée"}
        isLoading={isLoading}
      />
      <HeaderCard
        icon={<InfoIcon sx={{ fill: theme.palette.primary.main }} />}
        iconColor={theme.palette.primary.main}
        value={isLoading ? 'Chargement...' : averageDepartTime}
        title={'Heure moyenne de départ'}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default HeaderCards;
