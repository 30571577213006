import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLogin } from './hooks/useLogin';
import SnackbarMessage from './components/SnackbarMessage/SnackbarMessage';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import LogoRMF from '../../../assets/images/fullLogo.png';
import LoginForm from './components/LoginForm/LoginForm';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const Login = () => {
  const { t } = useTranslation('login');
  const {
    email,
    setEmail,
    password,
    setPassword,
    rememberMe,
    setRememberMe,
    emailError,
    passwordError,
    isSubmitting,
    snackbarMessage,
    handleLogin,
  } = useLogin();

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  useDocumentTitle('Log in');

  useEffect(() => {
    if (snackbarMessage) {
      setSnackbarOpen(true);
    }
  }, [snackbarMessage]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Empêche le rechargement de la page
    handleLogin(); // Appelle la fonction de connexion
  };

  return (
    <Stack
      maxHeight="100vh"
      height={'85vh'}
      alignItems="center"
      justifyContent="center"
      spacing={4}
      sx={{ width: '100%', maxWidth: 400, mx: 'auto' }}
    >
      <Box
        component="img"
        src={LogoRMF}
        alt="Logo RaiseMyFunds"
        sx={{
          width: '100%',
          maxWidth: '200px',
          height: 'auto',
        }}
      />

      <Stack textAlign={'center'}>
        <Typography variant="h4" component={'h1'} fontWeight={'bold'}>
          {t('TITLE_WELCOME')}
        </Typography>
        <Typography variant="h5" component={'h2'}>
          {t('TITLE_LOG_IN_TO_YOUR_ACCOUNT')}
        </Typography>
      </Stack>

      <Stack width={'100%'}>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <LoginForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            rememberMe={rememberMe}
            setRememberMe={setRememberMe}
            emailError={emailError}
            passwordError={passwordError}
          />

          <Button
            type="submit" // Définir le type du bouton sur submit
            variant="contained"
            fullWidth
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : t('BUTTON_LOG_IN')}
          </Button>
        </form>
      </Stack>

      <Stack mt={6} alignItems="center">
        <Typography variant="body1" component={'p'}>
          {t('TEXT_YOU_DONT_HAVE_ACCOUNT')}
        </Typography>
        <Typography
          variant="body1"
          component={Link}
          to={'/buy-challenge'}
          fontWeight={'bold'}
          sx={{ textDecoration: 'underline', color: 'inherit' }}
        >
          {t('LINK_BUY_A_CHALLENGE')}
        </Typography>
      </Stack>

      <SnackbarMessage
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </Stack>
  );
};

export default Login;
