import React from 'react';
import HeaderCard from '../../../../features/shared/components/HeaderCard/HeaderCard';
import { Box, CircularProgress, Stack, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useLightDashboard } from '../../context/LightDashboardContext/LightDashboardContext';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import { useTranslation } from 'react-i18next';

const HeaderCards = () => {
  const theme = useTheme();
  const { data, isLoading, error } = useLightDashboard();
  const { t } = useTranslation('lightDashboard');

  if (!data && !isLoading) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Stack direction="row" flexWrap="wrap" gap={2} sx={{ height: '100%' }}>
        <Stack sx={{ flex: '1 1 calc(50% - 8px)' }}>
          <HeaderCard
            icon={<Filter1Icon sx={{ fill: '#ff6384' }} />}
            iconColor={'#ff6384'}
            value={data?.totalPhase1.toLocaleString('fr-FR') ?? 0}
            title={t('HEADER_CARDS.TOTAL_PHASE_1')}
            isLoading={isLoading}
          />
        </Stack>

        <Stack sx={{ flex: '1 1 calc(50% - 8px)' }}>
          <HeaderCard
            icon={<Filter2Icon sx={{ fill: '#ffce56' }} />}
            iconColor={'#ffce56'}
            value={data?.totalPhase2.toLocaleString('fr-FR') ?? 0}
            title={t('HEADER_CARDS.TOTAL_PHASE_2')}
            isLoading={isLoading}
          />
        </Stack>

        <Stack sx={{ flex: '1 1 calc(50% - 8px)' }}>
          <HeaderCard
            icon={<AccountBalanceIcon sx={{ fill: '#9966ff' }} />}
            iconColor={'#9966ff'}
            value={data?.totalFunded.toLocaleString('fr-FR') ?? 0}
            title={t('HEADER_CARDS.TOTAL_FUNDED')}
            isLoading={isLoading}
          />
        </Stack>

        <Stack sx={{ flex: '1 1 calc(50% - 8px)' }}>
          <HeaderCard
            icon={<PaymentIcon sx={{ fill: theme.palette.primary.main }} />}
            iconColor={theme.palette.primary.main}
            value={data?.pendingPayments.toLocaleString('fr-FR') ?? 0}
            title={t('HEADER_CARDS.PENDING_PAYMENTS')}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default HeaderCards;
