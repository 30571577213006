import React, { useState } from 'react';
import { useCertificates } from './hooks/useCertificate';
import CertificateTabs from './components/CertificateTabs';
import {
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import classes from './styles/certificate.module.scss';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const Certificate = () => {
  const { t } = useTranslation('certificate');
  const [value, setValue] = useState(0);
  const [anonymous, setAnonymous] = useState(false);

  // Utilisation du loading provenant du hook
  const { certificates, loading } = useCertificates(anonymous);

  useDocumentTitle('Certificate');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnonymous(event.target.checked);
  };

  const getFilteredCertificates = () => {
    switch (value) {
      case 1:
        return certificates.filter(
          (cert: any) => cert.certificateType.toUpperCase() === 'PAYOUT',
        );
      case 2:
        return certificates.filter(
          (cert: any) => cert.certificateType.toUpperCase() === 'FUNDED',
        );
      default:
        return certificates;
    }
  };

  return (
    <>
      <Paper className={classes.banner}>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <SchoolIcon sx={{ fontSize: '50px' }} />
          <Typography gutterBottom mt={4} mb={4} variant="h6" component={'h1'}>
            {t('YOU_DO_NOT_HAVE_ANY_DIPLOMA_AT_THE_MOMENT')}
          </Typography>
          <Button variant="contained">
            {t('START_A_NEW_CHALLENGE_BUTTON')}
          </Button>
        </Stack>
      </Paper>

      <Tabs value={value} onChange={handleChange} variant="fullWidth">
        <Tab label={t('TOTAL_TAB_TITLE')} />
        <Tab label={t('PAYOUT_TAB_TITLE')} />
        <Tab label={t('FUNDED_TAB_TITLE')} />
      </Tabs>

      <Box sx={{ p: 3 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={anonymous} onChange={handleCheckboxChange} />
            }
            label={t('HIDE_LASTNAME_BUTTON')}
            sx={{ marginBottom: 3 }}
          />
        </FormGroup>
        {/* Passage de l'état loading à CertificateTabs */}
        <CertificateTabs
          certificates={getFilteredCertificates()}
          loading={loading}
        />
      </Box>
    </>
  );
};

export default Certificate;
