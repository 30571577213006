import React from 'react';
import {
  Button,
  List,
  ListItem,
  IconButton,
  Card,
  CardMedia,
  Box,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

interface FileUploadProps {
  files: File[];
  handleFileChange: (files: File[]) => void;
  handleFileRemove: (fileName: string) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({
  files,
  handleFileChange,
  handleFileRemove,
}) => {
  const { t } = useTranslation('support');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files || []);
    handleFileChange(newFiles);
    e.target.value = '';
  };

  const isImage = (file: File) => file.type.startsWith('image/');

  return (
    <>
      <Button variant="outlined" component="label" fullWidth>
        {t('BUTTON_ADD_FILES')}
        <input type="file" hidden multiple onChange={handleInputChange} />
      </Button>

      {files.length > 0 && (
        <List
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            overflowX: 'auto',
          }}
        >
          {files.map((file) => (
            <ListItem key={file.name} sx={{ position: 'relative', padding: 0 }}>
              {isImage(file) ? (
                <Card
                  sx={{
                    width: 100,
                    height: 100,
                    position: 'relative',
                    marginRight: 2,
                  }}
                >
                  <CardMedia
                    component="img"
                    image={URL.createObjectURL(file)}
                    title={file.name}
                    sx={{ objectFit: 'contain', height: '100%', width: '100%' }}
                  />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      borderRadius: '50%',
                    }}
                    size="large"
                    onClick={() => handleFileRemove(file.name)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Card>
              ) : (
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography>{file.name}</Typography>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      borderRadius: '50%',
                    }}
                    size="large"
                    onClick={() => handleFileRemove(file.name)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default FileUpload;
