import React from 'react';

import classes from './statsCard.module.scss';
import { Card, Skeleton, Stack } from '@mui/material';

interface StatsCardSkeletonProps {
  elevation: number;
}

const StatsCardSkeleton: React.FC<StatsCardSkeletonProps> = ({ elevation }) => {
  return (
    <Stack direction={'row'} gap={2}>
      <Card
        elevation={elevation}
        sx={{
          width: '100%',
          height: '150px',
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Card>
      <Card
        elevation={elevation}
        sx={{
          width: '100%',
          height: '150px',
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Card>
      <Card
        elevation={elevation}
        sx={{
          width: '100%',
          height: '150px',
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Card>
      <Card
        elevation={elevation}
        sx={{
          width: '100%',
          height: '150px',
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Card>
    </Stack>
  );
};

export default StatsCardSkeleton;
