import React from 'react';

import { Snackbar, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

interface SnackbarMessageProps {
  open: boolean;
  message: string;
  handleClose: () => void;
}

const SnackbarMessage: React.FC<SnackbarMessageProps> = ({
  open,
  message,
  handleClose,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      message={message}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default SnackbarMessage;
