// errors.ts
import { AxiosError } from 'axios';

export const handleApiError = (error: AxiosError) => {
  if (error.response) {
    const { status, data } = error.response;

    // Cast 'data' as having an 'error' field of type 'string'
    const errorMessage = (data as { error: string }).error;

    switch (status) {
      case 401:
        console.error('Unauthorized:', errorMessage);
        break;
      case 404:
        console.error('User not found:', errorMessage);
        break;
      case 500:
        console.error('Internal Server Error:', errorMessage);
        break;
      default:
        console.error('An unexpected error occurred:', errorMessage);
    }
  } else {
    console.error('Network Error:', error.message);
  }
};
