import React from 'react';

import { Card, Skeleton, Stack } from '@mui/material';

const CommissionsCardSkeleton = () => {
  return (
    <Card elevation={1} style={{ padding: '16px', flexGrow: '1' }}>
      <Skeleton variant="rectangular" height={30} width={250} />
      <Stack mt={4} spacing={4}>
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={30} width={350} />
          <Skeleton variant="rectangular" height={40} width={200} />
        </Stack>
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={30} width={350} />
          <Skeleton variant="rectangular" height={40} width={200} />
        </Stack>
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={30} width={350} />
          <Skeleton variant="rectangular" height={40} width={200} />
        </Stack>
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={30} width={350} />
          <Skeleton variant="rectangular" height={40} width={200} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default CommissionsCardSkeleton;
