import axiosInstance from '../../../../../utils/axios';
import { DashboardSummaryDTO } from './models';

class GetDashboardSummaryService {
  async getDashboardSummary(token: string): Promise<DashboardSummaryDTO> {
    try {
      const response = await axiosInstance.get<DashboardSummaryDTO>(
        '/v2/affiliations/dashboard-summary',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch dashboard summary data');
    }
  }
}

export default new GetDashboardSummaryService();
