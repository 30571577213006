import axiosInstance from '../../../../../utils/axios';
import { ChallengeAccountChart } from './models';
import { ApiError } from './errors';

const getAccountBaseData = async (
  accountLogin: string,
  token: string,
  period: number = 1,
): Promise<ChallengeAccountChart> => {
  try {
    const response = await axiosInstance.get<ChallengeAccountChart>(
      `/v2/trading-account/account/${accountLogin}/chart`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          period,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 500) {
      throw new ApiError(error.response.data.error);
    } else {
      throw new ApiError('An unexpected error occurred');
    }
  }
};

export default getAccountBaseData;
