import { toast } from 'react-toastify';

import axiosInstance from '../../../utils/axios';

import {
  AffiliationPayoutRequestResponse,
  CreationErrorsResponse,
  PaymentErrorsResponse,
  PayoutRequestsResponse,
  WithdrawRequestResponse,
} from './interface';

export const fetchWithdrawRequests = async (
  accessToken: string,
  page: number,
  size: number,
  type: string,
  filters: { [key: string]: string },
): Promise<WithdrawRequestResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<WithdrawRequestResponse>(
      `/withdraw/pending/${type}?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des withdraw requests');
  }
};

export const fetchAffiliationPayoutRequests = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<AffiliationPayoutRequestResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<AffiliationPayoutRequestResponse>(
      `/affiliations/payout-requests/pending?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    const canExecuteHeader = response.headers['x-can-execute'];

    return {
      ...response.data,
      canExecuteHeader,
    };
  } catch (error) {
    throw new Error(
      'Erreur lors de la récupération des affiliations payouts requests',
    );
  }
};

export const fetchReferralPayoutRequest = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<AffiliationPayoutRequestResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<AffiliationPayoutRequestResponse>(
      `/affiliations/payout-requests/pending?referral=true&${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error(
      'Erreur lors de la récupération des referral payouts requests',
    );
  }
};

export const executePayoutRequest = async (accessToken: string, id: number) => {
  try {
    const response = await axiosInstance.put(
      `/affiliations/payout-requests/${id}/execute`,
      {
        headers: { Authorization: accessToken },
      },
    );
    if (response.status === 200) {
      toast.success(`Payout for ${response.data}USDT successfully proceed.`, {
        progressStyle: { backgroundColor: 'green' },
      });
    }
    return response.data;
  } catch (error: any) {
    if (error.response.status === 400) {
      toast.error('Element not found.', {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 500) {
      toast.error('An unrecognized error has occurred', {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 405) {
      toast.error(error.response.data, {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  }
};

export const exportAffiliationPayoutToXLS = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<void> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    params.append('export', 'true');

    const response = await axiosInstance.get(
      '/affiliations/payout-requests/pending',
      {
        params: params,
        headers: { Authorization: accessToken },
        responseType: 'blob',
      },
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(
      "Erreur lors de l'exportation des affiliations payout",
      error,
    );
  }
};

export const fetchPaymentErrors = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<PaymentErrorsResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<PaymentErrorsResponse>(
      `/payments/failed?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des payments errors');
  }
};

export const fetchCreationErrors = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<CreationErrorsResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<CreationErrorsResponse>(
      `/payments/creation-failed?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des creation errors');
  }
};

export const acceptWithdrawRequest = async (
  accessToken: string,
  id: number,
  type: string,
) => {
  try {
    const response = await axiosInstance.post(
      `/withdraw/${type}/execute-payout/${id}`,
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );

    if (response.status === 200) {
      toast.success('Le requête a été acceptée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error: any) {
    if (error.response.status === 401) {
      toast.info("You don't have the permission.", {
        progressStyle: { backgroundColor: 'cornflowerblue' },
      });
    } else {
      toast.error("Le requête n'a pas pu être acceptée", {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  }
};
export const refuseWithdrawRequest = async (
  accessToken: string,
  id: number,
) => {
  try {
    const response = await axiosInstance.put(`/withdraw/reject/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.status === 200) {
      toast.success('Le requête a été rejetée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le requête n'a pas pu être rejetée", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const acceptAffiliationPayoutRequest = async (
  accessToken: string,
  id: number,
) => {
  try {
    const response = await axiosInstance.put(
      `/affiliations/payout-requests/${id}/approve`,
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );
    if (response.status === 200) {
      toast.success('Le requête a été acceptée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le requête n'a pas pu être acceptée", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};
export const refuseAffiliationPayoutRequest = async (
  accessToken: string,
  id: number,
) => {
  try {
    const response = await axiosInstance.put(
      `/affiliations/payout-requests/${id}/reject`,
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );
    if (response.status === 200) {
      toast.success('Le requête a été rejetée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le requête n'a pas pu être rejetée", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const resolvePaymentError = async (accessToken: string, id: number) => {
  try {
    const response = await axiosInstance.patch(`/payments/${id}/resolve`, {
      headers: {
        Authorization: accessToken,
      },
    });
    if (response.status === 200) {
      toast.success("L'erreur a été résolue.", {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("L'erreur n'a pas pu être résolue.", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const fetchGetPayoutRequests = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<PayoutRequestsResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<PayoutRequestsResponse>(
      `/payout/request?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des payouts requests');
  }
};

export const acceptTradingPayoutRequest = async (
  accessToken: string,
  id: number,
) => {
  try {
    const response = await axiosInstance.post(
      `/payout/accept/${id}`,
      {},
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );
    if (response.status === 200) {
      toast.success('Le requête a été acceptée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error: any) {
    if (error.response && error.response.status === 500) {
      toast.error("Ce compte n'est pas éligible", {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 400) {
      const errorMessage = error.response.data;
      toast.error(`Erreur 400: ${errorMessage}`, {
        progressStyle: { backgroundColor: 'red' },
      });
    } else if (error.response.status === 406) {
      const maxProfitValue = error.response.data.maxProfitValue;
      const maxProfitDay = error.response.data.maxProfitDay;
      const minimumPayoutValue = error.response.data.minimumPayoutValue;
      const currentPercentage = error.response.data.currentPercentage;
      toast.error(
        <div>
          Gain max : {maxProfitValue} le {maxProfitDay}
          <br />
          Payout min : {minimumPayoutValue}
          <br />
          Max Daily Performance : {currentPercentage}%
        </div>,
        {
          progressStyle: { backgroundColor: 'red' },
        },
      );
    }
  }
};

export const refuseTradingPayoutRequest = async (
  accessToken: string,
  id: number,
) => {
  try {
    const response = await axiosInstance.post(`payout/reject/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });
    if (response.status === 200) {
      toast.success('Le requête a été rejetée', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le requête n'a pas pu être rejetée", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};
