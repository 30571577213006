import React from 'react';
import { Stack } from '@mui/material';
import PieChart from '../../layouts/PieChart/PieChart';
import Chart from '../../layouts/Chart/Chart';
import SalesCards from '../../layouts/SalesCards/SalesCards';
import BasicsCard from '../../layouts/BasicsCards/BasicsCard';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';

const Dashboard = () => {
  const { isBetweenXSAndLG } = useBreakpoints();

  useDocumentTitle('Dashboard');

  return (
    <>
      <BasicsCard />
      <Stack
        direction={isBetweenXSAndLG ? 'column' : 'row'}
        gap={2}
        mt={6}
        mb={6}
      >
        <PieChart />
        <SalesCards />
      </Stack>
      <Chart />
    </>
  );
};

export default Dashboard;
