import { createContext, useContext } from 'react';

import { DashboardSummaryDTO } from '../../services/get/summary/models';

interface SummaryContextProps {
  dashboardData: DashboardSummaryDTO | null;
  loading: boolean;
  error: string | null;
}

// Créer le contexte
export const SummaryContext = createContext<SummaryContextProps | undefined>(
  undefined,
);

// Le hook pour utiliser le contexte
export const useSummary = (): SummaryContextProps => {
  const context = useContext(SummaryContext);
  if (!context) {
    throw new Error(
      'useDashboardSummary must be used within a DashboardSummaryProvider',
    );
  }
  return context;
};
