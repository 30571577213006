import { Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const ProfileFormSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack>
      <Stack direction={isMobile ? 'column' : 'row'} gap={6} mb={6}>
        <Skeleton variant="rounded" width={'100%'} height={50} />
        <Skeleton variant="rounded" width={'100%'} height={50} />
      </Stack>
      <Stack direction={isMobile ? 'column' : 'row'} gap={6} mb={6}>
        <Skeleton variant="rounded" width={'100%'} height={50} />
        <Skeleton variant="rounded" width={'100%'} height={50} />
      </Stack>
      <Stack direction={isMobile ? 'column' : 'row'} gap={6} mb={6}>
        <Skeleton variant="rounded" width={'100%'} height={50} />
        <Skeleton variant="rounded" width={'100%'} height={50} />
      </Stack>
      <Stack direction={isMobile ? 'column' : 'row'} gap={6} mb={6}>
        <Skeleton variant="rounded" width={'100%'} height={50} />
        <Skeleton variant="rounded" width={'100%'} height={50} />
      </Stack>
      <Stack direction={isMobile ? 'column' : 'row'} gap={6} mb={6}>
        <Skeleton variant="rounded" width={'100%'} height={50} />
        <Skeleton variant="rounded" width={'100%'} height={50} />
      </Stack>
    </Stack>
  );
};

export default ProfileFormSkeleton;
