import useBreakpoints from '../../../hooks/useBreakpoints';
import { useDiscordPage } from './hooks/useDiscordPage';

import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import DiscordLogoImage from '../../../assets/images/discord/logo-discord.png';
import LogoRmfImage from '../../../assets/images/discord/logo-rmf.png';

import EastIcon from '@mui/icons-material/East';
import CircleIcon from '@mui/icons-material/Circle';
import PeopleIcon from '@mui/icons-material/People';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CampaignIcon from '@mui/icons-material/Campaign';
import BoltIcon from '@mui/icons-material/Bolt';

import classes from './styles/discord.module.scss';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import ProfileCardSkeleton from './components/ProfileCardSkeleton';

const Discord = () => {
  const { t } = useTranslation('discord');
  const { isBetweenXSAndLG, isBetweenXSAndSM } = useBreakpoints();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    profile,
    discordTag,
    error,
    openDialog,
    availableRoles,
    handleChange,
    handleSubmit,
    handleDialogClose,
    loading,
  } = useDiscordPage();

  useDocumentTitle('Discord');

  return (
    <Stack spacing={6}>
      <Paper elevation={0} className={classes.banner}>
        <img className={classes.logoRmf} src={LogoRmfImage} />
        <img className={classes.logoDiscord} src={DiscordLogoImage} />
        <Stack
          justifyContent={'center'}
          alignContent={'center'}
          alignItems={'center'}
          height={'100%'}
        >
          <Typography
            variant="h1"
            component="h1"
            color={'white'}
            fontWeight={'bold'}
            fontSize={'clamp(2.5rem, 4vw, 8vw)'}
            textAlign={'center'}
            textTransform={'uppercase'}
            zIndex={20}
            gutterBottom
          >
            {t('JOIN_OUR_COMMUNITY')}
          </Typography>
          <Button
            href="https://discord.gg/wuMMTSpax4"
            target="_blank"
            variant="outlined"
            size="large"
            endIcon={
              <EastIcon
                sx={{
                  transition: 'transform 0.3s ease',
                }}
              />
            }
            sx={{
              color: 'white',
              border: '2px solid white',
              borderRadius: '25px',
              '&:hover': {
                border: '2px solid white',
                backgroundColor: 'white',
                color: 'black',
                '& .MuiSvgIcon-root': {
                  transform: 'translateX(8px)',
                },
              },
            }}
          >
            {t('JOIN_BUTTON')}
          </Button>
        </Stack>
      </Paper>

      <Stack direction={isBetweenXSAndLG ? 'column' : 'row'} spacing={4}>
        {loading ? (
          <ProfileCardSkeleton />
        ) : (
          <Card
            elevation={0}
            sx={{
              padding: '16px',
              flex: 1,
            }}
          >
            <Typography variant="h4" component="h4" mb={4}>
              {profile?.fullName}
            </Typography>
            <Typography variant="h5" component="h5" gutterBottom>
              {t('ROLES_AVAILABLE_FOR_YOU')}
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={2}>
              {availableRoles.length > 0 ? (
                availableRoles.map((role) => (
                  <Chip
                    key={role.label}
                    icon={<CircleIcon />}
                    label={role.label}
                    sx={{
                      '& .MuiChip-icon': {
                        color: role.color,
                      },
                    }}
                  />
                ))
              ) : (
                <Typography>{t('YOU_DONT_HAVE_AVAILABLE_ROLES')}</Typography>
              )}
            </Stack>
            <Stack direction="row" spacing={2} mt={6}>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                gap={2}
                mt={6}
                width="100%"
              >
                <TextField
                  id="discord-username"
                  label={t('DISCORD_USERNAME')}
                  variant="outlined"
                  value={discordTag}
                  onChange={handleChange}
                  error={!!error}
                  helperText={error}
                  fullWidth={isMobile ? true : false}
                />
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ width: isMobile ? '100%' : 'auto' }}
                >
                  {t('CLAIM_YOUR_ROLE')}
                </Button>
              </Stack>

              <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>{t('ROLE_CLAIM')}</DialogTitle>
                <DialogContent>
                  <p>{t('ROLE_CLAIM_TEXT')}</p>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose} variant="text">
                    {t('UNDERSTOOD_BUTTON')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </Card>
        )}

        <Stack
          sx={{ width: isBetweenXSAndLG ? '100%' : '50%' }}
          direction={isBetweenXSAndSM ? 'column' : 'row'}
          spacing={isBetweenXSAndSM ? 2 : 0}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Stack
            spacing={2}
            sx={{ width: isBetweenXSAndSM ? '100%' : '49.3%' }}
          >
            <Card elevation={0} className={classes.cards}>
              <PeopleIcon color="primary" sx={{ fontSize: '64px' }} />
              <Typography variant="h5" component="h5" textAlign="center">
                1300+ {t('MEMBERS')}
              </Typography>
            </Card>
            <Card elevation={0} className={classes.cards}>
              <CampaignIcon color="primary" sx={{ fontSize: '64px' }} />
              <Typography variant="h5" component="h5" textAlign="center">
                {t('LIVE_ANNOUNCEMENT')}
              </Typography>
            </Card>
          </Stack>

          <Stack
            spacing={2}
            sx={{ width: isBetweenXSAndSM ? '100%' : '49.3%' }}
          >
            <Card elevation={0} className={classes.cards}>
              <EmojiEventsIcon color="primary" sx={{ fontSize: '64px' }} />
              <Typography variant="h5" component="h5" textAlign="center">
                {t('SHOW_SUCCESS')}
              </Typography>
            </Card>
            <Card elevation={0} className={classes.cards}>
              <BoltIcon color="primary" sx={{ fontSize: '64px' }} />
              <Typography variant="h5" component="h5" textAlign="center">
                {t('FLASH')}
              </Typography>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Discord;
