import React, { useState, createContext, useContext } from 'react';

interface TabProviderProps {
  children: React.ReactNode;
}

type ActiveTabsType = {
  [key: string]: number;
};

const TabContext = createContext<{
  activeTabs: ActiveTabsType;
  setActiveTab: (id: string, index: number) => void;
}>({
  activeTabs: {},
  setActiveTab: () => {},
});

export const TabProvider: React.FC<TabProviderProps> = ({ children }) => {
  const [activeTabs, setActiveTabs] = useState({});

  const setActiveTab = (id: string, index: number) => {
    setActiveTabs((prevTabs) => ({ ...prevTabs, [id]: index }));
  };

  return (
    <TabContext.Provider value={{ activeTabs, setActiveTab }}>
      {children}
    </TabContext.Provider>
  );
};

export const useTab = () => useContext(TabContext);
