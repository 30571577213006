import React from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { useAuth } from '../../../../../context/AuthContext/AuthContext';
import { ProfileInformations } from '../../services/put/profile/models';
import {
  Stack,
  TextField,
  Autocomplete,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ProfileFormProps {
  updatedProfile: ProfileInformations;
  handleInputChange: (field: keyof ProfileInformations, value: string) => void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  updatedProfile,
  handleInputChange,
}) => {
  const { t } = useTranslation('profile');
  const { getCode } = require('country-list');
  const { user } = useAuth();

  // Utilisation des breakpoints de Material UI pour gérer le responsive
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detecte les petits écrans

  return (
    <Stack>
      <Stack direction={isMobile ? 'column' : 'row'} gap={6} mb={6}>
        <TextField
          id="firstname"
          label={t('PROFILE.LABEL_FIRSTNAME')}
          variant="outlined"
          type="text"
          fullWidth
          value={updatedProfile.firstName}
          onChange={(e) => handleInputChange('firstName', e.target.value)}
        />
        <TextField
          id="lastname"
          label={t('PROFILE.LABEL_LASTNAME')}
          variant="outlined"
          type="text"
          fullWidth
          value={updatedProfile.lastName}
          onChange={(e) => handleInputChange('lastName', e.target.value)}
        />
      </Stack>

      <Stack direction={isMobile ? 'column' : 'row'} gap={6} mb={6}>
        <Autocomplete
          disablePortal
          options={[
            t('PROFILE.OPTION_SIR'),
            t('PROFILE.OPTION_MRS'),
            t('PROFILE.OPTION_NOT_SPECIFIED'),
          ]}
          sx={{ width: '100%' }}
          value={updatedProfile.title}
          onChange={(e, value) => handleInputChange('title', value || '')}
          renderInput={(params) => (
            <TextField
              {...params}
              id="Title"
              label={t('PROFILE.LABEL_TITLE')}
              fullWidth
            />
          )}
        />
        <TextField
          type="date"
          id="birth-date"
          label={t('PROFILE.LABEL_BIRTHDATE')}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={updatedProfile.birthdate}
          onChange={(e) => handleInputChange('birthdate', e.target.value)}
        />
      </Stack>

      <Stack direction={isMobile ? 'column' : 'row'} gap={6} mb={6}>
        <MuiTelInput
          id="phone-number"
          label={t('PROFILE.LABEL_PHONE_NUMBER')}
          fullWidth
          defaultCountry={getCode(updatedProfile.country) || null}
          value={updatedProfile.phoneNumber || ''}
          onChange={(value) => handleInputChange('phoneNumber', value)}
        />
        <TextField
          id="email"
          label={t('PROFILE.LABEL_EMAIL_ADDRESS')}
          variant="outlined"
          type="text"
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          value={user?.sub}
        />
      </Stack>

      <Stack direction={isMobile ? 'column' : 'row'} gap={6} mb={6}>
        <TextField
          id="country"
          label={t('PROFILE.LABEL_COUNTRY')}
          variant="outlined"
          type="text"
          fullWidth
          value={updatedProfile.country}
          onChange={(e) => handleInputChange('country', e.target.value)}
        />
        <TextField
          id="city"
          label={t('PROFILE.LABEL_CITY')}
          variant="outlined"
          type="text"
          fullWidth
          value={updatedProfile.city}
          onChange={(e) => handleInputChange('city', e.target.value)}
        />
      </Stack>

      <Stack direction={isMobile ? 'column' : 'row'} gap={6} mb={6}>
        <TextField
          id="street"
          label={t('PROFILE.LABEL_STREET')}
          variant="outlined"
          type="text"
          fullWidth
          value={updatedProfile.street}
          onChange={(e) => handleInputChange('street', e.target.value)}
        />
        <TextField
          id="zip-code"
          label={t('PROFILE.LABEL_ZIP_CODE')}
          variant="outlined"
          type="text"
          fullWidth
          value={updatedProfile.postalCode}
          onChange={(e) => handleInputChange('postalCode', e.target.value)}
        />
      </Stack>
    </Stack>
  );
};

export default ProfileForm;
