import React, { useState } from 'react';
import PDFViewer from '../../../../pages/Certificate/components/PDFViewer/PDFViewer';
import {
  Button,
  Modal,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import ContractPDF from '../../../../assets/images/certificate/Independant_Contractor.pdf';
import { useTranslation } from 'react-i18next';

interface ContractDialogProps {
  open: boolean;
  onClose: () => void;
  selectedAccountLogin: string;
}

const ContractDialog: React.FC<ContractDialogProps> = ({
  open,
  onClose,
  selectedAccountLogin,
}) => {
  const { t } = useTranslation('area');

  const [showContract, setShowContract] = useState(false);

  const handleOpenContract = () => {
    setShowContract(true);
  };

  const handleCloseContract = () => {
    setShowContract(false);
    onClose();
  };

  return (
    <>
      {!showContract && (
        <Dialog
          open={open && !showContract}
          onClose={onClose}
          aria-labelledby="contract-dialog-title"
          aria-describedby="contract-dialog-description"
        >
          <DialogTitle id="contract-dialog-title">
            {t('CHALLENGE_CARD.MODALS.CONTRACT.TITLE_SIGN_THE_CONTRACT')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="contract-dialog-description">
              {t('CHALLENGE_CARD.MODALS.CONTRACT.DESCRIPTION_CONTRACT')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              {t('CHALLENGE_CARD.MODALS.CONTRACT.BUTTON_CANCEL')}
            </Button>
            <Button onClick={handleOpenContract} autoFocus variant="contained">
              {t('CHALLENGE_CARD.MODALS.CONTRACT.BUTTON_SIGN_THE_CONTRACT')}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {showContract && (
        <Modal
          open={showContract}
          onClose={handleCloseContract}
          aria-labelledby="contract-modal-title"
          aria-describedby="contract-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(5px)',
          }}
        >
          <Box
            sx={{
              width: 'auto',
              height: '90%',
              overflow: 'auto',
              backgroundColor: 'transparent',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PDFViewer file={ContractPDF} accountUUID={selectedAccountLogin} />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ContractDialog;
