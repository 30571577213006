import axiosInstance from '../../../../../utils/axios';
import { IGetAccountPnLCalendarService } from './interfaces';
import { MonthProfitLossDTO } from './models';
import { handleError } from './errors';

class GetAccountPnLCalendarService implements IGetAccountPnLCalendarService {
  async getAccountPnLCalendar(
    accountLogin: string,
    year: number,
    month: number,
    token: string,
  ): Promise<MonthProfitLossDTO> {
    try {
      const response = await axiosInstance.get<MonthProfitLossDTO>(
        `/v2/trading-account/account/${accountLogin}/pnl-calendar`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          params: {
            year,
            month,
          },
        },
      );
      return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
}

export default new GetAccountPnLCalendarService();
