import axiosInstance from '../../../utils/axios';
import {
  UserResponse,
  UserCreation,
  AffiliateUserContent,
  AffiliateUserResponse,
} from './interface';
import { toast } from 'react-toastify';

export const fetchUsers = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<UserResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<UserResponse>(
      `/users?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des utilisateurs');
  }
};

export const exportUsersToXLS = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<void> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    params.append('export', 'true');

    const response = await axiosInstance.get('/users', {
      params: params,
      headers: { Authorization: accessToken },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Erreur lors de l'exportation des utilisateurs", error);
  }
};

export const fetchActiveClients = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<UserResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<UserResponse>(
      `/challenges/active?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des active clients');
  }
};

export const exportActiveUsersToXLS = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<void> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    params.append('export', 'true');

    const response = await axiosInstance.get('/challenges/active', {
      params: params,
      headers: { Authorization: accessToken },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Erreur lors de l'exportation des utilisateurs actif", error);
  }
};

export const fetchFundedClients = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<UserResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<UserResponse>(
      `/funded/all?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des funded clients');
  }
};

export const exportFundedUsersToXLS = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<void> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    params.append('export', 'true');

    const response = await axiosInstance.get('/funded/all', {
      params: params,
      headers: { Authorization: accessToken },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(
      "Erreur lors de l'exportation des utilisateurs funded",
      error,
    );
  }
};

export const createUser = async (
  accessToken: string,
  user: UserCreation,
  businessDevelopperId?: string,
) => {
  try {
    const response = await axiosInstance.post<UserCreation>(
      '/users?businessDevelopperId=' + businessDevelopperId,
      user,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    if (response.status === 201) {
      toast.success('User successfully created.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error('An error occurred creating the user.', {
      progressStyle: { backgroundColor: 'red' },
    });
    console.error('Error creating user:', error);
    throw new Error('An error occurred creating the user.');
  }
};

export const fetchAffiliateClients = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<AffiliateUserResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<AffiliateUserResponse>(
      `/affiliations/all?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );

    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des affiliates clients');
  }
};

export const exportAffiliateClientsToXLS = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
) => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    params.append('export', 'true');

    const response = await axiosInstance.get('/affiliations/all', {
      params: params,
      headers: { Authorization: accessToken },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Erreur lors de l'exportation des affiliate", error);
  }
};
