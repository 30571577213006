import React from 'react';
import { red, green } from '../../../../themes/index';
import { Card, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DayInfo {
  day: number;
  isProfit: boolean;
  totalProfitLoss: number; // Renommé pour correspondre à la structure de la réponse API
}

interface DaysInMonthCardsProps {
  year: number;
  month: number;
  daysInfo: DayInfo[];
  onDayClick: (date: string) => void;
}

const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
};

const getDayOfWeek = (year: number, month: number, day: number): number => {
  return new Date(year, month - 1, day).getDay();
};

const generateDaysArray = (
  year: number,
  month: number,
  daysInfo: DayInfo[],
): (DayInfo | number)[] => {
  const daysInMonth = getDaysInMonth(year, month);
  const daysArray: (DayInfo | number)[] = Array.from(
    { length: daysInMonth },
    (_, index) => index + 1,
  );

  daysInfo.forEach((info) => {
    if (info.day > 0 && info.day <= daysInMonth) {
      daysArray[info.day - 1] = info;
    }
  });

  return daysArray;
};

const DaysInMonthCards: React.FC<DaysInMonthCardsProps> = ({
  year,
  month,
  daysInfo,
  onDayClick,
}) => {
  const { t } = useTranslation('area');
  const daysArray = generateDaysArray(year, month, daysInfo);
  const daysOfWeek = [
    t('CALENDAR.SUNDAY'),
    t('CALENDAR.MONDAY'),
    t('CALENDAR.TUESDAY'),
    t('CALENDAR.WEDNESDAY'),
    t('CALENDAR.THURSDAY'),
    t('CALENDAR.FRIDAY'),
    t('CALENDAR.SATURDAY'),
  ];

  const firstDayOfWeek = getDayOfWeek(year, month, 1);

  const handleDayClick = (day: number, totalProfitLoss: number) => {
    if (totalProfitLoss !== 0) {
      const date = new Date(year, month - 1, day + 1)
        .toISOString()
        .split('T')[0];
      onDayClick(date);
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: 2,
        overflowX: 'scroll',
      }}
    >
      {daysOfWeek.map((dayOfWeek, index) => (
        <Box key={index} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          {dayOfWeek}
        </Box>
      ))}
      {daysArray.map((day, index) => {
        const dayOfWeek = getDayOfWeek(
          year,
          month,
          typeof day === 'number' ? day : day.day,
        );
        let backgroundColor = 'rgba(0, 0,0, 0)';
        let borderColor = '#697399';
        let textColor = 'black';
        let cursorStyle = 'default';
        if (typeof day === 'object') {
          if (day.totalProfitLoss > 0) {
            backgroundColor = 'rgba(31, 204, 146, 0.5)';
            borderColor = green[400];
            textColor = green[400];
            cursorStyle = 'pointer';
          } else if (day.totalProfitLoss < 0) {
            backgroundColor = 'rgba(231, 71, 80, 0.5)';
            borderColor = red[400];
            textColor = red[400];
            cursorStyle = 'pointer';
          }
        }

        return (
          <Box
            key={index}
            sx={{
              gridColumnStart: index === 0 ? firstDayOfWeek + 1 : 'auto',
              gridColumnEnd: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() =>
              typeof day === 'number'
                ? handleDayClick(day, 0)
                : handleDayClick(day.day, day.totalProfitLoss)
            }
          >
            <Card
              elevation={0}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                flexDirection: 'column',
                padding: '8px',
                height: '80px',
                width: '100%',
                backgroundColor: backgroundColor,
                border: `2px solid ${borderColor}`,
                cursor: cursorStyle,
              }}
            >
              <Typography variant="h5">
                {typeof day === 'number' ? day : day.day}
              </Typography>
              {typeof day === 'object' && day.totalProfitLoss !== 0 && (
                <Typography whiteSpace="nowrap" variant="h6" color={textColor}>
                  {day.totalProfitLoss > 0
                    ? `+ $${day.totalProfitLoss.toFixed(2)}`
                    : `- $${Math.abs(day.totalProfitLoss).toFixed(2)}`}
                </Typography>
              )}
            </Card>
          </Box>
        );
      })}
    </Box>
  );
};

export default DaysInMonthCards;
