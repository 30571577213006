import React from 'react';
import { Box } from '@mui/material';

interface PieChartTabsPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const PieChartTabsPanel: React.FC<PieChartTabsPanelProps> = ({
  children,
  value,
  index,
}) => {
  return (
    <div hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default PieChartTabsPanel;
