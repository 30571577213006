export const handleErrorPayout = (
  error: any,
  onError: (message: string) => void,
): void => {
  let errorMessage = 'Échec du payout.';

  if (error.response?.status === 403) {
    errorMessage = "Votre compte n'est pas éligible pour un payout.";
  } else if (error.response?.status === 409) {
    errorMessage = error.response.data;
  } else if (error.response?.status === 405) {
    errorMessage = 'Le KYC doit être validé pour effectuer un payout.';
  }

  onError(errorMessage);
  console.error(errorMessage, error);
};
