export class PhaseApiError extends Error {
  constructor(
    message: string,
    public statusCode: number,
  ) {
    super(message);
    this.name = 'PhaseApiError';
  }
}

export const handlePhaseApiError = (response: any) => {
  if (!response || !response.status) {
    throw new PhaseApiError('Unknown error', 500);
  }
  if (!response.ok) {
    throw new PhaseApiError(
      `Phase API error: ${response.statusText}`,
      response.status,
    );
  }
};
