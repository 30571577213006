import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { isCountryAuthorized } from '../../utils/isCountryAuthorized';

interface AccessControlContextType {
  accessGranted: boolean;
}

const initialContextValue: AccessControlContextType = {
  accessGranted: true,
};

const AccessControlContext =
  createContext<AccessControlContextType>(initialContextValue);

export const useAccessControl = () => useContext(AccessControlContext);

interface AccessControlProviderProps {
  children: ReactNode;
}

export const AccessControlProvider: React.FC<AccessControlProviderProps> = ({
  children,
}) => {
  const [accessGranted, setAccessGranted] = useState<boolean>(
    initialContextValue.accessGranted,
  );

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const authorized = await isCountryAuthorized(); // Appeler la fonction et attendre le résultat
        if (!authorized) {
          setAccessGranted(false);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la vérification de l'autorisation:",
          error,
        );
        setAccessGranted(false); // Gérer les erreurs en refusant l'accès
      }
    };

    checkAuthorization(); // Exécuter la fonction asynchrone
  }, []);

  return (
    <AccessControlContext.Provider value={{ accessGranted }}>
      {children}
    </AccessControlContext.Provider>
  );
};
