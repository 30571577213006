import axiosInstance from '../../../../../utils/axios';
import { handleErrorPayout } from './errors';
import { PayoutRequestDTO } from './models';

class PayoutService {
  async requestPayout(
    accountUUID: number,
    amount: number,
    accessToken: string,
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
    successMessage: string, // Nouveau paramètre pour le message de succès
  ): Promise<void> {
    const data: PayoutRequestDTO = {
      login: accountUUID,
      amountRequested: amount,
    };

    try {
      const response = await axiosInstance.post('/payout/request', data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response.status === 200) {
        // Utilisation du message de succès personnalisé
        onSuccess(
          successMessage ||
            'Votre demande de payout a été effectuée avec succès.',
        );
      }
    } catch (error) {
      handleErrorPayout(error, onError);
    }
  }
}

export default new PayoutService();
