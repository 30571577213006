import axiosInstance from '../../../../../../utils/axios';
import { DateAmountDTO } from './models';
import { CashEntryApiError } from './errors';

const getCashEntry = async (
  token: string,
  timeframe: string = 'TODAY',
): Promise<DateAmountDTO[]> => {
  try {
    const response = await axiosInstance.get<DateAmountDTO[]>(
      `/v2/admin/full-dashboard/chart/cash-entry`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          timeframe,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    throw new CashEntryApiError(error.message);
  }
};

export default getCashEntry;
