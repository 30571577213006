import { useState } from 'react';
import { postDiscordActivity } from '../services/post/postDiscordActivity';
import {
  PostDiscordActivityRequest,
  PostDiscordActivityResponse,
} from '../services/post/interfaces';
import { useAuth } from '../../../../context/AuthContext/AuthContext';

const convertToGMT = (date: string): string => {
  const dateObj = new Date(date);
  return dateObj.toISOString();
};

export const usePostDiscordActivity = () => {
  const [activityData, setActivityData] = useState<
    PostDiscordActivityResponse[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { tokens } = useAuth();

  const postActivity = async (
    users: PostDiscordActivityRequest['users'],
    startPeriod?: string,
    endPeriod?: string,
  ) => {
    try {
      setLoading(true);
      const activityRequest: PostDiscordActivityRequest = { users };

      // Conversion des dates au format GMT
      const startPeriodGMT = startPeriod
        ? convertToGMT(startPeriod)
        : undefined;
      const endPeriodGMT = endPeriod ? convertToGMT(endPeriod) : undefined;

      const response = await postDiscordActivity(
        activityRequest,
        tokens?.accessToken || '',
        startPeriodGMT,
        endPeriodGMT,
      );
      setActivityData(response);
    } catch (err) {
      setError((err as Error).message || 'Failed to post Discord activity');
    } finally {
      setLoading(false);
    }
  };

  return { activityData, loading, error, postActivity };
};
