import { createContext, useContext } from 'react';
import { CommissionSummaryDTO } from '../../services/get/commissions/models';

interface CommissionsContextProps {
  commissionsData: CommissionSummaryDTO | null;
  loading: boolean;
  error: string | null;
}

// Créer le contexte
export const CommissionsContext = createContext<
  CommissionsContextProps | undefined
>(undefined);

// Le hook pour utiliser le contexte
export const useCommissions = (): CommissionsContextProps => {
  const context = useContext(CommissionsContext);
  if (!context) {
    throw new Error('useCommissions must be used within a CommissionsProvider');
  }
  return context;
};
