import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Card,
  CardHeader,
  Divider,
  Button,
  Grid,
  Tooltip,
} from '@mui/material';
import { useUpdatePermissions } from './hooks/useUpdatePermissions';
import { useGetAvailablePermissions } from './hooks/useGetAvailablePermissions';
import { useGetUserPermissions } from './hooks/useGetPermissions';

interface PermissionsTransferListProps {
  uuid: string;
}

function not(a: readonly string[], b: readonly string[]): string[] {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]): string[] {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly string[], b: readonly string[]): string[] {
  return [...a, ...not(b, a)];
}

const PermissionsTransferList: React.FC<PermissionsTransferListProps> = ({
  uuid,
}) => {
  const [checked, setChecked] = useState<string[]>([]);

  // Récupération des permissions disponibles et accordées
  const {
    permissions: availablePermissions,
    loading: loadingAvailable,
    error: errorAvailable,
  } = useGetAvailablePermissions(uuid);
  const {
    permissions: grantedPermissions,
    loading: loadingGranted,
    error: errorGranted,
  } = useGetUserPermissions(uuid);

  const [left, setLeft] = useState<string[]>([]);
  const [right, setRight] = useState<string[]>([]);

  useEffect(() => {
    if (availablePermissions.length > 0) {
      setLeft(availablePermissions);
    }
  }, [availablePermissions]);

  useEffect(() => {
    if (grantedPermissions.length > 0) {
      setRight(grantedPermissions);
    }
  }, [grantedPermissions]);

  const { updatePermissions, loading: updating } = useUpdatePermissions();

  // Suppression des toasts dans cette fonction
  const handleUpdatePermissions = async () => {
    const newPermissions = {
      newPermissions: right,
    };

    await updatePermissions(uuid, newPermissions);
  };

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: string[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 400,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <Tooltip key={value} title={value}>
              <ListItem role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value} />
              </ListItem>
            </Tooltip>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const numberOfChecked = (items: string[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: string[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{customList('Available Permissions', left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList('Granted Permissions', right)}</Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdatePermissions}
        disabled={updating}
      >
        {updating ? 'Updating...' : 'Update Permissions'}
      </Button>
    </div>
  );
};

export default PermissionsTransferList;
