import axiosInstance from '../../../../../utils/axios';
import { SalesKPIResponse } from './models';
import { handleSalesApiError } from './errors';

const getTotalSalesKPI = async (token: string): Promise<SalesKPIResponse> => {
  try {
    const response = await axiosInstance.get<SalesKPIResponse>(
      `/v2/admin/full-dashboard/kpi/total`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error: any) {
    handleSalesApiError(error);
    throw error;
  }
};

export default getTotalSalesKPI;
