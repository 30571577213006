export class GetDiscordUsersError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'GetDiscordUsersError';
  }
}

export const handleGetDiscordUsersError = (error: any): void => {
  if (error.response && error.response.data && error.response.data.message) {
    throw new GetDiscordUsersError(error.response.data.message);
  } else {
    throw new GetDiscordUsersError(
      'An unexpected error occurred while fetching Discord users.',
    );
  }
};
