import React from 'react';

import { Skeleton, Stack } from '@mui/material';

const DataChartTabSkeleton = () => {
  return (
    <Stack direction={'column'} gap={2}>
      <Stack direction={'row'} gap={2}>
        <Skeleton variant="circular" width={25} height={25} />
        <Skeleton variant="circular" width={25} height={25} />
        <Skeleton variant="circular" width={25} height={25} />
      </Stack>
      <Skeleton
        variant="rounded"
        sx={{ width: '100%', height: 'auto', aspectRatio: '16/9' }}
      />
      <Stack direction={'row'} gap={0.5}>
        <Skeleton variant="rounded" height={40} width={120} />
        <Skeleton variant="rectangular" height={40} width={120} />
        <Skeleton variant="rectangular" height={40} width={120} />
        <Skeleton variant="rounded" height={40} width={120} />
      </Stack>
    </Stack>
  );
};

export default DataChartTabSkeleton;
