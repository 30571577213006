import { useState } from 'react';
import { submitSupportTicket } from '../services/post/ticket/submitTicket';
import { useAuth } from '../../../context/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';

export const useSupportForm = () => {
  const { tokens } = useAuth();
  const { t } = useTranslation('support');
  const [subject, setSubject] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [subjectError, setSubjectError] = useState<string | null>(null);
  const [contentError, setContentError] = useState<string | null>(null);

  const handleFileChange = (newFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileRemove = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleSubmit = async () => {
    setSubjectError(null);
    setContentError(null);

    if (!subject) {
      setSubjectError(t('ERRORS.THE_SUBJECT_IS_REQUIRED'));
    }
    if (!content) {
      setContentError(t('ERRORS.THE_CONTENT_IS_REQUIRED'));
    }

    if (!subject || !content) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      if (tokens?.accessToken) {
        await submitSupportTicket(
          tokens.accessToken,
          subject,
          content,
          files[0] || null,
        );
        setSnackbarMessage(t('SUCCESS.YOUR_REQUEST_HAS_BEEN_SENT'));
      } else {
        setError(t('ERRORS.UNAUTHENTICATED_USER'));
      }
    } catch (e) {
      setSnackbarMessage(
        t('ERRORS.AN_ERROR_HAS_OCCURED_PLEASE_TRY_AGAIN_LATER'),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    subject,
    setSubject,
    content,
    setContent,
    files,
    handleFileChange,
    handleFileRemove,
    handleSubmit,
    isLoading,
    error,
    snackbarMessage,
    setSnackbarMessage,
    subjectError,
    contentError,
  };
};
