export interface JWTPayload {
  sub: string;
  userId: string;
  role: string;
  sessionId: string;
  exp: number;
}

export const decodeJWT = (token: string): JWTPayload | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
};
