import { useState } from 'react';
import UpgradeAccountService from '../../../services/post/upgradeAccount/postUpgradeAccount';
import { Snackbar, Alert } from '@mui/material';

export const useUpgradeAccount = () => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>(
    'success',
  );

  const handleUpgrade = async (accountUUID: string) => {
    setLoading(true);

    const onSuccess = (message: string) => {
      setSnackbarMessage(message);
      setSnackbarType('success');
      setSnackbarOpen(true);
      setLoading(false);
    };

    const onError = (message: string) => {
      setSnackbarMessage(message);
      setSnackbarType('error');
      setSnackbarOpen(true);
      setLoading(false);
    };

    try {
      await UpgradeAccountService.upgradeAccount(
        accountUUID,
        onSuccess,
        onError,
      );
    } catch (error) {
      console.error('Failed to upgrade account', error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return {
    loading,
    handleUpgrade,
    snackbarOpen,
    snackbarMessage,
    snackbarType,
    handleSnackbarClose,
  };
};
