import React from 'react';
import { green } from '../../../../themes/colors/colors';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

interface SalesCardProps {
  title: string;
  value: string;
  date: string;
  tooltip?: string;
  variation: number | null;
  actions?: boolean;
  onPrevious?: () => void;
  onNext?: () => void;
  previousValue?: number;
}

const SalesCard: React.FC<SalesCardProps> = ({
  title,
  value,
  date,
  tooltip,
  variation,
  actions = true,
  onPrevious,
  onNext,
  previousValue,
}) => {
  const theme = useTheme();

  let variationIcon = null;
  let variationColor = theme.palette.text.primary;

  if (variation !== null) {
    if (variation < 0) {
      variationIcon = (
        <TrendingDownIcon
          fontSize="small"
          sx={{ color: theme.palette.primary.main }}
        />
      );
      variationColor = theme.palette.primary.main;
    } else if (variation > 0) {
      variationIcon = (
        <TrendingUpIcon fontSize="small" sx={{ color: green[300] }} />
      );
      variationColor = green[300];
    }
  }

  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '32px',
        }}
      >
        <Typography
          variant="h5"
          component={'h5'}
          fontWeight={'normal'}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          component={'h6'}
          fontWeight={'bold'}
          color={theme.palette.primary.main}
        >
          {parseFloat(value).toLocaleString('fr-FR')} €
        </Typography>
      </CardContent>

      {actions && (
        <CardActions
          sx={{
            justifyContent: 'center',
            paddingBottom: '16px',
          }}
        >
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            {onPrevious && (
              <IconButton onClick={onPrevious}>
                <KeyboardArrowLeftIcon fontSize="large" />
              </IconButton>
            )}

            <Stack direction={'column'}>
              <Stack
                direction={'row'}
                gap={1}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {variationIcon && variationIcon}
                <Tooltip
                  title={tooltip || ''}
                  placement="top"
                  enterTouchDelay={0}
                  leaveTouchDelay={1500}
                >
                  <Typography
                    variant="overline"
                    component={'p'}
                    sx={{ color: variationColor }}
                  >
                    {variation !== null
                      ? `${variation.toLocaleString('fr-FR')}%`
                      : 'N/A'}
                  </Typography>
                </Tooltip>
              </Stack>
              <Typography variant="button" component={'p'} textAlign={'center'}>
                {date}
              </Typography>
            </Stack>

            {onNext && (
              <IconButton onClick={onNext} disabled={previousValue === 0}>
                <KeyboardArrowRightIcon fontSize="large" />
              </IconButton>
            )}
          </Stack>
        </CardActions>
      )}
    </Card>
  );
};

export default SalesCard;
