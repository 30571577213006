import { User } from '../auth/types';
import { LoginResponse, JWTPayload } from './interfaces';
import axiosInstance from '../../utils/axios';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}

export const loginUser = async (
  email: string,
  password: string,
  remember: boolean,
): Promise<{ tokens: LoginResponse; user: JWTPayload }> => {
  const user: User = {
    email: email,
    password: password,
  };

  try {
    const response = await axiosInstance.post<LoginResponse>(
      `/users/login`,
      user,
    );

    const tokens = response.data;

    // Stocker les tokens dans localStorage ou sessionStorage selon "remember"
    if (remember) {
      localStorage.setItem('accessToken', tokens.accessToken);
      localStorage.setItem('refreshToken', tokens.refreshToken);
    } else {
      sessionStorage.setItem('accessToken', tokens.accessToken);
      sessionStorage.setItem('refreshToken', tokens.refreshToken);
    }

    const decoded = decodeJWT(tokens.accessToken);

    if (!decoded) {
      throw new Error('Failed to decode the JWT.');
    }

    return { tokens, user: decoded };
  } catch (error: any) {
    throw new Error(
      error.response?.data?.error || 'An error occurred during login.',
    );
  }
};

export const logout = () => {
  // Supprimer les tokens de localStorage et sessionStorage
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('refreshToken');
  return (window.location.href = '/login');
};
