import axiosInstance from '../../../../../utils/axios';
import { IScaleUpAccountService } from './interfaces';
import { handleErrorScaleUpAccount } from './errors';
import { ScaleUpAccountDTO } from './models';

class ScaleUpAccountService implements IScaleUpAccountService {
  async scaleUpAccount(
    accountUUID: string,
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
  ): Promise<void> {
    const data: ScaleUpAccountDTO = {
      accountUUID,
    };

    try {
      const response = await axiosInstance.post('/funded/scaleup', data);
      if (response.status === 200) {
        onSuccess('Scale up successfully.');
      }
    } catch (error) {
      handleErrorScaleUpAccount(error, onError);
      throw error;
    }
  }
}

export default new ScaleUpAccountService();
