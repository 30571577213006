import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useDiscordUsers } from '../hooks/useDiscordUsers';
import { usePostDiscordActivity } from '../hooks/usePostDiscordActivity';
import { DiscordUser } from '../services/get/interfaces';

const OverviewTab: React.FC = () => {
  const [selectedUsers, setSelectedUsers] = useState<DiscordUser[]>([]);
  const [startPeriod, setStartPeriod] = useState<string | undefined>(undefined);
  const [endPeriod, setEndPeriod] = useState<string | undefined>(undefined);
  const [dateError, setDateError] = useState<boolean>(false);
  const [userError, setUserError] = useState<boolean>(false);
  const { users, loading: usersLoading, error: usersError } = useDiscordUsers();
  const {
    activityData,
    loading: activityLoading,
    error: activityError,
    postActivity,
  } = usePostDiscordActivity();

  const formatDate = (date: string): string => {
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSearch = () => {
    if (selectedUsers.length === 0) {
      setUserError(true);
      return;
    }
    if ((startPeriod && !endPeriod) || (!startPeriod && endPeriod)) {
      setDateError(true);
      return;
    }
    setDateError(false);
    setUserError(false);

    const userRequests = selectedUsers.map((user) => ({
      id: user.id,
      displayName: user.displayName,
    }));
    postActivity(userRequests, startPeriod, endPeriod);
  };

  const getPeriodText = (): string => {
    if (!startPeriod && !endPeriod) {
      return 'Suivi sur le mois en cours';
    }
    const startDate = startPeriod ? formatDate(startPeriod) : '00/00/0000';
    const endDate = endPeriod ? formatDate(endPeriod) : '00/00/0000';
    return `Suivi sur la période du ${startDate} au ${endDate}`;
  };

  return (
    <>
      <Stack direction={'row'} gap={2} mb={6} mt={6}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 'fit-content',
          }}
        >
          <Autocomplete
            multiple
            options={[{ displayName: 'Tous', id: 'all' }, ...(users || [])]}
            getOptionLabel={(option) => option.displayName}
            value={selectedUsers}
            onChange={(event, newValue) => {
              if (newValue.some((user) => user.id === 'all')) {
                setSelectedUsers(users || []);
              } else {
                setSelectedUsers(newValue);
              }
            }}
            sx={{ width: selectedUsers.length ? 'fit-content' : 300 }}
            renderTags={(value, getTagProps) => {
              const displayUsers = value.slice(0, 5);
              const remainingCount = value.length - displayUsers.length;

              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {displayUsers.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.displayName}
                      {...getTagProps({ index })}
                      style={{ marginRight: 4, marginBottom: 4 }}
                    />
                  ))}
                  {remainingCount > 0 && (
                    <Chip
                      variant="outlined"
                      label={`+${remainingCount}`}
                      style={{ marginRight: 4, marginBottom: 4 }}
                    />
                  )}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Employés"
                error={userError && selectedUsers.length === 0}
                helperText={
                  userError && selectedUsers.length === 0
                    ? 'Veuillez sélectionner au moins un employé'
                    : ''
                }
              />
            )}
          />
        </Box>

        <TextField
          type="date"
          label="Début de Période"
          InputLabelProps={{
            shrink: true,
          }}
          value={startPeriod || ''}
          onChange={(e) => setStartPeriod(e.target.value)}
          error={dateError && (!startPeriod || !endPeriod)}
          helperText={
            dateError && (!startPeriod || !endPeriod)
              ? 'Veuillez remplir les deux champs de période'
              : ''
          }
        />

        <TextField
          type="date"
          label="Fin de Période"
          InputLabelProps={{
            shrink: true,
          }}
          value={endPeriod || ''}
          onChange={(e) => setEndPeriod(e.target.value)}
          error={dateError && (!startPeriod || !endPeriod)}
          helperText={
            dateError && (!startPeriod || !endPeriod)
              ? 'Veuillez remplir les deux champs de période'
              : ''
          }
        />

        <Button
          variant="contained"
          onClick={handleSearch}
          disabled={activityLoading}
        >
          {activityLoading ? 'Loading...' : 'Search'}
        </Button>
      </Stack>

      <Typography variant="h4" component={'h1'} gutterBottom>
        {getPeriodText()}
      </Typography>

      {activityError && <Typography color="error">{activityError}</Typography>}

      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom Prénom</TableCell>
              <TableCell align="left">
                Heures/jour (sans pauses et déjeuner)
              </TableCell>
              <TableCell align="left">Pause moyenne/jour</TableCell>
              <TableCell align="left">
                Total des heures sur la période
              </TableCell>
              <TableCell align="left">Heure moyenne d'arrivée</TableCell>
              <TableCell align="left">Heure moyenne de départ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityData.length === 0 && !activityLoading && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Aucune donnée disponible
                </TableCell>
              </TableRow>
            )}
            {activityData.map((activity, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row" align="left">
                  {activity.displayName}
                </TableCell>
                <TableCell align="left">
                  {activity.averageHoursWorkedPerDays.hours}h
                  {activity.averageHoursWorkedPerDays.minutes}
                </TableCell>
                <TableCell align="left">
                  {activity.averageHoursBreakPerDays.hours}h
                  {activity.averageHoursBreakPerDays.minutes}
                </TableCell>
                <TableCell align="left">
                  {activity.totalWorkingHours.hours}h
                  {activity.totalWorkingHours.minutes}
                </TableCell>
                <TableCell align="left">
                  {activity.averageArrivalTime}
                </TableCell>
                <TableCell align="left">{activity.averageDepartTime}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OverviewTab;
