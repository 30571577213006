import { useTheme, useMediaQuery, Theme } from '@mui/material';

interface Breakpoints {
  isXS: boolean;
  isSM: boolean;
  isMD: boolean;
  isLG: boolean;
  isXL: boolean;
  isBetweenXSAndLG: boolean;
  isBetweenXSAndSM: boolean;
}

const useBreakpoints = (): Breakpoints => {
  const theme = useTheme<Theme>();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  const isSM = useMediaQuery(theme.breakpoints.only('sm'));
  const isMD = useMediaQuery(theme.breakpoints.only('md'));
  const isLG = useMediaQuery(theme.breakpoints.only('lg'));
  const isXL = useMediaQuery(theme.breakpoints.only('xl'));
  const isBetweenXSAndLG = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const isBetweenXSAndSM = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  return { isXS, isSM, isMD, isLG, isXL, isBetweenXSAndLG, isBetweenXSAndSM };
};

export default useBreakpoints;
