import axiosInstance from '../../../../../../utils/axios';
import { DateCountDTO } from './models';
import { PassedChallengesApiError } from './errors';

const getPassedChallenges = async (
  token: string,
  timeframe: string = 'TODAY',
): Promise<DateCountDTO[]> => {
  try {
    const response = await axiosInstance.get<DateCountDTO[]>(
      `/v2/admin/full-dashboard/chart/challenges-passed`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          timeframe,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    throw new PassedChallengesApiError(error.message);
  }
};

export default getPassedChallenges;
