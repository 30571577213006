import { useEffect, useState } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import GetProfileService from '../services/get/profile/getProfile';
import SendDiscordTagService from '../services/post/postDiscordTag';
import { Profile } from '../services/get/profile/models';
import { useTranslation } from 'react-i18next';

export const useDiscordPage = () => {
  const { t } = useTranslation('discord');
  const { tokens, user } = useAuth();

  const [profile, setProfile] = useState<Profile | null>(null);
  const [discordTag, setDiscordTag] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true); // État de chargement

  useEffect(() => {
    const fetchProfile = async () => {
      if (tokens && user && tokens.accessToken && user.userId) {
        setLoading(true); // Début du chargement
        try {
          const fetchedProfile = await GetProfileService.getProfile(
            tokens.accessToken,
            user.userId,
          );
          setProfile(fetchedProfile);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false); // Fin du chargement
        }
      } else {
        setLoading(false); // Si aucune donnée, pas de chargement
      }
    };

    fetchProfile();
  }, [tokens?.accessToken, user?.userId]);

  const validateDiscordTag = (tag: string) => {
    const usernameRegex = /^(?=.{2,32}$)[a-zA-Z0-9]+(?:[_\.][a-zA-Z0-9]+)*$/;
    if (!tag) {
      setError(t('THIS_FIELD_IS_REQUIRED'));
      return false;
    }
    if (!usernameRegex.test(tag)) {
      setError(t('INVALID_USERNAME'));
      return false;
    }
    setError(null);
    return true;
  };

  const handleSubmit = async () => {
    if (validateDiscordTag(discordTag)) {
      if (tokens && user) {
        try {
          await SendDiscordTagService.sendDiscordTag(
            tokens.accessToken,
            user.userId,
            discordTag,
          );
          setOpenDialog(true);
        } catch (error) {
          setError(t('AN_ERROR_OCCURED_WHILE_LINKING_THE_DISCORD_TAG'));
        }
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscordTag(event.target.value);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const availableRoles = [
    {
      label: '📈 - Phase 1',
      available: profile?.hasPhase1,
      color: '#FFD700', // Exemple de couleur
    },
    {
      label: '🏅 - Phase 2',
      available: profile?.hasPhase2,
      color: '#00BFFF',
    },
    {
      label: '💸 - Funded',
      available: profile?.funded,
      color: '#32CD32',
    },
  ].filter((role) => role.available);

  return {
    profile,
    discordTag,
    error,
    openDialog,
    availableRoles,
    loading, // Retourner l'état de chargement
    handleChange,
    handleSubmit,
    handleDialogClose,
  };
};
