import { useCallback, useEffect, useState } from 'react';

import { logout } from '../../../services/auth/login';
import { accountInformations } from '../../../services/admin/userAbout/userAbout';
import {
  acceptKycRequest,
  downloadKycDocument,
  fetchKycDocuments,
  refuseKycRequest,
} from '../../../services/admin/kyc/kyc';
import { KycDocumentResponseContent } from '../../../services/admin/kyc/interface';

import { useAuth } from '../../../context/AuthContext/AuthContext';

import useDocumentTitle from '../../../hooks/useDocumentTitle';

import AdminTable from '../../../components/Admin/AdminTable/AdminTable';
import Pagination from '../../../components/Pagination/Pagination';
import Icon from '../../../components/userInterface/Icon/Icon';
import Loader from '../../../components/Loader/Loader';
import UserProfile from '../../../components/Admin/UserProfile/UserProfile';

import classes from './_kyc.module.scss';

const Kyc = () => {
  const { tokens, isTokensLoading } = useAuth();
  useDocumentTitle('KYC');

  const size = 10;

  const tableKycDocumentsData = [];
  const tableKycDocumentsColumns = [
    'ID',
    'Document Type',
    'Document',
    'Firstname',
    'Lastname',
    'Email',
    'UUID',
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userUuid, setUserUuid] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [kycDocuments, setKycDocuments] = useState<
    KycDocumentResponseContent[]
  >([]);
  const [kycDocumentsPage, setKycDocumentsPage] = useState<number>(0);
  const [totalKycDocumentsPages, setTotalKycDocumentsPages] =
    useState<number>(0);
  const [kycTotalOfElements, setKycTotalOfElements] = useState<number>(0);

  for (const document of kycDocuments) {
    tableKycDocumentsData.push({
      ID: document.id,
      'Document Type': document.documentType,
      Document: (
        <span
          className={classes.downloadButton}
          onClick={() =>
            downloadKycDocuments(
              document.id,
              `KYC_${document.id}_${document.fileName}`,
            )
          }
        >
          <Icon
            color="white"
            size={16}
            path="M13 10H18L12 16L6 10H11V3H13V10ZM4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19Z"
          />
          {document.fileName}
        </span>
      ),
      Firstname: document.firstname,
      Lastname: document.lastname,
      Email: document.email,
      UUID: document.userUUID,
    });
  }

  const loadKycDocuments = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchKycDocuments(
          tokens.accessToken,
          kycDocumentsPage,
          size,
        );
        setKycDocuments(response.content);
        setTotalKycDocumentsPages(response.totalPages);
        setKycTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, kycDocumentsPage, size]);

  useEffect(() => {
    if (!isTokensLoading) {
      loadKycDocuments();
    }
  }, [loadKycDocuments, isTokensLoading]);

  const userAccount = async (uuid: string) => {
    if (tokens && tokens.accessToken) {
      await accountInformations(tokens.accessToken, uuid);
      setUserUuid(uuid);
      openModal();
    }
  };

  const handleAcceptKycRequest = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await acceptKycRequest(tokens.accessToken, id);
      const response = await fetchKycDocuments(
        tokens.accessToken,
        kycDocumentsPage,
        size,
      );
      setKycDocuments(response.content);
      setTotalKycDocumentsPages(response.totalPages);
    } else {
      logout();
    }
  };

  const handleRefuseKycRequest = async (id: number, reason: string) => {
    if (tokens && tokens.accessToken) {
      await refuseKycRequest(tokens.accessToken, id, reason);
      const response = await fetchKycDocuments(
        tokens.accessToken,
        kycDocumentsPage,
        size,
      );
      setKycDocuments(response.content);
      setTotalKycDocumentsPages(response.totalPages);
    } else {
      logout();
    }
  };
  const downloadKycDocuments = useCallback(
    async (id: number, fileName: string) => {
      if (tokens && tokens.accessToken) {
        setIsLoading(true);
        try {
          downloadKycDocument(tokens.accessToken, id, fileName);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        logout();
      }
    },
    [tokens],
  );

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <UserProfile isOpen={isModalOpen} onClose={openModal} uuid={userUuid} />
      <h1 className={classes.title}>
        KYC Documents <span className={classes.chip}>{kycTotalOfElements}</span>
      </h1>
      {tableKycDocumentsData.length === 0 ? (
        'Aucun documents'
      ) : (
        <AdminTable
          data={tableKycDocumentsData}
          columns={tableKycDocumentsColumns}
          aboutButton={true}
          refuseButton={true}
          acceptButton={true}
          onAboutButtonClick={userAccount}
          onRefuseButtonClick={(id, reason) =>
            handleRefuseKycRequest(id, reason ?? 'RAISON NON SPECIFIEE')
          }
          onAcceptButtonClick={handleAcceptKycRequest}
        />
      )}
      <div className={classes.footer}>
        <Pagination
          total={totalKycDocumentsPages}
          perPage={size}
          currentPage={kycDocumentsPage}
          onPageChange={(page) => setKycDocumentsPage(page)}
        />
      </div>
    </>
  );
};
export default Kyc;
