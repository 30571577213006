import React, { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
  ListItemIcon,
  ListItemText,
  Snackbar,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import PostWithdrawalRequestService from '../../services/post/withdrawalRequest/postWithdrawalRequest';
import GetUserPatrimonyService from '../../services/get/patrimony/getUserPatrimony';
import classes from './withdrawModal.module.scss';
import { useTranslation } from 'react-i18next';

interface WithdrawModalProps {
  open: boolean;
  onClose: () => void;
  walletBalance: number;
  setWalletBalance: (balance: number) => void;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  open,
  onClose,
  walletBalance,
  setWalletBalance,
}) => {
  const { t } = useTranslation('area');
  const { tokens, user } = useAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [wallet, setWallet] = useState<string>('');
  const [cryptoType, setCryptoType] = useState<string>('');
  const [amount, setAmount] = useState<number | string>('');
  const [error, setError] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>(
    'success',
  );

  const resetForm = (): void => {
    setActiveStep(0);
    setPaymentMethod('');
    setWallet('');
    setCryptoType('');
    setAmount('');
    setError('');
    setTermsAccepted(false);
  };

  const handleModalClose = (): void => {
    resetForm();
    onClose();
  };

  const handleSnackbarClose = (): void => {
    setSnackbarOpen(false);
  };

  const handleNext = (): void => {
    if (activeStep === 0 && !paymentMethod) {
      setError(
        t(
          'MODAL_WITHDRAW.CONTENT.STEP_ONE.ERROR_YOU_HAVE_TO_SELECT_A_PAIEMENT_METHOD',
        ),
      );
      return;
    }
    if (activeStep === 1) {
      if (paymentMethod === 'BANK' && !termsAccepted) {
        setError(
          t(
            'MODAL_WITHDRAW.CONTENT.STEP_TWO_CREDIT_CARD.ERROR_YOU_HAVE_TO_ACCEPT_CONDITIONS',
          ),
        );
        return;
      }
      if (paymentMethod === 'CRYPTO' && (!wallet || !cryptoType)) {
        setError(
          t(
            'MODAL_WITHDRAW.CONTENT.STEP_TWO_CRYPTO_WALLET.ERROR_ALL_FIELDS_ARE_REQUIRED',
          ),
        );
        return;
      }
    }
    setError('');
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setError('');
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const updateWalletBalance = async () => {
    if (tokens && user) {
      try {
        const patrimonyData = await GetUserPatrimonyService.getUserPatrimony(
          user.userId,
          tokens.accessToken,
        );
        setWalletBalance(Number(patrimonyData.walletBalance));
      } catch (error) {
        console.error(
          'Erreur lors de la mise à jour du solde du portefeuille',
          error,
        );
      }
    }
  };

  const handleWithdraw = async (): Promise<void> => {
    const numericAmount = Number(amount);

    if (amount === '' || isNaN(numericAmount)) {
      setError(
        t(
          'MODAL_WITHDRAW.CONTENT.STEP_THREE.ERROR_PLEASE_ENTER_A_VALID_AMOUNT',
        ),
      );
      return;
    }

    if (numericAmount > walletBalance) {
      setError(
        t(
          'MODAL_WITHDRAW.CONTENT.STEP_THREE.ERROR_THE_AMOUNT_CANNOT_EXCEED_THE_WALLET_BALANCE',
        ),
      );
      return;
    }

    if (paymentMethod === 'BANK' && numericAmount > 8400) {
      setError(
        t(
          'MODAL_WITHDRAW.CONTENT.STEP_THREE.ERROR_THE_AMOUNT_CANNOT_EXCEED_8400_FOR_CREDIT_CARD_PAYMENTS',
        ),
      );
      return;
    }

    try {
      if (tokens && user) {
        await PostWithdrawalRequestService.submitWithdrawalRequest(
          user.userId,
          tokens.accessToken,
          numericAmount,
          paymentMethod === 'BANK' ? 'BANK' : 'CRYPTO',
          paymentMethod === 'CRYPTO' ? wallet : null,
          paymentMethod === 'CRYPTO' ? cryptoType : null,
          async (message: string) => {
            setSnackbarType('success');
            setSnackbarMessage(message);
            setSnackbarOpen(true);
            await updateWalletBalance();
            handleModalClose();
          },
          (message: string) => {
            setSnackbarType('error');
            setSnackbarMessage(message);
            setSnackbarOpen(true);
            handleModalClose();
          },
        );
      }
    } catch (error) {
      console.error(
        'Erreur lors de la soumission de la demande de retrait',
        error,
      );
      handleModalClose();
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <TextField
            select
            label={t('MODAL_WITHDRAW.CONTENT.STEP_ONE.LABEL_PAIEMENT_METHOD')}
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            fullWidth
            error={!!error && !paymentMethod}
            helperText={!!error && !paymentMethod ? error : ''}
          >
            <MenuItem value="BANK">
              {t('MODAL_WITHDRAW.CONTENT.STEP_ONE.OPTION_CREDIT_CARD')}
            </MenuItem>
            <MenuItem value="CRYPTO">
              {t('MODAL_WITHDRAW.CONTENT.STEP_ONE.OPTION_CRYPTO_WALLET')}
            </MenuItem>
          </TextField>
        );
      case 1:
        if (paymentMethod === 'BANK') {
          return (
            <Box>
              <Typography component="h2" variant="h5">
                {t(
                  'MODAL_WITHDRAW.CONTENT.STEP_TWO_CREDIT_CARD.TITLE_WARNING_WITHDRAWAL_INFORMATION',
                )}
              </Typography>
              <Typography component="p" variant="body1" mt={2} mb={3}>
                {t(
                  'MODAL_WITHDRAW.CONTENT.STEP_TWO_CREDIT_CARD.TEXT_WARNING_WITHDRAWAL_INFORMATION',
                )}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                }
                label={t(
                  'MODAL_WITHDRAW.CONTENT.STEP_TWO_CREDIT_CARD.CHECKBOX_READ_AND_APPROVED',
                )}
              />
              {!!error && !termsAccepted && (
                <Typography component="p" variant="body1" color="error">
                  {error}
                </Typography>
              )}
            </Box>
          );
        } else if (paymentMethod === 'CRYPTO') {
          return (
            <Box>
              <Typography component="h2" variant="h5">
                {t(
                  'MODAL_WITHDRAW.CONTENT.STEP_TWO_CRYPTO_WALLET.TITLE_WARNING_CRYPTO_WALLET_ADDRESS_INFORMATION',
                )}
              </Typography>
              <Typography component="p" variant="body1" mt={2} mb={3}>
                {t(
                  'MODAL_WITHDRAW.CONTENT.STEP_TWO_CRYPTO_WALLET.TEXT_WARNING_CRYPTO_WALLET_ADDRESS_INFORMATION',
                )}
              </Typography>

              <TextField
                label={t(
                  'MODAL_WITHDRAW.CONTENT.STEP_TWO_CRYPTO_WALLET.LABEL_CRYPTO_WALLET',
                )}
                value={wallet}
                onChange={(e) => setWallet(e.target.value)}
                fullWidth
                error={!!error && !wallet}
                helperText={!!error && !wallet ? error : ''}
                sx={{ marginBottom: '20px' }}
              />
              <TextField
                select
                label={t(
                  'MODAL_WITHDRAW.CONTENT.STEP_TWO_CRYPTO_WALLET.LABEL_CRYPTO_TYPE',
                )}
                value={cryptoType}
                onChange={(e) => setCryptoType(e.target.value)}
                fullWidth
                error={!!error && !cryptoType}
                helperText={!!error && !cryptoType ? error : ''}
              >
                <MenuItem
                  value="USDT TRC-20"
                  sx={{ display: 'flex', alignItems: 'center', padding: 1 }}
                >
                  <img
                    src="https://i.postimg.cc/wqRKh8wB/Tether-USDT.png"
                    alt="USDT TRC-20"
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 8,
                      verticalAlign: 'middle',
                    }}
                  />
                  <span>USDT TRC-20</span>
                </MenuItem>

                <MenuItem
                  value="USDT ERC-20"
                  sx={{ display: 'flex', alignItems: 'center', padding: 1 }}
                >
                  <img
                    src="https://i.postimg.cc/bwnYCDTZ/image-removebg-preview.png"
                    alt="USDT ERC-20"
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 8,
                      verticalAlign: 'middle',
                    }}
                  />
                  <span>USDT ERC-20</span>
                </MenuItem>
              </TextField>
            </Box>
          );
        }
        return null;
      case 2:
        return (
          <Box>
            {paymentMethod === 'BANK' && (
              <Typography
                component="p"
                variant="body1"
                color="textPrimary"
                mb={1}
              >
                {t('MODAL_WITHDRAW.CONTENT.STEP_THREE.TEXT_MAXIMUM')} 8400
              </Typography>
            )}
            <Typography
              component="p"
              variant="body1"
              color="textPrimary"
              mb={3}
            >
              {t('MODAL_WITHDRAW.CONTENT.STEP_THREE.TEXT_WALLET')}{' '}
              {walletBalance}
            </Typography>
            <TextField
              label={t('MODAL_WITHDRAW.CONTENT.STEP_THREE.LABEL_AMOUNT')}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
              type="number"
              inputProps={{
                max: paymentMethod === 'BANK' ? 8400 : undefined,
              }}
              error={
                !!error &&
                (amount === '' ||
                  isNaN(Number(amount)) ||
                  Number(amount) > walletBalance ||
                  (paymentMethod === 'BANK' && Number(amount) > 8400))
              }
              helperText={
                !!error &&
                (amount === '' ||
                  isNaN(Number(amount)) ||
                  Number(amount) > walletBalance ||
                  (paymentMethod === 'BANK' && Number(amount) > 8400))
                  ? error
                  : ''
              }
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleModalClose}>
        <Paper
          elevation={0}
          className={classes.modal}
          sx={{
            width: isSmallScreen ? '95%' : '35%',
            minWidth: isSmallScreen ? '95%' : '500px',
            padding: isSmallScreen ? '16px' : '24px',
          }}
        >
          <Stepper
            activeStep={activeStep}
            orientation={isSmallScreen ? 'vertical' : 'horizontal'}
          >
            <Step key={1}>
              <StepLabel>
                {t('MODAL_WITHDRAW.TITLE_STEPS.STEP_ONE_PAIEMENT_METHOD')}
              </StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>
                {t('MODAL_WITHDRAW.TITLE_STEPS.STEP_TWO_INFORMATIONS')}
              </StepLabel>
            </Step>
            <Step key={3}>
              <StepLabel>
                {t('MODAL_WITHDRAW.TITLE_STEPS.STEP_THREE_AMOUNT')}
              </StepLabel>
            </Step>
          </Stepper>
          <Box sx={{ mt: 6, mb: 6 }}>{renderStepContent(activeStep)}</Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column-reverse' : 'row',
              justifyContent: 'space-between',
              gap: isSmallScreen ? '16px' : '0px',
            }}
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="contained"
            >
              {t('MODAL_WITHDRAW.BUTTONS.PREVIOUS')}
            </Button>
            {activeStep === 2 ? (
              <Button onClick={handleWithdraw} variant="contained">
                {t('MODAL_WITHDRAW.BUTTONS.WITHDRAW')}
              </Button>
            ) : (
              <Button onClick={handleNext} variant="contained">
                {t('MODAL_WITHDRAW.BUTTONS.NEXT')}
              </Button>
            )}
          </Box>
        </Paper>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default WithdrawModal;
