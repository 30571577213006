import { forwardRef } from 'react';

import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';

import classes from './_input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, disabled, ...props }, ref) => {
    const { isDarkMode } = useThemeContext();

    return (
      <input
        type={type}
        className={`${classes.input} ${className || ''} ${
          isDarkMode ? classes.dark : classes.light
        } `}
        disabled={disabled}
        ref={ref}
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

export default Input;
