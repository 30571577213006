import React from 'react';

import useBreakpoints from '../../../../hooks/useBreakpoints';

import { Card, Skeleton, Stack } from '@mui/material';

const WalletCardSkeleton = () => {
  const { isBetweenXSAndSM } = useBreakpoints();

  return (
    <Card
      elevation={0}
      sx={{
        width: isBetweenXSAndSM ? '100%' : '50%',
        padding: '16px',
      }}
    >
      <Skeleton variant="rectangular" width={200} height={30} />
      <Stack direction={'row'} spacing={2} mt={2}>
        <Skeleton width={'50%'} height={80} />
        <Skeleton width={'50%'} height={80} />
      </Stack>
      <Skeleton width={'100%'} height={60} />
    </Card>
  );
};

export default WalletCardSkeleton;
