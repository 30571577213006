import { useState } from 'react';
import { sendKycDocuments } from '../services/post/kyc/sendKYC';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';

export const useKYCUpload = () => {
  const { t } = useTranslation('profile');
  const { tokens } = useAuth();
  const [documents, setDocuments] = useState<{
    recto: File | null;
    verso: File | null;
    address: File | null;
  }>({
    recto: null,
    verso: null,
    address: null,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
  });

  const handleFileUpload = (file: File, documentType: string) => {
    switch (documentType) {
      case 'IDENTITY_PROOF_RECTO':
        setDocuments((prev) => ({ ...prev, recto: file }));
        break;
      case 'IDENTITY_PROOF_VERSO':
        setDocuments((prev) => ({ ...prev, verso: file }));
        break;
      case 'ADDRESS_PROOF':
        setDocuments((prev) => ({ ...prev, address: file }));
        break;
      default:
        break;
    }
  };

  // Envoyer tous les documents à l'API
  const handleSendAllDocuments = async () => {
    if (!tokens || !tokens.accessToken) return;

    const uploadPromises = [
      documents.recto &&
        sendKycDocuments(
          tokens.accessToken,
          documents.recto,
          'IDENTITY_PROOF_RECTO',
        ),
      documents.verso &&
        sendKycDocuments(
          tokens.accessToken,
          documents.verso,
          'IDENTITY_PROOF_VERSO',
        ),
      documents.address &&
        sendKycDocuments(
          tokens.accessToken,
          documents.address,
          'ADDRESS_PROOF',
        ),
    ];

    try {
      await Promise.all(uploadPromises.filter(Boolean));
      setSnackbar({
        open: true,
        message: t('KYC.SUCCESS_ALL_DOCUMENTS_UPLOADED_SUCCESSFULLY'),
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: t('KYC.ERROR_UPLOADING_DOCUMENTS_PLEASE_TRY_AGAIN'),
      });
    }
  };

  const isButtonDisabled = !(
    documents.recto &&
    documents.verso &&
    documents.address
  );

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '' });
  };

  return {
    documents,
    handleFileUpload,
    handleSendAllDocuments,
    isButtonDisabled,
    snackbarOpen: snackbar.open,
    snackbarMessage: snackbar.message,
    handleCloseSnackbar,
  };
};
