import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import PieChart from '../PieChart/PieChart';
import { usePhaseData } from '../../hooks/pieChart/usePhaseData';
import PieChartSkeleton from '../PieChart/PieChartSkeleton';
import { useTranslation } from 'react-i18next';

interface PhaseTabProps {
  phase: 'phase1' | 'phase2'; // Le paramètre phase pour déterminer Phase 1 ou 2
}

const PhaseTab: React.FC<PhaseTabProps> = ({ phase }) => {
  const { t } = useTranslation('dashboard');

  const { loading, error, data } = usePhaseData(phase, true); // Appel du hook avec phase dynamique

  if (loading) {
    return <PieChartSkeleton checkboxs={3} />;
  } else if (!data) {
    return null;
  }

  if (error) {
    return <Typography>Error loading phase data: {error.message}</Typography>;
  }

  // Données brutes pour l'affichage
  const labels = [
    t('PIE_CHART.LABELS.PHASES.PASSED'),
    t('PIE_CHART.LABELS.PHASES.PENDING'),
    t('PIE_CHART.LABELS.PHASES.LOST'),
  ];
  const chartData = [data.totalPassed, data.totalPending, data.totalLost];
  const backgroundColors = [
    'rgba(31, 204, 146, 1)',
    'rgba(249, 168, 37, 1)',
    'rgba(231, 71, 80, 1)',
  ];

  return (
    <PieChart
      labels={labels}
      data={chartData}
      backgroundColors={backgroundColors}
    />
  );
};

export default PhaseTab;
