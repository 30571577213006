import { createContext, useContext } from 'react';
import { ChallengeAccountBase } from '../../services/get/accountBase/models';

interface AccountBaseDataContextProps {
  accountBaseData: ChallengeAccountBase | null;
  loading: boolean;
  error: string | null;
  fetchAccountBaseData: (selectedAccountLogin: string) => Promise<void>;
}

export const AccountBaseDataContext = createContext<
  AccountBaseDataContextProps | undefined
>(undefined);

export const useAccountBaseData = (): AccountBaseDataContextProps => {
  const context = useContext(AccountBaseDataContext);
  if (!context) {
    throw new Error(
      'useAccountBaseData must be used within an AccountBaseDataProvider',
    );
  }
  return context;
};
