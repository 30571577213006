import axiosInstance from '../../../../../../utils/axios';
import { AccountOverviewDTO } from './models';
import { handleOverviewApiError } from './errors';

const getOverviewData = async (token: string): Promise<AccountOverviewDTO> => {
  try {
    const response = await axiosInstance.get<AccountOverviewDTO>(
      '/v2/admin/full-dashboard/pie/overview',
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error: any) {
    handleOverviewApiError(error);
    throw error;
  }
};

export default getOverviewData;
