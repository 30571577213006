import React from 'react';

import { Card, Paper, Skeleton, Stack } from '@mui/material';

import classes from './chart.module.scss';

const ChartSkeleton = () => {
  return (
    <Paper elevation={1} className={classes.chart}>
      <Stack direction={'row'} gap={2} mb={4}>
        <Skeleton variant="circular" width={25} height={25} />
        <Skeleton variant="circular" width={25} height={25} />
        <Skeleton variant="circular" width={25} height={25} />
      </Stack>

      <Skeleton variant="rounded" height={900} />

      <Stack direction={'row'} gap={0.5} mt={4}>
        <Skeleton variant="rounded" width={120} height={40} />
        <Skeleton variant="rounded" width={120} height={40} />
        <Skeleton variant="rounded" width={120} height={40} />
        <Skeleton variant="rounded" width={120} height={40} />
      </Stack>
    </Paper>
  );
};

export default ChartSkeleton;
