import { toast } from 'react-toastify';
import axiosInstance from '../../../utils/axios';
import { PromotionCodesResponse } from './interface';
import { PromotionCode } from './type';

export const fetchPromotionCodes = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<PromotionCodesResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<PromotionCodesResponse>(
      `/promocodes?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );

    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des promotion codes');
  }
};

export const deletePromotionCode = async (accessToken: string, id: any) => {
  try {
    const response = await axiosInstance.delete(`/promocodes/code/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });
    if (response.status === 204) {
      toast.success('Code de promotion supprimé.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error("Le code de promotion n'a pas pu être supprimé.", {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const createPromotionCode = async (
  accessToken: string,
  promotionCode: PromotionCode,
) => {
  try {
    const response = await axiosInstance.post<PromotionCode>(
      `/promocodes`,
      promotionCode,
      {
        headers: { Authorization: accessToken },
      },
    );

    if (response.status === 201) {
      toast.success('Promotion code successfully created.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error('An error occured creating promotion code.', {
      progressStyle: { backgroundColor: 'red' },
    });
    throw new Error('("An error occured creating promotion code.');
  }
};

export const editPromotionCode = async (
  accessToken: string,
  id: string,
  promotionCode: PromotionCode,
) => {
  try {
    const response = await axiosInstance.put(
      `/promocodes/update/${id}`,
      promotionCode,
      {
        headers: { Authorization: accessToken },
      },
    );

    if (response.status === 404) {
      toast.error('Promotion code not found', {
        progressStyle: { backgroundColor: 'red' },
      });
    }

    if (response.status === 400) {
      toast.error('An error occured editing promotion code.', {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  } catch (error) {
    throw new Error('("An error occured editing promotion code.');
  }
};
