import axiosInstance from '../../../../../../utils/axios';
import { IGetCertificateService } from './interfaces';
import { CertificatesResponse } from './models';
import { handleError } from './errors';

class GetCertificateService implements IGetCertificateService {
  async getCertificates(
    token: string,
    anonymous: boolean,
  ): Promise<CertificatesResponse> {
    try {
      const response = await axiosInstance.get<CertificatesResponse>(
        `/certificate/all?anonymous=${anonymous}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      throw new Error(errorMessage);
    }
  }
}

export default new GetCertificateService();
