import axiosInstance from '../../../../../utils/axios';
import { IPostWithdrawalRequestService } from './interfaces';
import { WithdrawalRequestDTO } from './models';
import { handleErrorWithdrawalRequest } from './errors';

class PostWithdrawalRequestService implements IPostWithdrawalRequestService {
  async submitWithdrawalRequest(
    userId: string,
    accessToken: string,
    amount: number,
    withdrawalType: string,
    cryptoWalletAddress: string | null,
    network: string | null,
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
  ): Promise<WithdrawalRequestDTO> {
    const data: WithdrawalRequestDTO = {
      userId: userId,
      amount: amount,
      withdrawalType: withdrawalType,
      cryptoWalletAddress:
        withdrawalType === 'CRYPTO' && cryptoWalletAddress
          ? cryptoWalletAddress
          : undefined,
      network: withdrawalType === 'CRYPTO' && network ? network : undefined,
    };

    try {
      const response = await axiosInstance.post<WithdrawalRequestDTO>(
        '/withdraw/request',
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.status === 200) {
        onSuccess('Votre demande a été effectuée.');
      }
      return response.data;
    } catch (error) {
      handleErrorWithdrawalRequest(error, onError);
      throw error;
    }
  }
}

export default new PostWithdrawalRequestService();
