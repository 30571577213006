import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';
import classes from './_icon.module.scss';

interface IconProps {
  color: string;
  fill?: string;
  size: number;
  path: string;
  onClick?: () => void;
}

const Icon: React.FC<IconProps> = ({ fill, color, size, path, onClick }) => {
  const { isDarkMode } = useThemeContext();

  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      className={`${classes[color]} ${
        isDarkMode ? classes.dark : classes.light
      }`}
    >
      <path d={path}></path>
    </svg>
  );
};
export default Icon;
