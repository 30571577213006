import axiosInstance from '../../../../../utils/axios';
import { IDefineWalletService } from './interfaces';
import { handleErrorDefineWallet } from './errors';

class DefineWalletService implements IDefineWalletService {
  async defineWallet(
    address: string,
    network: string,
    userId: string,
    accessToken: string,
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
  ): Promise<void> {
    try {
      const response = await axiosInstance.put(
        '/affiliations/define-wallet',
        {
          address: address,
          network: network,
          userUuid: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === 200) {
        onSuccess('Your information has been correctly set.');
      }
    } catch (error) {
      handleErrorDefineWallet(error, onError);
    }
  }
}

export default new DefineWalletService();
