import { toast } from 'react-toastify';
import axiosInstance from '../../../utils/axios';

import { ChallengesResponse } from './interface';

export const fetchChallenges = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<ChallengesResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    const response = await axiosInstance.get<ChallengesResponse>(
      `/challenges?${params.toString()}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des challenges');
  }
};

export const exportChallengesToXLS = async (
  accessToken: string,
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<void> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

    params.append('export', 'true');

    const response = await axiosInstance.get('/challenges', {
      params: params,
      headers: { Authorization: accessToken },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Erreur lors de l'exportation des challenges", error);
  }
};

export const reactiveChallenge = async (accessToken: string, login: string) => {
  try {
    const response = await axiosInstance.post(
      `/challenges/reactivate?login=${login}`,
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );
    if (response.status === 200) {
      toast.success('Account successfully reactivated', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error('Account not reactivated!', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};
