import {
  GetDiscordUserActivityParams,
  GetDiscordUserActivityResponse,
} from './interfaces';
import { handleGetDiscordUserActivityError } from './errors';
import { DiscordUserActivity, PaginationInfo } from './models';

export async function getDiscordUserActivity(
  params: GetDiscordUserActivityParams,
  accessToken: string
): Promise<GetDiscordUserActivityResponse> {
  try {
    const { userId, startPeriod, endPeriod } = params;

    // Utiliser URLSearchParams pour gérer les paramètres d'URL
    const queryParams = new URLSearchParams();
    queryParams.append('userId', userId);

    // Ajouter les paramètres startPeriod et endPeriod uniquement s'ils sont définis
    if (startPeriod) queryParams.append('startPeriod', startPeriod);
    if (endPeriod) queryParams.append('endPeriod', endPeriod);

    const response = await fetch(
      `https://preprod.raisemyfunds.com:4000/api/discord/user-activity?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken || ''}`
        },
      },
    );

    // Vérification du succès de la réponse
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.message || 'Failed to fetch Discord user activity',
      );
    }

    if(response.status === 204){
      return {
        document: {
          total: 0,
          currentPage: 0,
          maxPage: 0,
          limit: 0
        },
        result: []
      };
    }

    // Récupérer et traiter les données de la réponse JSON
    const data = await response.json();

    // Extraction des informations de pagination
    const document: PaginationInfo = {
      total: data.document.total,
      currentPage: data.document.currentPage,
      maxPage: data.document.maxPage,
      limit: data.document.limit,
    };

    // Transformation des données d'activités
    const activities: DiscordUserActivity[] = data.result.map(
      (activity: any) => ({
        day: activity.day,
        arrivalAt: activity.arrivalAt,
        departAt: activity.departAt,
        lunchBreakStartAt: activity.lunchBreakStartAt,
        lunchBreakEndAt: activity.lunchBreakEndAt,
        totalWorkTime: activity.totalWorkTime,
        totalBreakTime: activity.totalBreakTime,
        phoneUsagePeriods: activity.phoneUsagePeriods || [], // Utilisation du tableau de périodes de téléphone
      }),
    );

    // Retourner l'objet qui correspond à l'interface GetDiscordUserActivityResponse
    return { document, result: activities };
  } catch (error) {
    handleGetDiscordUserActivityError(error);
    throw error;
  }
}
